import { OrderDetailContents } from '@kinderlabs-pos/feature/pos/orders';
import {
	KitchenOrderInfoType,
	KitchenOrderMethodType,
	kitchenOrderMethodTypeLabel,
} from '@kinderlabs-pos/shared-data-type';
import { KitchenOrderQueryState, OrderQueryState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { FullSizeCircularProgress, useConfirm } from '@kinderlabs-pos/ui-components';
import { DialogContent, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Suspense } from 'react';
import { CallButton } from '../PosHeader/PosSideDrawer/buttons/CallButton';
import { CheckDetailButton } from '../PosHeader/PosSideDrawer/buttons/CheckDetailButton';
import { CompleteButton } from '../PosHeader/PosSideDrawer/buttons/CompleteButton';
import { DeleteButton } from '../PosHeader/PosSideDrawer/buttons/DeleteButton';
import { ReplayButton } from '../PosHeader/PosSideDrawer/buttons/ReplayButton';
import { RunButton } from '../PosHeader/PosSideDrawer/buttons/RunButton';

export interface IKitchenOrderActionButtonsProps {
	kitchOrder: KitchenOrderInfoType;
	closePannel?: () => void;
	withCallButton?: boolean;
}
export const KitchenOrderActionButtons: React.FC<IKitchenOrderActionButtonsProps> = ({
	kitchOrder,
	closePannel,
	withCallButton = false,
}) => {
	const confirm = useConfirm();
	const updateStatus = KitchenOrderQueryState.updateKitchenOrderStatus();
	const handleSwitchStatus = (targetStatusMethod: KitchenOrderMethodType) => {
		confirm(
			`해당 상품을 ${kitchenOrderMethodTypeLabel[targetStatusMethod]} 상태로 변경하시겠습니까?`,
			() =>
				updateStatus.mutate(
					{
						kitchenOrderId: [kitchOrder.id],
						kitchenOrderLineIds: undefined,
						targetStatus: targetStatusMethod,
					},
					{
						onSuccess: () => {},
					}
				)
		);
	};

	const updateKitchenOrderCall = KitchenOrderQueryState.updateKitchenOrderCall();
	const callKitchenOrder = async () => {
		await updateKitchenOrderCall.mutateAsync(
			{ kitchenOrderId: kitchOrder.id },
			{
				onSuccess: () => {},
			}
		);
	};

	const { isCookBtnAvailable, isCancelBtnAvailable, isCompleteBtnAvailable, isReCookBtnAvailable } =
		getButtonDisabledInfo(kitchOrder.status);

	return (
		<Stack
			direction={'row'}
			justifyContent={'center'}
			spacing={2}>
			<RunButton
				disabled={isCookBtnAvailable}
				onClick={() => handleSwitchStatus('cook')}
				label={'조리 시작'}
			/>
			<DeleteButton
				label={'조리 취소'}
				disabled={isCancelBtnAvailable}
				onClick={() => handleSwitchStatus('cancel')}
			/>
			<CompleteButton
				disabled={isCompleteBtnAvailable}
				onClick={() => handleSwitchStatus('complete')}
			/>
			<ReplayButton
				disabled={isReCookBtnAvailable}
				onClick={() => handleSwitchStatus('cook')}
				label={'재조리'}
			/>
			<WithDialog
				dialog={(open, closeDialog) => (
					<OriginOrderDetailDialog
						open={open}
						closeDialog={closeDialog}
						orderId={kitchOrder.orderId}
					/>
				)}>
				{(openDialog) => (
					<CheckDetailButton
						disabled={false}
						onClick={openDialog}
					/>
				)}
			</WithDialog>
			{withCallButton && (
				<CallButton
					callCount={kitchOrder.kitchenOrderCalls.length}
					onClick={callKitchenOrder}
				/>
			)}
		</Stack>
	);
};

interface IOriginOrderDetailDialogProps {
	orderId: string;
	open: boolean;
	closeDialog: () => void;
}
const OriginOrderDetailDialog: React.FC<IOriginOrderDetailDialogProps> = ({
	orderId,
	open,
	closeDialog,
}) => {
	return (
		<WithDialog.XlDialog
			scroll='paper'
			open={open}
			closeDialogAction='닫기'
			closeDialog={closeDialog}>
			<DialogContent sx={{ minWidth: 480 }}>
				<Suspense fallback={<FullSizeCircularProgress />}>
					<OrderDetailContentsWrapper orderId={orderId} />
				</Suspense>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};

const OrderDetailContentsWrapper = ({ orderId }: { orderId: string }) => {
	const { data } = useQuery({
		...OrderQueryState.keys.detailByOrderId(orderId),
		refetchOnWindowFocus: true,
	});

	if (!data) return null;
	return <OrderDetailContents order={data} />;
};

const getButtonDisabledInfo = (
	orderStatus: KitchenOrderInfoType['status']
): {
	isCookBtnAvailable: boolean;
	isCancelBtnAvailable: boolean;
	isCompleteBtnAvailable: boolean;
	isReCookBtnAvailable: boolean;
} => {
	return {
		isCookBtnAvailable: orderStatus !== 'ORDERED',
		isCancelBtnAvailable: orderStatus !== 'ORDERED' && orderStatus !== 'COOKING',
		isCompleteBtnAvailable: orderStatus !== 'COOKING',
		isReCookBtnAvailable: orderStatus !== 'COMPLETED' && orderStatus !== 'CANCELED',
	};
};
