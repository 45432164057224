import {
	CreditCardOutlined,
	DollarCircleOutlined,
	MobileOutlined,
	SelectOutlined,
} from '@ant-design/icons';
import { OrderType, PaymentMethodTypeLabel } from '@kinderlabs-pos/shared-data-type';
import { canceledStyleUtils, numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
	Box,
	Button,
	Card,
	CardActionArea,
	CardContent,
	Divider,
	InputLabel,
	List,
	ListItem,
	ListItemButton,
	OutlinedInput,
	Stack,
	Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import React, { ReactNode, useEffect, useState } from 'react';
import { PaymentDetailDialog } from '../../dialogs/PaymentDetailDialog';
import { CardPayDialog } from './CardPayDialog';
import { CashPayDialog } from './CashPayDialog';
import { EasyPayDialog } from './EasyPayDialog';
import { OtherPayDialog } from './OtherPayDialog';
import { PointUsageDialog } from './PointDiscountCoupon/PointUsageDialog';
import { SplitPayDialog } from './SplitPayDialog';
import { useHandleSubmitPayment } from './useHandleSubmitPayment';

export interface IPaymentContentsProps {
	mode: OrderType['type'];
}

export const PaymentContents: React.FC<IPaymentContentsProps> = ({ mode }) => {
	const { storeId, deviceId: posId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const { policy } = useAtomValue(StoreInfoState.curStoreInfo);
	const { totalToPay } = useAtomValue(OrderState.aggregate.cart);
	const { remainingPay } = useAtomValue(OrderState.aggregate.payments);
	const [결제할금액, set결제할금액] = useState(remainingPay);
	const orderInfo = useAtomValue(OrderState.value);
	const { payments, id: orderId, cart, status: orderStatus } = orderInfo;

	const { handleSubmitPayment, errorMessage, isSubmitting } = useHandleSubmitPayment({
		orderId,
		mode,
	});

	const [isAlreadyCompleted, setIsAlreadyCompleted] = useState(false);
	const alreadyOrderCompleted = isAlreadyCompleted || orderStatus === 'COMPLETED';

	useEffect(() => {
		set결제할금액(remainingPay);

		// 이미 주문 완료였다면 중복 결제완료 방지.
		if (alreadyOrderCompleted) return;

		// 결제할금액이 0이 된순간 진행
		if (remainingPay === 0) {
			handleSubmitPayment();
			setIsAlreadyCompleted(true);
		}
	}, [remainingPay, alreadyOrderCompleted]);

	return (
		<Stack
			flex={1}
			spacing={2}
			height={'100%'}
			overflow={'auto'}>
			<List sx={{ overflow: 'auto' }}>
				<ListItem>
					<Typography
						variant='h1'
						sx={{ flex: 1 }}>
						상품 총액
					</Typography>
					<Typography
						variant='h1'
						sx={{ textAlign: 'right', fontSize: 20 }}>
						{`${numberWithCommasAnd원(totalToPay)}`}
					</Typography>
				</ListItem>
				<Divider />
				{payments.map((payment, idx) => (
					<WithDialog
						key={idx}
						dialog={(open, closeDialog) => (
							<PaymentDetailDialog
								open={open}
								closeDialog={closeDialog}
								payment={payment}
								withRefunds
							/>
						)}>
						{(openDialog) => (
							<ListItemButton onClick={openDialog}>
								<Stack
									direction={'row'}
									sx={{ flex: 1 }}
									alignItems={'center'}
									spacing={1}>
									<Button
										disabled={!!payment.refunded}
										size={'small'}
										variant={'outlined'}>
										{PaymentMethodTypeLabel[payment.method]}
									</Button>
									{/* <ProfileOutlined style={{ fontSize: 18 }} /> */}
									<Typography
										sx={{ ...canceledStyleUtils(!!payment.refunded) }}
										variant='h4'>
										<span style={{ marginRight: 8 }}>분할 결제</span>
										<SelectOutlined style={{ fontSize: 18 }} />
									</Typography>
								</Stack>
								<Typography
									variant='h4'
									sx={{ textAlign: 'right', flex: 1, ...canceledStyleUtils(!!payment.refunded) }}>
									{`${numberWithCommasAnd원(payment.amount)}`}
								</Typography>
							</ListItemButton>
						)}
					</WithDialog>
				))}
				{remainingPay !== totalToPay && (
					<ListItem>
						<Typography
							variant='h1'
							sx={{ flex: 1 }}>
							남은 결제 금액
						</Typography>
						<Typography
							variant='h1'
							sx={{ textAlign: 'right', fontSize: 20 }}>
							{`${numberWithCommasAnd원(remainingPay)}`}
						</Typography>
					</ListItem>
				)}
			</List>
			{/* 공간 메우기 */}
			<Box flex={1} />
			<Stack>
				{isSubmitting && (
					<Typography color='primary'>주문정보 전송중... 잠시만 기다려주세요.</Typography>
				)}
				{errorMessage && <Typography color='error'>{`[주문 실패] ${errorMessage}`}</Typography>}
			</Stack>
			<Stack>
				<InputLabel>결제할 금액</InputLabel>
				<Stack
					direction={'row'}
					spacing={1}>
					<OutlinedInput
						readOnly
						size={'small'}
						sx={(theme) => ({
							flex: 1,
							textAlign: 'right',
							fontSize: theme.typography.h1.fontSize,
							py: 0,
						})}
						value={numberWithCommasAnd원(결제할금액)}
					/>
					<WithDialog
						dialog={(open, closeDialog) => (
							<SplitPayDialog
								결제할금액={결제할금액}
								set결제할금액={set결제할금액}
								remainingPay={remainingPay}
								open={open}
								closeDialog={closeDialog}
							/>
						)}>
						{(openDialog) => (
							<Button
								variant={'outlined'}
								onClick={openDialog}>
								분할결제
							</Button>
						)}
					</WithDialog>
					<WithDialog
						dialog={(open, closeDialog) => (
							<PointUsageDialog
								open={open}
								closeDialog={closeDialog}
								mode={mode}
								storeId={storeId}
								posId={posId}
								handleSubmitPayment={handleSubmitPayment}
							/>
						)}>
						{(openDialog) => (
							<Button
								variant={'outlined'}
								disabled={remainingPay === 0}
								onClick={openDialog}>
								포인트 사용
							</Button>
						)}
					</WithDialog>
				</Stack>
			</Stack>
			<Stack
				width={'100%'}
				direction={'row'}
				spacing={2}
				p={'1px'}>
				<Card sx={{ flex: 1, height: 100 }}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CardPayDialog
								storeId={storeId}
								posId={posId}
								orderId={orderId}
								isVirtualCardDefaultMethod={policy.isVirtualCardDefaultMethod}
								handleSubmitPayment={handleSubmitPayment}
								결제할금액={결제할금액}
								open={open}
								closeDialog={closeDialog}
							/>
						)}>
						{(openDialog) => (
							<PaymentCardButton
								onClick={openDialog}
								disabled={remainingPay === 0}
								label={<Typography variant={'h1'}>{'카드 결제'}</Typography>}
								icon={<CreditCardOutlined style={{ fontSize: '200%' }} />}
							/>
						)}
					</WithDialog>
				</Card>
				<Card sx={{ flex: 1, height: 100 }}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CashPayDialog
								storeId={storeId}
								posId={posId}
								handleSubmitPayment={handleSubmitPayment}
								결제할금액={결제할금액}
								open={open}
								closeDialog={closeDialog}
							/>
						)}>
						{(openDialog) => (
							<PaymentCardButton
								onClick={openDialog}
								disabled={remainingPay === 0}
								label={<Typography variant={'h1'}>{'현금 결제'}</Typography>}
								icon={<DollarCircleOutlined style={{ fontSize: '200%' }} />}
							/>
						)}
					</WithDialog>
				</Card>
				<Card sx={{ flex: 1, height: 100 }}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<EasyPayDialog
								storeId={storeId}
								posId={posId}
								orderId={orderId}
								handleSubmitPayment={handleSubmitPayment}
								결제할금액={결제할금액}
								open={open}
								closeDialog={closeDialog}
							/>
						)}>
						{(openDialog) => (
							<PaymentCardButton
								disabled
								onClick={openDialog}
								label={
									<Typography
										variant={'h1'}
										sx={{ color: 'grey' }}>
										{'간편 결제'}
									</Typography>
								}
								icon={<MobileOutlined style={{ fontSize: '200%', color: 'grey' }} />}
							/>
						)}
					</WithDialog>
				</Card>
				<Card sx={{ flex: 1, height: 100 }}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<OtherPayDialog
								storeId={storeId}
								posId={posId}
								orderId={orderId}
								handleSubmitPayment={handleSubmitPayment}
								결제할금액={결제할금액}
								open={open}
								closeDialog={closeDialog}
							/>
						)}>
						{(openDialog) => (
							<PaymentCardButton
								onClick={openDialog}
								disabled={remainingPay === 0}
								label={<Typography variant={'h1'}>{'기타 결제'}</Typography>}
								icon={<MoreHorizIcon style={{ fontSize: '200%' }} />}
							/>
						)}
					</WithDialog>
				</Card>
			</Stack>
		</Stack>
	);
};

const PaymentCardButton = ({
	onClick,
	icon,
	label,
	disabled = false,
}: {
	onClick: () => void;
	icon: ReactNode;
	label: ReactNode;
	disabled?: boolean;
}) => (
	<CardActionArea
		sx={{
			height: '100%',
		}}
		disabled={disabled}
		onClick={onClick}>
		<CardContent
			sx={{
				flexDirection: 'column',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			{icon}
			<br />
			{label}
		</CardContent>
	</CardActionArea>
);
