import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import { IconButtonProps, Typography } from '@mui/material';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

export interface ITicketEnterCancelButtonProps extends IconButtonProps {}
export const TicketEnterCancelButton: React.FC<
  ITicketEnterCancelButtonProps
> = ({ onClick, disabled }) => {
  return (
    <IconTextButton
      disabled={disabled}
      onClick={onClick}
      icon={<CancelPresentationIcon fontSize="small" />}
      label={<Typography>{'입장취소'}</Typography>}
    />
  );
};
