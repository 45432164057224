import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { OrderAndPaymentsType } from './OrderAndPaymentsType';
import { canceledStyleUtils } from '@kinderlabs-pos/shared-util';
import { getPointStatus, canEditPoint, PointStatusColor } from '@kinderlabs-pos/shared-data-type';

export const OrderAndPaymentPoints = ({
	selected,
	setSelected,
	orderWithDetail,
	paymentTotal,
}: OrderAndPaymentsType) => {
	const pointStatus = getPointStatus(orderWithDetail);

	return (
		<>
			<Divider sx={{ mt: 2 }} />
			<Typography
				variant='h4'
				textAlign={'center'}
				sx={{ py: 0.5 }}>
				{`포인트`}
			</Typography>
			<Divider />
			<SelectableListItem
				sx={{ py: 1, px: 0 }}
				selected={selected.type === 'point'}
				onClick={() => {
					setSelected({ type: 'point' });
				}}>
				<Stack
					direction={'row'}
					sx={{ flex: 1 }}
					alignItems={'center'}
					spacing={1}
					justifyContent={'space-between'}>
					<Button
						size={'small'}
						disabled={!canEditPoint[pointStatus]}
						color={PointStatusColor[pointStatus]}
						variant={selected.type === 'point' ? 'contained' : 'outlined'}>
						{pointStatus}
					</Button>
					<Typography
						variant={'h5'}
						sx={{
							textAlign: 'right',
							...canceledStyleUtils(pointStatus === '적립 취소됨'),
						}}>
						{`${orderWithDetail.points} P`}
					</Typography>
				</Stack>
			</SelectableListItem>
		</>
	);
};
