import { PosState } from '@kinderlabs-pos/state';
import { FormControlLabel, Stack, Switch, TextField, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { VaultcashMenuItem } from '../common';

export const VaultcashManageTab = () => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSettingAtom((prev) => ({
			...prev,
			vaultcashWithdrawalAlertAmount: parseInt(e.target.value.replace(/[^0-9]/g, '')),
		}));
	};

	const [settingAtom, setSettingAtom] = useAtom(PosState.settings);

	return (
		<Stack
			direction={'column'}
			flexGrow={1}>
			<VaultcashMenuItem
				title='개장 시 시재 점검'
				innerElement={
					<FormControlLabel
						control={<Switch checked={settingAtom.vaultcashCheckOnOpening} />}
						onChange={() =>
							setSettingAtom((prev) => ({
								...prev,
								vaultcashCheckOnOpening: !prev.vaultcashCheckOnOpening,
							}))
						}
						label={<Typography variant={'subtitle1'}></Typography>}
						disabled
					/>
				}
			/>
			<VaultcashMenuItem
				title='출금 알림 금액 설정'
				innerElement={
					<TextField
						id='vaultcashWithdrawalAlertAmount'
						variant='outlined'
						defaultValue={settingAtom.vaultcashWithdrawalAlertAmount.toLocaleString() + ' 원'}
						onChange={handleChange}
						disabled></TextField>
				}
			/>
		</Stack>
	);
};
