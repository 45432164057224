import { WarningOutlined } from '@ant-design/icons';
import {
	CartActionBoard,
	CartSummary,
	ProductItem,
	useSortedCartLines,
} from '@kinderlabs-pos/feature/pos/cart-table';
import {
	CartLineInfoType,
	CartLineTypeRules,
	OrderDetailType,
} from '@kinderlabs-pos/shared-data-type';
import { isSame, numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogProps,
	Divider,
	List,
	Stack,
	Typography,
} from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Fragment, useEffect, useMemo } from 'react';
import { CardPayDialog } from '../../modules/payments/CardPayDialog';
import { CashPayDialog } from '../../modules/payments/CashPayDialog';
import { EasyPayDialog } from '../../modules/payments/EasyPayDialog';
import { OtherPayDialog } from '../../modules/payments/OtherPayDialog';
import { PointUsageDialog } from '../../modules/payments/PointDiscountCoupon/PointUsageDialog';
import { useHandleSubmitPaymentForEditOrder } from '../../modules/payments/useHandleSubmitPaymentForEditOrder';
import { PosBoardInOrderEdit } from '../../modules/productBoard/components/PosBoardInPos/PosBoardInOrderEdit';
import { usePosRouter } from '../routes';

const BOARD_WIDTH = 500;
const BOARD_HEIGHT = 563.75;
export interface OrderEditDialogProps extends DialogProps {
	order: OrderDetailType;
	closeDialog: () => void;
}
export const OrderEditDialog = ({ open, closeDialog, order }: OrderEditDialogProps) => {
	const [selectedCartLine, selectCartLine] = useAtom(CartActionBoard.selectedCartLineAtom);
	const dispatchOrder = useSetAtom(OrderState.value);
	const newCart = useAtomValue(OrderState.cart.selector);
	const { remainingPay } = useAtomValue(OrderState.aggregate.payments);

	const {
		originCartLines,
		newCartLinesWithRemovedOptions,
		removedCartlinesWithoutOptions,
		원래_띠지중_취소된것이_있는지,
		새로운_띠지중_수량감소된것이_있는지,
		띠지를_새로발급할것이_있는지,
	} = newCartLinesAndOriginCartLines({
		newCartLines: newCart.lines,
		originCartLines: order.cart.lines,
	});

	const { storeId, deviceId: posId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const { policy } = useAtomValue(StoreInfoState.curStoreInfo);
	const cart = useAtomValue(OrderState.cart.selector);
	const { totalToPay, totalTaxFreePay } = OrderState.cart.utils.getAggregate(cart);

	const { navigate주문수정완료 } = usePosRouter();
	const { handleEditOrder, isLoading } = useHandleSubmitPaymentForEditOrder({
		orderId: order.id,
		newPoints: (policy.pointRatio / 100) * totalToPay,
		onSuccess: (result) => {
			if (result.order.status === 'COMPLETED') navigate주문수정완료();
			else closeDialog();
		},
	});

	const 주문수정경고 = use주문수정경고({
		원래_띠지중_취소된것이_있는지,
		새로운_띠지중_수량감소된것이_있는지,
		띠지를_새로발급할것이_있는지,
		주문이_결제미완상태인건지: order.status === 'NOT_COMPLETED',
	});

	const handle결제없이주문수정 = 주문수정경고(() => handleEditOrder());

	useEffect(() => {
		dispatchOrder({ type: 'LOAD', order: order });
	}, []);

	const handleClose = () => {
		dispatchOrder({ type: 'CLEAR' });
		closeDialog();
	};

	const handleReset = () => {
		dispatchOrder({ type: 'LOAD', order: order });
	};

	const hasChanged = !isSame(originCartLines, newCart.lines);

	const diabledEditPayment = remainingPay <= 0 || !hasChanged || order.status === 'NOT_COMPLETED';

	const diabledEditWithoutPaymentWhenOnceCompleted =
		['COMPLETED', 'PARTIALLY_CANCELED'].includes(order.status) &&
		(remainingPay !== 0 || !hasChanged);

	const diabledEditWithoutPaymentWhenNOT_COMPLETED =
		order.status === 'NOT_COMPLETED' && (remainingPay < 0 || !hasChanged);

	const diabledEditWithoutPayment =
		diabledEditWithoutPaymentWhenOnceCompleted || diabledEditWithoutPaymentWhenNOT_COMPLETED;

	return (
		<WithDialog.XlDialog
			open={open}
			fullWidth
			scroll='body'
			closeDialog={closeDialog}
			dialogTitle={'주문 수정'}>
			<DialogContent sx={{ height: BOARD_HEIGHT, mb: 2 }}>
				<Stack
					direction={'row'}
					spacing={2}
					height={'100%'}
					overflow={'auto'}>
					<Stack
						flex={1}
						overflow={'auto'}>
						<Stack
							flex={1}
							overflow={'auto'}>
							<List
								disablePadding
								sx={{ flex: 1, overflow: 'auto' }}>
								{[...newCartLinesWithRemovedOptions, ...removedCartlinesWithoutOptions].map(
									(cl, idx) => {
										return (
											<Fragment key={idx}>
												{!cl.isRemoved ? (
													<ProductItem
														selectOption={{
															select: () => selectCartLine(cl),
															selected: cl.id === selectedCartLine?.id,
															disabled: !CartLineTypeRules.canEditCartLine[cl.type],
														}}
														highlightOption={
															cl.isNew
																? 'quantity-increase'
																: cl.quantityChange
																? cl.quantityChange > 0
																	? 'quantity-increase'
																	: 'quantity-decrease'
																: undefined
														}
														quantityChange={
															cl.quantityChange && cl.quantityChange !== 0
																? cl.quantityChange
																: undefined
														}
														cartLine={cl}
														key={idx}
													/>
												) : (
													<ProductItem
														selectOption={{
															select: () => {},
															selected: false,
															disabled: true,
														}}
														highlightOption={'canceled'}
														cartLine={cl}
														key={idx}
													/>
												)}
												<Divider component={'li'} />
											</Fragment>
										);
									}
								)}
							</List>
						</Stack>
						<CartSummary.Mini />
						<CartActionBoard.MiniBoard
							hasChanged={hasChanged}
							handle초기화={handleReset}
						/>
					</Stack>
					<Stack width={BOARD_WIDTH}>
						<PosBoardInOrderEdit originCartLines={originCartLines} />
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Stack
					direction={'row'}
					spacing={1}
					pr={2}
					flex={1}
					justifyContent={'flex-end'}>
					<Typography variant={'h3'}>{`차액 ${numberWithCommasAnd원(remainingPay)}`}</Typography>
				</Stack>
				<Stack
					direction={'row'}
					spacing={3}>
					<Button
						disabled={
							diabledEditWithoutPayment ||
							isLoading ||
							// 결제 금액은 같은데 면세 금액이 달라지면 주문 수정 안돼요.
							order.aggregate.totalTaxFreePay !== totalTaxFreePay
						}
						size={'large'}
						variant={'contained'}
						onClick={handle결제없이주문수정}>
						<Typography variant={'h4'}>주문 수정</Typography>
					</Button>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CardPayDialog
								orderId={order.id}
								storeId={storeId}
								posId={posId}
								handleSubmitPayment={handleEditOrder}
								결제할금액={remainingPay}
								open={open}
								closeDialog={closeDialog}
							/>
						)}>
						{(openDialog) => (
							<Button
								disabled={diabledEditPayment || isLoading}
								size={'large'}
								variant={'contained'}
								onClick={주문수정경고(openDialog)}>
								<Typography variant={'h4'}>카드</Typography>
							</Button>
						)}
					</WithDialog>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CashPayDialog
								storeId={storeId}
								posId={posId}
								handleSubmitPayment={handleEditOrder}
								결제할금액={remainingPay}
								open={open}
								color={'secondary'}
								closeDialog={closeDialog}
							/>
						)}>
						{(openDialog) => (
							<Button
								disabled={diabledEditPayment || isLoading}
								size={'large'}
								variant={'contained'}
								color={'warning'}
								onClick={주문수정경고(openDialog)}>
								<Typography variant={'h4'}>현금</Typography>
							</Button>
						)}
					</WithDialog>
					{/* <WithDialog
						dialog={(open, closeDialog) => (
							<EasyPayDialog
								submitPayment={handleSubmitEasyPayment}
								결제할금액={remainingPay}
								open={open}
								closeDialog={closeDialog}
							/>
						)}>
						{(openDialog) => (
							<Button
								disabled={diabledEditPayment || isLoading}
								size={'large'}
								variant={'contained'}
								color={'warning'}
								onClick={주문수정경고(openDialog)}>
								<Typography variant={'h4'}>간편</Typography>
							</Button>
						)}
					</WithDialog> */}
					<WithDialog
						dialog={(open, closeDialog) => (
							<OtherPayDialog
								orderId={order.id}
								storeId={storeId}
								posId={posId}
								handleSubmitPayment={handleEditOrder}
								결제할금액={remainingPay}
								open={open}
								closeDialog={closeDialog}
							/>
						)}>
						{(openDialog) => (
							<Button
								disabled={diabledEditPayment || isLoading}
								size={'large'}
								variant={'contained'}
								color={'info'}
								onClick={주문수정경고(openDialog)}>
								<Typography variant={'h4'}>기타</Typography>
							</Button>
						)}
					</WithDialog>
					<WithDialog
						dialog={(open, closeDialog) => (
							<PointUsageDialog
								storeId={storeId}
								posId={posId}
								handleSubmitPayment={handleEditOrder}
								mode={order.type}
								open={open}
								closeDialog={closeDialog}
							/>
						)}>
						{(openDialog) => (
							<Button
								disabled={diabledEditPayment || isLoading}
								size={'large'}
								variant={'contained'}
								onClick={주문수정경고(openDialog)}
								color={'success'}>
								<Typography variant={'h4'}>포인트</Typography>
							</Button>
						)}
					</WithDialog>
					<Button
						variant='outlined'
						onClick={handleClose}>
						<Typography variant={'h4'}>닫기</Typography>
					</Button>
				</Stack>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};

const newCartLinesAndOriginCartLines = ({
	newCartLines,
	originCartLines,
}: {
	newCartLines: CartLineInfoType[];
	originCartLines: CartLineInfoType[];
}) => {
	const sortCartLines = useSortedCartLines();
	const sortedOriginCartLines = sortCartLines(originCartLines);
	const removedCartlines = useMemo(
		() =>
			sortedOriginCartLines
				// .filter((cl) => !CartLineTypeRules.isOptionsInCart[cl.type])
				.filter((cl) => newCartLines.findIndex((newCl) => newCl.id === cl.id) < 0),
		[newCartLines, sortedOriginCartLines]
	);

	const sortedNewCartLines = sortCartLines(newCartLines);
	const newCartLinesResult = useMemo(
		() =>
			sortedNewCartLines.map((cartLine, idx) => {
				const originCartLine = sortedOriginCartLines.find((cl) => cl.id === cartLine.id);
				const quantityChange = originCartLine
					? cartLine.quantity - originCartLine.quantity
					: undefined;

				return { ...cartLine, quantityChange: quantityChange, isNew: !originCartLine };
			}),
		[sortedNewCartLines, sortedOriginCartLines]
	);

	const removedCartlinesWithoutOptions = useMemo(
		() =>
			removedCartlines
				// 옵션이고 newCartLine 에 있는 녀석들을 제거
				.filter(
					(cl) =>
						!(
							CartLineTypeRules.isOptionsInCart[cl.type] &&
							newCartLinesResult.findIndex((targetCl) => targetCl.id === cl.targetCartLineId) >= 0
						)
				)
				.map((cl) => ({ ...cl, isRemoved: true, quantityChange: undefined, isNew: false })),
		[removedCartlines, newCartLinesResult]
	);

	const newCartLinesWithRemovedOptions = useMemo(() => {
		let result = newCartLinesResult.map((cl) => ({ ...cl, isRemoved: false }));

		removedCartlines
			.filter((cl) => CartLineTypeRules.isOptionsInCart[cl.type])
			.forEach((cl) => {
				const targetCartLineIndex = result.findIndex(
					(resultCl) => resultCl.id === cl.targetCartLineId
				);
				if (targetCartLineIndex >= 0) {
					const targetCartLineOptionLength = result.filter(
						(resultCl) => resultCl.targetCartLineId === cl.targetCartLineId
					).length;

					result.splice(targetCartLineIndex + 1 + targetCartLineOptionLength, 0, {
						...cl,
						isRemoved: true,
						isNew: false,
						quantityChange: undefined,
					});
				}
			});
		return result;
	}, [removedCartlines, newCartLinesResult]);

	const 원래_띠지중_취소된것이_있는지 =
		removedCartlines.filter((cl) => !!cl.productInfo.ddiziInfo).length > 0;
	const 새로운_띠지중_수량감소된것이_있는지 =
		newCartLinesResult.filter(
			(cl) => !!cl.productInfo.ddiziInfo && !!cl.quantityChange && cl.quantityChange < 0
		).length > 0;

	const 띠지를_새로발급할것이_있는지 =
		newCartLinesResult.filter(
			(cl) => !!cl.productInfo.ddiziInfo && !!cl.quantityChange && cl.quantityChange > 0
		).length > 0;

	return {
		originCartLines,
		newCartLines: newCartLinesResult,
		removedCartlines,
		newCartLinesWithRemovedOptions,
		removedCartlinesWithoutOptions,
		원래_띠지중_취소된것이_있는지,
		새로운_띠지중_수량감소된것이_있는지,
		띠지를_새로발급할것이_있는지,
	};
};

const use주문수정경고 = ({
	원래_띠지중_취소된것이_있는지,
	새로운_띠지중_수량감소된것이_있는지,
	띠지를_새로발급할것이_있는지,
	주문이_결제미완상태인건지,
}: {
	원래_띠지중_취소된것이_있는지: boolean;
	새로운_띠지중_수량감소된것이_있는지: boolean;
	띠지를_새로발급할것이_있는지: boolean;
	주문이_결제미완상태인건지: boolean;
}) => {
	const customConfirm = useConfirm();

	const 이전띠지가_모두_취소될것인지 =
		원래_띠지중_취소된것이_있는지 || 새로운_띠지중_수량감소된것이_있는지;
	const 새로운_띠지만_발급될것인지 = !이전띠지가_모두_취소될것인지 && 띠지를_새로발급할것이_있는지;

	return (originFunction: () => void | Promise<void>) => () => {
		!(이전띠지가_모두_취소될것인지 || 새로운_띠지만_발급될것인지)
			? originFunction()
			: customConfirm(
					<Stack spacing={1}>
						<Typography
							color={'error'}
							variant={'h3'}>
							<WarningOutlined />
							{'주문을 수정 하시겠습니까?'}
						</Typography>
						{!주문이_결제미완상태인건지 && 이전띠지가_모두_취소될것인지 && (
							<>
								<Typography variant={'subtitle2'}>∙ 모든 티켓이 새로이 발급됩니다.</Typography>
								<Typography variant={'subtitle2'}>
									∙ 이전에 발급한 티켓은 모두 취소되니 모두 수거해주세요.
								</Typography>
							</>
						)}
						{!주문이_결제미완상태인건지 && (
							<Typography variant={'subtitle2'}>
								∙ 새로이 발급되는 티켓은 시간이 차감된 채로 발급됩니다.
							</Typography>
						)}
					</Stack>,
					originFunction
			  );
	};
};
