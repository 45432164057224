import { OrderDetailType, PaymentInfoType } from '@kinderlabs-pos/shared-data-type';
import { PaymentState } from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useSetAtom } from 'jotai';

export interface CardPaymentInfoToRefundType extends PaymentInfoType {
	method: 'CARD' | 'CASH_IC' | 'CARD_MS' | 'VIRTUAL_CARD';
}

export const useHandle카드환불Formik = ({
	payment,
	order,
	onComplete,
}: {
	payment: CardPaymentInfoToRefundType;
	order: OrderDetailType;
	onComplete?: () => void;
}) => {
	const confirm = useConfirm();
	const refund = PaymentState.actions.refund.useRefund();

	return useFormik<{ submit: string | null }>({
		initialValues: {
			submit: null,
		},
		onSubmit: async (values, { setErrors }) => {
			confirm(
				dayjs(payment.created).isToday()
					? '환불하시겠습니까?'
					: '오늘 결제한 건이 아니면 환불시 문제가 될수 있습니다. 환불하시겠습니까?',
				async () => {
					try {
						if (payment.receipt) {
							await refund({
								payment: payment,
								receipt: payment.receipt,
								order: order,
								method: payment.method,
							});

							onComplete && onComplete();
						} else if (payment.method === 'VIRTUAL_CARD') {
							await refund({
								payment: payment,
								order: order,
								method: payment.method,
							});
							onComplete && onComplete();
						}
					} catch (e) {
						//@ts-ignore
						setErrors({ submit: e.message });
					}
				}
			);
		},
	});
};
