import { PosInfoType, PosStatusDetailedType, PosType } from '@kinderlabs-pos/shared-data-type';
import { getShortDateFormatWithSlash } from '@kinderlabs-pos/shared-util';
import { Typography } from '@mui/material';
import { ReactNode } from 'react';

export const getPosSummaryText = ({
	posStatusDetailed,
	openDate,
	closeDate,
}: {
	posStatusDetailed: PosStatusDetailedType | undefined;
	openDate: Date | undefined;
	closeDate: Date | undefined;
}): ReactNode => {
	switch (posStatusDetailed) {
		default:
		case 'inUse':
			return `영업 중`;
		case 'notOpened':
		case 'alreadyOpened':
			return '영업 가능';
		case 'needToBeClosed':
			return `영업 마감 필요 - ${openDate && getShortDateFormatWithSlash(openDate)}`;
		case 'alreadyClosed':
			return (
				<Typography color='error'>{`영업 마감됨 - ${
					closeDate && getShortDateFormatWithSlash(closeDate)
				}`}</Typography>
			);
	}
};

export const getDetailedVaultCashStateText = ({
	posState,
	posType,
}: {
	posState?: PosStatusDetailedType;
	posType?: PosType;
}) => {
	if (posType === 'WEBPOS') return '[웹포스] 시재 점검 필요 없음';

	switch (posState) {
		default:
		case 'inUse':
			return '';
		case 'notOpened':
			return `시재 점검 필요`;
		case 'alreadyOpened':
			return `시재 점검 완료`;
		case 'needToBeClosed':
			return `시재 마감 필요`;
		case 'alreadyClosed':
			return `영업 마감됨`;
	}
};

export const getLoginButtonText = (
	posState: PosStatusDetailedType | undefined,
	posType?: PosInfoType['posType'] | undefined
) => {
	if (posType === 'WEBPOS') return '영업 시작';

	switch (posState) {
		default:
		case 'alreadyClosed':
		case 'notOpened':
		case 'inUse':
		case 'alreadyOpened':
			return '영업 시작';
		case 'needToBeClosed':
			return '영업 마감';
	}
};
