import { DdiziType } from '@kinderlabs-pos/shared-data-type';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { CircularProgress, DialogContent } from '@mui/material';
import { Suspense } from 'react';
import { DdiziDetailContent } from '@kinderlabs-pos/feature/pos/ddizis';

export interface IDdiziDetailDialogProps extends XlDialogProps {
	ddizi: DdiziType;
}
export const DdiziDetailDialog: React.FC<IDdiziDetailDialogProps> = ({
	ddizi,
	open,
	closeDialog,
}) => {
	return (
		<WithDialog.XlDialog
			open={open}
			closeDialog={closeDialog}
			dialogTitle={'티켓 상세'}
			closeDialogAction={'닫기'}>
			<Suspense fallback={<CircularProgress />}>
				<DialogContent sx={{ minWidth: 360 }}>
					<DdiziDetailContent ddizi={ddizi} />
				</DialogContent>
			</Suspense>
		</WithDialog.XlDialog>
	);
};
