import { DayCalendarFilterOptionType, DdiziType } from '@kinderlabs-pos/shared-data-type';
import { getGroupBy } from '@kinderlabs-pos/shared-util';
import {
	DdiziQueryState,
	DdiziState,
	PosElectronStoreState,
	PrintReceiptState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import {
	Box,
	CircularProgress,
	List,
	ListItem,
	ListItemButton,
	ListSubheader,
	Stack,
	Typography,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAtom, useAtomValue } from 'jotai';
import { Fragment, useEffect, useMemo } from 'react';
import { DdiziManagementPage } from '.';
import { DdiziDisplay } from '../../components/DdiziDisplay';
import { TicketPrintButton } from '../../modules/tickets/components/buttons/TicketPrintButton';
import { DdiziEnterExitButtons } from './DdiziEnterExitButtons';
import { DdiziFilterType } from './DdiziFilterDialog';
import { DdiziTimeAdjustButtons } from './DdiziTimeAdjustButtons';

export const DdiziListContent = ({
	filter,
	keyword,
	option,
}: {
	keyword?: string;
	filter: DdiziFilterType;
	option: DayCalendarFilterOptionType;
}) => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const ticketShareStoreIdList = storeInfo.policy.ticketShareStoreIdList || [];
	const storeIdListToSearch = useMemo(
		() => [...ticketShareStoreIdList, storeInfo.id],
		[ticketShareStoreIdList, storeInfo.id]
	);
	const curDeviceInfo = StoreInfoState.useCurDeviceInfoForOrder();
	const 띠지프린터라벨출력 = PrintReceiptState.LabelPrinter.usePrintLabelPrinter({
		labelPrinterList: curDeviceInfo.labelPrinterList,
	});

	const [selectedDdizi, selectDdizi] = useAtom(DdiziManagementPage.selectedDdizi);
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings().data;
	const {
		data: ddiziDataPage,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		...DdiziQueryState.keys.infinite({
			params: {
				storeIdList: storeIdListToSearch,
				search: keyword,
				status: filter.status,
				posId: filter.targetPosId,
				...option,
			},
		}),
		getNextPageParam: (lastPage, allPage) => {
			return lastPage.startCursorId ? { startCursorId: lastPage.startCursorId } : undefined;
		},
		refetchOnWindowFocus: true,
	});

	const ddiziData = useMemo(() => {
		if (!ddiziDataPage) return [];
		else return ddiziDataPage.pages.flatMap((data) => data.content);
	}, [ddiziDataPage]);

	const ddizisGroupBy = getGroupBy(ddiziData, (item) => item.publishInfo.orderId);

	const { print영수증띠지Async } = PrintReceiptState.useReceiptDdiziPrinter({
		storeId: storeInfo.id,
		deviceType: 'POS',
	});

	const handlePrinterClick = (ddizi: DdiziType) => async () => {
		const ddiziData = { ...DdiziState.utils.getDdizi인쇄Data(ddizi) };
		await 띠지프린터라벨출력({
			...ddiziData,
			deviceType: 'POS',
			newDdizi:
				ddiziData.deviceNum === 'LabelPrinter1'
					? localSettings?.useNewDesignOnLabelPrinter1 ?? false
					: localSettings?.useNewDesignOnLabelPrinter2 ?? false,
		});
		await print영수증띠지Async({
			ddizi,
		});
	};

	useEffect(() => {
		if (selectedDdizi) {
			selectDdizi(ddiziData.find((ddizi) => ddizi.id === selectedDdizi.id));
		}
	}, [selectedDdizi, ddiziData]);

	return (
		<List>
			{[...Object.keys(ddizisGroupBy)]
				.sort((a: string, b: string) => b.localeCompare(a))
				.map((key, idx) => (
					<Fragment key={idx}>
						<ListSubheader
							sx={(theme) => ({
								py: 1,
								borderBottom: `1px solid ${theme.palette.divider}`,
							})}>
							<Typography variant={'body1'}>{`주문번호 : ${key}`}</Typography>
						</ListSubheader>
						{ddizisGroupBy[key].map((ddizi, ddiziIdx) => {
							return (
								<SelectableListItem
									key={ddiziIdx}
									selected={ddizi.id === selectedDdizi?.id}
									onClick={() => {
										selectDdizi(ddizi);
									}}
									sx={(theme) => ({
										py: 1,
										borderBottom: `1px solid ${theme.palette.divider}`,
									})}>
									<DdiziDisplay
										ddizi={ddizi}
										sx={{ width: '100%' }}
										userOnPage={'TICKET'}
										itemButton={
											dayjs(ddizi.publishInfo.published).isToday() && (
												<Stack>
													<Stack
														direction={'row'}
														spacing={1}>
														<TicketPrintButton
															label={'재발권'}
															onClick={handlePrinterClick(ddizi)}
														/>
														<DdiziEnterExitButtons ddizi={ddizi} />
													</Stack>
													<Stack
														direction={'row'}
														spacing={1}>
														<Box sx={{ visibility: 'hidden' }}>
															<TicketPrintButton
																label={'재발권'}
																onClick={handlePrinterClick(ddizi)}
															/>
														</Box>
														<DdiziTimeAdjustButtons ddizi={ddizi} />
													</Stack>
												</Stack>
											)
										}
									/>
								</SelectableListItem>
							);
						})}
					</Fragment>
				))}
			{isFetchingNextPage && (
				<ListItem>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						<CircularProgress />
					</Stack>
				</ListItem>
			)}
			{hasNextPage && !isFetchingNextPage && (
				<ListItemButton onClick={() => fetchNextPage()}>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						더 보기
					</Stack>
				</ListItemButton>
			)}
		</List>
	);
};
