import { DeleteOutlined } from '@ant-design/icons';
import { CartLineInfoType } from '@kinderlabs-pos/shared-data-type';
import { OrderState } from '@kinderlabs-pos/state';
import { Box, Button, Divider, List, Stack, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { Fragment, ReactNode } from 'react';
import { CartActionBoard } from '../CartActionBoard';
import { ProductItem } from '../Items/ProductItem';
import { useSortedCartLines } from '../useSortedCartLines';

export interface ICartTableProps {
	selectable?: boolean;
	hasTitle?: boolean;
	componentsInTitle?: ReactNode;
	cartLines: CartLineInfoType[];
}
export const CartTable: React.FC<ICartTableProps> = ({
	selectable = false,
	hasTitle = true,
	componentsInTitle = null,
	cartLines,
}) => {
	const sortCartLines = useSortedCartLines();
	const sortedCartLines = sortCartLines(cartLines);

	const [selected, select] = useAtom(CartActionBoard.selectedCartLineAtom);

	return (
		<Stack height={'100%'}>
			{hasTitle && (
				<Stack
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
					py={1}
					px={2}>
					<Typography
						sx={{ flex: 1 }}
						variant={'h4'}>
						주문 목록
					</Typography>
					{componentsInTitle}
				</Stack>
			)}
			{hasTitle && <Divider />}
			<List
				disablePadding
				sx={{ flex: 1, overflow: 'auto' }}>
				{sortedCartLines.map((cartLine, idx) => (
					<Fragment key={idx}>
						<ProductItem
							selectOption={
								selectable
									? {
											selected: selected?.id === cartLine.id,
											select: () => select(cartLine),
									  }
									: undefined
							}
							cartLine={cartLine}
							key={idx}
						/>
						<Divider component={'li'} />
					</Fragment>
				))}
			</List>
		</Stack>
	);
};

export const CartTableWithItems = ({
	selectable,
	hasTitle,
}: {
	selectable?: boolean;
	hasTitle?: boolean;
}) => {
	const cartLines = useAtomValue(OrderState.cart.selector).lines;

	return (
		<CartTable
			cartLines={cartLines}
			selectable={selectable}
			hasTitle={hasTitle}
		/>
	);
};
