import {
	OrderStatusTypeColor,
	OrderStatusTypeLabel,
	OrderTypeWithPayments,
} from '@kinderlabs-pos/shared-data-type';
import {
	getTelephoneBackDigits,
	getTimeFormat,
	numberWithCommasAndWon,
} from '@kinderlabs-pos/shared-util';
import { AdminPosInfoState } from '@kinderlabs-pos/state';
import { Button, Stack, Typography } from '@mui/material';

export const OrderDisplay = ({ order }: { order: OrderTypeWithPayments }) => {
	const deviceInfo = AdminPosInfoState.useDeviceInfo({
		storeId: order.storeId,
		deviceId: order.posId,
	});

	return (
		<Stack
			width={'100%'}
			spacing={1.5}
			direction={'row'}
			justifyContent={'space-between'}
			alignItems={'center'}>
			<Stack
				flex={1}
				alignItems={'flex-start'}>
				<Typography variant={'h6'}>{order.id}</Typography>
				<Typography variant={'body1'}>
					{numberWithCommasAndWon(order.aggregate.totalToPay)}
				</Typography>
				<Typography variant={'body2'}>
					{order.guestMemberInfo && order.guestMemberInfo.telephone
						? `${order.guestMemberInfo.name ?? '이름없음'} (${getTelephoneBackDigits(
								order.guestMemberInfo.telephone
						  )})`
						: '-'}
				</Typography>
			</Stack>
			<Button
				variant={'outlined'}
				size={'small'}
				color={OrderStatusTypeColor[order.status]}>
				{OrderStatusTypeLabel[order.status]}
			</Button>
			<Stack alignItems={'center'}>
				<Typography
					variant='body2'
					color={'text.secondary'}>
					{deviceInfo?.type === 'KIOSK' ? 'KIOSK' : ''}
				</Typography>
				<Typography variant={'subtitle2'}>{getTimeFormat(order.created)}</Typography>
			</Stack>
		</Stack>
	);
};
