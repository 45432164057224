import { Box, InputLabel, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface IStringFormProps extends Omit<TextFieldProps, 'fullWidth' | 'size' | 'variant'> {
	helperText?: string;
	defaultPlaceholder?: string;
	changed?: boolean;
	readOnly?: boolean;
	disabledHelperText?: boolean;
}
export const StringForm: React.FC<IStringFormProps> = ({
	label,
	InputProps,
	helperText = 'ㅤ',
	changed = false,
	defaultPlaceholder,
	placeholder,
	readOnly = false,
	disabledHelperText = false,
	...others
}) => {
	return (
		<Stack>
			<InputLabel>
				<Typography
					variant={'body2'}
					color={changed ? 'primary' : 'unset'}>
					{label}
				</Typography>
			</InputLabel>
			<TextField
				fullWidth
				size={'small'}
				placeholder={placeholder || defaultPlaceholder}
				InputProps={{
					...InputProps,
					readOnly: readOnly,
				}}
				{...others}
				helperText={
					!disabledHelperText ? (
						<Typography
							variant='caption'
							component={'span'}
							color={'error'}>
							{helperText}
						</Typography>
					) : undefined
				}
			/>
		</Stack>
	);
};
