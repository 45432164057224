import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import ArticleIcon from '@mui/icons-material/Article';
import { IconButtonProps, Typography } from '@mui/material';

export interface IDetailButtonProps extends IconButtonProps {
	iconSize?: 'large' | 'default';
}
export const DetailButton: React.FC<IDetailButtonProps> = ({ iconSize, ...props }) => {
	return (
		<IconTextButton
			iconSize={iconSize}
			icon={<ArticleIcon fontSize="small" />}
			label={<Typography>{'상세보기'}</Typography>}
			{...props}
		/>
	);
};
