import { EyeOutlined, HistoryOutlined } from '@ant-design/icons';
import {
	VaunceAppMUTTicketOnPosType,
	VaunceAppTicketOnPosType,
} from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { AccordionDetails, Button, Checkbox, IconButton, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { VaunceAppTicketUserMemberInfoBox } from './common/VaunceAppTicketUserMemberInfoBox';
import { VaunceTicketItemAccordion } from './common/VaunceTicketItemAccordion';
import { getSortedUserIdList } from './common/getSortedUserIdList';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { VaunceAppTicketHistoryDialog } from './VaunceAppTicketHistoryDialog';
import dayjs from 'dayjs';

export const VaunceAppTicketMutListContent = ({
	appTicketInfoMap,
}: {
	appTicketInfoMap: {
		[userId: string]: (VaunceAppMUTTicketOnPosType & { canUseTicket: boolean })[];
	};
}) => {
	const userIdList = getSortedUserIdList({ ticketOrCouponInfoMap: appTicketInfoMap });

	return (
		<Stack>
			{userIdList.map((memberId, idx) => {
				const { uid, userName, userId, userBirth, userTelephone } = appTicketInfoMap[memberId][0];

				return (
					<Stack
						key={idx}
						spacing={1}>
						<VaunceAppTicketUserMemberInfoBox
							uid={uid}
							userName={userName}
							userId={userId}
							userBirth={userBirth}
							userTelephone={userTelephone}
						/>
						{appTicketInfoMap[memberId].map((mut, mutIdx) => {
							const leftChance = mut.canUseTicket
								? mut.detailTicket.filter(
										(detail) => detail.status === 'UNUSED' || detail.status === 'USE_CANCELED'
								  ).length
								: 0;

							return (
								<VaunceTicketItemAccordion
									key={mutIdx}
									ticket={mut}
									canUseTicket={mut.canUseTicket && mut.expiredTicket === 'N'}
									rightPanel={
										<Button
											color={leftChance !== 0 ? 'success' : 'error'}
											variant={'outlined'}
											sx={{ pointerEvents: 'none' }}>
											<Typography variant={'body1'}>
												{`${leftChance} 회 / ${mut.detailTicket.length} 회`}
											</Typography>
										</Button>
									}>
									<AccordionDetails>
										<Suspense>
											<Stack>
												<Stack
													direction={'row'}
													justifyContent={'flex-end'}
													spacing={1}>
													<WithDialog
														dialog={(open, closeDialog) => (
															<VaunceAppTicketHistoryDialog
																ticketId={mut.ticketId}
																open={open}
																closeDialog={closeDialog}
															/>
														)}>
														{(openDialog) => (
															<IconButton onClick={openDialog}>
																<HistoryOutlined />
															</IconButton>
														)}
													</WithDialog>
												</Stack>
												<Typography
													variant={'body1'}
													color={'text.secondary'}>
													{'티켓 사용하기'}
												</Typography>
												<VaunceAppMutUsePanel mut={mut} />
											</Stack>
										</Suspense>
									</AccordionDetails>
								</VaunceTicketItemAccordion>
							);
						})}
					</Stack>
				);
			})}
		</Stack>
	);
};

const VaunceAppMutUsePanel = ({ mut }: { mut: VaunceAppTicketOnPosType }) => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const ticketUsages = useAtomValue(OrderState.cart.lines.ticketUsageSelector).filter(
		(usage) => usage.type === 'VAUNCE_APP_MUT'
	);
	const cartDispatch = OrderState.cart.useDispatcher();

	const useInfoList = mut.detailTicket;

	return (
		<Stack
			flex={1}
			direction={'row'}
			justifyContent={'center'}
			flexWrap={'wrap'}>
			{(useInfoList || []).map((useInfo, idx) => (
				<Stack
					key={idx}
					justifyContent={'flex-start'}
					alignItems={'center'}
					direction={'row'}
					spacing={-1}
					width={'33%'}>
					<Checkbox
						key={idx}
						disabled={useInfo.status === 'USED'}
						checked={
							useInfo.status === 'USED' ||
							ticketUsages.map((u) => u.ticketUsageInfo.ticketUsageId).includes(useInfo.ticketNum)
						}
						onChange={(e) => {
							if (e.target.checked) {
								cartDispatch({
									type: 'USAGES',
									subAction: {
										type: 'ADD_TICKET',
										cartLineId: getUuidV4(),
										ticketUsage: {
											type: 'MULTIUSE',
											useId: useInfo.ticketNum,
											name: mut.ticketTitle,
											ticketId: mut.ticketId,
											ticketInfoId: 0,
											userName: mut.ticketUserName,
											userTelephone: mut.userTelephone,
											memberId: mut.uid,
											ddiziInfo: {
												name: mut.ticketTitle,
												ddiziType: 'NORMAL',
												minutes: storeInfo.policy.defaultMinutesInCenter,
												hasNoAdditionalCharge: false,
												isNotForStatistics: false,
												available: 'ALL',
											},
										},
									},
								});
							} else {
								cartDispatch({
									type: 'USAGES',
									subAction: { type: 'DELETE', useId: useInfo.ticketNum, ticketType: 'MULTIUSE' },
								});
							}
						}}
					/>
					<Stack alignItems={'flex-start'}>
						<Typography
							variant={'subtitle1'}
							textAlign={'left'}>
							{`#${idx + 1} 회차`}
						</Typography>
					</Stack>
				</Stack>
			))}
		</Stack>
	);
};
