import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import { IconButtonProps, Typography } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';

export interface ITicketEnterButtonProps extends IconButtonProps {}
export const TicketEnterButton: React.FC<ITicketEnterButtonProps> = ({
  onClick,
  disabled,
}) => {
  return (
    <IconTextButton
      onClick={onClick}
      disabled={disabled}
      icon={<LoginIcon fontSize="small" />}
      label={<Typography>{'티켓입장'}</Typography>}
    />
  );
};
