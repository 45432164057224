import { ReactPMIPC } from '@kinderlabs-pos/ipc/react';
import { StoreInfoState } from '@kinderlabs-pos/state';
import { DatePicker, WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { NumberBoard, TelephoneBoard } from '@kinderlabs-pos/ui-components';
import {
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	Divider,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import check_image from 'assets/check_image.jpg';
import dayjs, { Dayjs } from 'dayjs';
import { useMutation } from '@tanstack/react-query';

type 수표Type = {
	수표권종: 0 | 1 | 2;
	수표발행일자: Dayjs;
	수표금액: string;
	수표일련번호: string;
	수표발행점번호: string;
};
const today = dayjs();
export interface CheckCheckDialog extends XlDialogProps {}
export const CheckCheckDialog = ({ open, closeDialog }: CheckCheckDialog) => {
	const { vanCompany } = StoreInfoState.useCurDeviceInfoForOrder();
	const [resultMessage, setResultMessage] = useState('');
	const handle수표체크Mutation = useMutation(async (value: 수표Type) => {
		try {
			const result = await ReactPMIPC.invoke수표확인요청({
				vanCompany,
				amount: Number(value.수표금액) ?? 0,
				installment: value.수표권종,
				approvalDate: value.수표발행일자.toDate(),
				customerId: value.수표일련번호 ?? '',
				approvalId: value.수표발행점번호 ?? '',
			});

			setResultMessage(result.responseMessage);
		} catch (e) {
			//@ts-ignore
			setResultMessage(e?.message ?? '');
		}
	});

	const [selectedInput, setSelectedInput] = useState<
		'수표금액' | '수표일련번호' | '수표발행점번호'
	>('수표금액');
	const formik = useFormik<수표Type>({
		initialValues: {
			수표발행일자: dayjs(),
			수표권종: 0,
			수표금액: '',
			수표일련번호: '',
			수표발행점번호: '',
		},
		onSubmit: async (value) => {
			await handle수표체크Mutation.mutateAsync(value);
		},
	});

	return (
		<WithDialog.XlDialog
			open={open}
			scroll='body'
			closeDialog={closeDialog}
			dialogTitle={'수표 검증'}>
			<FormikProvider value={formik}>
				<DialogContent sx={{ minWidth: 620 }}>
					<Stack spacing={2}>
						<Stack height={180}>
							<img
								src={check_image}
								style={{ objectFit: 'contain', width: '100%', height: '100%' }}
							/>
						</Stack>
						<Stack
							spacing={1}
							width='100%'
							direction={'row'}>
							<Stack
								flex={1}
								spacing={1.5}>
								<Stack
									width={'100%'}
									alignItems={'center'}
									direction='row'>
									<Stack
										flex={1}
										sx={{ borderRight: '1px solid gray' }}>
										<Typography variant='h4'>① 수표권종</Typography>
									</Stack>
									<Stack
										flex={1}
										paddingX={2}>
										<Select
											size='small'
											value={formik.values.수표권종}
											onChange={(e) => {
												formik.setFieldValue('수표권종', e.target.value);
											}}>
											<MenuItem value={0}>자기앞수표</MenuItem>
											<MenuItem value={1}>당좌수표</MenuItem>
											<MenuItem value={2}>가계수표</MenuItem>
										</Select>
									</Stack>
								</Stack>
								<Stack
									width={'100%'}
									alignItems={'center'}
									direction='row'>
									<Stack
										flex={1}
										sx={{ borderRight: '1px solid gray' }}>
										<Typography variant='h4'>② 수표금액</Typography>
									</Stack>
									<Stack
										flex={1}
										paddingX={2}>
										<TextField
											size='small'
											focused={selectedInput === '수표금액'}
											value={formik.values.수표금액}
											onClick={(e) => setSelectedInput('수표금액')}
											onChange={(e) => formik.setFieldValue('수표금액', e.target.value)}
										/>
									</Stack>
								</Stack>
								<Stack
									width={'100%'}
									alignItems={'center'}
									spacing={1}
									direction='row'>
									<Button
										size='small'
										variant='outlined'
										onClick={(e) => formik.setFieldValue('수표금액', '100000')}>
										10만원
									</Button>
									<Button
										size='small'
										variant='outlined'
										onClick={(e) => formik.setFieldValue('수표금액', '500000')}>
										50만원
									</Button>
									<Button
										size='small'
										variant='outlined'
										onClick={(e) => formik.setFieldValue('수표금액', '1000000')}>
										100만원
									</Button>
								</Stack>
								<Stack
									width={'100%'}
									alignItems={'center'}
									direction='row'>
									<Stack
										flex={1}
										sx={{ borderRight: '1px solid gray' }}>
										<Typography variant='h4'>③ 수표발행일자</Typography>
									</Stack>
									<Stack
										flex={1}
										paddingX={2}>
										<DatePicker
											value={formik.values.수표발행일자}
											minDate={today.add(-10, 'year')}
											maxDate={today}
											onChange={(value) => {
												if (value) formik.setFieldValue('수표발행일자', value);
											}}
											usageType={'POS'}
										/>
									</Stack>
								</Stack>
								<Stack
									width={'100%'}
									alignItems={'center'}
									direction='row'>
									<Stack
										flex={1}
										sx={{ borderRight: '1px solid gray' }}>
										<Typography variant='h4'>④ 수표일련번호</Typography>
									</Stack>
									<Stack
										flex={1}
										paddingX={2}>
										<TextField
											size='small'
											focused={selectedInput === '수표일련번호'}
											value={formik.values.수표일련번호}
											onClick={(e) => setSelectedInput('수표일련번호')}
											onChange={(e) => formik.setFieldValue('수표일련번호', e.target.value)}
										/>
									</Stack>
								</Stack>
								<Stack
									width={'100%'}
									alignItems={'center'}
									direction='row'>
									<Stack
										flex={1}
										sx={{ borderRight: '1px solid gray' }}>
										<Typography variant='h4'>⑤ 수표발행점번호</Typography>
									</Stack>
									<Stack
										flex={1}
										paddingX={2}>
										<TextField
											size='small'
											focused={selectedInput === '수표발행점번호'}
											onClick={(e) => setSelectedInput('수표발행점번호')}
											value={formik.values.수표발행점번호}
											onChange={(e) => formik.setFieldValue('수표발행점번호', e.target.value)}
										/>
									</Stack>
								</Stack>
							</Stack>
							<Stack
								direction={'row'}
								spacing={5}
								pb={5}>
								<TelephoneBoard
									value={formik.values[selectedInput] ?? ''}
									setValue={(value) => {
										formik.setFieldValue(selectedInput, value);
									}}
									mode={'number'}
								/>
							</Stack>
						</Stack>
						<Typography>{resultMessage}</Typography>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button
						variant='contained'
						type='submit'
						startIcon={<CircularProgress size={'small'} />}
						disabled={handle수표체크Mutation.isLoading}
						onClick={() => {
							formik.handleSubmit();
						}}>
						{'수표 검증'}
					</Button>
					<Button
						variant='outlined'
						onClick={closeDialog}>
						취소
					</Button>
				</DialogActions>
			</FormikProvider>
		</WithDialog.XlDialog>
	);
};
