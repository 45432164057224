import { OrderDetailType, PaymentInfoType } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { PaymentState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { useHandle현금환불Formik } from '../PaymentDetailDialog/hooks/useHandleCashRefundFormik';
import { 현금영수증발급ActionStack, 현금영수증선택Stack } from './CashReceiptActionStacks';
export interface CashReceiptRefundDialogProps extends Omit<XlDialogProps, 'maxWidth'> {
	order: OrderDetailType;
	payment: PaymentInfoType;
	onCompleteRefund?: (payment: PaymentInfoType) => void;
}
export const CashReceiptRefundDialogContent = ({
	open,
	closeDialog,
	order,
	payment,
	onCompleteRefund,
}: CashReceiptRefundDialogProps) => {
	const 현금영수증자동환불가능 =
		payment.receipt?.customerId && !payment.receipt.customerId.includes('*');
	const { values, isValid, setValues, isSubmitting, handleSubmit, errors } =
		useHandle현금환불Formik({
			order,
			payment,
			initialOptions: payment.receipt
				? {
						type: payment.receipt.isEnterprise ? '지출증빙' : '소득공제',
						customerId: 현금영수증자동환불가능 ? payment.receipt.customerId ?? '' : '',
						isSignPadUsed: 현금영수증자동환불가능 ? false : true,
				  }
				: {
						type: '현금영수증미발급',
						customerId: undefined,
						isSignPadUsed: true,
				  },
			onComplete: () => {
				onCompleteRefund && onCompleteRefund(payment);
			},
		});

	const handle결제모듈강제취소 = PaymentState.actions.submit.useHandlePaymentPM강제취소();
	const handleClose = async () => {
		await handle결제모듈강제취소();
		closeDialog && closeDialog();
	};

	return (
		<WithDialog.XlDialog
			open={open}
			closeDialog={closeDialog}
			dialogTitle={'현금 환불'}
			maxWidth={'md'}>
			<form onSubmit={handleSubmit}>
				<DialogContent>
					<Stack minWidth={428}>
						<Stack spacing={2}>
							<Stack
								direction={'row'}
								justifyContent={'space-between'}>
								<Typography variant={'h3'}>{'결제 금액'}</Typography>
								<Typography variant={'h3'}>{numberWithCommasAnd원(payment.amount)}</Typography>
							</Stack>
							<현금영수증선택Stack
								refundMode={true}
								//@ts-ignore
								options={values.options}
								setOptions={(options) => setValues({ ...values, options })}
							/>
							{values.options.type !== '현금영수증미발급' && (
								<현금영수증발급ActionStack
									options={values.options}
									setOptions={(options) => setValues({ ...values, options })}
								/>
							)}
						</Stack>

						{errors.submit && (
							<Stack direction={'row'}>
								<Typography
									variant='subtitle1'
									color={'error'}>
									{errors.submit}
								</Typography>
							</Stack>
						)}
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button
						variant={'contained'}
						// type={'submit'}
						onClick={() => {
							handleSubmit();
						}}
						disabled={!isValid || isSubmitting}>
						확인
					</Button>
					<Button
						variant={'outlined'}
						disabled={isSubmitting}
						onClick={handleClose}>
						취소
					</Button>
				</DialogActions>
			</form>
		</WithDialog.XlDialog>
	);
};
