import { createContext, useContext, useState, useEffect } from 'react';

const EventSourceContext = createContext<EventSource | null>(null); // EventSource는 내장 type LIKE BOX OR DIV

// 이전 버전
// 왜 함수로 만들었지, 단순히 값을 return해 주는 것 밖에는 없는데 => 재사용성, 응집성을 위해서
const useEventSource = () => {
	const eventSource = useContext(EventSourceContext);
	// console.log(eventSource)
	// if (!eventSource) throw new Error("[DEV] useEventStore must be used in EventSourceContextProvider")

	return eventSource;
};

interface EventSourceProvider {
	url: string;
	onReconnect?: () => void;
	onConnect?: () => void;
	onDisconnect?: () => void;
}
export const EventSourceProvider: React.FC<React.PropsWithChildren<EventSourceProvider>> & {
	useEventSource: () => EventSource | null;
} = ({ url, children, onConnect, onReconnect, onDisconnect }) => {
	// 여기서부터 바로 new EventSource 를 하면 서버쪽에서 문제가 생기는 이슈가 있음
	const [eventSource, setEventSource] = useState<EventSource | null>(null);

	useEffect(() => {
		const eventSource = new EventSource(url);

		// 창구가 열렸을 때
		eventSource.onopen = (e) => {
			// console.log('Event Source Open : ', e);
			onConnect && onConnect();

			onReconnect && onReconnect();
		};

		// 창구에 문제가 생겼을 때
		eventSource.onerror = (e) => {
			onDisconnect && onDisconnect();

			console.log('Event Source Error :', e);
		};

		setEventSource(eventSource);
		console.log('Event Source Connected');
		onConnect && onConnect();

		return () => {
			// 창구를 닫을 때, URL이 바뀔때 마다 연결을 끊었다가 다시 연결을 한다.
			eventSource.close();
			onDisconnect && onDisconnect();

			console.log('Event Source Closed');
		};
	}, [url]);

	return <EventSourceContext.Provider value={eventSource}>{children}</EventSourceContext.Provider>;
};
EventSourceProvider.useEventSource = useEventSource;
