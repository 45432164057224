import {
	CashUnitKeyType,
	CashUnitType,
	PosStatusDetailedType,
	VaultCashCheckType,
	VaultCashStatusType,
} from '@kinderlabs-pos/shared-data-type';

export type VaultcashCheckerModeType = {
	storeId: number;
	posId: number;
} & (
	| {
			type: 'OPEN';
			closeDialog: () => void;
			setPosState: React.Dispatch<React.SetStateAction<PosStatusDetailedType | undefined>>;
	  }
	| {
			type: 'CLOSE';
			closeDialog?: () => void;
			onComplete?: () => void;
			setPosState?: React.Dispatch<React.SetStateAction<PosStatusDetailedType | undefined>>;
	  }
	| { type: Extract<VaultCashCheckType, 'DEPOSIT' | 'WITHDRAW' | 'CHECK'> }
);

export const initNumBills: CashUnitType = {
	cash50000: 0,
	cash10000: 0,
	cash5000: 0,
	cash1000: 0,
	cash500: 0,
	cash100: 0,
	cash50: 0,
	cash10: 0,
};

export type VaultcashFunctionType = {
	total: number;
	numBills: CashUnitType;
	setNumBills: React.Dispatch<React.SetStateAction<CashUnitType>>;
	selectedBill: CashUnitKeyType;
	setSelectedBill: React.Dispatch<React.SetStateAction<CashUnitKeyType>>;
};
