import { DayCalendarFilterOptionType } from '@kinderlabs-pos/shared-data-type';
import { Stack, StackProps, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

export const CalendarSelectIndicator = ({
	onClick,
	option,
	height = 34,
}: {
	onClick: StackProps['onClick'];
	option: DayCalendarFilterOptionType;
	height?: number;
}) => {
	return (
		<Stack
			direction={'row'}
			alignItems={'center'}
			flex={1}
			spacing={1}
			borderRadius={1}
			height={height}
			px={1}
			sx={{ border: `1px solid ${grey[400]}` }}
			onClick={onClick}>
			<Typography
				variant={'body2'}
				color={'black'}>
				{`${option.from.format('YYYY.MM.DD')} ~ ${option.to.format('YYYY.MM.DD')}`}
			</Typography>
		</Stack>
	);
};
