import { InfoCircleOutlined } from '@ant-design/icons';
import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import InfoIcon from '@mui/icons-material/Info';
import { IconButtonProps, Typography } from '@mui/material';

export interface ICheckDetailButtonProps {
	onClick: IconButtonProps['onClick'];
	disabled?: boolean;
	label?: string;
}
export const CheckDetailButton: React.FC<ICheckDetailButtonProps> = ({
	onClick,
	disabled,
	label,
}) => {
	return (
		<IconTextButton
			disabled={disabled}
			iconSize='large'
			onClick={onClick}
			icon={<InfoIcon fontSize='small' />}
			label={<Typography>{label || '원주문 보기'}</Typography>}
		/>
	);
};
