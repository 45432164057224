import { useTheme } from '@mui/material';
import { PosBoardBaseItem } from './PosBoardBaseItem';

export const PosBoardEmptyItem = () => {
	const theme = useTheme();

	return (
		<PosBoardBaseItem
			CardProps={{
				sx: {
					color: 'white',
					backgroundColor: theme.palette.divider,
				},
			}}
			CardActionProps={{
				disabled: true,
				sx: {
					'&.Mui-disabled': {
						backgroundColor: 'unset',
					},
				},
			}}
		/>
	);
};
