import { EventSourceProvider } from '@kinderlabs-pos/apis/contexts';
import {
	DeviceType,
	DomainEventType,
	GuestVisitHistoryType,
	GuestVisitType,
	KitchenOrderInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { rootUrl } from 'libs/apis/waivers/src/WaiverApis/const';

import { useEffect } from 'react';

export interface ISubscriberProviderProps {
	storeId: number;
	deviceType: DeviceType;
	subscriberId: number; // 나중에 subscriberId 로 바꾸자. - displayer, pos 두가지가 있기 때문
	onConnect?: () => void;
	onReconnect?: () => void;
	onDisconnect?: () => void;
	guestVisitActions?: {
		onGuestVisitCall?: (eventData: DomainEventType<GuestVisitType>) => void;
		onGuestVisitRegister?: (eventData: DomainEventType<GuestVisitType>) => void;
		onGuestVisitHistory?: (eventData: DomainEventType<GuestVisitHistoryType>) => void;
	};
	kitchenOrderActions?: {
		onKitchenOrder?: (eventData: DomainEventType<KitchenOrderInfoType>) => void;
	};
}
export const EventSubscriberProvider: React.FC<
	React.PropsWithChildren<ISubscriberProviderProps & { enterpriseId: string }>
> = ({
	children,
	enterpriseId,
	storeId,
	subscriberId,
	deviceType,
	onConnect,
	onDisconnect,
	onReconnect,
	...others
}) => {
	const serviceUrl = `${rootUrl}/sse-service/${enterpriseId}/sse/store/${storeId}/subscribe?id=${subscriberId}&type=${deviceType}`;

	return (
		<EventSourceProvider
			url={serviceUrl}
			onConnect={onConnect}
			onReconnect={onReconnect}
			onDisconnect={onDisconnect}>
			<SubscriberProviderInner {...others}>{children}</SubscriberProviderInner>
		</EventSourceProvider>
	);
};

const SubscriberProviderInner: React.FC<
	React.PropsWithChildren<
		Omit<ISubscriberProviderProps, 'subscriberId' | 'storeId' | 'deviceType' | 'connectActions'>
	>
> = ({ children, kitchenOrderActions, guestVisitActions }) => {
	const eventSource = EventSourceProvider.useEventSource();

	useEffect(() => {
		if (!eventSource) return;

		eventSource.onmessage = (e) => {
			if (e == null) return;
			try {
				const eventData: DomainEventType<any> = JSON.parse(e.data) as DomainEventType<any>;

				if (guestVisitActions) {
					const { onGuestVisitCall, onGuestVisitRegister, onGuestVisitHistory } = guestVisitActions;
					switch (eventData.type) {
						case 'GuestCall':
							onGuestVisitCall && onGuestVisitCall(eventData);
							break;
						case 'GuestVisitPublish':
							onGuestVisitRegister && onGuestVisitRegister(eventData);
							break;
						case 'GuestVisitStatusChange':
							onGuestVisitHistory && onGuestVisitHistory(eventData);
							break;
					}
				}

				if (kitchenOrderActions) {
					const { onKitchenOrder } = kitchenOrderActions;
					switch (eventData.type) {
						case 'KitchenOrderCall':
							onKitchenOrder && onKitchenOrder(eventData);
							break;
						case 'KitchenOrderStatusChange':
							onKitchenOrder && onKitchenOrder(eventData);
							break;
					}
				}
			} catch {
				return;
			}
		};
	}, [eventSource, guestVisitActions, kitchenOrderActions]);

	return <>{children}</>;
};

// const useSubscriber = () => EventSourceProvider.useEventSource();
