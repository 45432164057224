import { VaultCashState } from '@kinderlabs-pos/state';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
	Divider,
	Stack,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { VaultcashCheckerModeType } from './common';
import { getDateFormatWithHyphen, getToday } from '@kinderlabs-pos/shared-util';
import { CashSalesType, VaultCashStatusDetailType } from '@kinderlabs-pos/shared-data-type';

const today = getToday();

export const FinalResultDialog: React.FC<
	DialogProps & {
		checkMode: VaultcashCheckerModeType;
		closeDialog: () => void;
		closeCheckDialog: () => void;
		cashStatus: VaultCashStatusDetailType | null;
		cashSales: CashSalesType | null;
	}
> = ({ open, closeDialog, closeCheckDialog, checkMode, cashStatus, cashSales }) => {
	return (
		<Dialog
			open={open}
			fullWidth={true}>
			<DialogTitle>
				<Typography variant='h1'>시재 점검 결과</Typography>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Stack direction='column'>
						{checkMode.type === 'OPEN' && (
							<>
								<Stack
									direction='row'
									justifyContent='space-between'>
									<Typography variant='subtitle1'>영업시작 시각</Typography>
									<Typography variant='subtitle1'>
										{dayjs(today).format('YYYY-MM-DD HH:mm:ss')}
									</Typography>
								</Stack>
								<Divider sx={{ mt: 1, mb: 1 }} />
								<Stack
									direction='row'
									justifyContent='space-between'>
									<Typography
										variant='subtitle1'
										color={'black'}
										fontWeight={'bold'}>
										입력금액
									</Typography>
									<Typography
										variant='subtitle1'
										color={'black'}
										fontWeight={'bold'}>
										{cashStatus?.countedAmount.toLocaleString()} 원
									</Typography>
								</Stack>
								<Stack
									direction='row'
									justifyContent='space-between'>
									<Typography
										variant='subtitle1'
										color={'black'}
										fontWeight={'bold'}>
										기대현금 (직전영업마감금)
									</Typography>
									<Typography
										variant='subtitle1'
										color={'black'}
										fontWeight={'bold'}>
										{cashStatus?.expectedAmount.toLocaleString()} 원
									</Typography>
								</Stack>
								<Divider sx={{ mt: 1, mb: 1 }} />
								<Stack
									direction='row'
									justifyContent='space-between'>
									<Typography
										variant='h4'
										color={'red'}>
										과부족 (직전영업마감금 대비)
									</Typography>
									<Typography
										variant='h4'
										color={'red'}>
										{cashStatus?.overAndShort.toLocaleString()} 원
									</Typography>
								</Stack>
								<Stack>
									<Typography
										variant='body2'
										color='error'>
										※ 직전일대비 과부족은 현재 영업일 과부족에 영향을 미치지 않습니다.
									</Typography>
								</Stack>
							</>
						)}
						{(checkMode.type === 'CHECK' || checkMode.type === 'CLOSE') && (
							<>
								{checkMode.type === 'CHECK' && (
									<Stack
										direction='row'
										justifyContent='space-between'>
										<Typography variant='subtitle1'>점검시각</Typography>
										<Typography variant='subtitle1'>
											{dayjs(today).format('YYYY-MM-DD HH:mm:ss')}
										</Typography>
									</Stack>
								)}
								{checkMode.type === 'CLOSE' && (
									<>
										<Stack
											direction='row'
											justifyContent='space-between'>
											<Typography variant='subtitle1'>영업시작 시각</Typography>
											<Typography variant='subtitle1'>
												{dayjs(cashStatus?.openInfo.date).format('YYYY-MM-DD HH:mm:ss')}
											</Typography>
										</Stack>
										<Stack
											direction='row'
											justifyContent='space-between'>
											<Typography variant='subtitle1'>영업마감 시각</Typography>
											<Typography variant='subtitle1'>
												{dayjs(today).format('YYYY-MM-DD HH:mm:ss')}
											</Typography>
										</Stack>
									</>
								)}
								<Divider sx={{ mt: 1, mb: 1 }} />
								<Stack
									direction='row'
									justifyContent='space-between'>
									<Typography
										variant='subtitle1'
										color={'black'}
										fontWeight={'bold'}>
										입력금액
									</Typography>
									<Typography
										variant='subtitle1'
										color={'black'}
										fontWeight={'bold'}>
										{cashStatus?.countedAmount.toLocaleString()} 원
									</Typography>
								</Stack>
								<Stack
									direction='row'
									justifyContent='space-between'>
									<Typography variant='subtitle1'>영업준비금</Typography>
									<Typography variant='subtitle1'>
										{cashStatus ? cashStatus.openInfo.totalCash.toLocaleString() : '미확인'} 원
									</Typography>
								</Stack>
								<Stack
									direction='row'
									justifyContent='space-between'>
									<Typography variant='subtitle1'>현금매출</Typography>
									<Typography variant='subtitle1'>
										{cashSales ? cashSales.cashAmount.toLocaleString() : '미확인'} 원
									</Typography>
								</Stack>
								<Stack
									direction='row'
									justifyContent='space-between'>
									<Typography variant='subtitle1'>입금액</Typography>
									<Typography variant='subtitle1'>
										{cashStatus ? cashStatus.deposits.toLocaleString() : '미확인'} 원
									</Typography>
								</Stack>
								<Stack
									direction='row'
									justifyContent='space-between'>
									<Typography variant='subtitle1'>출금액</Typography>
									<Typography variant='subtitle1'>
										{cashStatus ? cashStatus.withdraws.toLocaleString() : '미확인'} 원
									</Typography>
								</Stack>
								<Stack
									direction='row'
									justifyContent='space-between'>
									<Typography
										variant='subtitle1'
										color={'black'}
										fontWeight={'bold'}>
										기대현금
									</Typography>
									<Typography
										variant='subtitle1'
										color={'black'}
										fontWeight={'bold'}>
										{cashStatus?.expectedAmount.toLocaleString()} 원
									</Typography>
								</Stack>
								<Divider sx={{ mt: 1, mb: 1 }} />
								<Stack
									direction='row'
									justifyContent='space-between'>
									<Typography
										variant='h4'
										color={'red'}>
										과부족
									</Typography>
									<Typography
										variant='h4'
										color={'red'}>
										{cashStatus?.overAndShort.toLocaleString()} 원
									</Typography>
								</Stack>
							</>
						)}
						{(checkMode.type === 'WITHDRAW' || checkMode.type === 'DEPOSIT') && (
							<Typography variant='h4'>완료</Typography>
						)}
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					onClick={() => {
						closeDialog();
						closeCheckDialog();
						(checkMode.type === 'OPEN' || checkMode.type === 'CLOSE') &&
							!!checkMode.closeDialog &&
							checkMode.closeDialog();
						if (checkMode.type === 'CLOSE') checkMode.onComplete && checkMode.onComplete();
					}}>
					확인
				</Button>
			</DialogActions>
		</Dialog>
	);
};
