import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { NumberBoard } from '@kinderlabs-pos/ui-components';
import { Button, DialogActions, DialogContent, OutlinedInput, Stack } from '@mui/material';
import { useState } from 'react';

export interface ISplitPayDialogProps extends XlDialogProps {
	결제할금액: number;
	set결제할금액: React.Dispatch<React.SetStateAction<number>>;
	remainingPay: number;
}
export const SplitPayDialog: React.FC<ISplitPayDialogProps> = ({
	결제할금액,
	set결제할금액,
	remainingPay,
	open,
	closeDialog,
}) => {
	const [tempValue, setTempValue] = useState<number>(결제할금액);

	return (
		<WithDialog.XlDialog
			dialogTitle={'분할 결제 금액 입력'}
			open={open}>
			<DialogContent>
				<Stack spacing={1}>
					<OutlinedInput
						type={'number'}
						value={tempValue}
						startAdornment={'₩ '}
						sx={(theme) => ({
							fontSize: theme.typography.h3.fontSize,
						})}
						onChange={(e) => setTempValue(+e.target.value)}
					/>
					<NumberBoard
						isInitial={tempValue === remainingPay}
						value={tempValue}
						setValue={setTempValue}
						minValue={0}
						maxValue={remainingPay}
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					variant={'contained'}
					onClick={() => {
						set결제할금액(tempValue);
						closeDialog && closeDialog();
					}}>
					확인
				</Button>
				<Button
					variant={'outlined'}
					onClick={closeDialog}>
					취소
				</Button>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
