import { VaultCashAPI } from '@kinderlabs-pos/apis/pos';
import { VaultCashCheckType, VaultCashStatusType } from '@kinderlabs-pos/shared-data-type';
import { PosStatusQueryState } from '@kinderlabs-pos/state';
import { VaultCashState } from '.';
import { QueryClient } from '../QueryClient';

const useChangePosStatus = () => {
	return QueryClient.useMutation(
		async ({
			checkType,
			payload,
		}: {
			checkType: VaultCashCheckType;
			payload: VaultCashStatusType;
		}) => {
			await VaultCashAPI.postChangePosStatus({
				checkType,
				payload,
			});
		},
		{
			onSuccess: () => {
				VaultCashState.invalidateStatus();
				PosStatusQueryState.invalidateQueries();
			},
		}
	);
};

const useCancelPosClose = () => {
	return QueryClient.useMutation(
		async ({ posId }: { posId: number }) => {
			await VaultCashAPI.postCancelPosClose({
				posId,
			});
		},
		{
			onSuccess: () => {
				VaultCashState.invalidateStatus();
				PosStatusQueryState.invalidateQueries();
			},
		}
	);
};

export const VaultCashActions = {
	useChangePosStatus,
	useCancelPosClose,
};
