import { KitchenOrderQueryState } from '@kinderlabs-pos/state';
import { CardStack } from '@kinderlabs-pos/ui-atoms';
import { FullSizeCircularProgress, RotatingRefreshButton } from '@kinderlabs-pos/ui-components';
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Suspense, useEffect, useState } from 'react';
import { KitchenOrderPannels } from './KitchenOrderPannels';

export const KitchenPosDrawerContents = ({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: (open: boolean) => void;
}) => {
	const [kitchenTabValue, setKitchTabValue] = useState<'ALL' | 'ORDER-COOK' | 'COMPLETE-CANCEL'>(
		'ALL'
	);

	useEffect(() => {
		setKitchTabValue('ALL');
	}, [open]);

	return (
		<Stack
			flex={1}
			p={1}
			spacing={1}
			overflow={'auto'}>
			<Stack
				direction={'row'}
				spacing={1}>
				<ToggleButtonGroup
					fullWidth
					exclusive
					onChange={(e, value) => {
						if (!value) return;
						setKitchTabValue(value as 'ALL' | 'ORDER-COOK' | 'COMPLETE-CANCEL');
					}}
					size={'small'}
					value={kitchenTabValue}
					sx={{ flex: 1 }}>
					{[
						{ value: 'ALL', label: '전체' },
						{ value: 'ORDER-COOK', label: '접수 / 조리중' },
						{ value: 'COMPLETE-CANCEL', label: '완료 / 취소됨' },
					].map((info, idx) => (
						<ToggleButton
							key={idx}
							value={info.value}>
							<Typography variant='subtitle1'>{info.label}</Typography>
						</ToggleButton>
					))}
				</ToggleButtonGroup>
				<RotatingRefreshButton
					handleClick={() => {
						KitchenOrderQueryState.invalidateQueries();
					}}
				/>
			</Stack>
			<CardStack
				flex={1}
				spacing={1}
				overflow={'auto'}>
				<Suspense fallback={<FullSizeCircularProgress />}>
					<KitchenOrderPannels
						tabValue={kitchenTabValue}
						closePannel={() => setOpen(false)}
					/>
				</Suspense>
			</CardStack>
		</Stack>
	);
};
