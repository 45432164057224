import { Button, Stack, Typography } from '@mui/material';
import { usePosRouter } from '../../routes';
import { SmileOutlined } from '@ant-design/icons';

export const StoreCloseResultPage = () => {
	const { navigate통계 } = usePosRouter();

	return (
		<Stack
			height={'100%'}
			overflow={'auto'}
			direction={'column'}
			flex={1}
			justifyContent={'space-between'}
			spacing={2}>
			<Stack
				flex={1}
				spacing={3}
				justifyContent={'center'}
				alignItems={'center'}>
				<Typography variant={'h3'}>
					<SmileOutlined style={{ fontSize: 48 }} />
				</Typography>
				<Typography variant={'h3'}>{`해당 POS의 영업이 마감되었습니다.`}</Typography>
				<Typography variant={'h3'}>{`오늘도 수고하셨습니다.`}</Typography>
			</Stack>
			<Stack
				justifyContent={'flex-end'}
				alignItems={'flex-end'}>
				<Button
					variant='outlined'
					onClick={navigate통계}>
					{'매출 현황 확인'}
				</Button>
			</Stack>
		</Stack>
	);
};
