import { useIsLargeTablet, useIsMobile } from '@kinderlabs-pos/shared-react-util';
import {
	FormControl,
	Unstable_Grid2 as Grid,
	MenuItem,
	PaginationItem,
	Select,
	SelectChangeEvent,
	Stack,
	Typography,
} from '@mui/material';
import { PaginationState, Table as TableType } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';

// export const NewMuiTablePagination = <TData,>({
// 	table,
// 	setSorting,
// 	onScroll,
// }: {
// 	table: TableType<TData>;
// 	setSorting?: React.Dispatch<React.SetStateAction<SortingState>>;
// 	onScroll?: React.UIEventHandler<HTMLDivElement>;
// }) => {

interface TablePaginationProps<TData> {
	table: TableType<TData>;
	onlyPaginationNumbers?: boolean;
	// pagination: PaginationState;
	// setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
}
export const NewMuiTablePagination = <TData,>({
	table,
	onlyPaginationNumbers = false,
}: // pagination,
// setPagination,
TablePaginationProps<TData>) => {
	const pagination = table.getState().pagination;
	const setPagination = table.options.onPaginationChange as React.Dispatch<
		React.SetStateAction<PaginationState>
	>;

	const [open, setOpen] = useState(false);
	const isTablet = useIsLargeTablet();
	const totalPageCount = table.getPageCount();

	const paginationItemNumbers: number = isTablet ? 5 : 10;

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleChange = (event: SelectChangeEvent<number>) => {
		setPagination((prev) => ({ ...prev, pageSize: +event.target.value }));
	};

	useEffect(() => {
		if (pagination.pageIndex >= totalPageCount)
			setPagination((prev) => ({ ...prev, pageIndex: 0 }));
	}, [pagination.pageIndex, totalPageCount]);

	return (
		<Grid
			py={1}
			px={3}
			container
			alignItems='center'
			justifyContent='space-between'
			sx={{ width: 'auto' }}>
			{!onlyPaginationNumbers && (
				<Grid
					// item
					lg={3}
					xs={0}
					display={{ xs: 'none', lg: 'block' }}>
					<Stack
						width={'100%'}
						spacing={1}
						alignItems='flex-start'>
						<Stack>{<Typography>총 {totalPageCount} 페이지</Typography>}</Stack>
					</Stack>
				</Grid>
			)}
			<Grid
				// item
				lg={onlyPaginationNumbers ? 12 : 6}
				xs={12}>
				<Stack
					width={'100%'}
					alignItems={'center'}
					spacing={5}>
					<Stack
						direction={'row'}
						spacing={2}>
						<PaginationItem
							disabled={!table.getCanPreviousPage()}
							onClick={() => {
								setPagination((prev) => ({
									...prev,
									pageIndex: Math.max(prev.pageIndex - paginationItemNumbers, 0),
								}));
							}}
							type={'previous'}
							variant='squared'
						/>
						<Stack direction={'row'}>
							{table
								.getPageOptions()
								.filter((pageNumber) => {
									const startIndex =
										Math.floor(pagination.pageIndex / paginationItemNumbers) *
										paginationItemNumbers;
									// const startIndex = Math.floor(pagination.pageIndex / 10) * 10;
									return (
										pageNumber >= startIndex && pageNumber < startIndex + paginationItemNumbers
									);
								})
								.map((pageNumber, idx) => (
									<PaginationItem
										key={idx}
										selected={pageNumber === pagination.pageIndex}
										component={'div'}
										page={pageNumber + 1}
										color='primary'
										variant='squared'
										onClick={() => {
											setPagination((prev) => ({ ...prev, pageIndex: pageNumber }));
										}}
									/>
								))}
						</Stack>
						<PaginationItem
							disabled={!table.getCanNextPage()}
							onClick={() => {
								setPagination((prev) => ({
									...prev,
									pageIndex: Math.min(prev.pageIndex + paginationItemNumbers, totalPageCount - 1),
								}));
							}}
							type={'next'}
							variant='squared'
						/>
					</Stack>
				</Stack>
			</Grid>
			{!onlyPaginationNumbers && (
				<Grid
					// item
					xs={0}
					lg={3}
					display={{ xs: 'none', lg: 'block' }}>
					<Stack
						width={'100%'}
						spacing={1}
						direction={'row'}
						alignItems={'center'}
						justifyContent='flex-end'>
						{<Typography variant={'body1'}>{'페이지 당'}</Typography>}

						<FormControl sx={{ m: 1 }}>
							<Select
								id='demo-controlled-open-select'
								open={open}
								onClose={handleClose}
								onOpen={handleOpen}
								value={pagination.pageSize}
								onChange={handleChange}
								size='small'
								sx={{ '& .MuiSelect-select': { py: 0.75, px: 1.25 } }}>
								<MenuItem value={5}>5 개</MenuItem>
								<MenuItem value={10}>10 개</MenuItem>
								<MenuItem value={20}>20 개</MenuItem>
								<MenuItem value={100}>100 개</MenuItem>
								<MenuItem value={1000}>1000 개</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</Grid>
			)}
		</Grid>
	);
};
