import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import { Typography, IconButtonProps } from '@mui/material';

export interface IParkingButtonProps extends IconButtonProps { }
export const ParkingButton: React.FC<IParkingButtonProps> = ({
  onClick
}) => {
  return (
    <IconTextButton
      onClick={onClick}
      variant='white'
      icon={<DriveEtaIcon fontSize="small" />}
      label={<Typography>
        {"주차 등록"}
      </Typography>}
    />
  )
};