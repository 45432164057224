import { OrderType, PointUseInfoType } from '@kinderlabs-pos/shared-data-type';
import { OrderState, PaymentState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { PointUsageDialogContent } from './PointUsageDialogContent';

export type HandleSubmitPointPaymentArgsType = {
	method: 'POINT';
	amount: number;
	memberId: number;
};

export interface IPointUsageDialogProps extends XlDialogProps {
	mode: OrderType['type'];
	handleSubmitPayment: (args: PointUseInfoType) => Promise<void>;
	storeId: number;
	posId: number;
	// orderId?: OrderType['id'];
}
export const PointUsageDialog = ({
	storeId,
	posId,
	// orderId,
	mode,
	open,
	closeDialog,
	handleSubmitPayment,
}: // submitPointPayment,
IPointUsageDialogProps) => {
	const orderState = useAtomValue(OrderState.value);
	const handleSubmitPaymentPM = PaymentState.actions.submit.useHandlePaymentPM({
		storeId,
		posId,
		// orderId,
	});

	const handleSubmitPointPayment = useCallback(
		async (args: HandleSubmitPointPaymentArgsType) => {
			const paymentRequestData = await handleSubmitPaymentPM(
				{
					method: args.method,
					memberId: args.memberId,
					amount: args.amount,
				},
				orderState
			);

			await handleSubmitPayment(paymentRequestData as PointUseInfoType);
		},
		[handleSubmitPayment]
	);

	return (
		<WithDialog.XlDialog
			dialogTitle={'포인트 사용'}
			open={open}
			closeDialog={closeDialog}>
			{
				<PointUsageDialogContent
					mode={mode}
					submitPointPayment={handleSubmitPointPayment}
					closeDialog={closeDialog}
				/>
			}
		</WithDialog.XlDialog>
	);
};
