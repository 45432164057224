import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

/**
 * Stack 을 통해 Key: Value 정보를 줄 단위로 표현할때 유용
 */
export const InfoPrintStackRow = ({
	label,
	value,
	font = 'body1',
	spaceBetween = false,
}: {
	label: ReactNode;
	value: ReactNode;
	font?: 'body1' | 'body2';
	spaceBetween?: boolean;
}) => {
	return (
		<Stack
			direction={'row'}
			alignItems={'center'}>
			<Stack
				flex={1}
				alignItems={'flex-start'}>
				<Typography variant={font}>{label}</Typography>
			</Stack>
			<Stack
				flex={1}
				alignItems={spaceBetween ? 'flex-end' : 'flex-start'}>
				<Typography variant={font}>{value}</Typography>
			</Stack>
		</Stack>
	);
};
