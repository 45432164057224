import { InboxOutlined } from '@ant-design/icons';
import { VaultCashAPI } from '@kinderlabs-pos/apis/pos';
import { StoreInfoState } from '@kinderlabs-pos/state';
import { Button, Stack, Typography } from '@mui/material';
import { VaultcashChecker } from 'apps/pos-main/src/components/VaultcashChecker';
import { useAtomValue } from 'jotai';

export const StoreCloseCheck = ({ onComplete }: { onComplete: () => void }) => {
	const storeId = useAtomValue(StoreInfoState.curStoreInfo).id;
	const posInfo = useAtomValue(StoreInfoState.curPosInfo);

	const onClick다음WhenWebPos = async () => {
		await VaultCashAPI.closePosWithout시재점검({
			storeId: storeId,
			posId: posInfo.id,
		});

		onComplete();
	};

	return (
		<Stack
			direction={'column'}
			flexGrow={1}>
			{posInfo.posType === 'WEBPOS' ? (
				<Stack flex={1}>
					<Stack
						flex={1}
						spacing={1}
						alignItems={'center'}
						justifyContent={'center'}>
						<Typography variant='h3'>
							<InboxOutlined style={{ fontSize: 36 }} />
						</Typography>
						<Typography variant='h3'>시재점검 필요없음</Typography>
					</Stack>
					<Stack alignItems={'flex-end'}>
						<Button
							variant='contained'
							onClick={onClick다음WhenWebPos}>
							다음
						</Button>
					</Stack>
				</Stack>
			) : (
				<VaultcashChecker
					mode={{
						type: 'CLOSE',
						storeId,
						posId: posInfo.id,
						onComplete,
					}}
				/>
			)}
		</Stack>
	);
};
