import { VaunceAppTicketOnPosType } from '@kinderlabs-pos/shared-data-type';
import { useMemo } from 'react';

export const getSortedUserIdList = <T extends { canUseTicket: boolean }>({
	ticketOrCouponInfoMap,
}: {
	ticketOrCouponInfoMap: { [userId: string]: T[] };
}) => {
	return useMemo(
		() =>
			[...Object.keys(ticketOrCouponInfoMap)].map(Number).sort((memberId, nextMemberId) => {
				if (전부사용불가능한지(ticketOrCouponInfoMap[memberId])) {
					return 1;
				} else if (전부사용불가능한지(ticketOrCouponInfoMap[nextMemberId])) {
					return -1;
				} else {
					return 0;
				}
			}),
		[ticketOrCouponInfoMap]
	);
};

const 전부사용불가능한지 = <T extends { canUseTicket: boolean }>(tickets: T[]) =>
	tickets.every((ticket) => !ticket.canUseTicket);
