import { ReactPMIPC } from '@kinderlabs-pos/ipc/react';
import {
	PaymentResponseType,
	VanCompanyType,
	calculateVAT,
} from '@kinderlabs-pos/shared-data-type';
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import { SettingMenuItem } from '../common';
import { StoreInfoState } from '@kinderlabs-pos/state';
import { useAtomValue } from 'jotai';

export const PaymentTestPage = () => {
	const [methodIndex, setMethodIndex] = useState(0);
	let payAmount = 10;
	let payOption = '0';
	let paymentResponse: PaymentResponseType;
	const { vanCompany } = useAtomValue(StoreInfoState.curPosInfo);
	const [info, setInfo] = useState<string>('');
	const requestBin = async () => {
		try {
			paymentResponse = await ReactPMIPC.invoke카드BIN요청({
				vanCompany: vanCompany,
			});
			setInfo(paymentResponse.bin);
		} catch (e) {
			setInfo('대기시간 초과');
		}
		console.log(paymentResponse);
	};
	const requestCardInserted = async () => {
		try {
			paymentResponse = await ReactPMIPC.invoke카드삽입여부확인요청({
				vanCompany: vanCompany,
			});
			setInfo(paymentResponse.isInserted ? '카드 삽입됨' : '카드 미삽입');
		} catch (e) {
			setInfo('확인 오류');
		}
		console.log(paymentResponse);
	};

	const request결제모듈입력 = async () => {
		try {
			paymentResponse = await ReactPMIPC.invoke서명패드숫자입력요청({
				vanCompany: vanCompany,
			});
			setInfo(paymentResponse.responseMessage ?? '메세지없음');
		} catch (e) {
			setInfo('확인 오류');
		}
		console.log(paymentResponse);
	};

	const requestApproval = async () => {
		methodIndex === 0
			? (paymentResponse = await ReactPMIPC.invoke현금영수증승인({
					vanCompany: vanCompany,
					amount: payAmount,
					tax: calculateVAT({ revenue: payAmount, taxFree: false }),
					customerId: payOption,
					isEnterprise: false,
					isSignPadUsed: false,
			  }))
			: (paymentResponse = await ReactPMIPC.invoke카드IC승인({
					vanCompany: vanCompany,
					amount: payAmount,
					tax: calculateVAT({ revenue: payAmount, taxFree: false }),
					installment: parseInt(payOption),
			  }));
		console.log(paymentResponse);
	};

	const requestCancel = async () => {
		methodIndex === 0
			? (paymentResponse = await ReactPMIPC.invoke현금영수증취소({
					vanCompany: vanCompany,
					amount: payAmount,
					tax: calculateVAT({ revenue: payAmount, taxFree: false }),
					customerId: payOption,
					isEnterprise: false,
					isSignPadUsed: false,
					approvalDate: paymentResponse.approvalDate,
					approvalId: paymentResponse.approvalId,
			  }))
			: (paymentResponse = await ReactPMIPC.invoke카드IC취소({
					vanCompany: vanCompany,
					amount: payAmount,
					tax: calculateVAT({ revenue: payAmount, taxFree: false }),
					installment: parseInt(payOption),
					approvalDate: paymentResponse.approvalDate,
					approvalId: paymentResponse.approvalId,
			  }));
		console.log(paymentResponse);
	};

	const requestForcedCancel = async () => {
		await ReactPMIPC.invoke결제강제취소({ vanCompany: vanCompany });
	};

	return (
		<Stack
			direction={'column'}
			flexGrow={1}>
			<SettingMenuItem
				title='VAN 설정'
				innerElement={
					<TextField
						id='van'
						variant='outlined'
						value={vanCompany}
					/>
				}
			/>
			<SettingMenuItem
				title='테스트 결제 수단'
				innerElement={
					<FormControl>
						<FormLabel id='pay_method'></FormLabel>
						<RadioGroup
							row
							name='pay_method'
							defaultValue='cash'
							onChange={(e) => {
								e.target.value === 'cash' ? setMethodIndex(0) : setMethodIndex(1);
							}}>
							<FormControlLabel
								value='cash'
								control={<Radio />}
								label='현금영수증 발급'
							/>
							<FormControlLabel
								value='card'
								control={<Radio />}
								label='신용/체크카드'
							/>
							<FormControlLabel
								value='easypay'
								control={<Radio />}
								label='간편결제'
								disabled
							/>
						</RadioGroup>
					</FormControl>
				}
			/>
			<SettingMenuItem
				title='결제 금액(원)'
				innerElement={
					<TextField
						id='payAmount'
						variant='outlined'
						placeholder='최소결제금액 : 10원'
						onChange={(e) => {
							payAmount = parseInt(e.target.value);
						}}></TextField>
				}
			/>
			{methodIndex === 0 && (
				<SettingMenuItem
					title='소비자/사업자 번호'
					innerElement={
						<TextField
							id='amount'
							variant='outlined'
							placeholder='010-1234-1234'
							onChange={(e) => {
								payOption = e.target.value.replace(/[^0-9]/g, '');
							}}></TextField>
					}
				/>
			)}
			{methodIndex === 1 && (
				<SettingMenuItem
					title='할부 개월'
					innerElement={
						<Select
							sx={{ height: 45 }}
							defaultValue='0'
							onChange={(e) => {
								payOption = e.target.value;
							}}>
							<MenuItem value='0'>일시불</MenuItem>
							<MenuItem value='3'>3개월</MenuItem>
							<MenuItem value='6'>6개월</MenuItem>
							<MenuItem value='12'>12개월</MenuItem>
						</Select>
					}
				/>
			)}
			<SettingMenuItem
				title='결제 형태'
				innerElement={
					<Stack
						direction={'row'}
						spacing={1}>
						<Button
							variant='outlined'
							onClick={requestApproval}>
							결제 승인
						</Button>
						<Button
							variant='outlined'
							onClick={requestCancel}>
							직전 결제 취소
						</Button>
					</Stack>
				}
			/>
			<SettingMenuItem
				title='기타 기능'
				innerElement={
					<Stack
						direction={'row'}
						spacing={1}>
						<Button
							variant='outlined'
							onClick={requestForcedCancel}>
							결제 강제 취소
						</Button>
						<Button
							variant='outlined'
							onClick={requestBin}>
							카드 BIN 요청
						</Button>
						<Button
							variant='outlined'
							onClick={requestCardInserted}>
							카드 삽입 확인
						</Button>
						<Button
							variant='outlined'
							onClick={request결제모듈입력}>
							결제 모듈 입력 확인
						</Button>
					</Stack>
				}
			/>
			<SettingMenuItem
				title='요청 정보'
				innerElement={
					<TextField
						id='info'
						variant='outlined'
						value={info}
					/>
				}
			/>
		</Stack>
	);
};
