import { Backdrop, Button, DialogActions, DialogContent, DialogTitle, Paper } from '@mui/material';
import { ReactNode } from 'react';

export const IFrameDialogWithURI = ({
	title,
	src,
	show,
	setShow,
}: {
	title: ReactNode;
	src: string;
	show: boolean;
	setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	return (
		<Backdrop
			open={true}
			sx={(theme) => ({ display: show ? 'flex' : 'none', zIndex: theme.zIndex.tooltip })}
			onClick={() => setShow(false)}>
			<Paper sx={{ width: 850, height: 700 }}>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent
					sx={{
						display: 'flex',
						minWidth: 850,
						minHeight: 550,
						justifyContent: 'center',
					}}>
					<iframe
						src={src}
						width='800'
						height='550'
					/>
				</DialogContent>
				<DialogActions>
					<Button
						variant={'outlined'}
						onClick={() => {
							setShow(false);
						}}>
						닫기
					</Button>
				</DialogActions>
			</Paper>
		</Backdrop>
	);
};

// return (
// 	<WithDialog
// 		dialog={(open, closeDialog) => (
// 			<WithDialog.XlDialog dialogTitle={'주차 등록'} open={open} closeDialog={closeDialog}>
// 				<DialogContent sx={{ minWidth: 850, minHeight: 550 }}>
// 					<iframe src={atomValue.parkingLotUrl} width={800} height={550} referrerPolicy="" frameBorder="0"></iframe>
// 					{/* <Iframe url="https://www.naver.com" width="800" height="550" frameBorder={0}></Iframe> */}
// 				</DialogContent>
// 				<DialogActions>
// 					<Button variant={'outlined'} onClick={closeDialog}>
// 						완료
// 					</Button>
// 				</DialogActions>
// 			</WithDialog.XlDialog>
// 		)}
// 		children={(openDialog) => <ParkingButton onClick={openDialog} />}
// 	/>
// );
