import {
	CashUnitKeyType,
	CashUnitKeyTypeArray,
	CashUnitTypeValue,
} from '@kinderlabs-pos/shared-data-type';
import { Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { ActionStack } from './ActionStack';
import { BillInputTable } from './BillInputTable';
import { initNumBills, VaultcashCheckerModeType } from './common';
import { VaultcashNumberBoard } from './VaultcashNumberBoard';
import { atom, useAtom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export const VaultcashChecker = ({ mode }: { mode: VaultcashCheckerModeType }) => {
	const [checkMode, setCheckMode] = useState<VaultcashCheckerModeType>(mode);
	const [numBills, setNumBills] = useAtom(VaultcashChecker.checkAtom);
	const [selectedBill, setSelectedBill] = useState<CashUnitKeyType>('cash50000');

	const total = useMemo(
		() =>
			CashUnitKeyTypeArray.reduce(
				(arr, billsKey) => arr + numBills[billsKey] * CashUnitTypeValue[billsKey],
				0
			),
		[numBills]
	);

	return (
		<Stack
			height={'100%'}
			direction='column'
			justifyContent={'space-between'}
			spacing={2}>
			<Stack
				direction='row'
				spacing={2}
				justifyContent={'space-between'}
				alignItems={'center'}>
				<BillInputTable
					total={total}
					selectedBill={selectedBill}
					setSelectedBill={setSelectedBill}
					numBills={numBills}
					setNumBills={setNumBills}
				/>
				<VaultcashNumberBoard
					numBills={numBills}
					setNumBills={setNumBills}
					selectedBill={selectedBill}
					setSelectedBill={setSelectedBill}
				/>
			</Stack>
			<Stack
				direction={'row'}
				justifyContent={'flex-end'}>
				<ActionStack
					storeId={mode.storeId}
					posId={mode.posId}
					checkMode={checkMode}
					setCheckMode={setCheckMode}
					setNumBills={setNumBills}
					total={total}
					numBills={numBills}
					setPosState={
						(mode.type === 'OPEN' || mode.type === 'CLOSE') && mode.setPosState
							? mode.setPosState
							: undefined
					}
				/>
			</Stack>
		</Stack>
	);
};
VaultcashChecker.checkAtom = atomWithReset(initNumBills);
