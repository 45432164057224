import { PosState } from '@kinderlabs-pos/state';
import { Stack, TextField, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { SettingFinishButton, SettingMenuItem } from '../common';

export const ParkingPage = () => {
	const initialParkingLotUrl = useAtomValue(PosState.settings).parkingLotUrl;
	const [parkingLotUrl, setParkingLotUrl] = useState(initialParkingLotUrl);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const parkingLotUrl = e.target.value;
		setParkingLotUrl(parkingLotUrl);
	};

	return (
		<Stack
			direction={'column'}
			flexGrow={1}>
			<SettingMenuItem
				title='주차 페이지 설정'
				innerElement={
					<TextField
						defaultValue={parkingLotUrl}
						sx={{ width: '70%' }}
						onChange={handleChange}
						helperText={
							<Typography
								variant='subtitle1'
								color='error'
								textAlign='right'>
								'http://', 'https://' 등을 포함해주세요
							</Typography>
						}
					/>
				}
			/>
			<SettingFinishButton
				method='parking-url'
				parkingLotUrl={parkingLotUrl}
			/>
		</Stack>
	);
};
