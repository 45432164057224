import {
	GuestMemberInfoState,
	GuestVisitQueryState,
	OrderState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Badge, Button, Stack, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useMemo, useState } from 'react';
import { MemberInfoDialog } from '../../UserInfoBox/MemberInfoDialog';
import { PosSideDrawer } from '../PosSideDrawer';
import { GuestVisitDetailDialog } from '../PosSideDrawer/pannels/GuestVisitDetailDialog';
import { CompaniesButton } from './buttons/CompaniesButton';
import { MembershipButton } from './buttons/MemershipButton';
import { TerminateButton } from './buttons/TerminateButton';

export interface IWaitingIndicatorProps {
	disabled?: boolean;
}
export const WaitingIndicator: React.FC<IWaitingIndicatorProps> = ({ disabled = false }) => {
	const [open, setOpen] = useState(false);
	const openDrawer = () => {
		setOpen(true);
	};

	const posInfo = useAtomValue(StoreInfoState.curPosInfo);
	const waitingGuestVisits = GuestVisitQueryState.useTodayWaitingGvList({});

	const { guestMemberInfo } = useAtomValue(OrderState.value);
	const dispatchOrder = useSetAtom(OrderState.value);

	const handleClickTerminateButton = () =>
		dispatchOrder({ type: 'SET_GUEST_USERS_INFO', guestMemberInfo: undefined });

	const DrawerButtonInnerTypography = useMemo(() => {
		if (posInfo.posType === 'KITCHEN' && !!posInfo.kitchenSystemId)
			return <Typography variant={'body1'}>주방 주문 보기</Typography>;
		else
			return <Typography variant={'subtitle1'}>{`대기 : ${waitingGuestVisits.length}`}</Typography>;
	}, [posInfo.posType, posInfo.kitchenSystemId, waitingGuestVisits]);

	return (
		<>
			<Stack
				direction={'row'}
				spacing={1}
				flex={1}
				sx={(theme) => ({
					py: 0.5,
					px: 2,
					alignItems: 'center',
					bgcolor: disabled ? grey[500] : theme.palette.secondary.light,
				})}>
				<Button
					variant={'contained'}
					disabled={disabled}
					color={'secondary'}
					onClick={openDrawer}
					sx={{ width: 114 }}>
					{DrawerButtonInnerTypography}
				</Button>
				{/* 최근 호출 버튼 일단 뺐음 */}
				{/* <StyledBadge sx={{ alignItems: "center", '& .MuiBadge-badge': { top: 6, right: 2 } }}
          badgeContent={guestOnMyPos?.calls?.length || 0} color="primary">
          <StyledButton
            variant={"contained"}
            color={"secondary"}
            disabled={disabled || (!guestOnMyPos && waitingGuestVisits.length === 0)}
            onClick={handleClickCallButton}
          >
            <Typography>
              {`호출`}
            </Typography>
          </StyledButton>
        </StyledBadge> */}
				<Stack
					flex={1}
					direction='row'
					spacing={1}
					alignItems={'center'}>
					{/* 이름이 5자 이상이면 ... 으로 처리하게 */}
					<Button
						color={'inherit'}
						sx={{ px: 1, flex: 1, minWidth: 85 }}>
						<Typography
							variant={'h1'}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								gap: 0.5,
							}}>
							{guestMemberInfo && (
								<>
									{guestMemberInfo.sunbun && (
										<>
											<Typography
												variant={'body1'}
												component={'span'}>
												순번
											</Typography>
											{guestMemberInfo.sunbun}
										</>
									)}
									<Typography
										variant={'subtitle1'}
										component={'span'}>
										{`${guestMemberInfo.name ?? '이름없음'}`}
									</Typography>
								</>
							)}
						</Typography>
					</Button>
					<Suspense fallback={<MembershipButton disabled />}>
						<MemberInfoButtonWrapper memberId={guestMemberInfo?.memberId} />
					</Suspense>
					{guestMemberInfo && guestMemberInfo.guestVisitId && (
						<WithDialog
							dialog={(open, closeDialog) => (
								<GuestVisitDetailDialog
									guestVisitId={guestMemberInfo.guestVisitId!}
									open={open}
									closeDialog={closeDialog}
								/>
							)}>
							{(openPopover) => (
								<CompaniesButton
									onClick={openPopover}
									disabled={!guestMemberInfo || !guestMemberInfo.guestVisitId}
									numberOfUsers={guestMemberInfo?.users?.length}
								/>
							)}
						</WithDialog>
					)}
					<TerminateButton
						disabled={!guestMemberInfo}
						onClick={guestMemberInfo ? handleClickTerminateButton : undefined}
					/>
				</Stack>
			</Stack>
			<PosSideDrawer
				open={open}
				setOpen={setOpen}
			/>
		</>
	);
};

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		right: -5,
		top: 10,
		border: `1px solid ${theme.palette.background.paper}`,
		padding: '0 4px',
	},
}));

const MemberInfoButtonWrapper = ({ memberId }: { memberId?: number }) => {
	if (!memberId) return <MembershipButton disabled />;

	const { data } = useQuery({
		...GuestMemberInfoState.keys.detailById(memberId),
		refetchOnWindowFocus: true,
	});

	const memberInfo = data ?? null;

	return (
		<WithDialog
			dialog={(open, closeDialog) =>
				memberInfo && memberInfo.vaunceMemberInfo ? (
					<MemberInfoDialog
						vaunceMemberInfo={memberInfo.vaunceMemberInfo}
						visitCount={memberInfo.visitList?.length}
						open={open}
						closeDialog={closeDialog}
					/>
				) : null
			}>
			{(openPopover) => (
				<MembershipButton
					onClick={openPopover}
					disabled={!memberInfo || !memberInfo.vaunceMemberInfo}
				/>
			)}
		</WithDialog>
	);
};
