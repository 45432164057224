import { QueryClient } from '@kinderlabs-pos/state';
import { ConfirmProvider } from '@kinderlabs-pos/ui-components';
import { posMainTheme } from '@kinderlabs-pos/ui/themes';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as ReactDOM from 'react-dom/client';
import { MemoryRouter } from 'react-router-dom';
import App from './app/app';
import {
	BrowserClient,
	Breadcrumbs,
	Dedupe,
	defaultStackParser,
	getCurrentHub,
	GlobalHandlers,
	makeFetchTransport,
	LinkedErrors,
	BrowserTracing,
} from '@sentry/browser';
import packageJson from 'package.json';
import { POS_SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_USE } from '@kinderlabs-pos/shared-const';
import { ReactCommonCommandIPC } from '@kinderlabs-pos/ipc/react';
import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: SENTRY_USE ? POS_SENTRY_DSN : '',
	transport: makeFetchTransport,
	stackParser: defaultStackParser,
	release: `${packageJson.version}`,
	environment: SENTRY_ENVIRONMENT,
	integrations: [
		new Breadcrumbs({ console: true }),
		new BrowserTracing(),
		new GlobalHandlers(),
		new LinkedErrors(),
		new Dedupe(),
	],
});
Sentry.addBreadcrumb({ category: 'POS' });
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	// <StrictMode>
	<QueryClient.Provider>
		<ThemeProvider theme={posMainTheme}>
			<ConfirmProvider>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<CssBaseline />
					<ReactCommonCommandIPC.ElectronDebugToggle />
					<MemoryRouter basename={'/'}>
						<App />
					</MemoryRouter>
				</LocalizationProvider>
			</ConfirmProvider>
		</ThemeProvider>
	</QueryClient.Provider>
	// </StrictMode>
);
