import { CartActionBoard } from '@kinderlabs-pos/feature/pos/cart-table';
import {
	CartLineInfoType,
	PosBoardItemType,
	ProductInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { useAtom } from 'jotai';

export const useHandleClickPosBoardItem = () => {
	const dispatch = OrderState.cart.useDispatcher();
	const [selectedCartLine, selectCartLine] = useAtom(CartActionBoard.selectedCartLineAtom);

	return ({ cartLineId, productInfo }: { cartLineId: string; productInfo: ProductInfoType }) => {
		if (productInfo.type === 'OPTION') {
			if (!selectedCartLine) return;

			dispatch({
				type: 'PRODUCTS',
				subAction: {
					type: 'ADD_OPTION',
					cartLineId: getUuidV4(),
					productInfo,
					targetCartLineId: selectedCartLine.id,
					onComplete: (cartLine?: CartLineInfoType) => {
						selectCartLine(cartLine);
					},
				},
			});
		} else {
			dispatch({
				type: 'PRODUCTS',
				subAction: {
					type: 'ADD',
					cartLineId,
					productInfo,
					onComplete: (cartLine?: CartLineInfoType) => {
						selectCartLine(cartLine);
					},
				},
			});
		}
	};
};
