import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { DialogContent } from '@mui/material';
import { GuestVisitDetailContentWrapper } from 'apps/pos-main/src/pages/GuestVisitPage/GuestVisitDetailContent';
import { Suspense } from 'react';

export const GuestVisitDetailDialog = ({
	open,
	closeDialog,
	guestVisitId,
}: XlDialogProps & { guestVisitId: number }) => {
	return (
		<WithDialog.XlDialog
			scroll='paper'
			open={open}
			closeDialogAction='닫기'
			closeDialog={closeDialog}>
			<DialogContent sx={{ minWidth: 360 }}>
				<Suspense>
					<GuestVisitDetailContentWrapper guestVisitId={guestVisitId} />
				</Suspense>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
