import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { NumberForm } from '@kinderlabs-pos/frameworks/forms';
import {
	getArrangedAppUserId,
	OrderType,
	VaunceMemberInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { numberWithCommas } from '@kinderlabs-pos/shared-util';
import {
	GuestVisitQueryState,
	OrderState,
	SearchMemberState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { NumberBoard } from '@kinderlabs-pos/ui-components';
import SearchIcon from '@mui/icons-material/Search';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { AppUserMemberInfoSearchDialog } from '../../member-search/Template/AppUserMemberInfoSearchDialog';
import { HandleSubmitPointPaymentArgsType } from './PointUsageDialog';

export const PointUsageDialogContent = ({
	closeDialog,
	submitPointPayment,
	mode,
}: {
	closeDialog?: () => void;
	submitPointPayment: (args: HandleSubmitPointPaymentArgsType) => Promise<void>;
	mode: OrderType['type'];
}) => {
	// 1. GV, Member
	const { guestMemberInfo } = useAtomValue(OrderState.value);
	const [memberInfo, setMemberInfo] = useState<VaunceMemberInfoType | undefined>(undefined);

	const { data: memberInfoReuslt } = useQuery({
		...SearchMemberState.keys.getByUserId(guestMemberInfo?.appUserId),
		refetchOnWindowFocus: true,
	});

	useEffect(() => {
		if (!memberInfo && memberInfoReuslt) {
			setMemberInfo(memberInfoReuslt);
		}
	}, [memberInfo, memberInfoReuslt, setMemberInfo]);

	// 2. Payment
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const { usagePointUnit, minimumUsagePoint } = storeInfo.policy;
	const { remainingPay } = useAtomValue(OrderState.aggregate.payments);
	const availablePoints = memberInfo
		? Math.min(
				Math.floor(memberInfo.remainingPoint / usagePointUnit) * usagePointUnit,
				remainingPay
		  )
		: 0;
	const [input, setInput] = useState(0);
	useEffect(() => {
		if (!input) return;
		const parsed = Math.floor(input / usagePointUnit) * usagePointUnit;
		setValues((prev) => ({ ...prev, amount: parsed }));
	}, [input]);

	// 3. Formik
	const { handleSubmit, handleChange, isSubmitting, values, setValues, errors } = useFormik<{
		method: 'POINT';
		amount: number;
		submit: string | null;
	}>({
		initialValues: {
			method: 'POINT',
			amount: availablePoints,
			submit: null,
		},
		onSubmit: async (values, { setErrors, resetForm }) => {
			try {
				memberInfo &&
					(await submitPointPayment({
						method: values.method,
						amount: values.amount,
						memberId: memberInfo.id,
					}));
				GuestVisitQueryState.invalidateQueries();

				closeDialog && closeDialog();

				resetForm();
			} catch (e) {
				//@ts-ignore
				setErrors({ submit: e.message });
			}
		},
	});

	const isPointUsable =
		memberInfo &&
		memberInfo.remainingPoint >= minimumUsagePoint &&
		values.amount !== 0 &&
		values.amount >= minimumUsagePoint;

	const handleClickMinusButton = () => {
		const unit = minimumUsagePoint;
		setInput((prev) => (prev - unit < 0 ? 0 : Math.floor((prev - unit) / unit) * unit));
	};
	const handleClickPlusButton = () => {
		const unit = minimumUsagePoint;
		setInput((prev) => (prev + unit > availablePoints ? availablePoints : prev + unit));
	};
	const handleClickBiggerMinusButton = () => {
		const unit = minimumUsagePoint * 10;
		setInput((prev) => (prev - unit < 0 ? 0 : Math.floor((prev - unit) / unit) * unit));
	};
	const handleClickBiggerPlusButton = () => {
		const unit = minimumUsagePoint * 10;
		setInput((prev) => (prev + unit > availablePoints ? availablePoints : prev + unit));
	};

	const memberInfoData = [
		{
			label: <Typography variant={'subtitle2'}>회원 찾기</Typography>,
			value: (
				<WithDialog
					dialog={(open, closeDialog) => (
						<AppUserMemberInfoSearchDialog
							open={open}
							closeDialog={closeDialog}
							selectMemberInfo={setMemberInfo}
						/>
					)}>
					{(openDialog) => (
						<Button
							variant={'text'}
							onClick={openDialog}>
							<SearchIcon />
							<Typography variant='subtitle1'>{memberInfo ? '변경' : '검색'}</Typography>
						</Button>
					)}
				</WithDialog>
			),
		},
		{
			label: <Typography variant={'subtitle2'}>회원 이름</Typography>,
			value: (
				<Typography variant={'subtitle2'}>{memberInfo ? memberInfo.username : '-'}</Typography>
			),
		},
		{
			label: <Typography variant={'subtitle2'}>회원 번호</Typography>,
			value: <Typography variant={'subtitle2'}>{memberInfo ? memberInfo.id : '-'}</Typography>,
		},
		{
			label: <Typography variant={'subtitle2'}>회원 ID</Typography>,
			value: (
				<Typography variant={'subtitle2'}>
					{memberInfo ? getArrangedAppUserId(memberInfo.providerType, memberInfo.userId) : '-'}
				</Typography>
			),
		},
		{
			label: <Typography variant={'subtitle2'}>회원 등급</Typography>,
			value: (
				<Typography variant={'subtitle2'}>
					{memberInfo ? memberInfo.vaunceMemberGrade : '-'}
				</Typography>
			),
		},
		{
			label: <Typography variant={'subtitle2'}>총 보유 포인트</Typography>,
			value: (
				<Typography
					component={'span'}
					variant={'subtitle2'}
					color={'error'}>
					{`${memberInfo ? numberWithCommas(memberInfo.remainingPoint) : 0} P`}
				</Typography>
			),
		},
		{
			label: <Typography variant={'subtitle2'}>결제 가능 포인트</Typography>,
			value: (
				<Typography
					component={'span'}
					variant={'subtitle2'}
					color={'error'}>
					{`${memberInfo ? numberWithCommas(availablePoints) : 0} P`}
				</Typography>
			),
		},
		{
			label: <Typography variant={'subtitle2'}>사용 포인트</Typography>,
			value: (
				<Typography
					component={'span'}
					variant={'subtitle2'}
					color={'error'}>
					{`${memberInfo ? numberWithCommas(values.amount) : 0} P`}
				</Typography>
			),
		},
	];

	return (
		<>
			<DialogContent sx={{ width: 700, height: 370, mb: 2 }}>
				<Stack
					direction={'row'}
					spacing={4}
					height={1}
					justifyContent='space-around'
					alignItems='center'>
					<Stack
						width={350}
						direction='column'
						justifyContent={'center'}>
						{memberInfoData.map(({ label, value }, idx) => (
							<Stack
								direction={'row'}
								justifyContent={'space-between'}
								alignItems={'center'}
								sx={{ height: '40px' }}>
								<Typography variant={'subtitle2'}>{label}</Typography>
								<Typography variant={'subtitle2'}>{value}</Typography>
							</Stack>
						))}
						<NumberForm
							focused
							placeholder={`${numberWithCommas(minimumUsagePoint)}P 이상, ${numberWithCommas(
								usagePointUnit
							)}P 단위로 사용가능`}
							helperText={''}
							defaultValue={null}
							value={input !== 0 && input}
							inputProps={{ style: { textAlign: 'center' } }}
							disabled={!memberInfo}
							onChange={(e) => {
								const parsed = e.target.value
									? Math.min(Math.max(parseInt(e.target.value), 0), availablePoints)
									: 0;
								setInput(parsed);
							}}
						/>
					</Stack>
					<Stack>
						<NumberBoard
							value={input}
							setValue={setInput}
							minValue={0}
							maxValue={availablePoints}
						/>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Stack
					width={'100%'}
					direction={'row'}
					spacing={1}
					alignItems={'center'}
					justifyContent={'space-between'}>
					<Stack
						direction={'row'}
						spacing={1}>
						<Button
							onClick={handleClickBiggerPlusButton}
							variant='contained'
							color={'secondary'}
							startIcon={<PlusOutlined />}>
							{`${numberWithCommas(usagePointUnit * 10)} P`}
						</Button>
						<Button
							onClick={handleClickPlusButton}
							variant='contained'
							color={'secondary'}
							startIcon={<PlusOutlined />}>
							{`${numberWithCommas(usagePointUnit)} P`}
						</Button>
						<Button
							onClick={handleClickMinusButton}
							variant='contained'
							color={'secondary'}
							startIcon={<MinusOutlined />}>
							{`${numberWithCommas(usagePointUnit)} P`}
						</Button>
						<Button
							onClick={handleClickBiggerMinusButton}
							variant='contained'
							color={'secondary'}
							startIcon={<MinusOutlined />}>
							{`${numberWithCommas(usagePointUnit * 10)} P`}
						</Button>
					</Stack>
					<Stack
						direction={'row'}
						spacing={1}
						alignItems={'center'}>
						<Button
							onClick={() => handleSubmit()}
							disabled={!isPointUsable}
							variant={'contained'}>
							확인
						</Button>
						<Button
							onClick={closeDialog}
							variant={'outlined'}>
							취소
						</Button>
					</Stack>
				</Stack>
			</DialogActions>
		</>
	);
};
