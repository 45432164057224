// react table 은 다른데로 옮겨야함
import { RowData, Table } from '@tanstack/react-table';
import XLSX from 'xlsx-js-style';
import { getExcelData, getExcelFooters, getHeaderRows } from './excelUtils';

export function printExcelFromReactTable<TData extends RowData>({
	table,
	fileName,
	footerFromOriginTable = false,
}: {
	table: Table<TData>;
	fileName: string;
	// Footer 가 각 열의 합이 아닌 다른 경우가 있어서 임시로 체크해두었음
	footerFromOriginTable?: boolean;
}) {
	const headerRows = getHeaderRows(table);
	const data = getExcelData(table);
	const footers = getExcelFooters(table, footerFromOriginTable);

	return printExcelDataFromReactTable({
		headerRows,
		data,
		footers,
		fileName,
	});
}

export const printExcelDataFromReactTable = ({
	headerRows,
	data,
	footers,
	fileName,
}: {
	headerRows: { columns: { span?: number; label: string }[] }[];
	data: { isParent: boolean; columns: (string | number | null | undefined)[] }[];
	footers: { columns: { value: string | number | null | undefined }[] }[];
	fileName: string;
}) => {
	const wb = XLSX.utils.book_new();

	// STEP 1: 컬럼 세팅
	const excelHeaderRows = headerRows.map((headerRow) =>
		headerRow.columns.map((headerColumn) => ({
			v: headerColumn.label,
			t: 's',
			s: columnCommonProps,
		}))
	);

	// STEP 2: 로우 세팅
	const excelRows = data.map((row) =>
		row.columns.map((column) => ({
			v: typeof column === 'boolean' ? (column ? 'O' : 'X') : column ?? '',
			t: typeof column === 'number' ? 'n' : 's',
			s: row.isParent ? dataFillProps : dataCommonProps,
		}))
	);

	// STEP 3: footer 세팅
	const excelFooterRows = footers.map((footerRow) =>
		footerRow.columns.map((footerColumn) => ({
			v: footerColumn.value ?? '',
			t: typeof footerColumn.value === 'number' ? 'n' : 's',
			s: columnCommonProps,
		}))
	);

	const ws = XLSX.utils.aoa_to_sheet([...excelHeaderRows, ...excelRows, ...excelFooterRows]);

	// STEP 4: 컬럼 너비, 셀 머지 세팅
	ws['!cols'] = headerRows[headerRows.length - 1].columns.map(() => ({ wch: 20 }));
	ws['!merges'] = headerRows.reduce((aggr, headerRow, rowIndex) => {
		headerRow.columns.forEach((headerColumn, columnIndex) => {
			if (headerColumn.span) {
				aggr.push({
					s: { r: rowIndex, c: columnIndex },
					e: { r: rowIndex, c: columnIndex + headerColumn.span - 1 },
				});
			} else {
			}
		});

		return aggr;
	}, [] as NonNullable<(typeof ws)['!merges']>);

	XLSX.utils.book_append_sheet(wb, ws, `${fileName}`);
	XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const borderProps = { style: 'thin', color: { rgb: '000000' } };
const columnCommonProps = {
	font: { sz: 15 },
	border: {
		top: borderProps,
		bottom: borderProps,
		left: borderProps,
		right: borderProps,
	},
	alignment: { vertical: 'center', horizontal: 'center' },
	fill: {
		fgColor: { rgb: 'E9E9E9' },
	},
};

const dataCommonProps = {
	border: {
		top: { style: 'thin', color: { rgb: '000000' } },
		bottom: { style: 'thin', color: { rgb: '000000' } },
		left: { style: 'thin', color: { rgb: '000000' } },
		right: { style: 'thin', color: { rgb: '000000' } },
	},
	alignment: { vertical: 'center', horizontal: 'center' },
};

const dataFillProps = {
	...dataCommonProps,
	fill: {
		fgColor: { rgb: 'E9E9E9' },
	},
};
