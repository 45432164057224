import { OrderState } from '@kinderlabs-pos/state';
import { CardStack } from '@kinderlabs-pos/ui-atoms';
import { Box, BoxProps, Stack } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { CartActionBoard } from './CartActionBoard';
import { CartSummary } from './CartSummary';
import { CartTableWithItems } from './CartTable';

export interface ICartBoxProps extends BoxProps {
	withButton?: boolean;
}
export const CartBox: React.FC<ICartBoxProps> = ({}) => {
	return (
		<Stack
			direction={'column'}
			height={'100%'}
			overflow={'auto'}
			spacing={1}>
			<CartSelectClearHandler />
			<CardStack
				flex={1}
				sx={{
					overflow: 'auto',
				}}>
				<Box
					flex={1}
					overflow={'auto'}>
					<CartTableWithItems selectable />
				</Box>
				<CartSummary.OnTable />
			</CardStack>
		</Stack>
	);
};

/**
 * OrderState 내부를 주시하며 Select 가 무효한 순간 Select 를 지워주는 역할
 */
const CartSelectClearHandler = () => {
	const cartLines = useAtomValue(OrderState.cart.selector).lines;
	const [selectedCartLine, selectCartLine] = useAtom(CartActionBoard.selectedCartLineAtom);

	useEffect(() => {
		if (selectedCartLine && !cartLines.map((cl) => cl.id).includes(selectedCartLine.id)) {
			selectCartLine(undefined);
		}
	}, [cartLines]);
	return null;
};
