import { PosLoginForm, useElectronAutoUpdate } from '@kinderlabs-pos/feature/pos/pos-login';
import { ReactCommonCommandIPC } from '@kinderlabs-pos/ipc/react';
import { PosElectronStoreState } from '@kinderlabs-pos/state';
import { VersionDisplay } from '@kinderlabs-pos/ui-atoms';
import { Box, Card, CardContent, Paper, Stack, styled } from '@mui/material';
import KL_POS_LOGO from 'assets/KL_POS_LOGO.png';
import backgroundImage from 'assets/background.jpg';
import { usePosRouter } from './routes';

interface IPosLoginPageProps {}
export const PosLoginPage: React.FC<IPosLoginPageProps> = ({}) => {
	const { navigateToSelectStore } = usePosRouter();
	const handleClose = () => {
		ReactCommonCommandIPC.closeApp();
	};
	const { updateAvailable } = useElectronAutoUpdate();

	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings().data;
	const saveLocalSettings = PosElectronStoreState.useSavePosLocalSettings();

	return (
		<BackgroundPosPaper
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'relative',
			}}>
			<Card sx={{ position: 'relative', width: 520, minHeight: 520 }}>
				<CardContent>
					<Stack alignItems={'center'}>
						<KinderlabsLogoBox />
						<PosLoginForm
							recentId={localSettings?.recentLoginId}
							onClose={handleClose}
							onLogin={(args) => {
								if (localSettings && args) {
									saveLocalSettings.mutate({ ...localSettings, recentLoginId: args.email });
								}
								navigateToSelectStore();
							}}
							loginButtonLabel={updateAvailable ? 'UPDATE 필요' : 'LOGIN'}
							disableLogin={updateAvailable}
						/>
					</Stack>
				</CardContent>
			</Card>
			<VersionDisplay />
		</BackgroundPosPaper>
	);
};

const KinderlabsLogoBox = styled(Box)(({ theme }) => ({
	backgroundImage: `url(${KL_POS_LOGO})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: 180,
	aspectRatio: '1/1',
}));

export const BackgroundPosPaper = styled(Paper)(({ theme }) => ({
	position: 'relative',
	height: '100vh',
	'&::before': {
		position: 'absolute',
		top: 0,
		left: 0,
		content: "''",
		width: '100%',
		height: '100%',
		opacity: 0.5,
		backgroundImage: `url(${backgroundImage})`,

		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	},
}));
