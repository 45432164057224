import { CardProps, Typography, useTheme } from '@mui/material';
import { PosBoardBaseItem } from '../PosBoardItem/PosBoardBaseItem';
import { getTextRenderLength } from '@kinderlabs-pos/shared-util';
import { PosBoardDeviceType } from '@kinderlabs-pos/shared-data-type';

interface ICategoryCardProps {
	label: string;
	isSelected?: boolean;
	onClick?: () => void;
	CardProps?: CardProps;
	deviceType: PosBoardDeviceType;
}
export const PosCategoryItem = ({
	deviceType,
	CardProps,
	label,
	isSelected,
	onClick,
}: ICategoryCardProps) => {
	const theme = useTheme();
	const labelLength = getTextRenderLength(label);
	return (
		<PosBoardBaseItem
			CardProps={{
				...CardProps,
				sx: {
					flex: 1,
					color: !isSelected ? 'black' : undefined,
					bgcolor: isSelected ? theme.palette.primary.main : theme.palette.divider,
				},
			}}
			CardActionProps={{
				onClick,
				sx: {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-around',
					alignItems: 'center',
					height: '100%',
				},
			}}>
			<Typography
				style={{ whiteSpace: 'pre-wrap', lineHeight: 1.3 }}
				textAlign={'center'}
				variant={deviceType === 'POS' ? 'subtitle1' : 'body2'}>
				{label.replace('\\n', '\n')}
			</Typography>
		</PosBoardBaseItem>
	);
};
