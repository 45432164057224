import {
	CardCompanyStatisticsData,
	PaymentSummary,
	PosSalesAndGuestVisitData,
} from '@kinderlabs-pos/feature/pos/statistics';
import { useExpandableReactTable, useSimpleReactTable } from '@kinderlabs-pos/frameworks/table';
import {
	DayCalendarFilterOptionType,
	SalesDeviceInfoType,
	getSalesDeviceInfoName,
} from '@kinderlabs-pos/shared-data-type';
import { StoreInfoState } from '@kinderlabs-pos/state';
import { CardStack } from '@kinderlabs-pos/ui-atoms';
import { FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import { Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';

export const SalesAndGuestVisitsInPos = ({
	selectedDeviceInfoList,
	option,
}: {
	selectedDeviceInfoList?: SalesDeviceInfoType[];
	option: DayCalendarFilterOptionType;
}) => {
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	return (
		<Stack
			spacing={1}
			height={'100%'}
			overflow={'auto'}>
			<CardStack spacing={1}>
				<Stack alignItems={'center'}>
					<Typography variant='h3'>{`총 매장 매출/입장객`}</Typography>
				</Stack>
				<Suspense fallback={<FullSizeCircularProgress />}>
					<고객방문총매출테이블
						calendarOption={option}
						storeId={storeId}
					/>
				</Suspense>
			</CardStack>
			<CardStack spacing={1}>
				<Stack alignItems={'center'}>
					<Typography variant='h3'>{`${
						selectedDeviceInfoList
							? selectedDeviceInfoList.map((d) => getSalesDeviceInfoName(d)).join(', ')
							: ''
					} 카드사별 매출`}</Typography>
				</Stack>
				<Suspense fallback={<FullSizeCircularProgress />}>
					<카드사별매출통계테이블
						storeId={storeId}
						selectedPosIdList={selectedDeviceInfoList?.map((p) => p.id)}
						calendarOption={option}
					/>
				</Suspense>
			</CardStack>
			<CardStack spacing={1}>
				<Stack alignItems={'center'}>
					<Typography variant='h3'>{`${
						selectedDeviceInfoList
							? selectedDeviceInfoList.map((d) => getSalesDeviceInfoName(d)).join(', ')
							: ''
					} 결제 요약`}</Typography>
				</Stack>
				<Suspense fallback={<FullSizeCircularProgress />}>
					<결제매출요약통계테이블
						storeId={storeId}
						selectedPosIdList={selectedDeviceInfoList?.map((p) => p.id)}
						calendarOption={option}
					/>
				</Suspense>
			</CardStack>
		</Stack>
	);
};

const 고객방문총매출테이블 = ({
	storeId,
	calendarOption,
}: {
	storeId: number;
	calendarOption: DayCalendarFilterOptionType;
}) => {
	const data = PosSalesAndGuestVisitData.useData({
		filter: {
			option: calendarOption,
			storeIdList: [storeId],
		},
	});

	const columns = PosSalesAndGuestVisitData.useColumns({
		type: 'POS',
	});

	const { table, TableComponent } = useSimpleReactTable({
		reactTableOptions: {
			data,
			columns,
			enableSorting: false,
		},
	});

	return <TableComponent />;
};

const 카드사별매출통계테이블 = ({
	storeId,
	selectedPosIdList,
	calendarOption,
}: {
	storeId: number;
	selectedPosIdList?: number[];
	calendarOption: DayCalendarFilterOptionType;
}) => {
	const { data } = CardCompanyStatisticsData.useData({
		filter: {
			storeIdList: [storeId],
			calendarOption,
			posIdList: selectedPosIdList,
			dateType: 'DAY',
		},
	});

	const columns = CardCompanyStatisticsData.usePosColumns();

	const { table, TableComponent } = useExpandableReactTable({
		reactTableOptions: {
			columns,
			data,
		},
	});

	return <TableComponent />;
};

const 결제매출요약통계테이블 = ({
	storeId,
	selectedPosIdList,
	calendarOption,
}: {
	storeId: number;
	selectedPosIdList?: number[];
	calendarOption: DayCalendarFilterOptionType;
}) => {
	const columns = PaymentSummary.usePosColumns();
	const data = PaymentSummary.use결제민감정보들({
		calendarOption,
		storeIdList: [storeId],
		posIdList: selectedPosIdList,
	});

	const { table, TableComponent } = useSimpleReactTable({
		reactTableOptions: {
			columns,
			data,
		},
	});

	return <TableComponent />;
};
