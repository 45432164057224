// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { mainWindowSize } from '@kinderlabs-pos/shared-const';
import { Box, CircularProgress, Stack } from '@mui/material';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { MainPage } from '../pages/MainPage';
import { PosMainFallbackComponent } from './PosMainFallbackComponent';

export const App: React.FC<{}> = () => {
	return (
		<ErrorBoundary FallbackComponent={PosMainFallbackComponent}>
			<Suspense
				fallback={
					<Stack
						width={mainWindowSize.width}
						height={mainWindowSize.height}
						alignItems={'center'}
						justifyContent={'center'}>
						<CircularProgress />
					</Stack>
				}>
				<Box
					width={mainWindowSize.width}
					height={mainWindowSize.height}
					border={'1px solid gray'}>
					<MainPage />
				</Box>
			</Suspense>
		</ErrorBoundary>
	);
};

export default App;
