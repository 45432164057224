import { 매장별고객방문매출요약Type } from '@kinderlabs-pos/apis/storeinfo';
import { DayCalendarFilterOptionType, calculateVAT } from '@kinderlabs-pos/shared-data-type';
import { StatisticsState, authState } from '@kinderlabs-pos/state';
import { ColumnDef } from '@tanstack/react-table';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { ColumnDefsHelper } from './ColumnDefsHelper';
import { useQuery } from '@tanstack/react-query';

export const PosSalesAndGuestVisitData = {
	useData: ({
		filter,
	}: {
		filter: {
			option: DayCalendarFilterOptionType;
			storeIdList?: number[];
		};
	}) => {
		const storeIdListForManager = useAtomValue(authState.storeIdListForManager);
		const { from, to } = filter.option;

		const { data } = useQuery({
			...StatisticsState.keys.salesTotalSumByGVForStore({
				params: {
					from,
					to,
					storeIdList: filter.storeIdList || storeIdListForManager,
				},
			}),
			refetchOnWindowFocus: false,
			refetchOnMount: true,
		});

		return useMemo(() => data ?? [], [data]);
	},
	useColumns: ({ type }: { type: 'POS' | 'ADMIN' }) => {
		const enterpriseInfo = useAtomValue(authState.enterpriseInfo);
		return useMemo<ColumnDef<매장별고객방문매출요약Type>[]>(
			() => [
				{
					accessorKey: 'storeName',
					header: '매장명',
					size: 85,
					footer: '총 합',
				},
				{
					accessorKey: 'totalVisitors',
					header: '총 입장객',
					size: 85,
					...ColumnDefsHelper.get개수세기ColumnDef('totalVisitors', '명'),
				},
				{
					accessorKey: 'totalNormalVisitors',
					header: '이용권',
					size: 85,
					...ColumnDefsHelper.get개수세기ColumnDef('totalNormalVisitors', '명'),
				},
				{
					accessorKey: 'totalParentVisitors',
					header: '보호자',
					size: 85,
					...ColumnDefsHelper.get개수세기ColumnDef('totalParentVisitors', '명'),
				},
				{
					accessorKey: 'offlineRevenue',
					header: '오프라인 매출',
					size: 110,
					...ColumnDefsHelper.get금액ColumnDef('offlineRevenue'),
				},
				{
					accessorKey: 'onlineRevenue',
					header: '온라인 매출',
					size: 110,
					...ColumnDefsHelper.get금액ColumnDef('onlineRevenue'),
				},
				{
					accessorKey: 'totalRevenue',
					header: enterpriseInfo?.id === 'zoolung' ? '순 매출' : '매출',
					size: 110,
					...ColumnDefsHelper.get금액ColumnDef('totalRevenue'),
				},
				...(enterpriseInfo?.id === 'vaunce' && type === 'ADMIN'
					? ([
							{
								id: 'vat',
								accessorFn: (row) => calculateVAT({ revenue: row.totalRevenue, taxFree: false }),
								header: 'VAT',
								size: 90,
								...ColumnDefsHelper.get금액ColumnDef('vat'),
							},
							{
								id: 'revenueWithoutVAT',
								accessorFn: (row) =>
									row.totalRevenue - calculateVAT({ revenue: row.totalRevenue, taxFree: false }),
								header: '매출 (VAT 제외)',
								size: 150,
								...ColumnDefsHelper.get금액ColumnDef('revenueWithoutVAT'),
							},
					  ] as ColumnDef<매장별고객방문매출요약Type>[])
					: []),
			],
			[enterpriseInfo, type]
		);
	},
};
