import { OrderDetailType } from '@kinderlabs-pos/shared-data-type';
import { PointAcc } from '../../../modules/payments/PaymentResult/PointAcc';

export const PointProcessedStateInOrder = ({ order }: { order: OrderDetailType }) => {
	return (
		<PointAcc
			mode='post'
			orderDetail={order}
		/>
	);
};
