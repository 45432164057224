import {
	AvailableTypeLabel,
	DdiziSourceTypeLabel,
	DdiziStatusTypeLabel,
	DdiziType,
	DdiziTypeLabel,
} from '@kinderlabs-pos/shared-data-type';
import { getDateFormat, getTimeFormat } from '@kinderlabs-pos/shared-util';
import { AdminStoreInfoState } from '@kinderlabs-pos/state';
import { InfoPrintStackRow, InfoPrintStackTitle } from '@kinderlabs-pos/ui-components';
import { Link, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

interface IDdiziDetailContentProps {
	ddizi: DdiziType;
	showPublishInfo?: boolean;
	navigate주문내역?: () => void;
}
export const DdiziDetailContent: React.FC<IDdiziDetailContentProps> = ({
	ddizi,
	showPublishInfo = false,
	navigate주문내역,
}) => {
	const { data: storeDetailInfo } = useQuery({
		...AdminStoreInfoState.keys.detail(ddizi.publishInfo.storeId),
		refetchOnWindowFocus: false,
	});

	const getName =
		storeDetailInfo?.posInfoList.find((item) => item.id === ddizi.publishInfo.posId)?.name ?? '';

	const { id, status } = ddizi;
	const { publishInfo, started, exited, expiration, canceledInfo } = ddizi;

	const ddiziInfoData = [
		{
			label: '티켓 번호',
			value: id,
		},
		{
			label: '티켓 이름',
			value: ddizi.name,
		},
		{
			label: '티켓 권종',
			value: `${AvailableTypeLabel[ddizi.available]} (${ddizi.minutes} 분)`,
		},
		{
			label: '티켓 종류',
			value:
				ddizi.type === 'GROUP_TICKET'
					? `${DdiziTypeLabel[ddizi.type]} (${ddizi.multiple} 명)`
					: `${DdiziTypeLabel[ddizi.type]}`,
		},
		{
			label: '티켓 상태',
			value: DdiziStatusTypeLabel[status],
		},
		{
			label: '발권 날짜',
			value: publishInfo.published ? getDateFormat(publishInfo.published) : '-',
		},
		{
			label: '발권 시각',
			value: publishInfo.published ? getTimeFormat(publishInfo.published) : '-',
		},
		{
			label: '입장 시각',
			value: started ? getTimeFormat(started) : '-',
		},
		{
			label: '만료 시각',
			value: expiration ? getTimeFormat(expiration) : '-',
		},
		{
			label: '퇴장 시각',
			value: exited ? getTimeFormat(exited) : '-',
		},
	];

	if (canceledInfo) {
		ddiziInfoData.push({
			label: '취소 시각',
			value: getTimeFormat(canceledInfo.canceled),
		});
		ddiziInfoData.push({
			label: '취소 POS (ID)',
			value: `${canceledInfo.posId}`,
		});
	}

	const ddiziPurchaseInfoData = [
		{
			label: '구매',
			value: DdiziSourceTypeLabel[ddizi.publishInfo.sourceType],
		},
		{
			label: '매장 정보',
			value: `${storeDetailInfo?.name ?? ''}`,
		},
		{
			label: '포스 번호',
			value: getName ? `${getName} 번` : `확인불가 (posId : ${ddizi.publishInfo.posId})`,
		},
		{
			label: '주문 번호',
			value: navigate주문내역 ? (
				<Link onClick={navigate주문내역}>{ddizi.publishInfo.orderId}</Link>
			) : (
				ddizi.publishInfo.orderId
			),
		},
	];

	return (
		<Stack spacing={1}>
			<InfoPrintStackTitle title={`티켓 정보`} />
			{ddiziInfoData.map(({ label, value }, idx) => (
				<InfoPrintStackRow
					key={idx}
					label={label}
					value={value}
				/>
			))}
			{showPublishInfo && (
				<>
					<InfoPrintStackTitle title={`티켓 구매 정보`} />
					{ddiziPurchaseInfoData.map(({ label, value }, idx) => (
						<InfoPrintStackRow
							key={idx}
							label={label}
							value={value}
						/>
					))}
				</>
			)}
		</Stack>
	);
};
