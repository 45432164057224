import { boardColorSelectionsMap, PosBoardItemType } from '@kinderlabs-pos/shared-data-type';
import { Stack, useTheme } from '@mui/material';
import { PosBoardSelectableItemProps } from '../types';
import { PosBoardBaseItem } from './PosBoardBaseItem';

export const PosBoardSelectableItem = ({
	item,
	selected,
	variant = 'default',
	selectItemIdx,
	idx,
	children,
	CardActionProps,
	CardProps,
}: PosBoardSelectableItemProps<PosBoardItemType>) => {
	const theme = useTheme();

	const handleClick = () => selectItemIdx((prev) => (prev === idx ? null : idx));

	return (
		<PosBoardBaseItem
			CardProps={{
				...CardProps,
				sx: {
					color: 'white',
					bgcolor: boardColorSelectionsMap[item.color],
					borderWidth: 5,
					borderStyle: 'solid',
					borderColor: selected ? theme.palette.primary.main : boardColorSelectionsMap[item.color],
				},
			}}
			CardActionProps={{
				...CardActionProps,
				...(variant === 'selectable'
					? {
							onClick: handleClick,
					  }
					: undefined),
			}}>
			<Stack spacing={2}>{children}</Stack>
		</PosBoardBaseItem>
	);
};
