import { getDateTimeFormat } from '@kinderlabs-pos/shared-util';
import { VaunceAppTicketQueryState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { DialogContent, Divider, List, ListItem, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Fragment } from 'react';

interface VaunceAppTicketHistoryDialogProps extends XlDialogProps {
	ticketId: number;
}
export const VaunceAppTicketHistoryDialog = ({
	open,
	closeDialog,
	ticketId,
}: VaunceAppTicketHistoryDialogProps) => {
	const { data } = useQuery({
		...VaunceAppTicketQueryState.keys.history(`${ticketId}`),
	});

	const histories = data ?? [];

	return (
		<WithDialog.XlDialog
			dialogTitle={`티켓 #${ticketId} 사용내역 조회`}
			open={open}
			closeDialogAction={'닫기'}
			closeDialog={closeDialog}>
			<DialogContent sx={{ minWidth: 428 }}>
				{histories.length === 0 ? (
					<Stack
						height={'100%'}
						justifyContent={'cneter'}
						alignItems={'center'}>
						<Typography
							variant='subtitle1'
							color={'text.secondary'}>
							{'사용 내역 없음'}
						</Typography>
					</Stack>
				) : (
					<List>
						{histories.map((posHistory, idx) => (
							<Fragment key={idx}>
								<ListItem>
									<Stack
										direction={'row'}
										spacing={4}>
										<Stack flex={1}>
											<Typography
												variant={
													'subtitle1'
												}>{`${posHistory.title} #${posHistory.ticketId}`}</Typography>
											<Typography
												variant={'body1'}
												color={'text.secondary'}>
												{posHistory.historyDesc}
											</Typography>
										</Stack>
										<Stack>
											{posHistory.status === 'USED' && (
												<Typography
													variant='subtitle2'
													color={'primary'}>
													사용 처리
												</Typography>
											)}
											{posHistory.status === 'USE_CANCELED' && (
												<Typography
													variant='subtitle2'
													color={'error'}>
													취소 처리
												</Typography>
											)}
											<Typography
												variant={'body1'}
												color={'text.secondary'}>
												{getDateTimeFormat(posHistory.createDate)}
											</Typography>
										</Stack>
									</Stack>
								</ListItem>
								<Divider />
							</Fragment>
						))}
					</List>
				)}
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
