import { PrintReceiptState } from '@kinderlabs-pos/state';
import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Typography } from '@mui/material';

export interface IReceiptPrintButtonProps {}
export const ReceiptPrintButton: React.FC<IReceiptPrintButtonProps> = ({}) => {
	const 직전영수증인쇄 = PrintReceiptState.use직전영수증출력();
	const has직전영수증 = PrintReceiptState.useHas직전영수증();

	return (
		<IconTextButton
			variant='white'
			onClick={async () => await 직전영수증인쇄()}
			disabled={!has직전영수증}
			icon={<ReceiptLongIcon fontSize='small' />}
			label={<Typography>{'직전 영수증'}</Typography>}
		/>
	);
};
