import { getArrangedAppUserId, VaunceMemberInfoType } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommas } from '@kinderlabs-pos/shared-util';
import { OrderStateType } from '@kinderlabs-pos/state';
import { List, WithDialog } from '@kinderlabs-pos/ui-atoms';
import { ExpandButton } from '@kinderlabs-pos/ui-components';
import { Button, Collapse, Divider, Stack, Typography } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import { Fragment } from 'react';
import { AppUserMemberInfoSearchDialog } from '../../../member-search/Template/AppUserMemberInfoSearchDialog';
import { StyledProgress } from '../../../order/PrintHandler/common/StyledProgress';

export const ImmediateAcc = ({
	userMemberInfo,
	order,
	selectMemberInfo,
	pointEarned,
}: {
	userMemberInfo: VaunceMemberInfoType | null;
	order: OrderStateType;
	selectMemberInfo: (memberInfo?: VaunceMemberInfoType) => void;
	pointEarned: boolean;
}) => {
	const { expanded, toggleExpandButton } = ExpandButton.useToggle(true);

	const convertedUserId = userMemberInfo
		? getArrangedAppUserId(userMemberInfo.providerType, userMemberInfo.userId)
		: undefined;

	const memberInfoData = [
		{
			label: <Typography variant={'subtitle2'}>회원 정보</Typography>,
			value: (
				<Typography variant={'subtitle2'}>
					{userMemberInfo
						? `${userMemberInfo.username} / ${userMemberInfo.id} / ${convertedUserId} / ${userMemberInfo.vaunceMemberGrade}  `
						: '-'}
				</Typography>
			),
		},
		{
			label: <Typography variant={'subtitle2'}>적립 예정 포인트</Typography>,
			value: (
				<Typography
					component={'span'}
					variant={'subtitle2'}
					color={'error'}>
					{`${userMemberInfo ? numberWithCommas(order.points) : 0} P`}
				</Typography>
			),
		},
		{
			label: <Typography variant={'subtitle2'}>적립 후 포인트</Typography>,
			value: (
				<Typography
					component={'span'}
					variant={'subtitle2'}
					color={'error'}>
					{`${
						userMemberInfo
							? numberWithCommas(userMemberInfo.remainingPoint + (order.points || 0))
							: 0
					} P`}
				</Typography>
			),
		},
	];

	return (
		<Stack
			flex={1}
			sx={{ overflowY: 'auto' }}>
			<List
				sx={{ width: '100%' }}
				disablePadding>
				<List.ItemButton
					onClick={toggleExpandButton}
					sx={{ position: 'relative', width: '100%', justifyContent: 'space-between' }}>
					<StyledProgress
						isPaused={false}
						// isPaused={!pointEarned}
						variant='determinate'
						value={(pointEarned ? 1 : 0) * 100}
					/>
					<Typography variant={'h4'}>
						{pointEarned ? `포인트 적립 예정 (금일 자정)` : `포인트 적립 가능 (${order.points} P)`}
					</Typography>
					<Stack
						spacing={1}
						direction={'row'}>
						<WithDialog
							dialog={(open, closeDialog) => (
								<AppUserMemberInfoSearchDialog
									open={open}
									closeDialog={closeDialog}
									selectMemberInfo={selectMemberInfo}
								/>
							)}>
							{(openDialog) => (
								<Button
									variant={'outlined'}
									color={'success'}
									onClick={openDialog}>
									<SearchIcon />
									<Typography variant='subtitle1'>{userMemberInfo ? '변경' : '검색'}</Typography>
								</Button>
							)}
						</WithDialog>
						<ExpandButton
							expanded={expanded}
							onClick={(e) => {
								e.stopPropagation();
								toggleExpandButton();
							}}
						/>
					</Stack>
				</List.ItemButton>
			</List>
			<Stack sx={{ overflowY: 'auto', flex: 1, width: '100%' }}>
				<Collapse in={expanded}>
					<List
						sx={{ width: '100%' }}
						disablePadding>
						{memberInfoData.map(({ label, value }, idx) => (
							<Fragment key={idx}>
								<List.Item sx={{ width: '100%', paddingTop: 1, paddingBottom: 1, height: 40 }}>
									<Stack
										direction={'row'}
										width={1}
										justifyContent={'space-between'}
										alignItems={'center'}
										sx={{ height: '25px' }}>
										<Typography variant={'subtitle2'}>{label}</Typography>
										<Typography variant={'subtitle2'}>{value}</Typography>
									</Stack>
								</List.Item>
								<Divider />
							</Fragment>
						))}
					</List>
				</Collapse>
			</Stack>
		</Stack>
	);
};
