import { useFrameworkFormik } from '@kinderlabs-pos/frameworks/forms';
import { GuestMemberDetailInfoType } from '@kinderlabs-pos/shared-data-type';

const MEMBER_INFO_INITIALVALUE: GuestMemberDetailInfoType['member'] = {
	id: -1,
	name: '',
	telephone: '',
	gender: 'MAN',
	address: {
		region: '',
		district: '',
	},
	kinderlabsAgreement: {
		isAgree: true,
		agreeDateTime: null,
	},
	storeAgreement: {
		isAgree: true,
		agreeDateTime: null,
	},
	marketingAgreement: {
		isAgree: true,
		agreeDateTime: null,
	},
	birthday: '',
};

const MEMBERINFO_INITIAL_VALUE: GuestMemberDetailInfoType = {
	member: MEMBER_INFO_INITIALVALUE,
	vaunceMemberInfo: undefined,
	visitList: undefined,
};

export const useMemberInfoFormik = ({
	initialValues = MEMBERINFO_INITIAL_VALUE,
	onSubmit,
}: {
	initialValues?: GuestMemberDetailInfoType;
	onSubmit: (values: GuestMemberDetailInfoType) => Promise<void>;
}) => {
	const result = useFrameworkFormik({
		initialValues: initialValues,
		onSubmit: async (values) => {
			await onSubmit(values);
		},
	});

	return result;
};
