import { OrderDetailType } from '@kinderlabs-pos/shared-data-type';
import { Button, List, ListItem, Stack } from '@mui/material';
import { ExchangeCouponDisplay } from '../../../components/ExchangeCouponDisplay';

export const ExchangeCouponInOrder = ({ order }: { order: OrderDetailType }) => {
	return (
		<Stack
			spacing={1}
			sx={{ overflowY: 'auto' }}>
			<List>
				{order.exchangeCoupons.map((coupon, idx) => (
					<ListItem
						key={idx}
						sx={{ px: 0 }}>
						<ExchangeCouponDisplay
							sx={{ width: '100%' }}
							key={idx}
							coupon={coupon}
							itemButton={
								<Stack direction={'row'}>
									<Button variant='outlined'>재출력</Button>
								</Stack>
							}
						/>
					</ListItem>
				))}
			</List>
		</Stack>
	);
};
