import { useSimpleReactTable } from '@kinderlabs-pos/frameworks/table';
import { GuestVisitQueryState } from '@kinderlabs-pos/state';
import { Stack } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { Dayjs } from 'dayjs';
import { ReactNode, useMemo } from 'react';
import { ColumnDefsHelper } from '../ColumnDefsHelper';
import { useQuery } from '@tanstack/react-query';

export const GuestVisitsWaitingDisplayPerStore = ({
	from,
	to,
	label,
	storeIdList,
}: {
	from: Dayjs;
	to: Dayjs;
	label?: ReactNode;
	storeIdList?: number[];
}) => {
	const { data } = useQuery({
		...GuestVisitQueryState.keys.todayAllStore({
			storeIdList,
			date: from,
		}),
		refetchInterval: 10 * 1000,
		refetchOnWindowFocus: true,
	});

	let tableData = useMemo(() => (!data ? [] : [...Object.values(data.allGvList)]), [data]);

	const { TableComponent } = useSimpleReactTable({
		reactTableOptions: {
			columns,
			data: tableData,
		},
	});

	return (
		<Stack alignItems={'center'}>
			{label && (
				<Stack
					py={1}
					px={1}>
					{label}
				</Stack>
			)}
			<TableComponent />
		</Stack>
	);
};

const columns: ColumnDef<RowType>[] = [
	{
		accessorKey: 'storeName',
		header: '매장명',
		enableSorting: false,
		footer: '총 합',
	},
	{
		accessorKey: 'waiting',
		header: '대기 중',
		enableSorting: false,
		...ColumnDefsHelper.get개수세기ColumnDef('waiting', '팀'),
	},
	{
		accessorKey: 'processing',
		header: '응대 중',
		enableSorting: false,
		...ColumnDefsHelper.get개수세기ColumnDef('processing', '팀'),
	},
	{
		accessorKey: 'lastCompletedSunbun',
		header: '최근 완료 순번',
		enableSorting: false,
		cell: ({ getValue }) => {
			const value = getValue() as any;

			if (typeof value === 'number') return `${value} 번`;
			else return `-`;
		},
	},
	{
		accessorKey: 'completed',
		header: '처리 완료팀',
		enableSorting: false,
		...ColumnDefsHelper.get개수세기ColumnDef('completed', '팀'),
	},
];

type RowType = {
	lastCompletedSunbun: number;
	storeId: number;
	storeName: string;
	waiting: number;
	processing: number;
	completed: number;
};
