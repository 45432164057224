import { CardIssuerNameType, CardMainIssuerNameArray } from '@kinderlabs-pos/shared-data-type';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	styled,
} from '@mui/material';
import { FormikErrors } from 'formik';
import { useEffect, useState } from 'react';
import { HandleSubmitCardPaymentArgsType } from '.';
import { PaymentFormikType } from '@kinderlabs-pos/state';

export const VirtualPayDetailContent = ({
	values,
	setValues,
}: {
	values: HandleSubmitCardPaymentArgsType & PaymentFormikType;
	setValues: (
		values: HandleSubmitCardPaymentArgsType & PaymentFormikType,
		shouldValidate?: boolean | undefined
	) => Promise<void> | Promise<FormikErrors<HandleSubmitCardPaymentArgsType & PaymentFormikType>>;
}) => {
	const [text, setText] = useState('');
	const [selected, setSelected] = useState<'직접입력' | CardIssuerNameType>('직접입력');

	useEffect(() => {
		setText(selected === '직접입력' ? '' : selected.toString());
	}, [selected]);

	useEffect(() => {
		setValues({ ...values, memo: text });
	}, [text]);

	return (
		<Stack
			direction='column'
			spacing={1}
			marginBottom={3}>
			<FormControl size='small'>
				<InputLabel id='card-input'>
					<Typography variant='subtitle1'>메모 입력</Typography>
				</InputLabel>
				<Select
					labelId='card-input'
					id='card-input'
					value={selected}
					onChange={(e) => {
						setSelected(e.target.value as CardIssuerNameType);
					}}
					// autoFocus
					label='메모 입력'>
					<MenuItem value={'직접입력'}>{'직접입력'}</MenuItem>
					{CardMainIssuerNameArray.map((value, index) => {
						return (
							<MenuItem
								value={value}
								key={index}>
								{value}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
			<StyledTextField
				value={text}
				placeholder='(선택사항)'
				disabled={selected !== '직접입력'}
				onChange={(e) => {
					setText(e.target.value);
				}}
			/>
		</Stack>
	);
};

const StyledTextField = styled(TextField)(({ theme }) => ({
	'& .MuiInputBase-input': { height: '5px' },
}));
