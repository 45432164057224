import {
	Box,
	Checkbox,
	Chip,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	SxProps,
} from '@mui/material';
import { ReactNode } from 'react';

export interface SelectTableListFilterProps<T extends string | number> {
	values: T[] | undefined;
	displayEmpty?: boolean;
	handleChange: (values: T[] | undefined) => void;
	items: { value: T; label: ReactNode }[];
	label?: ReactNode;
	sx?: SxProps;
	disabled?: boolean;
	maxLength?: number;
	disabledList?: T[];
	displayEmptyLabel?: ReactNode;
}

const ALL_VALUE = '';
export const SelectStringListTableFilter = <T extends string | number>({
	values,
	handleChange,
	items,
	label,
	sx,
	displayEmptyLabel = '전체',
	displayEmpty = false,
	disabled = false,
	maxLength = 3,
	disabledList = [],
}: SelectTableListFilterProps<T>) => {
	return (
		<FormControl sx={sx}>
			<InputLabel shrink>{label}</InputLabel>
			<Select
				size='small'
				label={label}
				disabled={disabled}
				multiple={true}
				notched
				value={values || []}
				displayEmpty
				renderValue={(selected) => (
					<Box sx={{ display: 'flex', overflow: 'hidden', gap: 0.5 }}>
						{displayEmpty && selected.length === 0 && (
							<Chip
								label={displayEmptyLabel}
								sx={{ height: 16 }}
							/>
						)}
						{selected
							.sort()
							.filter((_, index) => index < maxLength)
							.map((elem, index) => (
								<Chip
									key={index}
									label={items.find((item) => item.value === elem)?.label}
									sx={{ height: 16 }}
								/>
							))}
						{selected.length > maxLength && (
							<Chip
								label='...'
								sx={{ height: 16 }}
							/>
						)}
					</Box>
				)}
				onChange={(e) => {
					const targetValueList = e.target.value as T[];

					// @ts-ignore
					if (targetValueList.includes(ALL_VALUE)) {
						handleChange(undefined);
						return;
					} else {
						handleChange(targetValueList);
					}
				}}>
				{displayEmpty && (
					<MenuItem value={ALL_VALUE}>
						<Checkbox checked={values === undefined || values.length === 0} />
						<ListItemText>{'전체'}</ListItemText>
					</MenuItem>
				)}
				{items.map((item, idx) => {
					const checked = values?.includes(item.value);
					const isDisabled = disabledList.includes(item.value);

					return (
						<MenuItem
							key={item.value}
							value={item.value}
							disabled={isDisabled}>
							<Checkbox
								key={checked ? 'true' : 'false'}
								checked={checked}
							/>
							<ListItemText> {item.label}</ListItemText>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};
