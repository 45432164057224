import { DayCalendarFilterOptionType, GuestVisitType } from '@kinderlabs-pos/shared-data-type';
import { getToday } from '@kinderlabs-pos/shared-util';
import { useSearchParamsWrapper } from '@kinderlabs-pos/shared-react-util';
import { GuestVisitQueryState } from '@kinderlabs-pos/state';
import { CardStack, IconSquareButton, WithDialog } from '@kinderlabs-pos/ui-atoms';
import { CalendarSelect, RotatingRefreshButton, SearchBox } from '@kinderlabs-pos/ui-components';
import TuneIcon from '@mui/icons-material/Tune';
import { Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { atom, useAtomValue } from 'jotai';
import { Suspense, useState } from 'react';
import { GuestVisitDetailContentWrapper } from './GuestVisitDetailContent';
import { GuestVisitFilterDialog, GuestVisitFilterType } from './GuestVisitFilterDialog';
import { GuestVisitListContent } from './GuestVisitListContent';
import { OrderDetailContentsInGuestVisit } from './OrderDetailContentsInGuestVisit';

const today = getToday();
export const GuestVisitPage = ({}) => {
	const { searchParams, changeSearchParams } = useSearchParamsWrapper<{
		keyword?: string;
	}>();

	const keyword = searchParams.get('keyword');

	const [option, setOption] = useState<DayCalendarFilterOptionType>({
		from: today.hour(0).minute(0),
		to: today.endOf('day'),
	});
	const [filter, setFilter] = useState<GuestVisitFilterType>({});

	const selectedGuestVisit = useAtomValue(GuestVisitPage.selectedGuestVisit);
	const handleClickReload = () => GuestVisitQueryState.invalidateQueries();
	const theme = useTheme();

	const hasFilter = !!filter.status;

	return (
		<Stack
			direction={'row'}
			spacing={1}
			flex={1}
			width={'100%'}
			height={'100%'}
			sx={{ overflowY: 'auto' }}>
			<CardStack
				flex={1}
				height={'100%'}
				direction={'column'}
				spacing={2}>
				<Stack
					direction={'row'}
					spacing={1}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CalendarSelect.Dialog
								open={open}
								closeDialog={closeDialog}
								option={option}
								setOption={setOption}
							/>
						)}>
						{(openDialog) => (
							<CalendarSelect.Indicator
								option={option}
								onClick={openDialog}
								height={43}
							/>
						)}
					</WithDialog>
					<WithDialog
						dialog={(open, closeDialog) => (
							<GuestVisitFilterDialog
								open={open}
								closeDialog={closeDialog}
								filter={filter}
								setFilter={setFilter}
							/>
						)}>
						{(openDialog) => (
							<IconSquareButton
								color={!!hasFilter ? theme.palette.primary.main : undefined}
								onClick={openDialog}>
								<TuneIcon />
							</IconSquareButton>
						)}
					</WithDialog>
					<RotatingRefreshButton handleClick={handleClickReload} />
					<GuestVisitPageSearchBox
						keyword={keyword}
						setSearch={(keyword: string) => {
							// 내부 검색은 replace True
							changeSearchParams({ keyword });
						}}
					/>
				</Stack>
				<Stack
					direction={'row'}
					spacing={1}
					justifyContent={'center'}>
					<Typography variant={'body1'}>
						{'대기번호, 대표자, 대표자 전화번호 뒷자리, 동행자 이름으로 검색됩니다.'}
						{/* TODO: 추후 주문 번호 넣기 */}
					</Typography>
				</Stack>
				<Stack sx={{ flex: 1, border: `1px solid ${grey[400]}`, pb: 1, overflowY: 'auto' }}>
					<Suspense>
						<GuestVisitListContent
							keyword={keyword || undefined}
							option={option}
							filter={filter}
						/>
					</Suspense>
				</Stack>
			</CardStack>
			{selectedGuestVisit && (
				<Stack
					spacing={1}
					width={380}
					height={'100%'}
					sx={{ overflowY: 'auto' }}>
					<CardStack
						flex={1}
						sx={{ overflowY: 'auto' }}>
						<Suspense>
							<GuestVisitDetailContentWrapper
								guestVisitId={selectedGuestVisit.id}
								withActions
							/>
						</Suspense>
					</CardStack>
				</Stack>
			)}
			{!selectedGuestVisit && (
				<CardStack
					width={380}
					height={'100%'}
					alignItems={'center'}
					justifyContent={'center'}
					sx={{ bgcolor: 'white', overflowY: 'auto' }}>
					<Typography
						sx={{ textAlign: 'center' }}
						variant={'subtitle1'}
						color={'text.secondary'}>
						고객 정보를 선택하고
						<br />
						상세 정보를 확인하세요.
					</Typography>
				</CardStack>
			)}
		</Stack>
	);
};
GuestVisitPage.selectedGuestVisit = atom<GuestVisitType | undefined>(undefined);
const GuestVisitPageSearchBox = ({
	keyword,
	setSearch,
}: {
	keyword: string | null;
	setSearch: (keyword: string) => void;
}) => {
	const [tempSearchValue, setTempSearchValue] = useState(keyword || '');

	return (
		<SearchBox
			width={220}
			value={tempSearchValue}
			onChange={(val) => {
				setTempSearchValue(val);
			}}
			hasDeleteKey
			hasSearchButton
			onSearch={() => {
				setSearch(tempSearchValue);
			}}
		/>
	);
};
