import { Box, Button, ButtonProps, CircularProgress } from "@mui/material";

type ISubmitButtonProps = ButtonProps & {
  isSubmitting: boolean
}
export const SubmitButton = ({
  isSubmitting,
  disabled,
  sx,
  children,
  size,
  ...others
}: ISubmitButtonProps) => {
  const progressSize = size === "large" ? 24 : size === "medium" ? 20 : 16
  return (
    <Button
      disabled={disabled || isSubmitting}
      size={size}
      sx={{ position: "relative", ...sx }}
      {...others}>
      {children}
      {isSubmitting &&
        <CircularProgress size={progressSize} sx={{ position: "absolute" }} />}
    </Button>
  )
};