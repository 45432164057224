import { DragOutlined, PlusOutlined } from '@ant-design/icons';
import { PosBoardEmptyItemType } from '@kinderlabs-pos/shared-data-type';
import { Typography, useTheme } from '@mui/material';
import { PosBoardSelectableItemProps } from '../types';
import { PosBoardBaseItem } from './PosBoardBaseItem';

export const PosBoardSelectableEmptyItem = ({
	item,
	variant = 'default',
	selected,
	selectItemIdx,
	idx,
	CardProps,
	CardActionProps,
}: PosBoardSelectableItemProps<PosBoardEmptyItemType>) => {
	const theme = useTheme();
	const handleClick = () => selectItemIdx((prev) => (prev === idx ? null : idx));

	return (
		<PosBoardBaseItem
			CardProps={{
				...CardProps,
				sx: {
					bgcolor: 'lightgray',
					borderWidth: 5,
					borderStyle: 'solid',
					borderColor: selected ? theme.palette.primary.main : 'lightgray',
				},
			}}
			CardActionProps={{
				...CardActionProps,
				...(variant === 'selectable'
					? {
							onClick: handleClick,
					  }
					: undefined),
			}}>
			<Typography variant='h6'>
				{variant === 'selectable' && (
					<PlusOutlined
						style={{
							fontSize: 25,
							color: selected ? theme.palette.primary.main : 'inherit',
						}}
					/>
				)}
				{variant === 'draggable' && (
					<DragOutlined
						style={{
							fontSize: 25,
							color: 'white',
						}}
					/>
				)}
			</Typography>
		</PosBoardBaseItem>
	);
};
