import { OrderDetailType, PaymentInfoType } from '@kinderlabs-pos/shared-data-type';
import { PaymentState } from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useSetAtom } from 'jotai';
import { 현금결제OptionType } from '../../CashReceiptDialog/CashReceiptActionStacks';

export const useHandle현금환불Formik = ({
	payment,
	order,
	initialOptions,
	onComplete,
}: {
	payment: PaymentInfoType;
	order: OrderDetailType;
	initialOptions: 현금결제OptionType;
	onComplete?: () => void;
}) => {
	const confirm = useConfirm();
	const refund = PaymentState.actions.refund.useRefund();

	return useFormik<{ submit: string | null; options: 현금결제OptionType }>({
		initialValues: {
			submit: null,
			options: initialOptions,
		},
		onSubmit: async (values, { setErrors }) => {
			confirm(
				dayjs(payment.created).isToday()
					? '환불하시겠습니까?'
					: '오늘 결제한 건이 아니면 환불시 문제가 될수 있습니다. 환불하시겠습니까?',
				async () => {
					try {
						await refund(
							payment.receipt && values.options.type !== '현금영수증미발급'
								? {
										method: 'CASH_현금영수증_발급',
										payment,
										order,
										receipt: payment.receipt,
										customerId: values.options.customerId,
										isSignPadUsed: values.options.isSignPadUsed,
								  }
								: {
										method: 'CASH_현금영수증_미발급',
										payment,
										order,
								  }
						);

						onComplete && onComplete();
					} catch (e) {
						//@ts-ignore
						setErrors({ submit: e.message });
					}
				}
			);
		},
	});
};
