import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { AdminPosInfoState, PosState, StoreInfoState } from '@kinderlabs-pos/state';
import { useAlert } from '@kinderlabs-pos/ui-components';
import { Button, Divider, Stack, styled, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ReactNode } from 'react';

export const StyledTitle = styled(Typography)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	color: theme.palette.text.secondary,
	width: '20%',
	display: 'flex',
	alignItems: 'center',
	...theme.typography.h4,
	whiteSpace: 'pre-line',
}));

export const SettingMenuItem = ({
	title,
	innerElement,
}: {
	title: ReactNode;
	innerElement: ReactJSXElement;
}) => {
	return (
		<Stack
			direction={'row'}
			spacing={5}
			divider={<Divider orientation='vertical' />}
			marginBottom={4}>
			<StyledTitle>{title}</StyledTitle>
			{innerElement}
		</Stack>
	);
};

export const SettingMenuButton = styled(Button)({ marginRight: 16 });

export const SettingFinishButton = ({
	method,
	parkingLotUrl,
}: {
	method: 'parking-url' | 'auto-option';
	parkingLotUrl: string;
}) => {
	const updateAutomationOption = AdminPosInfoState.useUpdateAutomationOption();
	const updateParkingUrl = AdminPosInfoState.useUpdateParkingUrl();

	const { storeId, deviceId: posId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const settings = useAtomValue(PosState.settings);
	const customAlert = useAlert();

	const setPosSetting = () => {
		switch (method) {
			case 'parking-url':
				updateParkingUrl.mutate(
					{
						storeId: storeId,
						id: posId,
						parkingUrl: parkingLotUrl,
					},
					{
						onSuccess: () => customAlert('설정이 완료되었습니다.'),
					}
				);
				break;
			case 'auto-option':
				updateAutomationOption.mutate(
					{
						storeId: storeId,
						id: posId,
						receiptAutoPrint: settings.autoReceiptPrintOn,
					},
					{
						onSuccess: () => {
							// do something
						},
					}
				);
				break;
		}
	};

	return (
		<Stack
			flexGrow={1}
			direction='row'
			justifyContent='flex-end'
			alignItems='flex-end'
			spacing={1}>
			<Button
				variant='contained'
				sx={{ width: 80 }}
				onClick={setPosSetting}>
				저장
			</Button>
		</Stack>
	);
};

// export const AlertMessage = (
// 	state: boolean,
// 	setState: React.Dispatch<React.SetStateAction<boolean>>,
// 	titleSuccess: string,
// 	titleError: string
// ) => {
// 	return (
// 		<Box>
// 			<Snackbar open={state} onClose={() => setState(false)} autoHideDuration={3000}>
// 				<Alert onClose={() => setState(false)} severity="success" sx={{ width: '100%' }}>
// 					<AlertTitle>{titleSuccess}</AlertTitle>
// 				</Alert>
// 			</Snackbar>
// 			<Snackbar open={state} onClose={() => setState(false)} autoHideDuration={3000}>
// 				<Alert onClose={() => setState(false)} severity="error" sx={{ width: '100%' }}>
// 					<AlertTitle>{titleError}</AlertTitle>
// 				</Alert>
// 			</Snackbar>
// 		</Box>
// 	);
// };
