import { PaymentInfoType, 현금영수증OptionType } from '@kinderlabs-pos/shared-data-type';
import { PaymentState } from '@kinderlabs-pos/state';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const useHandle현금영수증발행Formik = ({
	payment,
	handleSuccess,
}: {
	payment: PaymentInfoType;
	handleSuccess: () => void;
}) => {
	const publish현금영수증 = PaymentState.actions.submit.use사후Publish현금영수증();

	return useFormik<{
		submit: string | null;
		options: 현금영수증OptionType;
	}>({
		initialValues: {
			submit: null,
			options: {
				type: '소득공제',
				customerId: '',
				isSignPadUsed: true,
			},
		},
		validationSchema: Yup.object().shape({
			otions: Yup.object({
				isSignPadUsed: Yup.boolean(),
				customerId: Yup.object()
					.nullable()
					.when('isSignPadUsed', {
						is: false,
						then: Yup.number().min(1, '전화번호 / 사업자 등록번호를 입력하세요.'),
					}),
			}),
		}),
		validateOnMount: true,
		onSubmit: async (values, { setErrors }) => {
			try {
				if (
					payment.method === 'CASH' ||
					payment.method === 'TRANSFER' ||
					payment.method === 'GIFTCARD'
				)
					await publish현금영수증({
						payment: {
							method: payment.method,
							id: payment.id,
							created: payment.created,
							amount: payment.amount,
							tax: payment.tax,
						},
						options: values.options,
					});

				handleSuccess();
			} catch (e) {
				//@ts-ignore
				setErrors({ submit: e.message });
			}
		},
	});
};
