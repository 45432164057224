import { CloseOutlined } from '@ant-design/icons';
import { PrintingStatusType, PrintingStatusTypeLabel } from '@kinderlabs-pos/shared-data-type';
import CheckIcon from '@mui/icons-material/Check';
import { Box, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export const PrintItemDisplay = ({
	label,
	name,
	status,
	resumePrinting,
}: {
	label: ReactNode;
	name?: ReactNode;
	status: PrintingStatusType;
	resumePrinting: () => void;
}) => {
	return (
		<Stack
			direction={'row'}
			py={1}
			width={'100%'}
			alignItems={'center'}
			justifyContent={'space-between'}>
			<Stack flex={1}>
				<Typography variant={'subtitle2'}>{label}</Typography>
				{name && (
					<Typography
						variant={'body1'}
						color={'text.secondary'}>
						{name}
					</Typography>
				)}
			</Stack>
			<Stack
				direction={'row'}
				alignItems={'center'}
				spacing={1}>
				<Typography variant={'subtitle2'}>{PrintingStatusTypeLabel[status]}</Typography>
				<PrintLabel
					printStatus={status}
					resumePrinting={resumePrinting}
				/>
			</Stack>
		</Stack>
	);
};

const PrintLabel = ({
	printStatus,
	resumePrinting,
}: {
	printStatus: PrintingStatusType;
	resumePrinting: () => void;
}) => {
	const theme = useTheme();
	return (
		<>
			{printStatus === 'ON' && (
				<CircularProgress
					size={30}
					color='info'
				/>
			)}
			{printStatus === 'COMPLETE' && (
				<Box sx={{ width: 30, height: 30, color: theme.palette.success.main }}>
					<CheckIcon />
				</Box>
			)}
			{printStatus === 'FAILED' && (
				<CloseOutlined style={{ fontSize: 22, color: theme.palette.error.main }} />
			)}
		</>
	);
};
