import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { DialogContent, Stack, Typography } from '@mui/material';
import { WonButtonGroup } from './WonButtonGroup';
import { CalculatingChangeZoneZone } from './CalculatingChangeZone';

export const 현금결제DialogContent = ({
	receivedMoney,
	setReceivedMoney,
	결제할금액,
}: {
	receivedMoney: number;
	setReceivedMoney: React.Dispatch<React.SetStateAction<number>>;
	결제할금액: number;
}) => {
	const handleClickPlus = (val: number) => setReceivedMoney((prev) => prev + val);
	const handleClickMinus = (val: number) =>
		setReceivedMoney((prev) => (prev - val > 0 ? prev - val : 0));

	return (
		<DialogContent sx={{ minWidth: 768 }}>
			<Stack>
				<Stack
					direction={'row'}
					spacing={2}>
					<Stack
						width={240}
						spacing={1}>
						<Stack>
							<Typography
								variant='h1'
								sx={{ flex: 1 }}>
								결제할 금액
							</Typography>
							<Typography
								variant='h1'
								sx={{ textAlign: 'right', fontSize: 20 }}>
								{`${numberWithCommasAnd원(결제할금액)}`}
							</Typography>
						</Stack>
						<CalculatingChangeZoneZone
							receivedMoney={receivedMoney}
							결제할금액={결제할금액}
						/>
					</Stack>
					<Stack
						spacing={1}
						width={200}>
						{([50000, 10000, 5000, 1000] as const).map((val) => {
							return (
								<WonButtonGroup
									key={val}
									unit={val}
									onClickPlus={handleClickPlus}
									onClickMinus={handleClickMinus}
								/>
							);
						})}
					</Stack>
					<Stack
						spacing={1}
						width={200}>
						{([500, 100, 50, 10] as const).map((val) => {
							return (
								<WonButtonGroup
									key={val}
									unit={val}
									onClickPlus={handleClickPlus}
									onClickMinus={handleClickMinus}
								/>
							);
						})}
					</Stack>
				</Stack>
				<Typography color='text.secondary'>※ 거스름돈 계산은 선택사항</Typography>
			</Stack>
		</DialogContent>
	);
};
