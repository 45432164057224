import { Table } from '@tanstack/react-table';
import { MuiReactTableUtils } from './MuiReactTableUtils';

export const getHeaderRows = <TData,>(table: Table<TData>) =>
	table.getHeaderGroups().map((headerGroup) => ({
		columns: headerGroup.headers
			.filter((header) => header.column.getIsVisible())
			.flatMap((header) =>
				header.isPlaceholder
					? { label: '' }
					: header.colSpan > 1
					? [
							{
								span: header.colSpan,
								// label: header.getContext().header.id,
								label: (header.column.columnDef.header as string) || '',
							},
							...[...new Array(header.colSpan - 1)].map((idx) => ({
								label: '',
							})),
					  ]
					: [
							{
								// label: header.getContext().header.id,
								label: (header.column.columnDef.header as string) || '',
							},
					  ]
			),
	}));

export const getExcelData = <TData,>(table: Table<TData>) =>
	table
		.getPreExpandedRowModel()
		.rows.flatMap((row) => (row.subRows.length > 0 ? row.subRows : row))
		.map((row) => ({
			isParent: row.getCanExpand(),
			columns: row.getVisibleCells().map((cell) => {
				const data = cell.getContext().getValue() as string | number | undefined | null;
				//@ts-ignore
				const excel반올림 = cell.column.columnDef.meta?.excel반올림 ?? false;

				//@ts-ignore
				return excel반올림 ? Math.round(data) : data;
			}),
		}));

export const getExcelFooters = <TData,>(table: Table<TData>, footerFromOriginTable = false) =>
	table
		.getFooterGroups()
		.filter((_, idx) => idx === 0)
		.map((footerRow) => ({
			columns: footerRow.headers.map((footerHeader) => ({
				value: footerHeader.isPlaceholder
					? null
					: footerFromOriginTable &&
					  footerHeader.column.columnDef.footer &&
					  typeof footerHeader.column.columnDef.footer !== 'string'
					? footerHeader.column.columnDef.footer(footerHeader.getContext())
					: (MuiReactTableUtils.getAggregatedValue({
							headerContext: footerHeader.getContext(),
							columnId: footerHeader.column.id,
					  }) as string | number | undefined | null),
			})),
		}));
