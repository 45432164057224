import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { OrderAndPaymentsType } from './OrderAndPaymentsType';
import { CartLineTypeRules } from '@kinderlabs-pos/shared-data-type';

export const OrderAndPaymentsExternalTickets = ({
	selected,
	setSelected,
	orderWithDetail,
	paymentTotal,
}: OrderAndPaymentsType) => {
	return (
		<>
			<Divider sx={{ mt: 2 }} />
			<Typography
				variant='h4'
				textAlign={'center'}
				sx={{ py: 0.5 }}>
				{`앱티켓 / 온라인권 / 쿠폰`}
			</Typography>
			<Divider />
			<SelectableListItem
				sx={{ py: 1, px: 0 }}
				selected={selected.type === 'externalTickets'}
				onClick={() => {
					setSelected({ type: 'externalTickets' });
				}}>
				<Stack
					direction={'row'}
					sx={{ flex: 1 }}
					alignItems={'center'}
					spacing={1}
					justifyContent={'space-between'}>
					<Button
						size={'small'}
						variant={selected.type === 'externalTickets' ? 'contained' : 'outlined'}>
						앱티켓 / 온라인권 / 쿠폰
					</Button>
					<Typography variant='h5'>
						{`${
							orderWithDetail.cart.lines.filter((cl) => CartLineTypeRules.is외부티켓[cl.type])
								.length
						} 개`}
					</Typography>
				</Stack>
			</SelectableListItem>
		</>
	);
};
