import { FilterWithSelect } from "@kinderlabs-pos/ui-atoms";
import { Typography } from "@mui/material";
import { ReactNode } from "react";

export interface IFilterWith전체SelectorProps {
  label?: string
  value: string | undefined
  candidates: { label: string, value: string }[]
  onChangeValue: (val?: string) => void
  labelOnEmpty?: string
}
export const FilterWith전체Selector: React.FC<IFilterWith전체SelectorProps> = ({
  label,
  value,
  candidates,
  onChangeValue,
  labelOnEmpty = '전체'
}) => {
  return (
    <FilterWithSelect
      label={
        <Typography variant={"h4"}>
          {label}
        </Typography>}
      selectOptions={[{ label: labelOnEmpty, value: "ALL" }].concat(candidates)}
      value={value || "ALL"}
      onChangeValue={(val) => {
        onChangeValue(val !== "ALL" ? val : undefined)
      }} />
  )
};