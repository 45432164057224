import { InputLabel, Select, SelectProps, Stack, Typography } from '@mui/material';

export interface ISelectFormProps extends SelectProps {
	changed?: boolean;
	readOnly?: boolean;
	helperText?: string;
	multiple?: boolean;
	isDisabledRequired?: boolean;
	disabledHelperText?: boolean;
}
export const SelectForm: React.FC<ISelectFormProps> = ({
	label,
	children,
	changed,
	helperText = 'ㅤ',
	readOnly = false,
	multiple = false,
	value = '',
	isDisabledRequired,
	disabledHelperText = false,
	...others
}) => {
	return (
		<Stack>
			<InputLabel>
				<Typography
					variant={'body2'}
					color={changed ? 'primary' : 'unset'}>
					{label}
				</Typography>
			</InputLabel>
			<Select
				disabled={isDisabledRequired}
				multiple={multiple}
				className={readOnly ? 'readonly' : ''}
				id={'select'}
				size={'small'}
				readOnly={readOnly}
				value={value}
				{...others}>
				{children}
			</Select>
			{!disabledHelperText && (
				<Typography
					variant='caption'
					component={'span'}
					color={'error'}>
					{helperText}
				</Typography>
			)}
		</Stack>
	);
};
