import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import InventoryIcon from '@mui/icons-material/Inventory';
import { IconButtonProps, Typography } from '@mui/material';

export interface ICashBoxButtonProps extends IconButtonProps {
}
export const CashBoxButton: React.FC<ICashBoxButtonProps> = (props) => {
  return (
    <IconTextButton
      variant='white'
      icon={<InventoryIcon fontSize="small" sx={theme => ({ fill: "white" })} />}
      label={<Typography>
        {"금전함"}
      </Typography>}
      {...props}
    />
  )
};