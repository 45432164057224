import { authState } from '@kinderlabs-pos/state';
import { Stack } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { allRoutes } from './routes';

export const MainPage = ({}) => {
	const routes = useRoutes(allRoutes);

	return (
		<Stack
			position={'relative'}
			height={'100%'}
			sx={{ overflowY: 'auto' }}>
			<authState.InfiniteAuthRefresh />
			{routes}
		</Stack>
	);
};
