import { IconTextButton, WithDialog } from '@kinderlabs-pos/ui-atoms';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Typography } from '@mui/material';
import { BarcodeScanDialog } from './BarcodeScanDialog';

export interface IBarcodeScannerButtonProps {}
export const BarcodeScannerButton: React.FC<IBarcodeScannerButtonProps> = ({}) => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<BarcodeScanDialog
					open={open}
					closeDialog={closeDialog}
				/>
			)}>
			{(openDialog) => (
				<IconTextButton
					variant='white'
					onClick={openDialog}
					icon={<QrCodeScannerIcon fontSize='small' />}
					label={<Typography>{'상품 검색'}</Typography>}
				/>
			)}
		</WithDialog>
	);
};
