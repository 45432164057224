import {
	PosInfoType,
	PosStatusDetailedType,
	StoreInfoType,
	VaultCashCheckType,
	VaultCashStatusType,
} from '@kinderlabs-pos/shared-data-type';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { VaultcashChecker } from '../../components/VaultcashChecker';

export const VaultcashCheckDialogButton = ({
	store,
	pos,
	openOrClose,
	buttonDisabled,
	setPosState,
}: {
	store?: StoreInfoType;
	pos?: PosInfoType;
	openOrClose: VaultCashCheckType;
	buttonDisabled: boolean;
	setPosState: React.Dispatch<React.SetStateAction<PosStatusDetailedType | undefined>>;
}) => {
	// 임시

	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<WithDialog.XlDialog
					open={open}
					closeDialog={closeDialog}
					dialogTitle={
						<Typography variant='h1'>
							{openOrClose === 'CLOSE' ? '직전시재 점검 (영업마감금)' : '시재 점검 (영업준비금)'}
						</Typography>
					}>
					<DialogContent sx={{ minWidth: 850, minHeight: 520 }}>
						{store && pos && (
							<VaultcashChecker
								mode={{
									type: openOrClose,
									storeId: store.id,
									posId: pos.id,
									closeDialog,
									setPosState,
								}}
							/>
						)}
					</DialogContent>
					<DialogActions />
				</WithDialog.XlDialog>
			)}
			children={(openDialog) => (
				<Button
					variant='contained'
					disabled={buttonDisabled}
					onClick={openDialog}>
					{openOrClose === 'CLOSE' ? '시재 마감' : '시재 점검'}
				</Button>
			)}
		/>
	);
};
