import { ReloadOutlined } from '@ant-design/icons';
import { IconSquareButton } from '@kinderlabs-pos/ui-atoms';
import { useState } from 'react';

export const RotatingRefreshButton = ({ handleClick }: { handleClick: () => void }) => {
	const [rotating, setRotating] = useState(false);

	return (
		<IconSquareButton
			onClick={() => {
				handleClick();
				setRotating(true);
				setTimeout(() => {
					setRotating(false);
				}, 700);
			}}>
			<ReloadOutlined
				style={{
					transform: `rotate(${rotating ? 360 : 0}deg)`,
					transition: rotating ? 'transform 0.7s linear' : '',
				}}
			/>
		</IconSquareButton>
	);
};
