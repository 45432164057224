import { NewPaymentMethodStatisticsData } from '@kinderlabs-pos/feature/pos/statistics';
import { useExpandableReactTable } from '@kinderlabs-pos/frameworks/table';
import {
	DayCalendarFilterOptionType,
	SalesDeviceInfoType,
	getSalesDeviceInfoName,
} from '@kinderlabs-pos/shared-data-type';
import { StoreInfoState } from '@kinderlabs-pos/state';
import { CardStack } from '@kinderlabs-pos/ui-atoms';
import { FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import { Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';

export const PaymentMethodStatisticsInPos = ({
	selectedDeviceInfoList,
	option,
}: {
	selectedDeviceInfoList?: SalesDeviceInfoType[];
	option: DayCalendarFilterOptionType;
}) => {
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	return (
		<>
			<CardStack
				spacing={1}
				width={'100%'}
				overflow={'auto'}>
				<Stack
					width='100%'
					alignItems={'center'}>
					<Typography variant='h3'>{`${
						selectedDeviceInfoList
							? selectedDeviceInfoList.map((d) => getSalesDeviceInfoName(d)).join(', ')
							: ''
					} 결제수단별 매출`}</Typography>
				</Stack>
				<Typography
					sx={{ width: '100%', p: 1 }}
					textAlign={'right'}
					variant='body2'
					color='text.secondary'>
					{`카드 결제에는 앱카드 결제가 포함됩니다.`}
				</Typography>
				<Suspense fallback={<FullSizeCircularProgress />}>
					<결제수단별매출통계테이블
						storeId={storeId}
						selectedPosIdList={selectedDeviceInfoList?.map((p) => p.id)}
						calendarOption={option}
					/>
				</Suspense>
			</CardStack>
		</>
	);
};

const 결제수단별매출통계테이블 = ({
	storeId,
	selectedPosIdList,
	calendarOption,
}: {
	storeId: number;
	selectedPosIdList?: number[];
	calendarOption: DayCalendarFilterOptionType;
}) => {
	const { data: dataRows } = NewPaymentMethodStatisticsData.usePOSData({
		filter: {
			dateType: 'DAY',
			storeIdList: [storeId],
			calendarOption,
			posIdList: selectedPosIdList,
		},
	});

	const columns = NewPaymentMethodStatisticsData.usePosColumnInfo();

	const { table, TableComponent } = useExpandableReactTable({
		reactTableOptions: {
			columns,
			data: dataRows,
		},
	});

	return <TableComponent />;
};
