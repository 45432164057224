import { DayCalendarFilterOptionType } from '../CalendarFilterOptionType';
import { DeviceType } from '../ConnectInfoType';
import { CartLineType } from '../Order/Cart/CartLineInfoType';
import { Pageable } from '../PageType';

export const kitchenOrderDeviceTypeArray = ['POS', 'KIOSK', 'KDS'] as const;
export type KitchenOrderDeviceType = (typeof kitchenOrderDeviceTypeArray)[number];
export const kitchenOrderDeviceLabel: Record<KitchenOrderDeviceType, string> = {
	POS: '포스',
	KIOSK: '키오스크',
	KDS: '주방',
};

export const kitchenOrderStatusTypeArray = [
	'ORDERED',
	'COOKING',
	'COMPLETED',
	'REAL_COMPLETED',
	'CANCELED',
] as const;
export type KitchenOrderStatusType = (typeof kitchenOrderStatusTypeArray)[number];
export const kitchenOrderStatusLabel: Record<KitchenOrderStatusType, string> = {
	ORDERED: '주문 접수',
	COOKING: '조리 중',
	COMPLETED: '조리 완료',
	REAL_COMPLETED: '배부 완료',
	CANCELED: '주문 취소',
};

export const kitchenOrderMethodTypeArray = ['cook', 'complete', 'real-complete', 'cancel'] as const;
export type KitchenOrderMethodType = (typeof kitchenOrderMethodTypeArray)[number];
export const kitchenOrderMethodTypeLabel: Record<KitchenOrderMethodType, string> = {
	cook: '조리 중',
	complete: '조리 완료',
	'real-complete': '배부 완료',
	cancel: '주문 취소',
};

export const KitchenOrderStatusTypeColor: Record<
	KitchenOrderStatusType,
	'info' | 'error' | 'warning' | 'success' | 'primary' | 'inherit'
> = {
	ORDERED: 'primary',
	COOKING: 'info',
	COMPLETED: 'success',
	REAL_COMPLETED: 'success',
	CANCELED: 'error',
};

export type AdminKitchenOrderFilterType = {
	storeIdList?: number[];
	page: number;
	size?: number;
	status?: KitchenOrderStatusType;
	deviceType?: KitchenOrderDeviceType;
	deviceIdList?: number[];
	kitchenSystemId?: number;
	search?: string;
	calendarOption: DayCalendarFilterOptionType;
	sort?: Pageable['sort'];
};

export type KitchenOrderInfoType = {
	id: string;
	status: KitchenOrderStatusType;
	orderId: string;
	storeId: number;
	deviceId: number;
	deviceType: DeviceType;
	kitchenSystemId: number;
	dailySeq: number;
	totalQuantity: number;
	created: Date;
	createdDate: Date;
	cooked: Date;
	completed?: Date;
	canceled?: Date;
	telephone?: string;
	kitchenOrderLines: KitchenOrderLineType[];
	kitchenOrderCalls: KitchenOrderCallType[];
	printed?: Date;
	recentCalled?: Date;
};

export type KitchenOrderLineType = {
	id: string;
	cartLineId: string;
	orderLineStatus: KitchenOrderStatusType;
	name: string;
	quantity: number;
	productInfoId: number;
	isForKitchen: boolean;
	cartLineType: CartLineType;
	targetOrderLineId: string;
};

export type KitchenOrderCallType = {
	id: number;
	called: Date;
};

export type KitchenOrderTableDataType = Omit<
	KitchenOrderInfoType,
	'status' | 'created' | 'canceled'
> & {
	status: string;
	created: string;
	canceled: string;
};

const getMainKitchenOrderLines = (orderLineList: KitchenOrderLineType[]) => {
	return orderLineList.filter((order) => ['PRODUCT', 'SET_PRODUCT'].includes(order.cartLineType));
};

const getKitchenOrderLineLabel = (orderLineList: KitchenOrderLineType[]): string => {
	const filteredProductTypeCartLine =
		KitchenOrderLineTypeRule.getMainKitchenOrderLines(orderLineList);

	if (filteredProductTypeCartLine.length < 1) return '주문 내역이 없습니다';
	if (filteredProductTypeCartLine.length === 1) {
		const firstItemName = filteredProductTypeCartLine[0].name;
		return firstItemName;
	} else {
		const firstItemName = filteredProductTypeCartLine[0].name;
		return `${firstItemName} 외 ${filteredProductTypeCartLine.length - 1}건`;
	}
};

const getGroupedKitchenOrderLines = (kitchenOrderLines: KitchenOrderLineType[]) =>
	kitchenOrderLines
		.filter((ocl) => !ocl.targetOrderLineId)
		.map((productOrder) => {
			return {
				targetOrder: productOrder,
				optionList: kitchenOrderLines
					.filter((ocl) => !!ocl.targetOrderLineId)
					.filter((ocl) => ocl.targetOrderLineId === productOrder.cartLineId),
			};
		});

export const KitchenOrderLineTypeRule = {
	getMainKitchenOrderLines,
	getKitchenOrderLineLabel,
	getGroupedKitchenOrderLines,
};
