import { PrinterOutlined } from '@ant-design/icons';
import { OrderState } from '@kinderlabs-pos/state';
import { List } from '@kinderlabs-pos/ui-atoms';
import { ExpandButton } from '@kinderlabs-pos/ui-components';
import { Button, Collapse, Divider, Stack, StackProps, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Fragment } from 'react';
import { PrintItemDisplay } from '../common/PrintItemDisplay';
import { StyledProgress } from '../common/StyledProgress';
import { DdiziPrintEventHandler } from './DdiziPrintEventHandler';

export const DdiziPrintHandler = ({ StackProps }: { StackProps?: StackProps }) => {
	const dizzisWithStatus = useAtomValue(OrderState.printing.ddizis.printing);
	const { expanded, toggleExpandButton } = ExpandButton.useToggle(false);

	const isPaused = DdiziPrintEventHandler.useIsPaused();
	const pausePrinting = DdiziPrintEventHandler.usePause();
	const resumePrinting = DdiziPrintEventHandler.useResume();

	const completeLength = dizzisWithStatus.filter(
		(ddizi) => ddizi.printStatus === 'COMPLETE'
	).length;

	const isComplete = dizzisWithStatus.length > 0 && completeLength === dizzisWithStatus.length;
	return (
		<>
			{dizzisWithStatus.length > 0 && (
				<Stack {...StackProps}>
					<DdiziPrintEventHandler deviceType='POS' />
					<List
						sx={{ width: '100%' }}
						disablePadding>
						<List.ItemButton
							onClick={toggleExpandButton}
							sx={{ position: 'relative', width: '100%', justifyContent: 'space-between' }}>
							<StyledProgress
								isPaused={isPaused}
								variant='determinate'
								value={(100 * completeLength) / dizzisWithStatus.length}
							/>
							<Typography variant={'h4'}>
								{`티켓 출력 ${
									isComplete ? '완료' : isPaused ? '중지' : '중'
								} (${completeLength} / ${dizzisWithStatus.length})`}
							</Typography>
							<Stack
								spacing={1}
								direction={'row'}>
								{!isComplete &&
									(!isPaused ? (
										<Button
											color={'success'}
											variant={'outlined'}
											onClick={(e) => {
												e.stopPropagation();
												pausePrinting();
											}}>
											중지
										</Button>
									) : (
										<Button
											color={'error'}
											startIcon={<PrinterOutlined />}
											variant={'outlined'}
											onClick={(e) => {
												e.stopPropagation();
												resumePrinting();
											}}>
											재개
										</Button>
									))}
								{isComplete && (
									<Button
										color={'success'}
										sx={{ pointerEvents: 'none' }}
										variant={'outlined'}>
										출력 완료
									</Button>
								)}
								<ExpandButton
									expanded={expanded}
									onClick={(e) => {
										e.stopPropagation();
										toggleExpandButton();
									}}
								/>
							</Stack>
						</List.ItemButton>
					</List>
					<Stack sx={{ overflowY: 'auto', flex: 1, width: '100%' }}>
						<Collapse in={expanded}>
							<List
								sx={{ width: '100%' }}
								disablePadding>
								{dizzisWithStatus.map((ddizi, idx) => (
									<Fragment key={idx}>
										<List.Item sx={{ width: '100%', height: 40 }}>
											<PrintItemDisplay
												label={`${ddizi.name} ( #${ddizi.id} )`}
												status={ddizi.printStatus}
												resumePrinting={resumePrinting}
											/>
										</List.Item>
										<Divider />
									</Fragment>
								))}
							</List>
						</Collapse>
					</Stack>
				</Stack>
			)}
		</>
	);
};
