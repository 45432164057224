import { DeviceType } from '@kinderlabs-pos/shared-data-type';
import {
	AdminProductInfoState,
	DdiziState,
	OrderState,
	PosElectronStoreState,
	PrintReceiptState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useEffect } from 'react';

let timeout1: NodeJS.Timeout | null = null;
const curPrintingIdxAtom = atomWithReset(0);
const pausedAtom = atom(false);

export const DdiziPrintEventHandler = ({ deviceType }: { deviceType: DeviceType }) => {
	const ddizis = useAtomValue(OrderState.printing.ddizis.printing);
	const [paused, setPaused] = useAtom(pausedAtom);
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const curDeviceInfo = StoreInfoState.useCurDeviceInfoForOrder();

	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings().data;

	// 이따 리팩토링할 것
	const [curIdx, setCurIdx] = useAtom(curPrintingIdxAtom);
	const resetAtom = useResetAtom(curPrintingIdxAtom);
	useEffect(() => {
		return () => {
			resetAtom();
		};
	}, []);

	const { print영수증띠지Async } = PrintReceiptState.useReceiptDdiziPrinter({
		storeId,
		deviceType,
	});

	const 띠지프린터라벨출력 = PrintReceiptState.LabelPrinter.usePrintLabelPrinter({
		labelPrinterList: curDeviceInfo.labelPrinterList,
	});

	const changeStatus = useSetAtom(OrderState.printing.ddizis.changeStatus);

	useEffect(() => {
		if (paused) return;
		if (ddizis.length === 0 || curIdx >= ddizis.length) return;
		// IPC Send 는 여기서
		// sendTicketPrinterMessage({ ticket 정보 })

		const ddiziToPrint = ddizis[curIdx];

		// 2번씩 rerendering 되는 문제가 발견되어서 조치
		if (ddiziToPrint.printStatus === 'ON') return;
		timeout1 = setTimeout(async () => {
			changeStatus({
				id: ddiziToPrint.id,
				printingStatus: 'ON',
			});
			const ddiziData = { ...DdiziState.utils.getDdizi인쇄Data(ddiziToPrint) };

			try {
				const result = await 띠지프린터라벨출력({
					...ddiziData,
					deviceType,
					newDdizi:
						ddiziData.deviceNum === 'LabelPrinter1'
							? localSettings?.useNewDesignOnLabelPrinter1 ?? false
							: localSettings?.useNewDesignOnLabelPrinter2 ?? false,
				});

				const resultFromReceiptPrinter = await print영수증띠지Async({
					ddizi: ddiziToPrint,
				});

				changeStatus({
					id: ddiziToPrint.id,
					printingStatus: 'COMPLETE',
				});

				setCurIdx((state) => state + 1);
			} catch {
				changeStatus({
					id: ddiziToPrint.id,
					printingStatus: 'FAILED',
				});

				setPaused(true);
			}
		}, 1000);

		return () => {
			timeout1 && clearTimeout(timeout1);
		};
	}, [ddizis, curIdx, paused]);

	// 이건 clear 하는건데, 시점을 언제로 잡아야 좋을지ㅋㅋ

	return null;
};

DdiziPrintEventHandler.useIsPaused = () => {
	return useAtomValue(pausedAtom);
};
DdiziPrintEventHandler.usePause = () => {
	const setPaused = useSetAtom(pausedAtom);

	return () => setPaused(true);
};

DdiziPrintEventHandler.useResume = () => {
	const setPaused = useSetAtom(pausedAtom);
	return () => {
		setPaused(false);
	};
};
