import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import { IconButtonProps, Typography } from '@mui/material';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { ReactNode } from 'react';

export interface ITickeTimePlusButtonProps extends IconButtonProps {
  label?: ReactNode;
}
export const TickeTimePlusButton: React.FC<ITickeTimePlusButtonProps> = ({
  label,
  onClick,
  disabled,
}) => {
  return (
    <IconTextButton
      disabled={disabled}
      onClick={onClick}
      icon={<MoreTimeIcon fontSize="small" />}
      label={label || '10분추가'}
    />
  );
};
