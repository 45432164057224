import {
	DayCalendarFilterOptionType,
	MonthCalendarFilterOptionType,
} from '@kinderlabs-pos/shared-data-type';
import { getToday } from '@kinderlabs-pos/shared-util';

import { CalendarSelectDialog } from './CalendarSelectDialog';
import { CalendarSelectIndicator } from './CalendarSelectIndicator';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { MonthSelectDialog } from './MonthSelectDialog';
import { MonthSelectIndicator } from './MonthSelectIndicator';

const DayDialogAndIndicator = ({
	option,
	setOption,
}: {
	option: DayCalendarFilterOptionType;
	setOption: (option: DayCalendarFilterOptionType) => void;
}) => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<CalendarSelectDialog
					open={open}
					closeDialog={closeDialog}
					option={option}
					setOption={(calendarOption) => setOption(calendarOption)}
				/>
			)}>
			{(openDialog) => (
				<CalendarSelectIndicator
					option={option}
					onClick={openDialog}
				/>
			)}
		</WithDialog>
	);
};

const MonthDialogAndIndicator = ({
	option,
	setOption,
}: {
	option: MonthCalendarFilterOptionType;
	setOption: (option: MonthCalendarFilterOptionType) => void;
}) => {
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<MonthSelectDialog
					open={open}
					closeDialog={closeDialog}
					option={option}
					setOption={(calendarOption) => setOption(calendarOption)}
				/>
			)}>
			{(openDialog) => (
				<MonthSelectIndicator
					option={option}
					onClick={openDialog}
				/>
			)}
		</WithDialog>
	);
};

export const CalendarSelect = {
	Dialog: CalendarSelectDialog,
	Indicator: CalendarSelectIndicator,
	DayDialogAndIndicator,
	MonthDialogAndIndicator,
};
