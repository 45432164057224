import { ClockCircleFilled } from '@ant-design/icons';
import {
	DdiziTypeToJungsan,
	JungsanInfoType,
	getJungsanPrice,
} from '@kinderlabs-pos/shared-data-type';
import {
	getDateFormat,
	getTelephoneNumberWithDash,
	getTimeFormat,
	numberWithCommasAndWon,
} from '@kinderlabs-pos/shared-util';
import { AdminStoreInfoState, StoreInfoState } from '@kinderlabs-pos/state';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Stack, StackProps, Typography, TypographyProps, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { ReactNode } from 'react';

export interface IExitPageDdiziDisplayProps extends StackProps {
	ddizi: DdiziTypeToJungsan;
	itemButton?: ReactNode;
}
export const ExitPageDdiziDisplay: React.FC<IExitPageDdiziDisplayProps> = ({
	ddizi,
	itemButton,
	...stackProps
}) => {
	const { data } = useQuery({
		...AdminStoreInfoState.keys.all(),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});
	const storeInfoList = data ?? [];
	const storeName = storeInfoList.find((s) => s.id === ddizi.publishInfo.storeId)?.name;
	const curStoreInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const jungsanInfo = StoreInfoState.useCurStoreJungsanInfo();
	const { status, started, expiration, exited } = ddizi;

	const statusIndicator = getStatusIndicator({
		ddizi: ddizi,
		overtime: curStoreInfo.overtime,
		jungsanInfo,
	});
	return (
		<Stack
			direction={'row'}
			spacing={2}
			alignItems={'center'}
			{...stackProps}>
			<Stack
				alignItems={'center'}
				sx={{ minWidth: 120, pr: 1 }}>
				<ConfirmationNumberIcon style={{ color: statusIndicator.color }} />
				{statusIndicator.label}
			</Stack>
			<Stack>
				<Stack
					direction={'row'}
					alignItems={'center'}
					flex={1}
					maxWidth={220}
					spacing={1}>
					<Typography
						variant={'h6'}
						component={'span'}
						flex={1}
						sx={{
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							overflowX: 'hidden',
						}}>
						{`${ddizi.name} ${ddizi.type === 'GROUP_TICKET' ? `(${ddizi.multiple} 명)` : ''}`}
					</Typography>
				</Stack>
				<Typography
					variant={'body1'}
					color={'text.secondary'}>{`${storeName || ''} #${ddizi.id}`}</Typography>
				<Stack
					direction={'row'}
					alignItems={'center'}
					spacing={2}>
					<Typography variant={'h6'}>{`${getDateFormat(ddizi.publishInfo.published)}`}</Typography>
					<Stack
						direction={'row'}
						alignItems={'center'}
						spacing={1}>
						<ClockCircleFilled />
						<Stack
							direction={'row'}
							alignItems={'center'}
							spacing={1}>
							{status === 'PUBLISHED' && (
								<>
									<Typography variant={'body1'}>
										{getTimeFormat(ddizi.publishInfo.published)}
									</Typography>
								</>
							)}
							{status === 'USING' && started && expiration && (
								<>
									<Typography variant={'body1'}>{getTimeFormat(started)}</Typography>
									<Typography variant={'body1'}>{'~'}</Typography>
									<Typography variant={'body1'}>{getTimeFormat(expiration)}</Typography>
								</>
							)}
							{status === 'EXITED' && started && exited && (
								<>
									<Typography variant={'body1'}>{getTimeFormat(started)}</Typography>
									<Typography variant={'body1'}>{'~'}</Typography>
									<Typography variant={'body1'}>{getTimeFormat(exited)}</Typography>
								</>
							)}
						</Stack>
					</Stack>
				</Stack>
				{ddizi.publishInfo.userName && ddizi.publishInfo.userTelephone && (
					<Stack
						direction={'row'}
						alignItems={'center'}
						spacing={1}>
						<PersonIcon sx={{ fontSize: 20 }} />
						<Typography
							variant={'h6'}
							component={'span'}>
							{ddizi.publishInfo.userName}
						</Typography>
						<Typography
							variant={'body1'}
							component={'span'}>
							({getTelephoneNumberWithDash(ddizi.publishInfo.userTelephone)})
						</Typography>
					</Stack>
				)}
			</Stack>
			<Box flex={1} />
			{itemButton}
		</Stack>
	);
};

const getStatusIndicator = ({
	ddizi,
	overtime,
	jungsanInfo,
}: {
	ddizi: DdiziTypeToJungsan;
	overtime: number;
	jungsanInfo: JungsanInfoType;
}): {
	label: ReactNode;
	color?: string;
} => {
	const theme = useTheme();

	const isOvertime = ddizi.status === 'USING' && ddizi.leftMinute && ddizi.leftMinute < 0;

	const typographyProps: TypographyProps = {
		variant: 'h5',
		sx: { textAlign: 'center' },
	};

	switch (ddizi.status) {
		case 'EXITED':
			return {
				label: (
					<Stack alignItems={'center'}>
						<Typography
							{...typographyProps}
							sx={{ color: theme.palette.success.main }}>
							{`퇴장 완료`}
						</Typography>
						{ddizi.overMinuteAfterExit && ddizi.overMinuteAfterExit > overtime ? (
							<>
								<Typography
									variant={'body2'}
									sx={{ color: theme.palette.error.main }}>
									{`(퇴장 후 ${ddizi.overMinuteAfterExit}분 초과)`}
								</Typography>
								<Typography
									variant={'body2'}
									sx={{ color: theme.palette.error.main }}>
									{`(${numberWithCommasAndWon(
										ddizi.overTimeFeeMultiplier * getJungsanPrice(jungsanInfo) * ddizi.multiple
									)})`}
								</Typography>
							</>
						) : (
							<Typography
								variant={'body2'}
								sx={{ color: theme.palette.success.main }}>
								{`(${ddizi.overMinuteAfterExit}분 전 퇴장)`}
								{/* {`추가 요금 없음`} */}
							</Typography>
						)}
					</Stack>
				),
				color: theme.palette.success.main,
			};
		case 'PUBLISHED':
			return {
				label: <Typography {...typographyProps}>{`입장 전`}</Typography>,
				color: undefined,
			};
		case 'USING':
			return isOvertime
				? {
						label: (
							<Typography
								{...typographyProps}
								color={'error'}>
								{`${-(ddizi.leftMinute || 0)} 분 초과`}
								<br />
								<Typography variant={'body2'}>
									{ddizi.hasNoAdditionalCharge
										? '초과요금 없음'
										: `(${numberWithCommasAndWon(
												ddizi.overTimeFeeMultiplier * getJungsanPrice(jungsanInfo) * ddizi.multiple
										  )})`}
								</Typography>
							</Typography>
						),
						color: theme.palette.error.main,
				  }
				: {
						label: (
							<Typography
								{...typographyProps}
								color={'primary'}>
								{`체류 중`}
								<br />
								<Typography variant={'body2'}>
									{ddizi.leftMinute !== null ? `${ddizi.leftMinute} 분 남음` : '시간 무제한'}
								</Typography>
							</Typography>
						),
						color: theme.palette.primary.main,
				  };
		case 'CANCELED':
			return {
				label: (
					<Typography {...typographyProps}>
						{`취소 완료`}
						<br />
						<Typography variant={'body2'}>
							{`(${ddizi.canceledInfo ? getTimeFormat(ddizi.canceledInfo.canceled) : '-'})`}
						</Typography>
					</Typography>
				),
				color: theme.palette.text.secondary,
			};
		case 'NOTJUNGSAN':
			return {
				label: <Typography {...typographyProps}>{`미정산`}</Typography>,
				color: theme.palette.text.disabled,
			};
	}
};
