import {
	New결제수단매출PaymentAndCardRevenueInfo,
	StatisticsApis,
} from '@kinderlabs-pos/apis/storeinfo';
import {
	PaymentMethodFilterType,
	calculateVAT,
	결제수단별매출PaymentAndCardRevenueInfo,
	결제수단별매출paymentAndCardRevenueDetailInfo,
} from '@kinderlabs-pos/shared-data-type';
import { StatisticsState, authState } from '@kinderlabs-pos/state';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { ColumnDefsHelper } from './ColumnDefsHelper';

const 라벨: Record<'DAY' | 'MONTH' | 'STORE', string> = {
	DAY: '일자',
	MONTH: '월',
	STORE: '매장',
};

export const NewPaymentMethodStatisticsData = {
	use매장별Data: ({ filter }: { filter: PaymentMethodFilterType }) => {
		if (filter.dateType !== 'DAY') throw Error();

		const storeIdList = useAtomValue(authState.storeIdListForManager);
		const { data: result } = useQuery({
			...StatisticsState.keys.salesByPaymentMethodForStore({
				params: {
					...filter.calendarOption,
					storeIdList: filter.storeIdList || storeIdList,
					posIdList: filter.posIdList,
				},
			}),
			refetchOnWindowFocus: false,
			refetchOnMount: true,
		});

		return useMemo<New결제수단매출PaymentAndCardRevenueInfo[]>(
			() => result?.paymentAndCardRevenueList ?? [],
			[result]
		);
	},
	use일자별Data: ({ filter }: { filter: PaymentMethodFilterType }) => {
		if (filter.dateType === 'TIME') throw Error();
		const storeIdList = useAtomValue(authState.storeIdListForManager);

		const { data: result } = useQuery({
			...StatisticsState.keys.salesByPeriodForDate({
				params: {
					...(filter.dateType === 'MONTH'
						? {
								dateType: filter.dateType,
								...filter.calendarOption,
						  }
						: {
								dateType: filter.dateType,
								...filter.calendarOption,
						  }),
					storeIdList: filter.storeIdList || storeIdList,
					posIdList: filter.posIdList,
				},
			}),
			refetchOnWindowFocus: false,
			refetchOnMount: true,
		});

		return useMemo(() => result?.paymentAndCardRevenueList ?? [], [result]);
	},
	use시간대별Data: ({ filter }: { filter: PaymentMethodFilterType }) => {
		if (filter.dateType !== 'TIME') throw Error();
		const storeIdList = useAtomValue(authState.storeIdListForManager);

		const { data: result } = useQuery({
			...StatisticsState.keys.salesByTime({
				params: {
					dateType: 'TIME',
					...filter.calendarOption,
					storeIdList: filter.storeIdList || storeIdList,
					posIdList: filter.posIdList,
				},
			}),
			refetchOnWindowFocus: false,
			refetchOnMount: true,
		});

		return useMemo(() => result?.paymentAndCardRevenueList ?? [], [result]);
	},
	usePOSData: ({ filter }: { filter: PaymentMethodFilterType }) => {
		const storeIdList = useAtomValue(authState.storeIdListForManager);

		if (filter.dateType !== 'DAY') throw Error();
		const { data: result, ...others } = useQuery({
			...StatisticsState.keys.salesByPaymentMethodForStore({
				params: {
					...filter.calendarOption,
					storeIdList: filter.storeIdList || storeIdList,
					posIdList: filter.posIdList,
				},
			}),
			refetchOnWindowFocus: false,
			refetchOnMount: true,
		});

		const resultData = useMemo(() => {
			const x2List = (result?.paymentAndCardRevenueList ?? []).flatMap(
				(p) => p.paymentAndCardRevenueDetailInfo
			);

			const kv = x2List.reduce(
				(result, x2) => {
					// Initialize
					if (result[x2.horizontalAxis] === undefined) {
						result[x2.horizontalAxis] = {
							count: 0,
							amount: 0,
						};
					}

					// Aggregate Key Value
					result[x2.horizontalAxis].count += x2.paymentCount;
					result[x2.horizontalAxis].amount += x2.revenue;

					return result;
				},
				{} as {
					[verticalAxis: string]: {
						count: number;
						amount: number;
					};
				}
			);

			return [...Object.keys(kv)].map((key) => ({
				verticalAxis: key,
				amount: kv[key].amount,
				count: kv[key].count,
			}));
		}, [result]);

		return { ...others, data: resultData };
	},
	usePosColumnInfo: () => {
		const enterpriseInfo = useAtomValue(authState.enterpriseInfo);
		return useMemo<
			ColumnDef<{
				verticalAxis: string;
				amount: number;
				count: number;
			}>[]
		>(() => {
			return [
				{
					accessorKey: 'verticalAxis',
					header: '결제 수단',
					size: 120,
					footer: '총 합',
				},
				{
					accessorKey: 'amount',
					header: enterpriseInfo?.id === 'zoolung' ? '순 매출' : '매출',
					size: 180,
					...ColumnDefsHelper.get금액ColumnDef('amount'),
				},
				{
					accessorKey: 'count',
					header: '건 수',
					size: 180,
					...ColumnDefsHelper.get건수ColumnDef('count'),
				},
			];
		}, [enterpriseInfo]);
	},

	useAdminColumnInfo: ({
		dateType,
		면과세매출표시,
		x2List,
	}: {
		dateType: 'DAY' | 'MONTH' | 'STORE';
		면과세매출표시: boolean;
		x2List: 결제수단별매출paymentAndCardRevenueDetailInfo[];
	}) => {
		const enterpriseInfo = useAtomValue(authState.enterpriseInfo);
		return useMemo<ColumnDef<New결제수단매출PaymentAndCardRevenueInfo>[]>(() => {
			return [
				{
					accessorKey: 'verticalAxis',
					header: 라벨[dateType],
					size: 120,
					footer: '총 합',
				},
				{
					id: 'revenue',
					header: enterpriseInfo?.id === 'zoolung' ? '순 매출' : '총 매출',
					columns: [
						{
							accessorKey: 'revenueSum',
							header: enterpriseInfo?.id === 'zoolung' ? '순 매출' : '매출',
							size: 180,
							...ColumnDefsHelper.get금액ColumnDef('revenueSum'),
						},
						...(enterpriseInfo?.id === 'vaunce'
							? ([
									{
										id: 'vat',
										accessorFn: (row) => calculateVAT({ revenue: row.revenueSum, taxFree: false }),
										header: 'VAT',
										size: 120,
										...ColumnDefsHelper.get금액ColumnDef('vat'),
									},
									{
										id: 'revenueWithoutVAT',
										accessorFn: (row) =>
											row.revenueSum - calculateVAT({ revenue: row.revenueSum, taxFree: false }),
										header: '매출 (VAT 제외)',
										size: 190,
										...ColumnDefsHelper.get금액ColumnDef('revenueWithoutVAT'),
									},
							  ] as ColumnDef<New결제수단매출PaymentAndCardRevenueInfo>[])
							: ([
									{
										id: 'vat',
										accessorFn: (row) => (row.revenueSum - row.taxFreeRevenue) / 11,
										header: 'VAT',
										size: 120,
										...ColumnDefsHelper.get금액반올림ColumnDef('vat'),
									},
									{
										id: 'revenueWithoutVAT',
										accessorFn: (row) =>
											row.revenueSum - (row.revenueSum - row.taxFreeRevenue) / 11,
										header: '순매출 (VAT 제외)',
										size: 190,
										...ColumnDefsHelper.get금액반올림ColumnDef('revenueWithoutVAT'),
									},
							  ] as ColumnDef<New결제수단매출PaymentAndCardRevenueInfo>[])),
						{
							accessorKey: 'paymentCountSum',
							header: '건수',
							size: 90,
							...ColumnDefsHelper.get건수ColumnDef('paymentCountSum'),
						},
						...(enterpriseInfo?.id === 'zoolung' && 면과세매출표시
							? ([
									{
										id: '면세RevenueTotal',
										accessorFn: (row) => row.taxFreeRevenue,
										header: '면세 총액',
										size: 120,
										...ColumnDefsHelper.get금액ColumnDef('면세RevenueTotal'),
									},
									{
										id: '과세RevenueTotal',
										accessorFn: (row) => row.revenueSum - row.taxFreeRevenue,
										header: '과세 총액',
										size: 190,
										...ColumnDefsHelper.get금액ColumnDef('과세RevenueTotal'),
									},
							  ] as ColumnDef<New결제수단매출PaymentAndCardRevenueInfo>[])
							: []),
					],
				},
				...(!면과세매출표시
					? [
							{
								id: 'receipt',
								header: '영수증',
								columns: [
									{
										accessorKey: 'receiptAmountSum',
										header: '총 발행',
										size: 180,
										...ColumnDefsHelper.get금액ColumnDef('receiptAmountSum'),
									},
									{
										accessorKey: 'receiptCountSum',
										header: '건수',
										size: 90,
										...ColumnDefsHelper.get건수ColumnDef('receiptCountSum'),
									},
								],
							},
							{
								id: 'refund',
								header: '환불',
								columns: [
									{
										accessorKey: 'refundAmountSum',
										header: '총 환불',
										size: 180,
										...ColumnDefsHelper.get금액ColumnDef('refundAmountSum'),
									},
									{
										accessorKey: 'refundCount',
										header: '건수',
										size: 90,
										...ColumnDefsHelper.get건수ColumnDef('refundCount'),
									},
								],
							},
					  ]
					: []),
				...x2List.map((x2, x2Index) => ({
					id: `${x2.horizontalAxis}`,
					header: `${x2.horizontalAxis}`,
					columns: [
						{
							id: `${x2.horizontalAxis}_x2.revenue`,
							accessorFn: (row) => row.paymentAndCardRevenueDetailInfo[x2Index].revenue,
							header: enterpriseInfo?.id === 'zoolung' ? '순 매출' : '매출',
							size: 160,
							...ColumnDefsHelper.get금액ColumnDef(`${x2.horizontalAxis}_x2.revenue`),
						},
						...(enterpriseInfo?.id === 'zoolung'
							? ([
									{
										id: `VAT${x2.horizontalAxis}`,
										accessorFn: (row) =>
											(row.paymentAndCardRevenueDetailInfo[x2Index].revenue -
												row.paymentAndCardRevenueDetailInfo[x2Index].taxFreeRevenue) /
											11,
										header: 'VAT',
										size: 100,
										...ColumnDefsHelper.get금액반올림ColumnDef(`VAT${x2.horizontalAxis}`),
									},
									{
										id: `순매출VAT제외_${x2.horizontalAxis}`,
										accessorFn: (row) =>
											row.paymentAndCardRevenueDetailInfo[x2Index].revenue -
											(row.paymentAndCardRevenueDetailInfo[x2Index].revenue -
												row.paymentAndCardRevenueDetailInfo[x2Index].taxFreeRevenue) /
												11,
										header: '순매출 (VAT 제외)',
										size: 140,
										...ColumnDefsHelper.get금액반올림ColumnDef(
											`순매출VAT제외_${x2.horizontalAxis}`
										),
									},
							  ] as ColumnDef<New결제수단매출PaymentAndCardRevenueInfo>[])
							: []),
						{
							id: `${x2.horizontalAxis}_x2.paymentCount`,
							accessorFn: (row) => row.paymentAndCardRevenueDetailInfo[x2Index].paymentCount,
							header: '결제 건수',
							size: 90,
							...ColumnDefsHelper.get건수ColumnDef(`${x2.horizontalAxis}_x2.paymentCount`),
						},
						...(enterpriseInfo?.id === 'zoolung' && 면과세매출표시
							? ([
									{
										id: `면세RevenueTotal_${x2.horizontalAxis}`,
										accessorFn: (row) =>
											row.paymentAndCardRevenueDetailInfo[x2Index].taxFreeRevenue,
										header: '면세 총액',
										size: 120,
										...ColumnDefsHelper.get금액ColumnDef(`면세RevenueTotal_${x2.horizontalAxis}`),
									},
									{
										id: `과세RevenueTotal_${x2.horizontalAxis}`,
										accessorFn: (row) =>
											row.paymentAndCardRevenueDetailInfo[x2Index].revenue -
											row.paymentAndCardRevenueDetailInfo[x2Index].taxFreeRevenue,
										header: '과세 총액',
										size: 190,
										...ColumnDefsHelper.get금액ColumnDef(`과세RevenueTotal_${x2.horizontalAxis}`),
									},
							  ] as ColumnDef<New결제수단매출PaymentAndCardRevenueInfo>[])
							: []),
						...(!면과세매출표시
							? ([
									{
										id: `${x2.horizontalAxis}_x2.refundAmount`,
										accessorFn: (row) => row.paymentAndCardRevenueDetailInfo[x2Index].refundAmount,
										header: '환불',
										size: 160,
										...ColumnDefsHelper.get금액ColumnDef(`${x2.horizontalAxis}_x2.refundAmount`),
									},
									{
										id: `${x2.horizontalAxis}_x2.refundCount`,
										accessorFn: (row) => row.paymentAndCardRevenueDetailInfo[x2Index].refundCount,
										header: '환불 건수',
										size: 90,
										...ColumnDefsHelper.get건수ColumnDef(`${x2.horizontalAxis}_x2.refundCount`),
									},
							  ] as ColumnDef<New결제수단매출PaymentAndCardRevenueInfo>[])
							: []),
					] as ColumnDef<결제수단별매출PaymentAndCardRevenueInfo>[],
				})),
			];
		}, [x2List, enterpriseInfo, 면과세매출표시]);
	},
};
