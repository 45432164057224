import { rootUrl } from '../../const';
import { createVendorOrderAsync } from './createVendorOrderAsync';
import { deleteVendorOrderInfoAsync } from './deleteVendorOrderInfoAsync';
import { downloadVendorOrderFilesAsync } from './downloadVendorOrderReturnFilesAsync';
import { getCommomnProductListAsync } from './getCommonProductListAsync';
import { getVendorOrderDetailInfoAsync } from './getVendorOrderDetailInfoAsync';
import { getVendorOrderHistoryAsync } from './getVendorOrderHistoryAsync';
import { getVendorOrderInfoListAsync } from './getVendorOrderInfoListAsync';
import { updateToCancelVendorOrderAsync } from './updateToCancelVendorOrderAsync';
import { updateToCompleteVendorOrderAsync } from './updateToCompleteVendorOrderAsync';
import { uploadVendorOrderFilesAsync } from './uploadVendorOrderFilesAsync';

export const AdminVendorOrderRetrunApis = {
	createVendorOrder: createVendorOrderAsync(rootUrl),
	getVendorOrderList: getVendorOrderInfoListAsync(rootUrl),
	getVendorOrderDetailInfo: getVendorOrderDetailInfoAsync(rootUrl),
	updateToComplteOrder: updateToCompleteVendorOrderAsync(rootUrl),
	updateToCancelOrder: updateToCancelVendorOrderAsync(rootUrl),
	uploadVendorOrderFiles: uploadVendorOrderFilesAsync(rootUrl),
	downloadVendorOrderFiles: downloadVendorOrderFilesAsync(rootUrl),
	deleteVendorOrderInfo: deleteVendorOrderInfoAsync(rootUrl),
	getVendorOrderHistory: getVendorOrderHistoryAsync(rootUrl),
	getCommonProductList: getCommomnProductListAsync(rootUrl),
};
