import { ErrorMessageType } from '@kinderlabs-pos/shared-data-type';
import { isEmpty, isSame, lodashGet } from '@kinderlabs-pos/shared-util';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { useState } from 'react';

type FormikPropsType = 'select' | 'textfield' | 'radio' | 'switch' | 'chip' | 'checkbox' | 'list';

/**
 * Formik 에서 생성된 데이터들을 한 번 가공해주기 위한 Helper.
 * 가공한채로 form name 만 넣으면 Form 컴포넌트들에 주입할 수 있는 Props 가 만들어진다.
 */
export const useFrameworkFormik = <T extends FormikValues>({
	initialValues,
	onSubmit,
	onReset,
	validationSchema,
	...others
}: FormikConfig<T>) => {
	const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>(null);
	const resetErrorMessage = () => setSubmitErrorMessage(null);
	const formik = useFormik<T>({
		validateOnMount: true,
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values, formikHelpers) => {
			try {
				await onSubmit(values, formikHelpers);
			} catch (error) {
				setSubmitErrorMessage((error as ErrorMessageType).message);
			}
		},
		onReset: (values, formikHelpers) => {
			resetErrorMessage();
			onReset && onReset(values, formikHelpers);
		},
		...others,
	});

	const getFormikProps = (
		type: FormikPropsType,
		name: string,
		options?: {
			readOnly?: boolean;
			showChanged?: boolean;
		}
	) => {
		const touchedElem = lodashGet(formik.touched, name);
		const errorElem = lodashGet(formik.errors, name);

		const helperText = !!touchedElem && { helperText: errorElem };
		const isChanged = !isSame(
			lodashGet(formik.initialValues, name),
			lodashGet(formik.values, name)
		);

		const initialValue = lodashGet(formik.initialValues, name);
		const error = !!touchedElem && !!errorElem;

		//TODO: price 값에는 getCurrencyFormatter 값 적용해서 3자리 마다 끊어읽기 가능하게
		// console.log(getCurrencyFormat(formik.values["price"]))

		return {
			name: name,
			value: lodashGet(formik.values, name),
			onBlur: formik.handleBlur,
			onChange: formik.handleChange,
			...(type !== 'radio' && { error }),
			readOnly: !!options?.readOnly,
			...(type === 'textfield' && helperText),
			...(options?.showChanged && { changed: isChanged }),
			...(!isEmpty(initialValue) && { placeholder: initialValue }),
		};
	};

	return {
		...formik,
		submitErrorMessage,
		resetErrorMessage,
		getFormikProps,
	};
};
