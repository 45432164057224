import { getTelephoneNumberWithDash } from '@kinderlabs-pos/shared-util';
import { StoreInfoState, VaunceAppTicketQueryState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { DialogContent, Divider, List, ListItem, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { Fragment } from 'react';
import { VaunceTicketItemAccordion } from './common/VaunceTicketItemAccordion';

interface VaunceAppTicketListDialogProps extends XlDialogProps {
	uid: number;
	userName: string;
	userBirth: string;
	userTelephone: string;
}
export const VaunceAppTicketListDialog = ({
	open,
	closeDialog,
	uid,
	userName,
	userBirth,
	userTelephone,
}: VaunceAppTicketListDialogProps) => {
	const { data } = useQuery({
		...VaunceAppTicketQueryState.keys.forUser(uid),
	});
	const ticketData = data ?? [];

	const ticketList = ticketData.sort((ticket, nextTicket) => nextTicket.ticketId - ticket.ticketId);
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	return (
		<WithDialog.XlDialog
			dialogTitle={`${userName} (${getTelephoneNumberWithDash(userTelephone)}) 티켓 구매내역 조회`}
			open={open}
			closeDialogAction={'닫기'}
			closeDialog={closeDialog}>
			<DialogContent sx={{ minWidth: 628 }}>
				<List>
					{ticketList.map((ticket, idx) => (
						<Fragment key={idx}>
							<ListItem>
								<Stack sx={{ width: '100%' }}>
									<VaunceTicketItemAccordion
										ticket={ticket}
										rightPanel={null}
										children={null}
										canUseTicket={ticket.isMigrated && +ticket.storeId === storeId}
									/>
								</Stack>
							</ListItem>
							<Divider />
						</Fragment>
					))}
				</List>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
