import { StatisticsApis } from '@kinderlabs-pos/apis/storeinfo';
import { MuiReactTableUtils } from '@kinderlabs-pos/frameworks/table';
import {
	SalesPerProductFilterType,
	StatisticsPageType,
	calculateVAT,
	getCategoryDataForTable,
	getCategoryReactTableColumnDefs,
	get순매출,
	상품KeyUtil,
	상품별productRevenueInfo,
} from '@kinderlabs-pos/shared-data-type';
import { getGroupBy, numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import {
	AdminCategoryInfoState,
	AdminProductInfoState,
	ProductDateType,
	StatisticsState,
	authState,
} from '@kinderlabs-pos/state';
import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import produce from 'immer';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { ColumnDefsHelper } from './ColumnDefsHelper';
export type 상품매출커스터마이즈종류Type =
	| {
			pageType: '순매출';
			상세정보보기: boolean;
	  }
	| {
			pageType: '매출';
			상세정보보기: boolean;
			옵션합쳐보기: boolean; // POS 에선 합치고 ADMIN 에선 안 합침
	  }
	| {
			pageType: '옵션/할인';
			할인옵션중무얼볼지: '할인' | '옵션';
			옵션할인적용상품구분: boolean;
	  };

export const getInitialType = (
	pageType: '순매출' | '매출' | '옵션/할인'
): 상품매출커스터마이즈종류Type => {
	if (pageType === '순매출') return { pageType, 상세정보보기: false };
	else if (pageType === '매출') return { pageType, 상세정보보기: false, 옵션합쳐보기: false };
	if (pageType === '옵션/할인')
		return { pageType, 할인옵션중무얼볼지: '할인', 옵션할인적용상품구분: false };
	else throw Error();
};

export const NewProductStatisticsData = {
	useData: ({
		filter,
		statisticsType,
	}: {
		filter: SalesPerProductFilterType;
		statisticsType: 상품매출커스터마이즈종류Type;
	}) => {
		const storeIdList = useAtomValue(authState.storeIdListForManager);

		const { data, ...others } = useQuery({
			...StatisticsState.keys.salesByProduct({
				params: {
					...(filter.dateType === 'MONTH'
						? {
								dateType: filter.dateType,
								...filter.calendarOption,
						  }
						: {
								dateType: filter.dateType,
								...filter.calendarOption,
						  }),
					categorySpec: filter.categorySpec,
					storeIdList: filter.storeIdList || storeIdList,
					posIdList: filter.posIdList,
					isWithTargetCartLine: statisticsType.pageType === '순매출',
					search: filter.search,
				},
			}),
			refetchOnWindowFocus: false,
			refetchOnMount: true,
		});

		const productMap = AdminProductInfoState.useMapInStoreQuery({});

		const isZoolung = useAtomValue(authState.enterpriseInfo)?.id === 'zoolung';

		const categoryTree = AdminCategoryInfoState.useCategoryTreeForSearch();

		const dataRows = useMemo<상품별productRevenueInfo[]>(() => {
			if (!data) return [];
			const tempData = (data.revenueInfos ?? [])
				.flatMap((d) =>
					d.productRevenueInfo.map((r) => ({
						...r,
						category: d.category,
						categoryId: d.categoryId,
					}))
				)
				.filter((d) => {
					// 다봄
					if (statisticsType.pageType !== '옵션/할인') return true;
					// 옵션할인만 본다면 옵션 할인이아닌건 모두 아웃
					else if (!d.targetKey) return false;

					const is할인 = [
						'DISCOUNT',
						'CUSTOM_DISCOUNT',
						'VAUNCE_APP_DISCOUNT',
						'KL_COUPON_DISCOUNT',
					].includes(d.cartLineType ?? '');

					// 할인만 봄
					if (statisticsType.할인옵션중무얼볼지 === '할인') return is할인;
					// 옵션만 봄
					else return !is할인;
				});

			const totalData =
				statisticsType.pageType === '순매출'
					? tempData.reduce((result, d) => result + d.realRevenueSum, 0)
					: tempData.reduce((result, d) => result + d.revenueSum, 0);

			const tempDataWith비중 = tempData.map((d) => {
				return {
					...d,
					ratio: totalData !== 0 ? d.realRevenueSum / totalData : 0,
					categoryData: getCategoryDataForTable({
						baseCategoryId: d.categoryId,
						baseCategoryName: d.category,
						categoryTree,
					}),
				};
			});

			if (
				statisticsType.pageType === '순매출' ||
				(statisticsType.pageType === '매출' && !statisticsType.옵션합쳐보기) ||
				(statisticsType.pageType === '옵션/할인' && statisticsType.옵션할인적용상품구분)
			)
				return tempDataWith비중;

			const dataMapByProductKey = getGroupBy(tempDataWith비중, (item) =>
				statisticsType.pageType === '옵션/할인' && !statisticsType.옵션할인적용상품구분
					? 상품KeyUtil.get상품명코드기준통계Key(item.productKey)
					: item.productKey
			);

			return [...Object.keys(dataMapByProductKey)].map((key) => {
				const 데이터뭉치 = dataMapByProductKey[key];
				return 데이터뭉치.length === 1
					? 데이터뭉치[0]
					: 데이터뭉치
							.filter((_, idx) => idx > 0)
							.reduce(
								(result, item, agg) =>
									produce(result, (draft) => {
										draft.revenueSum += item.revenueSum;
										draft.discount += item.discount;
										draft.optionPrice += item.optionPrice;
										draft.realRevenueSum += item.realRevenueSum;
										draft.quantitySum += item.quantitySum;

										draft.timeInfos.forEach((d, idx) => {
											d.quantity += item.timeInfos[idx].quantity;
											d.revenue += item.timeInfos[idx].revenue;
										});
									}),
								데이터뭉치[0] as 상품별productRevenueInfo
							);
			});
		}, [data, statisticsType, categoryTree, isZoolung, productMap]);

		return { ...others, data: dataRows };
	},
	useAdminTableColumnInfo: ({
		rows,
		dateType,
		statisticsType,
		소계집계함,
	}: // isWithTargetCartline,
	// 옵션합치기,
	// 상품명코드기준통계,
	// 상세정보보기,
	{
		rows: 상품별productRevenueInfo[];
		dateType: ProductDateType;
		statisticsType: 상품매출커스터마이즈종류Type;
		소계집계함: boolean;
		// isWithTargetCartline: boolean;
		// 옵션합치기: boolean;
		// 상품명코드기준통계: boolean;
		// 상세정보보기: boolean;
	}) => {
		const enterpriseInfoId = useAtomValue(authState.enterpriseInfo)?.id;

		return useMemo<ColumnDef<상품별productRevenueInfo>[]>(
			() => [
				{
					id: 'categoryGroupKey',
					accessorKey: 'categoryData.categoryGroupKey',
				},
				...getCategoryReactTableColumnDefs<상품별productRevenueInfo>(),
				getCommonNameColumnDef(소계집계함),
				...(statisticsType.pageType === '매출' ||
				(statisticsType.pageType === '옵션/할인' && statisticsType.옵션할인적용상품구분)
					? [commonColumnDef['targetName']]
					: []),
				...(statisticsType.pageType !== '옵션/할인' || statisticsType.옵션할인적용상품구분
					? [commonColumnDef['price']]
					: []),
				{
					header: 'ㅤ',
					id: 'dimensions',
					columns: [
						commonColumnDef['productId'],
						...((
							statisticsType.pageType === '옵션/할인'
								? statisticsType.옵션할인적용상품구분
								: statisticsType.상세정보보기
						)
							? [
									{
										id: 'taxFree',
										//@ts-ignore
										accessorFn: (row) => (row.taxFree ? '면세' : '과세'),
										header: '과세',
										size: 90,
									},
									{
										accessorKey: 'rawPrice',
										header: '원가',
										//@ts-ignore
										cell: (info) => numberWithCommasAndWon(info.getValue()),
										size: 90,
										aggregationFn: null,
									},
									{
										accessorKey: 'supplyPrice',
										header: '공급가',
										//@ts-ignore
										cell: (info) => numberWithCommasAndWon(info.getValue()),
										size: 90,
										aggregationFn: null,
									},
							  ]
							: []),
					],
				},
				{
					header: 'ㅤ',
					id: 'values',
					columns: [
						// 1번 순매출기본필드
						...(statisticsType.pageType === '순매출'
							? ([
									{
										accessorKey: 'revenueSum',
										header: '기본 매출',
										size: 160,
										...ColumnDefsHelper.get금액ColumnDef('revenueSum'),
									},
									commonColumnDef['quantitySum'],
									{
										accessorKey: 'optionPrice',
										header: '옵션',
										size: 90,
										...ColumnDefsHelper.get금액ColumnDef('optionPrice'),
									},
									{
										accessorKey: 'revenueTotal',
										accessorFn: (row) => row.revenueSum + row.optionPrice,
										header: '총 매출',
										size: 160,
										...ColumnDefsHelper.get금액ColumnDef('revenueTotal'),
									},
									{
										accessorKey: 'discount',
										header: '할인',
										size: 100,
										...ColumnDefsHelper.get금액ColumnDef('discount'),
									},
							  ] as ColumnDef<상품별productRevenueInfo>[])
							: []),
						// 2번 주렁주렁만 포함하는 필드
						...(statisticsType.pageType === '순매출' && enterpriseInfoId === 'zoolung'
							? ([
									{
										id: 'accountRevenue',
										header: '회계 매출', // 주렁주렁 개념. 티브리지=총매출 / 나머지=순매출
										accessorFn: (row) => {
											if (상품KeyUtil.getType(row.productKey) === 'ONLINE_TICKET_ONOFFMIX')
												return row.revenueSum;
											else
												return get순매출({
													revenue: row.revenueSum,
													discount: row.discount,
													optionPrice: row.optionPrice,
												});
										},
										size: 160,
										...ColumnDefsHelper.get금액ColumnDef('accountRevenue'),
									},
							  ] as ColumnDef<상품별productRevenueInfo>[])
							: []),
						// 3번 순매출 필드
						...(statisticsType.pageType === '순매출'
							? ([
									{
										id: 'realRevenueSum',
										header: '순 매출',
										accessorFn: (row) =>
											get순매출({
												revenue: row.revenueSum,
												discount: row.discount,
												optionPrice: row.optionPrice,
											}),
										size: 160,
										...ColumnDefsHelper.get금액ColumnDef('realRevenueSum'),
									},
							  ] as ColumnDef<상품별productRevenueInfo>[])
							: []),
						...(statisticsType.pageType !== '순매출'
							? [commonColumnDef['revenueSum'], commonColumnDef['quantitySum']]
							: []),
						{
							id: 'vat',
							accessorFn: (row) =>
								calculateVAT({
									revenue: get순매출({
										revenue: row.revenueSum,
										discount: row.discount,
										optionPrice: row.optionPrice,
									}),
									taxFree: row.taxFree,
								}),
							header: 'VAT',
							size: 120,
							...ColumnDefsHelper.get금액반올림ColumnDef('vat'),
						},
						...(statisticsType.pageType === '순매출' && enterpriseInfoId === 'zoolung'
							? ([
									{
										id: 'accountRevenueSumWithoutVat',
										header: '회계 매출 (VAT제외)',
										accessorFn: (row) => {
											const 회계매출 =
												상품KeyUtil.getType(row.productKey) === 'ONLINE_TICKET_ONOFFMIX'
													? row.revenueSum
													: get순매출({
															revenue: row.revenueSum,
															discount: row.discount,
															optionPrice: row.optionPrice,
													  });
											return 회계매출 - calculateVAT({ revenue: 회계매출, taxFree: row.taxFree });
										},
										size: 160,
										...ColumnDefsHelper.get금액반올림ColumnDef('accountRevenueSumWithoutVat'),
									},
							  ] as ColumnDef<상품별productRevenueInfo>[])
							: []),
						{
							id: 'revenueSumWithoutVat',
							accessorFn: (row) => {
								const 순매출 = get순매출({
									revenue: row.revenueSum,
									discount: row.discount,
									optionPrice: row.optionPrice,
								});
								return 순매출 - calculateVAT({ revenue: 순매출, taxFree: row.taxFree });
							},
							header: `${statisticsType.pageType === '순매출' ? '순' : '총'} 매출 (VAT 제외)`,
							size: 170,
							...ColumnDefsHelper.get금액반올림ColumnDef('revenueSumWithoutVat'),
						},
						{
							accessorKey: 'ratio',
							accessorFn: (row) => (row.ratio ?? 0) * 100,
							cell: ({ getValue }) => `${(getValue() as number).toFixed(1)} %`,
							header: '비중',
							size: 70,
							aggregationFn: 'sum',
							aggregatedCell: ({ getValue }) => `${(getValue() as number).toFixed(1)} %`,
						},
					],
				},
				...(rows.length > 0 ? rows[0].timeInfos : []).map(
					(x2, x2Index) =>
						({
							header: `${x2.dateValue} ${dateType === 'TIME' ? '시' : ''}`,
							id: `${x2.dateValue}`,
							columns: [
								{
									header: `${statisticsType.pageType === '순매출' ? '순 매출' : '총 매출'}`,
									id: `${x2.dateValue}_revenue`,
									size: 140,
									accessorFn: (row) =>
										get순매출({
											revenue: row.timeInfos[x2Index].revenue,
											discount: row.timeInfos[x2Index].discount,
											optionPrice: row.timeInfos[x2Index].optionPrice,
										}),
									...ColumnDefsHelper.get금액ColumnDef(`${x2.dateValue}_revenue`),
								},
								{
									header: `건 수`,
									size: 100,
									id: `${x2.dateValue}_count`,
									accessorFn: (row) => row.timeInfos[x2Index].quantity,
									...ColumnDefsHelper.get건수ColumnDef(`${x2.dateValue}_count`),
								},
							],
						} as ColumnDef<상품별productRevenueInfo>)
				),
			],
			[rows, statisticsType, dateType, 소계집계함, enterpriseInfoId]
		);
	},
	usePosTableColumnInfo: () => {
		return useMemo<ColumnDef<상품별productRevenueInfo>[]>(
			() => [
				commonColumnDef['nameForPos'],
				commonColumnDef['price'],
				commonColumnDef['productId'],
				commonColumnDef['revenueSum'],
				commonColumnDef['quantitySum'],
			],
			[]
		);
	},
};

const getCommonNameColumnDef = (customSort: boolean): ColumnDef<상품별productRevenueInfo> => {
	return {
		accessorKey: 'name',
		header: '상품명',
		accessorFn: (row) => 상품KeyUtil.getName(row.productKey),
		cell: (info) => {
			return info.row.getCanExpand() ? (
				<Stack
					width='100%'
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}>
					<Stack sx={{ flex: 1 }}>
						{`${info.row.getLeafRows()[0].original.categoryData?.baseCategory.name ?? ''} 계`}
					</Stack>
					<Stack>
						<MuiReactTableUtils.ColumnWithExpandButton
							label={''}
							info={info}
						/>
					</Stack>
				</Stack>
			) : (
				상품KeyUtil.getName(info.row.original.productKey)
			);
		},

		sortingFn: customSort
			? (rowA, rowB, columnId) => {
					const colAKey = rowA.original.categoryData?.categoryGroupKey ?? '';
					const colBKey = rowB.original.categoryData?.categoryGroupKey ?? '';
					if (colAKey > colBKey) return 1;
					if (colAKey < colBKey) return -1;
					else {
						return rowA.original.name > rowB.original.name ? 1 : -1;
					}
			  }
			: (rowA, rowB, columnId) => {
					return rowA.original.name > rowB.original.name ? 1 : -1;
			  },
		footer: '총 합',
		size: 230,
	};
};

const commonColumnDef: Record<string, ColumnDef<상품별productRevenueInfo>> = {
	nameForPos: {
		accessorKey: 'name',
		header: '상품명',
		accessorFn: (row) => row.name,
		cell: (info) => {
			return (
				`${상품KeyUtil.getName(info.row.original.productKey)}` +
				(info.row.original.targetKey
					? ` (${상품KeyUtil.getName(info.row.original.targetKey)})`
					: '')
			);
		},
		sortingFn: (rowA, rowB, columnId) => {
			if (rowA.original.name > rowB.original.name) return 1;
			if (rowA.original.name < rowB.original.name) return -1;
			else {
				return (rowA.original.targetName || '') > (rowB.original.targetName || '') ? 1 : -1;
			}
		},
		footer: '총 합',
		size: 280,
	},
	targetName: {
		accessorKey: 'targetName',
		accessorFn: (row) =>
			row.targetKey
				? `${상품KeyUtil.getName(row.targetKey)} (${numberWithCommasAndWon(
						상품KeyUtil.getPrice(row.targetKey)
				  )})`
				: '',
		header: '옵션 적용',
		size: 230,
	},
	price: {
		accessorKey: 'price',
		header: '가격',
		cell: (info) => numberWithCommasAndWon(info.getValue() as number),
		size: 90,
		aggregationFn: undefined,
	},
	productId: {
		accessorKey: 'productId',
		accessorFn: (row) =>
			상품KeyUtil.getType(row.productKey) === 'SET_OPTION' ? '세트 옵션' : row.productId,
		header: '상품코드',
		size: 110,
	},
	revenueSum: {
		accessorKey: 'revenueSum',
		header: '총 매출',
		size: 160,
		...ColumnDefsHelper.get금액ColumnDef('revenueSum'),
	},
	quantitySum: {
		accessorKey: 'quantitySum',
		header: '건 수',
		size: 100,
		...ColumnDefsHelper.get건수ColumnDef('quantitySum'),
	},
};
