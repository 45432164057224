import { LabelRequestDummy } from '@kinderlabs-pos/shared-data-type';
import { PosElectronStoreState, PrintReceiptState, StoreInfoState } from '@kinderlabs-pos/state';
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
} from '@mui/material';
import { useState } from 'react';
import { SettingMenuItem } from '../common';

export const LabelPage = () => {
	const [selected, setSelected] = useState(1);
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings().data;
	const saveLocalSettingsMutation = PosElectronStoreState.useSavePosLocalSettings();

	const selectLP1 = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (localSettings)
			saveLocalSettingsMutation.mutate({
				...localSettings,
				useNewDesignOnLabelPrinter1: event.target.value === 'new',
			});
	};
	const selectLP2 = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (localSettings)
			saveLocalSettingsMutation.mutate({
				...localSettings,
				useNewDesignOnLabelPrinter2: event.target.value === 'new',
			});
	};
	const curDeviceInfo = StoreInfoState.useCurDeviceInfoForOrder();
	const 띠지프린터라벨출력 = PrintReceiptState.LabelPrinter.usePrintLabelPrinter({
		labelPrinterList: curDeviceInfo.labelPrinterList,
	});

	return (
		<Stack
			direction={'column'}
			flexGrow={1}>
			<SettingMenuItem
				title='라벨 프린터 1 디자인'
				innerElement={
					<FormControl>
						<FormLabel id='lp1-design'></FormLabel>
						<RadioGroup
							row
							name='lp1-design'
							defaultValue={localSettings?.useNewDesignOnLabelPrinter1 ? 'new' : 'old'}
							onChange={selectLP1}>
							<FormControlLabel
								value='old'
								control={<Radio />}
								label='구형'
							/>
							<FormControlLabel
								value='new'
								control={<Radio />}
								label='신형'
							/>
						</RadioGroup>
					</FormControl>
				}
			/>
			<SettingMenuItem
				title='라벨 프린터 2 디자인'
				innerElement={
					<FormControl>
						<FormLabel id='lp2-design'></FormLabel>
						<RadioGroup
							row
							name='lp2-design'
							defaultValue={localSettings?.useNewDesignOnLabelPrinter2 ? 'new' : 'old'}
							onChange={selectLP2}>
							<FormControlLabel
								value='old'
								control={<Radio />}
								label='구형'
							/>
							<FormControlLabel
								value='new'
								control={<Radio />}
								label='신형'
							/>
						</RadioGroup>
					</FormControl>
				}
			/>
			<SettingMenuItem
				title='라벨 프린터 명령'
				innerElement={
					<Box>
						<Select
							sx={{ marginRight: 2, height: 45 }}
							defaultValue='1'
							onChange={(e) => {
								setSelected(parseInt(e.target.value));
							}}>
							<MenuItem value='1'>라벨 프린터 1</MenuItem>
							<MenuItem value='2'>라벨 프린터 2</MenuItem>
						</Select>
						<Button
							variant='outlined'
							onClick={async () => {
								console.log(
									await 띠지프린터라벨출력({
										...LabelRequestDummy,
										deviceType: 'POS',
										deviceNum: selected === 1 ? 'LabelPrinter1' : 'LabelPrinter2',
										newDdizi:
											selected === 1
												? localSettings?.useNewDesignOnLabelPrinter1 ?? false
												: localSettings?.useNewDesignOnLabelPrinter2 ?? false,
									})
								);
							}}>
							샘플 라벨 출력
						</Button>
					</Box>
				}
			/>
			<SettingMenuItem
				title='기타 기능'
				innerElement={
					<Button
						sx={{ marginRight: 2 }}
						variant='outlined'
						disabled>
						프린터 상태
					</Button>
				}
			/>
		</Stack>
	);
};

{
	/* <IconTextButton
variant='white'
onClick={() =>
  setPosSettings((state) => ({
    ...state,
    autoBarcodeDetectorOn: !state.autoBarcodeDetectorOn,
  }))
}
icon={posSettings.autoBarcodeDetectorOn ? <QrCodeSharpIcon /> : <QrCodeTwoToneIcon />}
label={
  <Typography>{posSettings.autoBarcodeDetectorOn ? '신형 라벨지' : '구형 라벨지'}</Typography>
}
/> */
}
