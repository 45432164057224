import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import React from 'react';
import { FilterWith전체Selector } from '../../composites/FilterWithAllSelector';
import { PosFilterSelector } from '../../composites/PosFilterSelector';
import {
	PaymentMethodType,
	PaymentMethodTypeArray,
	PaymentMethodTypeLabel,
} from '@kinderlabs-pos/shared-data-type';

export type PaymentsFilterType = {
	targetPosId?: number;
	method?: PaymentMethodType;
};
export interface IPaymentsFilterDialogProps extends XlDialogProps {
	filter: PaymentsFilterType;
	setFilter: React.Dispatch<React.SetStateAction<PaymentsFilterType>>;
}
export const PaymentsFilterDialog: React.FC<IPaymentsFilterDialogProps> = ({
	open,
	closeDialog,
	filter,
	setFilter,
}) => {
	const [tempFilter, setTempFilter] = React.useState(filter);

	return (
		<WithDialog.XlDialog
			scroll='paper'
			open={open}
			closeDialog={closeDialog}>
			<DialogTitle>결제 필터</DialogTitle>
			<DialogContent>
				<Stack
					direction={'row'}
					spacing={1}
					py={1}>
					<Box
						width={180}
						py={1}>
						<FilterWith전체Selector
							label={'결제 방법'}
							candidates={PaymentMethodTypeArray.map((key) => ({
								label: PaymentMethodTypeLabel[key],
								value: key,
							}))}
							value={tempFilter.method}
							onChangeValue={(value) => {
								// @ts-ignore // 아 select 들 type 짜증나...
								setTempFilter((prev) => ({ ...prev, method: value }));
							}}
						/>
					</Box>
					<Box
						width={180}
						py={1}>
						<PosFilterSelector
							targetPosId={tempFilter.targetPosId}
							onChangeValue={(val) => {
								setTempFilter((state) => ({
									...state,
									targetPosId: val ? +val : undefined,
								}));
							}}
						/>
					</Box>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					variant={'contained'}
					onClick={() => {
						setFilter(tempFilter);
						closeDialog && closeDialog();
					}}>
					확인
				</Button>
				<Button
					onClick={closeDialog}
					variant={'outlined'}>
					취소
				</Button>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
