import { StoreInfoState } from '@kinderlabs-pos/state';
import { Stack } from '@mui/material';
import { VaultcashChecker } from 'apps/pos-main/src/components/VaultcashChecker';
import { useAtomValue } from 'jotai';

export const VaultcashCheckTab = () => {
	const storeId = useAtomValue(StoreInfoState.curStoreInfo).id;
	const posId = useAtomValue(StoreInfoState.curPosInfo).id;

	return (
		<Stack
			direction={'column'}
			flexGrow={1}
		>
			<VaultcashChecker
				mode={{
					type: 'CHECK',
					storeId,
					posId,
				}}
			/>
		</Stack>
	);
};
