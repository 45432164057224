import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { NoHeaderTable } from '@kinderlabs-pos/ui-components';
import { alpha, Stack, Typography, useTheme } from '@mui/material';

export const CalculatingChangeZoneZone = ({
	receivedMoney,
	결제할금액,
}: {
	receivedMoney: number;
	결제할금액: number;
}) => {
	const theme = useTheme();

	const change = receivedMoney - 결제할금액;

	return (
		<Stack>
			<Typography variant='subtitle1'>{'거스름돈 계산'}</Typography>
			<NoHeaderTable
				labelCellProps={{
					sx: {
						width: '40%',
						backgroundColor: alpha(theme.palette.primary.main, 0.1),
						color: theme.palette.primary.main,
					},
				}}
				valueCellProps={{
					sx: {
						width: '60%',
					},
				}}
				data={[
					{
						label: '결제 금액',
						value: numberWithCommasAnd원(결제할금액),
					},
					{
						label: '받은 현금',
						value: (
							<span style={{ color: theme.palette.primary.main }}>
								{numberWithCommasAnd원(receivedMoney)}
							</span>
						),
					},
					{
						label: '거스름돈',
						value: (
							<span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
								{change > 0 ? numberWithCommasAnd원(change) : ' - '}
							</span>
						),
					},
				]}
			/>
		</Stack>
	);
};
