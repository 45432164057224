/**
 * Lodash 의 Get method 를 흉내내어 좀 더 업그레이드해서 만들었음
 * <p> (Lodash 의 용량이 너무 커서 직접 만듬)
 * <p> lodashGet.spec.ts 테스트 코드를 참고하세요
 *
 * @exmaple ```
 *  const object1 = { a : {b : {c : 1} } }
 *  expect(getLodashGet(object1, 'a.b.c')).toBe(1)
 *
 *  const object1 = { a : {b : {c : 1} } }
 *  expect(getLodashGet(object1, 'a.b.c.d')).toBe(undefined)
 * ```
 * @url https://lodash.com/docs/4.17.15#get
 */
export const lodashGet = (object: any, path: string) => {
	const paths = path.split('.');
	let index = 0;
	const pathLength = paths.length;
	let returnObject = object;

	while ((returnObject === 0 || returnObject) && index < pathLength) {
		returnObject = returnObject[paths[index++]];
	}

	return index && index === pathLength ? returnObject : undefined;
};
