import {
	OrderType,
	OrderTypeWithDdizisAndExchanges,
	PaymentInfoType,
	PaymentRequestInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { HandlePaymentArgsType, OrderState } from '@kinderlabs-pos/state';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';

export const useHandleSubmitPaymentForEditOrder = ({
	orderId,
	newPoints,
	onSuccess,
}: {
	orderId: string;
	newPoints: number;
	onSuccess: (result: { order: OrderTypeWithDdizisAndExchanges; payment: PaymentInfoType }) => void;
}) => {
	const editOrder = OrderState.actions.useEditOrderPayment();

	const handleEditOrder = useCallback(
		async (paymentRequestData?: PaymentRequestInfoType) => {
			await editOrder.mutateAsync(
				{ paymentRequestData, newPoints, orderId },
				{
					onSuccess: (result) => {
						onSuccess(result);
					},
				}
			);
		},
		[orderId, newPoints]
	);

	return {
		isLoading: editOrder.isLoading,
		handleEditOrder,
	};
};
