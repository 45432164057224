import { PosBoard, PosCategoryBoard } from '@kinderlabs-pos/feature/pos-board';
import {
	PosBoardInfoType,
	PosBoardItemType,
	ProductInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { AdminPosBoardInfoState, StoreInfoState } from '@kinderlabs-pos/state';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ReactNode, useEffect, useMemo, useState } from 'react';

export const PosBoardBase = ({
	renderItem,
}: {
	renderItem: (args: {
		item: PosBoardItemType;
		productInfo: Omit<ProductInfoType, 'usedInPosBoard'>;
		idx: number;
	}) => ReactNode;
}) => {
	const [selectedId, selectPosBoard] = useState<PosBoardInfoType['id'] | undefined>(undefined);
	const [boardPage, setboardPage] = useState<0 | 1 | 2>(0);

	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const posBoardInfoList = AdminPosBoardInfoState.usePosBoardInPos({ storeId, refetch: false });
	const selectedPosBoard = posBoardInfoList.find((posBoard) => posBoard.id === selectedId);

	useEffect(() => {
		selectPosBoard(posBoardInfoList.length > 0 ? posBoardInfoList[0].id : undefined);
	}, [posBoardInfoList]);
	useEffect(() => {
		setboardPage(0);
	}, [selectedPosBoard]);

	const PosItems = useMemo(() => {
		if (!selectedPosBoard)
			return [...new Array(PosBoard.ITEM_LENTH_PER_PAGE)].map((item, idx) => (
				<PosBoard.Item.EmptyItem key={idx} />
			));

		return selectedPosBoard.items
			.filter((_, idx) => PosBoard.getPosBoardItemsFilterByPage(idx, boardPage))
			.map((item, idx) => {
				if (item.productInfoId === null) return <PosBoard.Item.EmptyItem key={idx} />;
				else return renderItem({ item, productInfo: item.productInfo, idx });
			});
	}, [selectedPosBoard, boardPage, renderItem]);

	return (
		<Stack
			spacing={1}
			height={'100%'}>
			<PosCategoryBoard onChangePagination={(idx) => selectPosBoard(posBoardInfoList[idx].id)}>
				{posBoardInfoList.map((posBoardInfo, idx) => (
					<PosCategoryBoard.Item
						key={idx}
						isSelected={posBoardInfo.id === selectedPosBoard?.id}
						label={posBoardInfo.name}
						deviceType={'POS'}
						onClick={() => {
							selectPosBoard(posBoardInfo.id);
						}}
					/>
				))}
			</PosCategoryBoard>
			<PosBoard
				page={boardPage}
				setPage={(page) => setboardPage(page)}
				sx={{ width: '100%', height: '100%' }}>
				{PosItems}
			</PosBoard>
		</Stack>
	);
};
