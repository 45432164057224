import { ReactPosMainPageIPC } from '@kinderlabs-pos/ipc/react';
import {
	PosInfoType,
	PosStatusDetailedType,
	StoreInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { AdminPosInfoState, authState, PosState, StoreInfoState } from '@kinderlabs-pos/state';
import { Button, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';
import {
	alertStateType,
	FinalFailureDialog,
} from '../../components/VaultcashChecker/FinalFailureDialog';
import { usePosRouter } from '../routes';
import { getLoginButtonText } from './StatusGuideText';
import * as Sentry from '@sentry/react';

export const PosGoToMainPageButton = ({
	posState,
	selectedStoreInfo,
	selectedPosInfoId,
}: {
	posState: PosStatusDetailedType | undefined;
	selectedStoreInfo?: StoreInfoType;
	selectedPosInfoId?: number;
}) => {
	const enterpriseInfo = useAtomValue(authState.enterpriseInfo);
	const { navigatePosMain } = usePosRouter();
	const loginPos = useSetAtom(StoreInfoState.deviceLoginInfo);

	const setPosSetting = useSetPosSettings();
	const { data: selectedPosInfo } = useQuery({
		...AdminPosInfoState.keys.detail({
			storeId: selectedStoreInfo?.id,
			posId: selectedPosInfoId,
		}),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	const [alertState, setAlertState] = useState<alertStateType>({
		alertOpen: false,
		alertMessage: 'no error',
	});

	const isWebPos = selectedPosInfo?.posType === 'WEBPOS';
	const canOpen = isWebPos ? posState !== 'inUse' : posState && posState === 'alreadyOpened';

	const handleLoginPos = () => {
		if (!selectedStoreInfo?.id || !selectedPosInfoId || !enterpriseInfo) return;

		if (canOpen) {
			loginPos({
				storeId: selectedStoreInfo?.id,
				deviceId: selectedPosInfoId,
				enterpriseId: enterpriseInfo.id,
				deviceType: 'POS',
			});

			Sentry.setContext('posInfo', {
				posName: `${selectedPosInfo?.posType ?? ''} ${selectedPosInfo?.name ?? ''}`,
				storeName: selectedStoreInfo?.name ?? '',
				enterpriseId: enterpriseInfo.id,
			});

			setPosSetting({ posInfo: selectedPosInfo ?? undefined });
			navigatePosMain();
		}
	};

	return (
		<Stack>
			<Button
				fullWidth
				variant='contained'
				disabled={!posState || !canOpen}
				onClick={handleLoginPos}>
				{getLoginButtonText(posState, selectedPosInfo?.posType)}
			</Button>
			<FinalFailureDialog
				alertState={alertState}
				setAlertState={setAlertState}
			/>
		</Stack>
	);
};

const useSetPosSettings = () => {
	// 여기 set atom 만 쓰면 안 된다. atom 을 해주어야지 Initilize 가 되어서 결과가 나온다.
	const [settings, setSettingAtom] = useAtom(PosState.settings);

	return ({ posInfo }: { posInfo?: PosInfoType }) => {
		if (!posInfo) {
			return;
		}
		setSettingAtom((prev) => ({
			...prev,
			autoReceiptPrintOn: posInfo.receiptAutoPrint,
			autoBarcodeDetectorOn: posInfo.barcodeAlert,
			posFileUrl: posInfo.posFileUrl || '',
			parkingLotUrl: posInfo.parkingUrl || '',
		}));
		// JIN: 임시로 해둔 것. 이후 리팩토링~
		ReactPosMainPageIPC.sendSubPageMessage({ key: 'subpage-file-url', args: posInfo.posFileUrl });
	};
};
