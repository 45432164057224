import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, Stack } from '@mui/material';
import React from 'react';
import { FinalCheckDialog } from './FinalCheckDialog';
import { LoadPrevStatusDialog } from './LoadPrevStatusDialog';
import { VaultcashCheckerModeType, VaultcashFunctionType, initNumBills } from './common';
import { PosStatusDetailedType } from '@kinderlabs-pos/shared-data-type';

export const ActionStack = ({
	storeId,
	posId,
	checkMode,
	setCheckMode,
	setNumBills,
	total,
	numBills,
	setPosState,
}: Pick<VaultcashFunctionType, 'setNumBills' | 'total' | 'numBills'> & {
	storeId: number;
	posId: number;
	checkMode: VaultcashCheckerModeType;
	setCheckMode: React.Dispatch<React.SetStateAction<VaultcashCheckerModeType>>;
	setPosState?: React.Dispatch<React.SetStateAction<PosStatusDetailedType | undefined>>;
}) => {
	const handleRefresh = () => {
		setNumBills(initNumBills);
	};

	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<FinalCheckDialog
					storeId={storeId}
					posId={posId}
					checkMode={checkMode}
					total={total}
					numBills={numBills}
					open={open}
					closeCheckDialog={closeDialog}
					setPosState={setPosState}
				/>
			)}>
			{(openFinalCheckDilaog) => (
				<Stack
					direction='row'
					justifyContent='flex-end'
					spacing={1}>
					{checkMode.type === 'OPEN' && (
						<WithDialog
							dialog={(open, closeDialog) => (
								<LoadPrevStatusDialog
									open={open}
									closeDialog={closeDialog}
									numBills={numBills}
									setNumBills={setNumBills}
									posId={posId}
								/>
							)}>
							{(openDialog) => (
								<Button
									variant='outlined'
									onClick={openDialog}>
									불러오기
								</Button>
							)}
						</WithDialog>
					)}
					<Button
						variant='outlined'
						onClick={handleRefresh}>
						초기화
					</Button>
					{(checkMode.type === 'OPEN' || (checkMode.type === 'CLOSE' && checkMode.closeDialog)) && (
						<Button
							variant='outlined'
							onClick={checkMode.closeDialog}>
							닫기
						</Button>
					)}
					{(checkMode.type === 'OPEN' ||
						checkMode.type === 'CLOSE' ||
						checkMode.type === 'CHECK') && (
						<Button
							variant='contained'
							onClick={openFinalCheckDilaog}>
							{checkMode.type === 'CLOSE' ? '마감' : '점검'}
						</Button>
					)}
					{(checkMode.type === 'DEPOSIT' || checkMode.type === 'WITHDRAW') && (
						<>
							<Button
								variant='contained'
								onClick={() => {
									setCheckMode({
										type: 'DEPOSIT',
										storeId: checkMode.storeId,
										posId: checkMode.posId,
									});
									openFinalCheckDilaog();
								}}>
								입금
							</Button>
							<Button
								variant='contained'
								onClick={() => {
									setCheckMode({
										type: 'WITHDRAW',
										storeId: checkMode.storeId,
										posId: checkMode.posId,
									});
									openFinalCheckDilaog();
								}}>
								출금
							</Button>
						</>
					)}
				</Stack>
			)}
		</WithDialog>
	);
};
