export interface CouponListType {
	vo: CouponListBodyType;
}

export interface CouponListBodyType {
	availableList: Coupon[];
	unavailableList: Coupon[];
}

export interface Coupon {
	idx: number;
	status: string;
	title: string;
	subTitle: string;
	expiredAt: string;
	usedAt: string;
	qrData: string;
	usingDescription: null | string;
	notice: null;
}

export interface EventListBodyType {
	id: number;
	title: string;
	createDate: number[];
	startDate: string;
	endDate: string;
	topText: string;
	bottomText: string;
	imageName: string;
	imgUrl: string;
	contentsImageName: string;
	contentsImageUrl: null | string;
	contentsImageName2X: string;
	contentsImageUrl2X: string;
	isActive: boolean;
	imgUrlFile: string | null;
	contentsImageUrlFile: string | null;
	contentsImageUrl2XFile: string | null;
}

export const CouponStatusTypeArray = [
	'PUBLISHED',
	'PUBLISH_FAILED',
	'USED',
	'USE_CANCELED',
	'EXPIRED',
] as const;

type CouponStatusType = (typeof CouponStatusTypeArray)[number];
/**
 * Vaunce App 에서 스는 것이 아닌 POS 에서 사용하는 Coupon Type
 */
export interface VaunceCouponInfoType {
	couponId: number;
	couponName: string;
	couponStatus: CouponStatusType;
	couponType: 'DISCOUNT_PERCENT' | 'DISCOUNT_VALUE' | 'FREE_TICKET';
	discount: number;
	used: Date;
	expired: Date;
}

export interface VaunceCouponSearchInfoType extends VaunceCouponInfoType {
	uid: number;
	userId: string;
	userName: string;
	userBirth: string;
	userTelephone: string;
}

export const isCouponUsable: Record<CouponStatusType, boolean> = {
	PUBLISHED: true,
	PUBLISH_FAILED: false,
	USED: false,
	USE_CANCELED: true,
	EXPIRED: false,
};

export const CouponStatusLabel: Record<CouponStatusType, string> = {
	PUBLISHED: '사용 가능',
	PUBLISH_FAILED: '발행 실패',
	USED: '사용 완료',
	USE_CANCELED: '사용 가능',
	EXPIRED: '만료',
};

export const CouponTypeLabel: Record<VaunceCouponInfoType['couponType'], string> = {
	DISCOUNT_PERCENT: '',
	DISCOUNT_VALUE: '',
	FREE_TICKET: '',
};
