import { ControlOutlined, FilterOutlined } from '@ant-design/icons';
import { DayCalendarFilterOptionType, OrderType } from '@kinderlabs-pos/shared-data-type';
import { getToday } from '@kinderlabs-pos/shared-util';
import { OrderQueryState, PosElectronStoreState, StoreInfoState } from '@kinderlabs-pos/state';
import { CardStack, IconSquareButton, WithDialog } from '@kinderlabs-pos/ui-atoms';
import { CalendarSelect, RotatingRefreshButton, SearchBox } from '@kinderlabs-pos/ui-components';
import { Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { atom } from 'jotai';
import { useAtomValue } from 'jotai';
import { Suspense, useEffect, useState } from 'react';
import { OrderAndPaymentsContents } from './OrderAndPaymentsContents';
import { OrderFilterDialog, OrderFilterType } from './OrderFilterDialog';
import { OrderListContent } from './OrderListConents';
import { SelectedDetailContents } from './SelectedDetailContents';
import TuneIcon from '@mui/icons-material/Tune';
import { useSearchParamsWrapper } from '@kinderlabs-pos/shared-react-util';
import { ReactPosMainPageIPC } from '@kinderlabs-pos/ipc/react';

const today = getToday();

export const OrderPage = ({}) => {
	const { searchParams, changeSearchParams } = useSearchParamsWrapper<{
		keyword?: string;
		status?: OrderType['status'];
	}>();

	const keyword = searchParams.get('keyword');
	const status = searchParams.get('status') as OrderType['status'] | undefined;

	const [option, setOption] = useState<DayCalendarFilterOptionType>({
		from: today.startOf('d'),
		to: today.endOf('day'),
	});

	const curDeviceInfo = StoreInfoState.useCurDeviceInfoForOrder();

	const electronSettings = PosElectronStoreState.usePosMainPageLocalSettings();
	useEffect(() => {
		if (electronSettings.data?.usingDefaultPosFilter)
			setFilter((prev) => ({ ...prev, targetPosId: curDeviceInfo.id }));
	}, [electronSettings.data?.usingDefaultPosFilter]);

	const [filter, setFilter] = useState<OrderFilterType>({
		status,
	});
	const selectedOrderId = useAtomValue(OrderPage.selectedOrder);

	const handleClickReload = () => {
		OrderQueryState.invalidateQueries();
	};

	const hasFilter = !!filter.type || !!filter.status || !!filter.targetPosId;
	const theme = useTheme();

	return (
		<Stack
			direction={'row'}
			spacing={1}
			flex={1}
			width={'100%'}
			height={'100%'}
			sx={{ overflowY: 'auto' }}>
			<CardStack
				flex={1}
				spacing={1}
				p={1}>
				<Stack
					height={43}
					direction={'row'}
					spacing={1}
					color={grey[400]}
					sx={{
						overflowY: 'auto',
					}}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CalendarSelect.Dialog
								open={open}
								closeDialog={closeDialog}
								option={option}
								setOption={setOption}
							/>
						)}>
						{(openDialog) => (
							<CalendarSelect.Indicator
								option={option}
								onClick={openDialog}
								height={43}
							/>
						)}
					</WithDialog>
					<WithDialog
						dialog={(open, closeDialog) => (
							<OrderFilterDialog
								open={open}
								closeDialog={closeDialog}
								filter={filter}
								setFilter={setFilter}
							/>
						)}>
						{(openDialog) => (
							<IconSquareButton
								color={!!hasFilter ? theme.palette.primary.main : undefined}
								onClick={openDialog}>
								<TuneIcon />
							</IconSquareButton>
						)}
					</WithDialog>
					<RotatingRefreshButton handleClick={handleClickReload} />
				</Stack>
				<Stack>
					<OrderSearchBox
						keyword={keyword}
						setSearch={(val) => {
							changeSearchParams({ keyword: val });
						}}
					/>
				</Stack>
				<Stack>
					<Typography
						variant={'body2'}
						sx={{ textAlign: 'center' }}>
						{'주문번호, 결제번호, 티켓번호, 온라인 티켓번호'}
						<br />
						{'대표자 이름, 대표자 전화번호 뒷자리로 검색됩니다.'}
					</Typography>
				</Stack>
				<Stack sx={{ flex: 1, border: `1px solid ${grey[400]}`, pb: 1, overflowY: 'auto' }}>
					<Suspense>
						<OrderListContent
							keyword={keyword || undefined}
							option={option}
							filter={filter}
						/>
					</Suspense>
				</Stack>
			</CardStack>
			<Stack
				direction={'row'}
				flex={2}
				spacing={1}
				height={'100%'}
				sx={{ overflowY: 'auto' }}>
				{selectedOrderId && (
					<Suspense>
						<CardStack
							spacing={1}
							overflow={'auto'}>
							<OrderAndPaymentsContents orderId={selectedOrderId} />
						</CardStack>
						<CardStack
							flex={1}
							overflow={'auto'}>
							<Suspense>
								<SelectedDetailContents orderId={selectedOrderId} />
							</Suspense>
						</CardStack>
					</Suspense>
				)}
				{!selectedOrderId && (
					<CardStack
						width={'100%'}
						height={'100%'}
						alignItems={'center'}
						justifyContent={'center'}
						sx={{ bgcolor: 'white', overflowY: 'auto' }}>
						<Typography
							sx={{ textAlign: 'center' }}
							variant={'subtitle1'}
							color={'text.secondary'}>
							주문을 선택하고
							<br />
							상세 정보를 확인하세요.
						</Typography>
					</CardStack>
				)}
			</Stack>
		</Stack>
	);
};
OrderPage.selectedOrder = atom<OrderType['id'] | undefined>(undefined);

const OrderSearchBox = ({
	keyword,
	setSearch,
}: {
	keyword: string | null;
	setSearch: (keyword: string) => void;
}) => {
	const [tempSearchValue, setTempSearchValue] = useState(keyword || '');

	return (
		<SearchBox
			value={tempSearchValue}
			onChange={(val) => {
				setTempSearchValue(val);
			}}
			hasDeleteKey
			hasSearchButton
			onSearch={() => {
				setSearch(tempSearchValue);
			}}
		/>
	);
};
