import { KitchenOrderDetailContents } from '@kinderlabs-pos/feature/pos/kitchen-order';
import {
	DayCalendarFilterOptionType,
	KitchenOrderInfoType,
	KitchenOrderStatusType,
} from '@kinderlabs-pos/shared-data-type';
import { useSearchParamsWrapper } from '@kinderlabs-pos/shared-react-util';
import { getToday } from '@kinderlabs-pos/shared-util';
import {
	AdminKitchenSystemInfoState,
	KitchenOrderQueryState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { CardStack, IconSquareButton, WithDialog } from '@kinderlabs-pos/ui-atoms';
import {
	CalendarSelect,
	FullSizeCircularProgress,
	InfoPrintStackTitle,
	RotatingRefreshButton,
	SearchBox,
} from '@kinderlabs-pos/ui-components';
import TuneIcon from '@mui/icons-material/Tune';
import { Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import { atom, useAtomValue } from 'jotai';
import { Suspense, useState } from 'react';
import { KitchenOrderActionButtons } from '../../components/KitchenOrderKitchenButtons';
import { KitchenOrderFilterDialog, KitchenOrderFilterType } from './KitchenOrderFilterDialog';
import { KitchenOrderListContent } from './KitchenOrderListContent';
import { KitchenOrderSummaryContent } from './KitchenOrderSummaryContent';

const today = getToday();

export const KitchenOrderPage = ({}) => {
	const theme = useTheme();
	const { searchParams, changeSearchParams } = useSearchParamsWrapper<{
		keyword: string;
		status?: KitchenOrderStatusType;
	}>();

	const posInfo = useAtomValue(StoreInfoState.curPosInfo);

	const { data: kitchenSystem } = useQuery({
		...AdminKitchenSystemInfoState.keys.detail(posInfo.kitchenSystemId),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const keyword = searchParams.get('keyword');

	const [option, setOption] = useState<DayCalendarFilterOptionType>({
		from: today.hour(0).minute(0),
		to: today.endOf('day'),
	});

	const handleClickReload = () => KitchenOrderQueryState.invalidateQueries();
	const selectedKitchenOrder = useAtomValue(KitchenOrderPage.selectedKitchenOrder);
	const kichenSystemInfo = StoreInfoState.useKitchenSystemInfo();

	const [filter, setFilter] = useState<KitchenOrderFilterType>({
		status: undefined,
		deviceTypeList: undefined,
		deviceIdList: undefined,
		kitchenSystemId: kichenSystemInfo?.id,
	});

	const hasFilter = !!filter.status || !!filter.deviceTypeList || !!filter.kitchenSystemId;

	return (
		<Stack
			direction={'row'}
			spacing={1}
			flex={1}
			width={'100%'}
			height={'100%'}
			sx={{ overflowY: 'auto' }}>
			<CardStack
				flex={1}
				height={'100%'}
				direction={'column'}
				spacing={2}>
				<Stack
					direction={'row'}
					spacing={1}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<CalendarSelect.Dialog
								open={open}
								closeDialog={closeDialog}
								option={option}
								setOption={setOption}
							/>
						)}>
						{(openDialog) => (
							<CalendarSelect.Indicator
								option={option}
								onClick={openDialog}
								height={43}
							/>
						)}
					</WithDialog>
					<WithDialog
						dialog={(open, closeDialog) => (
							<KitchenOrderFilterDialog
								open={open}
								closeDialog={closeDialog}
								filter={filter}
								setFilter={setFilter}
							/>
						)}>
						{(openDialog) => (
							<IconSquareButton
								color={!!hasFilter ? theme.palette.primary.main : undefined}
								onClick={openDialog}>
								<TuneIcon />
							</IconSquareButton>
						)}
					</WithDialog>
					<RotatingRefreshButton handleClick={handleClickReload} />
					<KitchenOrderSearchBox
						keyword={keyword}
						setSearch={(val) => {
							changeSearchParams({ keyword: val });
						}}
					/>
				</Stack>
				<Stack
					spacing={1}
					alignItems={'center'}>
					<Typography variant={'body1'}>
						{kitchenSystem
							? `${kitchenSystem.name}의 주문 순번, 주문 전화번호 뒷자리로 검색됩니다.`
							: '주방 시스템을 사용하지 않습니다.'}
					</Typography>
				</Stack>
				<Stack sx={{ flex: 1, border: `1px solid ${grey[400]}`, pb: 1, overflowY: 'auto' }}>
					<Suspense>
						<KitchenOrderListContent
							keyword={keyword}
							filter={filter}
							option={option}
						/>
					</Suspense>
				</Stack>
			</CardStack>
			{selectedKitchenOrder && (
				<Stack
					spacing={1}
					width={380}
					height={'100%'}
					sx={{ overflowY: 'auto' }}>
					<CardStack
						flex={1}
						sx={{ overflowY: 'auto' }}>
						<Suspense fallback={<FullSizeCircularProgress />}>
							<KitchenOrderDetailContents
								kitchenOrderInfo={selectedKitchenOrder}
								Actions={
									<InfoPrintStackTitle
										title={
											<Suspense>
												<KitchenOrderActionButtons
													kitchOrder={selectedKitchenOrder}
													withCallButton
												/>
											</Suspense>
										}
									/>
								}
							/>
						</Suspense>
					</CardStack>
					<CardStack>
						<Suspense>
							<KitchenOrderSummaryContent selectedKitchenOrder={selectedKitchenOrder} />
						</Suspense>
					</CardStack>
				</Stack>
			)}
			{!selectedKitchenOrder && (
				<CardStack
					width={380}
					height={'100%'}
					alignItems={'center'}
					justifyContent={'center'}
					sx={{ bgcolor: 'white', overflowY: 'auto' }}>
					<Typography
						sx={{ textAlign: 'center' }}
						variant={'subtitle1'}
						color={'text.secondary'}>
						주문 정보를 선택하고
						<br />
						상세 정보를 확인하세요.
					</Typography>
				</CardStack>
			)}
		</Stack>
	);
};

KitchenOrderPage.selectedKitchenOrder = atom<KitchenOrderInfoType | undefined>(undefined);

const KitchenOrderSearchBox = ({
	keyword,
	setSearch,
}: {
	keyword: string | null;
	setSearch: (keyWord: string) => void;
}) => {
	const [tempSearchValue, setTempSearchValue] = useState(keyword || '');

	return (
		<SearchBox
			value={tempSearchValue}
			onChange={(val) => {
				setTempSearchValue(val);
			}}
			hasDeleteKey
			hasSearchButton
			onSearch={() => {
				setSearch(tempSearchValue);
			}}
		/>
	);
};
