import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { OrderAndPaymentsType } from './OrderAndPaymentsType';

export const OrderAndPaymentsExchangeCoupon = ({
	selected,
	setSelected,
	orderWithDetail,
	paymentTotal,
}: OrderAndPaymentsType) => {
	return (
		<>
			<Divider sx={{ mt: 2 }} />
			<Typography
				variant='h4'
				textAlign={'center'}
				sx={{ py: 0.5 }}>
				{`교환권`}
			</Typography>
			<Divider />
			<SelectableListItem
				sx={{ py: 1, px: 0 }}
				selected={selected.type === 'exchangeCoupon'}
				onClick={() => {
					setSelected({ type: 'exchangeCoupon' });
				}}>
				<Stack
					direction={'row'}
					sx={{ flex: 1 }}
					alignItems={'center'}
					spacing={1}
					justifyContent={'space-between'}>
					<Button
						size={'small'}
						variant={selected.type === 'exchangeCoupon' ? 'contained' : 'outlined'}>
						교환권
					</Button>
					<Typography
						variant={'h5'}
						sx={{
							textAlign: 'right',
						}}>
						{`${orderWithDetail.exchangeCoupons.length} 개`}
					</Typography>
				</Stack>
			</SelectableListItem>
		</>
	);
};
