import { Paper, Stack, styled, Tab, Tabs, Typography } from '@mui/material';

import { Suspense, useState } from 'react';
import { AutomationPage } from './pages/AutomationPage';
import { LabelPage } from './pages/LabelPage';
import { ParkingPage } from './pages/ParkingPage';
import { PaymentTestPage } from './pages/PaymentTestPage';
import { ReceiptPage } from './pages/ReceiptPage';
import { SoftwareInfoPage } from './pages/SoftwareInfoPage';

import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsIcon from '@mui/icons-material/Settings';
import { CardStack } from '@kinderlabs-pos/ui-atoms';

const tabData = [
	{
		label: '주차 설정',
		icon: <LocalParkingIcon />,
	},
	{
		label: '자동화',
		icon: <ElectricBoltIcon />,
	},
	{
		label: '결제 모듈',
		icon: <PaymentIcon />,
	},
	{
		label: '영수증 프린터',
		icon: <ReceiptLongIcon />,
	},
	{
		label: '라벨 프린터',
		icon: <LocalOfferIcon />,
	},
	{
		label: '소프트웨어 정보',
		icon: <SettingsIcon />,
	},
];

export interface IPosSettingsPageProps {}
export const PosSettingsPage: React.FC<IPosSettingsPageProps> = ({}) => {
	const [tabIndex, setTabIndex] = useState(0);
	const handleChange = (event: any, newTabIndex: number) => {
		setTabIndex(newTabIndex);
	};

	return (
		<Stack
			direction={'row'}
			height={'100%'}
			spacing={1}>
			<CardStack
				height={'100%'}
				flex={1}>
				<StyledTabs
					orientation='vertical'
					value={tabIndex}
					onChange={handleChange}>
					{tabData.map((tab, idx) => (
						<Tab
							sx={{ marginTop: 1 }}
							label={
								<Stack
									direction={'row'}
									width={'100%'}
									sx={{ color: '#5b5a5a' }}>
									{tab.icon}
									<Typography
										variant='h5'
										paddingLeft={1}>
										{tab.label}
									</Typography>
								</Stack>
							}
						/>
					))}
				</StyledTabs>
			</CardStack>
			<CardStack
				flex={4}
				sx={{ p: 2 }}>
				<Suspense>
					{tabIndex === 0 && <ParkingPage />}
					{tabIndex === 1 && <AutomationPage />}
					{tabIndex === 2 && <PaymentTestPage />}
					{tabIndex === 3 && <ReceiptPage />}
					{tabIndex === 4 && <LabelPage />}
					{tabIndex === 5 && <SoftwareInfoPage />}
				</Suspense>
			</CardStack>
		</Stack>
	);
};

const StyledTabs = styled(Tabs)((theme) => ({
	borderRight: 1,
	'& .MuiTabs-indicator': {
		width: '100%',
		opacity: 0.15,
		backgroundColor: 'black',
	},
}));
