import { useControlledReactTable } from '@kinderlabs-pos/frameworks/table';
import {
	getArrangedAppUserId,
	GuestMemberInfoType,
	OrderType,
	VaunceMemberInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

export const GuestMemberMultipleInfoListDialog = ({
	open,
	closeDialog,
	guestMemberInfoActions,
}: {
	open: boolean;
	closeDialog: () => void;
	guestMemberInfoActions: {
		setGuestmemberInfo: (info: OrderType['guestMemberInfo']) => void;
		multipleUserInfoList: GuestMemberInfoType[];
	};
}) => {
	const [keyword, setKeyword] = useState<string>('');
	const resetAllState = () => {
		setKeyword('');
		closeDialog();
	};

	const { filteredMemberInfoList, selected, TableComponent } = useGetGuestMemberInfoListComponent({
		keyword,
		multipleInfoList: guestMemberInfoActions.multipleUserInfoList,
	});

	const handleMember = () => {
		selected.forEach((info) =>
			guestMemberInfoActions.setGuestmemberInfo({
				memberId: info.id,
				name: info.name,
				telephone: info.telephone,
			})
		);
	};

	return (
		<MultipleUserInfoDialog
			open={open}
			isSelected={selected.length === 0}
			closeDialog={closeDialog}
			clearState={resetAllState}
			setKeyword={(val: string) => setKeyword(val)}
			searchedListLength={filteredMemberInfoList.length}
			TableComponent={TableComponent}
			handleMember={handleMember}
		/>
	);
};

export const AppMemberMultipleInfoListDialog = ({
	open,
	closeDialog,
	appUserMemberInfoActions,
}: {
	open: boolean;
	closeDialog: () => void;
	appUserMemberInfoActions: {
		setAppMemberInfo: (info: VaunceMemberInfoType) => void;
		multipleUserInfoList: VaunceMemberInfoType[];
	};
}) => {
	const [keyword, setKeyword] = useState<string>('');
	const resetAllState = () => {
		setKeyword('');
		closeDialog();
	};

	const { filteredMemberInfoList, selected, TableComponent } = useGetAppMemberInfoListComponent({
		keyword,
		multipleInfoList: appUserMemberInfoActions.multipleUserInfoList,
	});

	const handleMember = () => {
		selected.forEach((info) => appUserMemberInfoActions.setAppMemberInfo(info));
	};

	return (
		<MultipleUserInfoDialog
			open={open}
			isSelected={selected.length === 0}
			closeDialog={closeDialog}
			clearState={resetAllState}
			setKeyword={(val: string) => setKeyword(val)}
			searchedListLength={filteredMemberInfoList.length}
			TableComponent={TableComponent}
			handleMember={handleMember}
		/>
	);
};

const useGetGuestMemberInfoListComponent = ({
	keyword,
	multipleInfoList,
}: {
	keyword: string;
	multipleInfoList: GuestMemberInfoType[];
}): {
	filteredMemberInfoList: GuestMemberInfoType[];
	selected: GuestMemberInfoType[];
	TableComponent: () => JSX.Element;
} => {
	const filteredMemberInfoList = useMemo(
		() =>
			multipleInfoList.filter(
				(member) =>
					member.telephone.includes(keyword) ||
					member.birthday.includes(keyword) ||
					member.name.includes(keyword)
			),
		[keyword, multipleInfoList]
	);

	const columns = GuestMemberMultipleInfoListDialog.useGuestMemberColumn();

	const { table, TableComponent } = useControlledReactTable({
		reactTableOptions: {
			columns,
			data: filteredMemberInfoList ?? [],
			enableRowSelection: true,
			enableMultiRowSelection: false,
		},
		useAutoPagination: true,
		hasCheckbox: true,
	});

	const selected = table.getSelectedRowModel().rows.map((row) => row.original);

	return {
		filteredMemberInfoList,
		selected,
		TableComponent,
	};
};

GuestMemberMultipleInfoListDialog.useGuestMemberColumn = () => {
	return useMemo<ColumnDef<GuestMemberInfoType>[]>(
		() => [
			{
				accessorKey: 'id',
				header: '순서',
				cell: ({ row }) => row.index + 1,
				size: 60,
				enableSorting: false,
			},
			{
				accessorKey: 'telephone',
				header: '휴대폰 번호',
				enableSorting: false,
			},
			{
				accessorKey: 'name',
				header: '이름',
				enableSorting: false,
				size: 100,
			},
			{
				accessorKey: 'birthday',
				header: '생년월일',
				enableSorting: false,
			},
		],
		[]
	);
};

const useGetAppMemberInfoListComponent = ({
	keyword,
	multipleInfoList,
}: {
	keyword: string;
	multipleInfoList: VaunceMemberInfoType[];
}): {
	filteredMemberInfoList: VaunceMemberInfoType[];
	selected: VaunceMemberInfoType[];
	TableComponent: () => JSX.Element;
} => {
	const filteredMemberInfoList = useMemo(
		() =>
			multipleInfoList.filter(
				(member) => member.telephone.includes(keyword) || member.username.includes(keyword)
			),
		[keyword, multipleInfoList]
	);

	const columns = AppMemberMultipleInfoListDialog.useAppMemberColumns();

	const { table, TableComponent } = useControlledReactTable({
		reactTableOptions: {
			columns,
			data: filteredMemberInfoList ?? [],
			enableRowSelection: true,
			enableMultiRowSelection: false,
		},
		useAutoPagination: true,
		hasCheckbox: true,
	});

	const selected: VaunceMemberInfoType[] = table
		.getSelectedRowModel()
		.rows.map((row) => row.original);

	return {
		filteredMemberInfoList,
		selected,
		TableComponent,
	};
};

AppMemberMultipleInfoListDialog.useAppMemberColumns = () => {
	return useMemo<ColumnDef<VaunceMemberInfoType>[]>(
		() => [
			{
				accessorKey: 'id',
				header: '순서',
				cell: ({ row }) => row.index + 1,
				size: 60,
				enableSorting: false,
			},
			{
				accessorKey: 'telephone',
				header: '휴대폰 번호',
				enableSorting: false,
			},
			{ accessorKey: 'id', header: '회원번호', enableSorting: false, size: 100 },
			{
				accessorKey: 'username',
				header: '이름',
				enableSorting: false,
				size: 100,
			},
			{
				accessorKey: 'userId',
				header: '아이디',
				accessorFn: (row) =>
					row.providerType !== 'LOCAL'
						? getArrangedAppUserId(row.providerType, row.userId)
						: row.userId,
				enableSorting: false,
				size: 200,
			},
		],
		[]
	);
};

const MultipleUserInfoDialog = ({
	open,
	isSelected,
	setKeyword,
	clearState,
	closeDialog,
	handleMember,
	TableComponent,
	searchedListLength,
}: {
	open: boolean;
	isSelected: boolean;
	setKeyword: (val: string) => void;
	clearState: () => void;
	closeDialog: () => void;
	handleMember: () => void;
	TableComponent: () => JSX.Element;
	searchedListLength: number;
}) => {
	const [temp, setTemp] = useState<string>('');
	const clearAllState = () => {
		setTemp('');
		clearState();
	};

	const setMember = () => {
		handleMember();
		clearAllState();
		closeDialog();
	};
	return (
		<WithDialog.XlDialog
			maxWidth={'md'}
			fullWidth
			open={open}
			closeDialog={closeDialog}>
			<DialogTitle>{`사용자 검색 결과 (총 검색 결과 : ${searchedListLength} 명)`}</DialogTitle>
			<DialogContent sx={{ width: '100%', height: '100%', flex: 1, overflowY: 'auto' }}>
				<Stack spacing={2}>
					<form>
						<Stack
							flex={1}
							direction={'row'}
							spacing={2}
							sx={{ alignItems: 'center' }}>
							<TextField
								fullWidth
								autoFocus
								placeholder={'검색어를 입력하여 주세요.'}
								size='small'
								value={temp}
								onChange={(e) => setTemp(e.target.value)}
							/>
							<Button
								onClick={(e) => {
									e.preventDefault();
									setKeyword(temp);
								}}
								variant='contained'
								type={'submit'}>
								{'검색'}
							</Button>
						</Stack>
					</form>
					<TableComponent />
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					disabled={isSelected}
					onClick={setMember}>
					{'선택'}
				</Button>
				<Button
					variant='contained'
					onClick={clearAllState}>
					{'닫기'}
				</Button>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
