import { CartActionBoard, CartBox } from '@kinderlabs-pos/feature/pos/cart-table';
import { AdminProductInfoState, OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { Box, Unstable_Grid2 as Grid, Stack } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { BottomMenu } from '../components/BottomMenu';
import { EtcCartMenuContent } from '../modules/cart/EtcCartMenuContent';
import { ProductBoard } from '../modules/productBoard/components';
import { useHandleClickPosBoardItem } from '../modules/productBoard/components/PosBoardInPos/handleClickPosBoardItem';
import { ExitPage } from './ExitPage';
import { PosPaymentButton } from './PosPaymentButton';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { canBarcodeScan } from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';

export interface IMainPosPageProps {}
export const MainPosPage: React.FC<IMainPosPageProps> = ({}) => {
	console.log('Rerender');
	const orderDispatch = useSetAtom(OrderState.value);
	const { guestMemberInfo } = useAtomValue(OrderState.value);
	const { hasJungSan, clearSelected } = ExitPage.useOrderInfoInJungSan();
	useEffect(() => {
		// 1번만 확인하면 되어서~
		if (hasJungSan) {
			console.log('CLEAR');
			orderDispatch({ type: 'CLEAR' });
			clearSelected();
		}
	}, [hasJungSan]);

	return (
		<Grid
			container
			spacing={1}
			sx={{ height: '100%', mt: 0 }}>
			<Grid
				xs={6}
				sx={{ height: '100%' }}>
				<Stack
					height={'100%'}
					spacing={1}>
					<Box
						flex={1}
						sx={{ overflow: 'auto' }}>
						<CartBox />
					</Box>
					<Stack
						direction={'row'}
						spacing={1}>
						<Box flex={1}>
							<PosPaymentButton isGuestMemberMapped={!!guestMemberInfo} />
						</Box>
						<Box flex={2}>
							<CartActionBoard EtcCartMenuContent={EtcCartMenuContent} />
						</Box>
					</Stack>
				</Stack>
			</Grid>
			{/* <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", gap: 1, height: "100%" }}> */}
			<Grid
				xs={6}
				sx={{
					height: '100%',
				}}>
				<Box
					height={'100%'}
					gap={1}
					display={'grid'}
					gridTemplateColumns={'1fr'}
					gridTemplateRows={'1fr 48px'}>
					<KeyBoardScanner />
					<ProductBoard />
					<BottomMenu />
				</Box>
			</Grid>
		</Grid>
	);
};

let inputStrings: string[] = [];
let savedInputStringClearTimeout: NodeJS.Timeout | null = null;

const inputClearTimeout = () =>
	setTimeout(() => {
		inputStrings = [];
	}, 2000);

const removeClearTimeout = () => {
	if (savedInputStringClearTimeout) clearTimeout(savedInputStringClearTimeout);
	savedInputStringClearTimeout = null;
};

const renewClearTimeout = () => {
	removeClearTimeout();
	savedInputStringClearTimeout = inputClearTimeout();
};

const KeyBoardScanner = () => {
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const { data: productList } = useQuery({
		...AdminProductInfoState.keys.inStore(storeId),
		keepPreviousData: true,
		refetchOnMount: true,
		refetchOnWindowFocus: true,
	});

	const productListWithBarcode = useMemo(
		() => (productList ?? []).filter((p) => canBarcodeScan[p.type] && !!p.barcode),
		[productList]
	);

	const handleClickProductItemButton = useHandleClickPosBoardItem();

	const handleSearchKeyboardScan = useCallback(
		async (search: string) => {
			const realRes = productListWithBarcode.filter((p) => p.barcode === search);

			if (realRes.length === 1) {
				handleClickProductItemButton({
					cartLineId: getUuidV4(),
					productInfo: realRes[0],
				});
			} else {
			}
		},
		[productListWithBarcode]
	);

	useEffect(() => {
		const keyboardEvent = async (event: KeyboardEvent) => {
			// INPUT 에 들어간건 무시합니다.
			if ((event.target as any).tagName === 'INPUT') return;
			// DIALOG 가 켜져있거나 DRAWER 가 열려있으면 입력을 무시합니다.
			if (document.querySelector('.MuiModal-root')) return;

			// 지우지말고 각자 테스트할때 사용합시다!
			// if (event && event.key === 'a') handleSearchOnlineTicket('922299045487');
			// if (event && event.key === 'b') handleSearchOnlineTicket('922703942636');

			if (!event || event.key === 'Escape' || event.key === 'Shift' || event.key === 'CapsLock') {
				inputStrings = [];
				removeClearTimeout();
			} else if (event.key === 'Enter') {
				const joinnedText = inputStrings.join('');

				try {
					// 처리는 여기서
					await handleSearchKeyboardScan(joinnedText);
				} finally {
					removeClearTimeout();
					inputStrings = [];
				}
			} else {
				inputStrings.push(event.key as string);

				// Timeout 갱신해주세요
				renewClearTimeout();
			}
		};

		window.addEventListener('keydown', keyboardEvent);
		return () => window.removeEventListener('keydown', keyboardEvent);
	}, [handleSearchKeyboardScan]);

	return <></>;
};
