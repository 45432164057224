import { EnterOutlined, LoadingOutlined, QrcodeOutlined } from '@ant-design/icons';
import { PaymentAPI } from '@kinderlabs-pos/apis/pos';
import {
	GiftCardProviderType,
	GiftCardProviderTypeLabel,
	GiftCardType,
	PaymentMethodTypeLabel,
	PaymentRequestInfoType,
	has공식현금영수증,
	need메모옵션,
} from '@kinderlabs-pos/shared-data-type';
import { getDateFormat, numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import {
	HandlePaymentArgsType,
	PaymentState,
	StoreInfoState,
	authState,
} from '@kinderlabs-pos/state';
import { CardStack, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import {
	Button,
	DialogActions,
	DialogContent,
	IconButton,
	InputAdornment,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import * as Yup from 'yup';
import {
	현금결제OptionType,
	현금영수증발급ActionStack,
	현금영수증선택Stack,
} from '../../dialogs/CashReceiptDialog/CashReceiptActionStacks';
import { PaymentDialog } from './PaymentDialog';

type HandleSubmitOtherPaymentArgsType = {
	method: AvailablePaymentMethod;
	memo: string;
	cashReceiptCheck: false;
	options: 현금결제OptionType;
	giftCard?: GiftCardType;
};

type AvailablePaymentMethod = 'TRANSFER' | 'GIFTCARD' | 'CREDIT' | 'ZEROPAY_MPM' | 'SEOULPAY_MPM';
export interface IOtherPayDialogProps extends XlDialogProps {
	storeId: number;
	posId: number;
	orderId?: string;
	결제할금액: number;
	is결산모드?: boolean; // 계좌이체 + 현금영수증 없이만 가능
	handleSubmitPayment: (args: PaymentRequestInfoType) => Promise<void>;
}
export const OtherPayDialog: React.FC<IOtherPayDialogProps> = ({
	결제할금액,
	is결산모드 = false,
	open,
	closeDialog,
	storeId,
	posId,
	orderId,
	handleSubmitPayment,
}) => {
	const giftCardProviderList = authState.use상품권ProviderList();
	// const [provider, setProvider] = useState<GiftCardProviderType | undefined>(
	// 	giftCardProviderList.find((_, idx) => idx === 0)
	// );

	// const [keyword, setKeyword] = useState<string>('');

	// const { data } = useQuery({
	// 	...PaymentQueryState.keys.giftCard({
	// 		keyword,
	// 		provider,
	// 	}),
	// });

	const { handleSubmit, isSubmitting, isValid, values, handleChange, setValues, errors } =
		PaymentState.actions.submit.usePaymentFormik<HandleSubmitOtherPaymentArgsType>({
			storeId,
			posId,
			onClose: closeDialog,
			initialValues: {
				method: 'TRANSFER',
				memo: '',
				cashReceiptCheck: false,
				giftCard: undefined,
				options: {
					type: '현금영수증미발급',
					customerId: undefined,
					isSignPadUsed: true,
				},
			},
			handleSubmitPayment,
			validationSchema: Yup.object().shape({
				memo: Yup.string().when('method', {
					is: (method: AvailablePaymentMethod) => need메모옵션[method] === '필수',
					then: Yup.string().required().min(1),
				}),
				giftCard: Yup.object().when('method', {
					is: (method: AvailablePaymentMethod) => method === 'GIFTCARD',
					then: Yup.object()
						.required('상품권 코드는 필수입니다.')
						.test({
							message: '남은 결제 금액이 상품권 금액보다 적습니다.',
							test: (value, { createError }) => {
								if (!value) return false;
								//@ts-ignore
								if (((value?.giftCardPrice ?? 0) as number) > 결제할금액) {
									return false;
								}

								return true;
							},
						})
						.test({
							message: '상품권 유효기간이 지났습니다.',
							test: (value, { createError }) => {
								if (!value) return false;
								const today = dayjs();
								//@ts-ignore
								const expiredDay = dayjs(value.expired as string).endOf('d');
								if (today.isAfter(expiredDay)) return false;
								else return true;
							},
						})
						.test({
							message: '상품권 사용 가능 기간이 아닙니다.',
							test: (value, { createError }) => {
								if (!value) return false;
								const today = dayjs();
								//@ts-ignore
								const startDay = dayjs(value.published as string).startOf('d');
								if (today.isBefore(startDay)) return false;
								else return true;
							},
						})
						.test({
							message: '이미 환불된 상품권입니다.',
							test: (value, { createError }) => {
								if (!value) return false;
								//@ts-ignore
								return !value.refunded;
							},
						})
						.test({
							message: '이미 사용된 상품권입니다.',
							test: (value, { createError }) => {
								if (!value) return false;
								//@ts-ignore
								return !value.used;
							},
						}),
				}),
			}),
			getHandlePaymentArgs: (values) =>
				getPaymentRequestPMData({
					values,
					결제할금액,
				}),
		});

	return (
		<PaymentDialog
			dialogTitle={'기타 결제'}
			open={open}
			handleSubmitPayment={handleSubmitPayment}
			onClose={closeDialog}
			failedPaymentRequest={values.failedPaymentRequestData}>
			<DialogContent>
				<Stack
					direction={'row'}
					spacing={3}>
					<Stack width={240}>
						<Stack>
							<Typography
								variant='h1'
								sx={{ flex: 1 }}>
								결제할 금액
							</Typography>
							<Typography
								variant='h1'
								sx={{ textAlign: 'right', fontSize: 20 }}>
								{`${numberWithCommasAnd원(결제할금액)}`}
							</Typography>
						</Stack>
					</Stack>
					<Stack
						spacing={1}
						width={480}>
						<ToggleButtonGroup
							color={'primary'}
							fullWidth
							orientation='horizontal'
							size={'medium'}
							disabled={is결산모드}
							exclusive
							value={values.method}
							onChange={(e, value) => {
								value && setValues((prev) => ({ ...prev, method: value }));
							}}>
							{(['TRANSFER', 'CREDIT', 'GIFTCARD', 'ZEROPAY_MPM', 'SEOULPAY_MPM'] as const).map(
								(value, idx) => (
									<ToggleButton
										disabled={value === 'GIFTCARD' && giftCardProviderList.length === 0}
										value={value}
										key={idx}>
										{PaymentMethodTypeLabel[value]}
									</ToggleButton>
								)
							)}
						</ToggleButtonGroup>
						<TextField
							multiline
							rows={4}
							sx={{ ...(need메모옵션[values.method] !== '필수' && { display: 'none' }) }}
							value={values.memo}
							onChange={(e) => setValues((prev) => ({ ...prev, memo: e.target.value }))}
							placeholder={'메모를 반드시 입력해주세요.'}
						/>
						{values.method === 'GIFTCARD' && (
							<Stack spacing={1}>
								<Suspense>
									<GiftCardBarcodeScanner
										providerList={giftCardProviderList}
										setGiftCard={(giftCard) => setValues((prev) => ({ ...prev, giftCard }))}
									/>
								</Suspense>
								{
									<GiftCardDisplay
										giftCard={values.giftCard}
										errorMessage={errors.giftCard}
									/>
								}
							</Stack>
						)}
						<Stack>
							<Typography
								variant='subtitle1'
								color={'error'}>
								{errors.submit}
							</Typography>
						</Stack>
					</Stack>
				</Stack>
				{has공식현금영수증[values.method] && !is결산모드 && (
					<Stack spacing={1}>
						<현금영수증선택Stack
							// @ts-ignore
							options={values.options}
							setOptions={(options) => setValues({ ...values, options: options })}
						/>
						{values.options.type !== '현금영수증미발급' && (
							<현금영수증발급ActionStack
								options={values.options}
								setOptions={(options) => setValues({ ...values, options: options })}
							/>
						)}
					</Stack>
				)}
			</DialogContent>

			<DialogActions>
				<Button
					disabled={!isValid || isSubmitting}
					variant={'contained'}
					onClick={() => handleSubmit()}>
					완료
				</Button>
				<Button
					disabled={isSubmitting}
					variant={'outlined'}
					onClick={closeDialog}>
					취소
				</Button>
			</DialogActions>
		</PaymentDialog>
	);
};

const getPaymentRequestPMData = ({
	values,
	결제할금액,
}: {
	values: HandleSubmitOtherPaymentArgsType;
	결제할금액: number;
}): HandlePaymentArgsType => {
	switch (values.method) {
		case 'CREDIT':
			return {
				amount: 결제할금액,
				method: values.method,
				memo: values.memo,
			};

		case 'TRANSFER':
			return values.options.type === '현금영수증미발급'
				? {
						amount: 결제할금액,
						method: 'TRANSFER_현금영수증_미발급',
						memo: values.memo,
				  }
				: {
						method: 'TRANSFER_현금영수증_발급',
						isEnterprise: values.options.type === '지출증빙',
						isSignPadUsed: values.options.isSignPadUsed,
						amount: 결제할금액,
						memo: values.memo,
						customerId: values.options.customerId,
				  };
		case 'GIFTCARD':
			if (!values.giftCard) throw Error();
			return values.options.type === '현금영수증미발급'
				? {
						amount: values.giftCard.giftCardPrice,
						method: 'GIFTCARD_현금영수증_미발급',
						giftCard: values.giftCard,
				  }
				: {
						method: 'GIFTCARD_현금영수증_발급',
						isEnterprise: values.options.type === '지출증빙',
						isSignPadUsed: values.options.isSignPadUsed,
						amount: values.giftCard.giftCardPrice,
						customerId: values.options.customerId,
						giftCard: values.giftCard,
				  };
		case 'ZEROPAY_MPM':
		case 'SEOULPAY_MPM':
			return {
				method: values.method,
				amount: 결제할금액,
			};
	}
};

const GiftCardBarcodeScanner = ({
	providerList,
	setGiftCard,
}: {
	providerList: GiftCardProviderType[];
	setGiftCard: (giftCard?: GiftCardType) => void;
}) => {
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	const { handleSubmit, isSubmitting, values, setValues } = useFormik<{
		provider?: GiftCardProviderType;
		keyword: string;
		submitError?: string;
		loading: boolean;
	}>({
		initialValues: {
			provider: providerList.find((_, idx) => idx === 0),
			keyword: '',
			submitError: undefined,
			loading: false,
		},
		onSubmit: async (values) => {
			if (!values.provider || values.keyword.length < 3) return null;

			try {
				const data = await PaymentAPI.searchGiftCard({
					storeId,
					posId: deviceId,
					keyword: values.keyword,
					provider: values.provider,
				});

				setGiftCard(data);
				return;
			} catch {
				return null;
			}
		},
	});

	if (!values.provider || providerList.length === 0) return null;
	return (
		<form onSubmit={handleSubmit}>
			<Stack spacing={0.5}>
				<Typography color='text.secondary'>상품권번호 바코드 입력</Typography>
				<Stack
					direction={'row'}
					spacing={1}>
					<Select
						value={values.provider}
						onChange={(e) =>
							setValues((prev) => ({
								...prev,
								provider: e.target.value ? (e.target.value as GiftCardProviderType) : undefined,
							}))
						}>
						{providerList.map((p) => (
							<MenuItem
								value={p}
								key={p}>
								{GiftCardProviderTypeLabel[p]}
							</MenuItem>
						))}
					</Select>
					<OutlinedInput
						sx={{ flex: 1 }}
						value={values.keyword}
						onChange={(e) => {
							setValues((prev) => ({ ...prev, keyword: e.target.value }));
						}}
						placeholder='상품권의 QR코드/바코드를 입력해주세요.'
						disabled={isSubmitting}
						autoFocus
						startAdornment={
							<InputAdornment position='start'>
								<QrcodeOutlined />
							</InputAdornment>
						}
						endAdornment={
							<InputAdornment position='end'>
								{isSubmitting ? (
									<LoadingOutlined />
								) : (
									<IconButton type='submit'>
										<EnterOutlined />
									</IconButton>
								)}
							</InputAdornment>
						}
					/>
				</Stack>
			</Stack>
		</form>
	);
};

const GiftCardDisplay = ({
	giftCard,
	errorMessage,
}: {
	giftCard?: GiftCardType;
	errorMessage?: string;
}) => {
	if (!giftCard) return <Stack height={80}></Stack>;

	const 날짜표시종류: '환불' | '사용' | '일반' = giftCard.refunded
		? '환불'
		: giftCard.used
		? '사용'
		: '일반';

	return (
		<Stack width='100%'>
			<CardStack>
				<Typography variant='h5'>{giftCard.giftCardName}</Typography>
				<Typography variant='body1'>{numberWithCommasAnd원(giftCard.giftCardPrice)}</Typography>
				<Typography
					color='text.secondary'
					variant='body1'>{`#${giftCard.giftCardNumber}`}</Typography>
				{날짜표시종류 === '환불' && giftCard.refunded && (
					<Typography
						variant='body1'
						color='error'>{`환불일 : ${getDateFormat(giftCard.refunded)}`}</Typography>
				)}
				{날짜표시종류 === '사용' && giftCard.used && (
					<Typography
						variant='body1'
						color='error'>{`사용일 : ${getDateFormat(giftCard.used)}`}</Typography>
				)}
				{날짜표시종류 === '일반' && (
					<Typography variant='body1'>{`${getDateFormat(giftCard.published)} ~ ${getDateFormat(
						giftCard.expired
					)}`}</Typography>
				)}
			</CardStack>
			{errorMessage && (
				<Typography
					variant='body1'
					color={'error'}>
					{errorMessage}
				</Typography>
			)}
		</Stack>
	);
};
