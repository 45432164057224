import { OnlineTicketChannelTypeLabel, OnlineTicketType } from '@kinderlabs-pos/shared-data-type';
import {
	canceledStyleUtils,
	getTelephoneNumberWithDash,
	getUuidV4,
} from '@kinderlabs-pos/shared-util';
import { AdminProductInfoState, OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import {
	Button,
	Checkbox,
	Collapse,
	Divider,
	ListItemButton,
	Stack,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { Fragment, useState } from 'react';

const today = dayjs();
export const OnlineTicketListGroupItem = ({
	onlineTicketsGroup,
	defaultOpen,
}: {
	onlineTicketsGroup: OnlineTicketType[];
	defaultOpen: boolean;
}) => {
	const onlineTicket = onlineTicketsGroup[0];
	const cannotUseOnlineTicket = false;
	const [openTicket, setOpenTicket] = useState(defaultOpen);

	return (
		<Fragment>
			<ListItemButton onClick={() => setOpenTicket((prev) => !prev)}>
				<Stack
					width={'100%'}
					direction={'row'}>
					<Stack flex={1}>
						<Typography variant='h5'>
							{`${dayjs(onlineTicket.purchased).format('YYYY-MM-DD HH:mm')} | ${
								onlineTicket.user?.name ?? ''
							} (${
								onlineTicket.user ? getTelephoneNumberWithDash(onlineTicket.user.telephone) : ''
							})`}
						</Typography>
						<Typography variant='body1'>{`${onlineTicketsGroup.length} 명 예약 | #${onlineTicket.externalOrderId}`}</Typography>
					</Stack>
					<Button
						variant={'outlined'}
						sx={{ px: 0.5, width: 120 }}
						disabled={cannotUseOnlineTicket}
						color={'primary'}>
						<Typography
							variant={'h6'}
							sx={{ ...canceledStyleUtils(cannotUseOnlineTicket) }}>
							{`${OnlineTicketChannelTypeLabel[onlineTicket.ticketChannel] || '기타'}`}
						</Typography>
					</Button>
				</Stack>
			</ListItemButton>
			<Collapse in={openTicket}>
				{onlineTicketsGroup.map((onlineTicket, idx) => {
					return (
						<Fragment key={idx}>
							<OnlineTicketListItem
								onlineTicket={onlineTicket}
								key={idx}
							/>
						</Fragment>
					);
				})}
			</Collapse>
			<Divider />
		</Fragment>
	);
};

const OnlineTicketListItem = ({ onlineTicket }: { onlineTicket: OnlineTicketType }) => {
	const cartDispatch = OrderState.cart.useDispatcher();
	const cartLines = useAtomValue(OrderState.cart.lines.onlineTicketUsageSelector);

	const alreadyUsed = !!onlineTicket.ticketUseInfo;
	const isExpired = dayjs(onlineTicket.expiration).isBefore(today);

	const using = !!cartLines.find(
		(cl) => cl.ticketUsageInfo.ticketUsageId === onlineTicket.externalTicketId
	);

	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const productMap = AdminProductInfoState.useMapInStoreQuery({ storeId: storeInfo.id });

	const handleUseOnlineTicket = () =>
		alreadyUsed || isExpired
			? () => {}
			: using
			? cartDispatch({
					type: 'ONLINE_TICKET_ONOFFMIX',
					subAction: {
						type: 'REMOVE',
						onoffmixTicketId: onlineTicket.externalTicketId,
					},
			  })
			: cartDispatch({
					type: 'ONLINE_TICKET_ONOFFMIX',
					subAction: {
						type: 'ADD',
						cartLineId: getUuidV4(),
						onlineTicketInfo: onlineTicket,
						productInfoId: AdminProductInfoState.utils.getProductInfoIdFromMap({
							productId: onlineTicket.externalTicketInfoId,
							productMap,
						})?.id,
					},
			  });

	return (
		<SelectableListItem
			onClick={handleUseOnlineTicket}
			disabled={alreadyUsed || isExpired}>
			<Stack
				width={'100%'}
				direction={'row'}>
				<Stack
					flex={1}
					sx={{ overflowX: 'hidden' }}>
					<Typography
						sx={{
							width: '100%',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
						}}
						variant='h5'
						color={'primary'}>
						{onlineTicket.ticketName}
					</Typography>
					{alreadyUsed ? (
						<Typography
							variant='h6'
							color={'error'}>
							{`사용됨 : ${dayjs(onlineTicket.ticketUseInfo?.used).format('YYYY-MM-DD HH:mm')}`}
						</Typography>
					) : (
						<Typography
							variant='body1'
							color={'text.primary'}>
							{`${dayjs(onlineTicket.purchased).format('YYYY-MM-DD')} ~ ${dayjs(
								onlineTicket.expiration
							).format('YYYY-MM-DD')}`}
						</Typography>
					)}
					<Typography
						variant='body1'
						color={'text.secondary'}>
						{`#${onlineTicket.externalTicketId}`}
					</Typography>
				</Stack>
				{isExpired ? (
					<Stack
						width={42}
						alignItems={'center'}
						justifyContent={'center'}>
						<Typography
							variant='h5'
							color={'error'}>
							{`만료`}
						</Typography>
					</Stack>
				) : (
					<Checkbox checked={using} />
				)}
			</Stack>
		</SelectableListItem>
	);
};
