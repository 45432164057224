const getAggregateByType = (data: { key: string; value: number }[]) => {
	const tempMap: Record<string, number> = {};

	data.forEach((item) => {
		if (tempMap[item.key]) {
			tempMap[item.key] = tempMap[item.key] + item.value;
		} else {
			tempMap[item.key] = item.value;
		}
	});

	return [...Object.keys(tempMap)].map((key) => ({
		key,
		value: tempMap[key],
	}));
};

export const StatisticsUtils = {
	getAggregateByType,
};
