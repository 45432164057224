import { EventSubscriberProvider } from '@kinderlabs-pos/apis/common';
import { KitchenOrderAPI, OrderAPI } from '@kinderlabs-pos/apis/pos';
import { DomainEventType, KitchenOrderInfoType } from '@kinderlabs-pos/shared-data-type';
import {
	GuestVisitQueryState,
	KitchenOrderQueryState,
	PosState,
	PrintReceiptState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';

// JIN : 가끔 Electron 에서 useEffect의 소멸자가 호출되지 않는 경우 문제가 발생
// -> localStorage 를 이용하여 문제를 해결
const KITCHEN_EVENT_SESSION_ID = 'KITCHEN_EVENT_SESSION_ID';
export const KitchenOrderSubscriber = ({
	storeId,
	posId,
	enterpriseId,
	주방주문증크게,
	children,
}: React.PropsWithChildren<{
	storeId: number;
	posId: number;
	enterpriseId: string;
	주방주문증크게: boolean;
}>) => {
	const setStatus = useSetAtom(PosState.status);
	const onConnect = () => {
		setStatus((state) => ({ ...state, sseConnected: true }));
	};

	useKitchenSystemPrinter({ storeId, posId, 주방주문증크게 });

	const onDisconnect = () => {
		setStatus((state) => ({ ...state, sseConnected: false }));
	};

	const onKitchenOrder = useCallback((eventData: DomainEventType<KitchenOrderInfoType>) => {
		KitchenOrderQueryState.invalidateQueries();
	}, []);

	return (
		<EventSubscriberProvider
			storeId={storeId}
			enterpriseId={enterpriseId}
			deviceType={'POS'}
			subscriberId={posId}
			onConnect={onConnect}
			onReconnect={() => GuestVisitQueryState.invalidateQueries()}
			onDisconnect={onDisconnect}
			guestVisitActions={undefined}
			kitchenOrderActions={{
				onKitchenOrder,
			}}>
			{children}
		</EventSubscriberProvider>
	);
};

/**
 * 여기에 집어넣고 프린트한다.
 */
// const kitchenOrderPrintMap: Record<KitchenOrderInfoType['id'], KitchenOrderInfoType> = {};

const useIsHostPos = ({ posId }: { posId: number }) => {
	const kitchenSystemInfo = StoreInfoState.useKitchenSystemInfo();
	return kitchenSystemInfo?.hostDeviceId == posId;
};

let timeoutToClear: NodeJS.Timeout | null = null;
const useKitchenSystemPrinter = ({
	storeId,
	posId,
	주방주문증크게,
}: {
	storeId: number;
	posId: number;
	주방주문증크게: boolean;
}) => {
	const isHostPos = useIsHostPos({ posId });
	const kitchenSystemInfo = StoreInfoState.useKitchenSystemInfo();

	const { printKitchen주문서Async } = PrintReceiptState.useKitchenPrinter({
		storeId,
		deviceType: 'POS',
	});
	const { printCafe주문서Async } = PrintReceiptState.useCafePrinter({
		storeId: storeId,
		deviceType: 'POS',
	});

	useEffect(() => {
		if (!kitchenSystemInfo || !isHostPos) return;
		const sessionIdLocal = getUuidV4();

		// JIN : 여기서 정의할수밖에 없네.
		const print = async () => {
			try {
				const kitchenOrderListToPrint = await KitchenOrderAPI.getKitchenOrderListToPrint({
					date: dayjs().format('YYYY-MM-DD'),
					kitchenSystemId: kitchenSystemInfo.id,
				});

				if (kitchenOrderListToPrint.length === 0) {
					// JIN : 가끔 Electron 에서 useEffect의 소멸자가 호출되지 않는 경우 문제가 발생
					// -> localStorage 를 이용하여 문제를 해결
					if (localStorage.getItem(KITCHEN_EVENT_SESSION_ID) === sessionIdLocal)
						timeoutToClear = setTimeout(() => print(), 2000);
					return;
				}

				// 하나씩 뽑게해야지 에러가 적다.
				const kitchenOrder = kitchenOrderListToPrint[0];

				const order = await OrderAPI.getDetail({ orderId: kitchenOrder.orderId });
				await printCafe주문서Async({ order, 주방주문증크게 });
				await printKitchen주문서Async({ order, 주방주문증크게 });

				await KitchenOrderAPI.printKitchenOrder({
					kitchenOrderId: kitchenOrder.id,
					kitchenSystemId: kitchenSystemInfo.id,
				});

				timeoutToClear = setTimeout(() => print(), 100);
			} catch (e) {
				Sentry.withScope((scope) => {
					scope.setTags({
						Type: '주방주문',
						Where: 'POS',
					});
					Sentry.captureMessage(`KITCHEN 1 ERROR : ${storeId}, ${posId}`);
					e && Sentry.captureException(e);
				});

				timeoutToClear = setTimeout(() => print(), 100);
			}
		};

		localStorage.setItem(KITCHEN_EVENT_SESSION_ID, sessionIdLocal);
		timeoutToClear = setTimeout(() => print(), 1000);

		return () => {
			timeoutToClear && clearTimeout(timeoutToClear);
		};
	}, [kitchenSystemInfo, isHostPos, 주방주문증크게]);

	// return { handlePrintKitchenOrder };
};
