import { UserOutlined } from '@ant-design/icons';
import { DdiziType, OrderTypeWithDdizisAndExchanges } from '@kinderlabs-pos/shared-data-type';
import { useSearchParamsWrapper } from '@kinderlabs-pos/shared-react-util';
import { ExitQueryState } from '@kinderlabs-pos/state';
import { CardStack } from '@kinderlabs-pos/ui-atoms';
import {
	FullSizeCircularProgress,
	RotatingRefreshButton,
	SearchBox,
} from '@kinderlabs-pos/ui-components';
import { Box, Divider, Slider, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import dayjs from 'dayjs';
import { atom, useAtom, useAtomValue } from 'jotai';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { ExitPageDdiziList } from './ExitPageDdiziList';
import { ExitPageOrderInfo } from './ExitPageOrderInfo';
import { ExitPageOrderList } from './ExitPageOrderList';
import { ExitPaymentCard } from './ExitPaymentCard';
import { ExitPaymentSummary } from './ExitPaymentSummary';

export interface IExitPageProps {}
export const ExitPage = ({}: IExitPageProps) => {
	const { searchParams, changeSearchParams } = useSearchParamsWrapper();
	const keyword = searchParams.get('keyword');
	const fixedDate = useMemo(() => new Date(), [keyword]);
	const selectedOrder = useAtomValue(ExitPage.selectedOrderAtom);
	const handleClickReload = () => {
		ExitQueryState.invalidateOrderQueries();
	};

	const { duplicated } = useClearPreviousSearchedKeyword(keyword);
	const [tempSearchValue, setTempSearchValue] = useState(!duplicated && !!keyword ? keyword : '');

	return (
		<Stack
			direction={'row'}
			spacing={1}
			flex={1}
			width={'100%'}
			height={'100%'}
			sx={{ overflowY: 'auto' }}>
			<CardStack
				height={'100%'}
				spacing={1}
				sx={{ overflowY: 'auto' }}>
				<Stack
					direction={'row'}
					spacing={1}>
					<RotatingRefreshButton handleClick={handleClickReload} />
					<SearchBox
						width={220}
						value={tempSearchValue}
						onChange={(val) => {
							setTempSearchValue(val);
						}}
						hasDeleteKey
						hasSearchButton
						blurWhenSearch
						onSearch={() => {
							changeSearchParams({ keyword: tempSearchValue });
						}}
					/>
				</Stack>
				<Typography
					variant={'body2'}
					sx={{ textAlign: 'center' }}>
					{'티켓번호, 주문번호 '}
					<br />
					{'대표자 이름과 전화번호 뒷 4자리로 검색됩니다.'}
				</Typography>
				<Stack
					height={'100%'}
					sx={(theme) => ({ overflowY: 'auto', pb: 1, border: `1px solid ${grey[400]}` })}>
					<Suspense fallback={<FullSizeCircularProgress />}>
						<ExitPageOrderList keyword={keyword} />
					</Suspense>
				</Stack>
			</CardStack>
			<Stack
				flex={1}
				spacing={1}>
				{/* <CardStack>
					<GuestVisitExitSlider />
				</CardStack> */}
				{selectedOrder ? (
					<Stack
						flex={1}
						spacing={1}
						overflow={'auto'}>
						<CardStack
							flex={1}
							sx={{ overflowY: 'auto' }}>
							<ExitPageOrderInfo selectedOrder={selectedOrder} />
							<Divider sx={{ py: 1 }} />
							<Suspense fallback={<FullSizeCircularProgress />}>
								<ExitPageDdiziList
									orderId={selectedOrder?.id}
									keyword={keyword}
									fixedDate={fixedDate}
								/>
							</Suspense>
						</CardStack>
						<CardStack>
							<Stack direction={'row'}>
								<Box flex={1}>
									<ExitPaymentSummary />
								</Box>
								<ExitPaymentCard
									orderId={selectedOrder?.id}
									onComplete={() => {
										setTempSearchValue('');
									}}
								/>
							</Stack>
						</CardStack>
					</Stack>
				) : (
					<CardStack
						flex={1}
						height={'100%'}
						alignItems={'center'}
						justifyContent={'center'}
						sx={{ bgcolor: 'white', overflowY: 'auto' }}>
						<Typography
							sx={{ textAlign: 'center' }}
							variant={'subtitle1'}
							color={'text.secondary'}>
							주문을 검색/선택하고
							<br />
							티켓 퇴장 정보를 확인하세요.
						</Typography>
					</CardStack>
				)}
			</Stack>
		</Stack>
	);
};
ExitPage.selectedOrderAtom = atom<OrderTypeWithDdizisAndExchanges | undefined>(undefined);
/**
 * 퇴장정산 페이지에서 작업을 하고 있었는지 여부 확인
 */
ExitPage.useOrderInfoInJungSan = () => {
	const [selected, seSelected] = useAtom(ExitPage.selectedOrderAtom);
	return {
		hasJungSan: !!selected,
		clearSelected: () => seSelected(undefined),
	};
};

const duplicateKeyWordAtom = atom('');

/**
 * 정확하게 직전에 검색한 내역이라면 검색창에서 비워주기
 */
const useClearPreviousSearchedKeyword = (keyword: string | null) => {
	const [duplicatedValue, setDuplicatedValue] = useAtom(duplicateKeyWordAtom);
	useEffect(() => {
		return () => {
			keyword && setDuplicatedValue(keyword);
		};
	}, [keyword]);

	return {
		duplicated: duplicatedValue === keyword,
	};
};
