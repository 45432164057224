import { FormikReturnType } from '@kinderlabs-pos/frameworks/forms';
import { DialogContent, Stack } from '@mui/material';
import {
	현금결제OptionType,
	현금영수증발급ActionStack,
	현금영수증선택Stack,
} from 'apps/pos-main/src/dialogs/CashReceiptDialog/CashReceiptActionStacks';
import cash from 'assets/won-icon.png';
import { CalculatingChangeZoneZone } from './CalculatingChangeZone';

export const 현금결제ConfirmDialogContent = ({
	receivedMoney,
	결제할금액,
	setValues,
	values,
}: Pick<FormikReturnType<{ options: 현금결제OptionType }>, 'setValues' | 'values'> & {
	receivedMoney: number;
	결제할금액: number;
	options: 현금결제OptionType;
}) => {
	return (
		<DialogContent sx={{ minWidth: 478 }}>
			<Stack spacing={1}>
				<Stack alignItems={'center'}>
					<img src={cash} />
				</Stack>
				<CalculatingChangeZoneZone
					receivedMoney={receivedMoney}
					결제할금액={결제할금액}
				/>
				<Stack spacing={1}>
					<현금영수증선택Stack
						// @ts-ignore
						options={values.options}
						setOptions={(options) => setValues({ ...values, options: options })}
					/>
					{values.options.type !== '현금영수증미발급' && (
						<현금영수증발급ActionStack
							options={values.options}
							setOptions={(options) => setValues({ ...values, options: options })}
						/>
					)}
				</Stack>
			</Stack>
		</DialogContent>
	);
};
