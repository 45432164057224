import { EyeOutlined, HistoryOutlined } from '@ant-design/icons';
import {
	VaunceAppOTTTicketOnPosType,
	VaunceAppTicketOnPosType,
} from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { AccordionDetails, Button, Checkbox, IconButton, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { VaunceAppTicketUserMemberInfoBox } from './common/VaunceAppTicketUserMemberInfoBox';
import { VaunceTicketItemAccordion } from './common/VaunceTicketItemAccordion';
import { getSortedUserIdList } from './common/getSortedUserIdList';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { VaunceAppTicketHistoryDialog } from './VaunceAppTicketHistoryDialog';
import dayjs from 'dayjs';

export const VaunceAppTicketOttListContent = ({
	appTicketInfoMap,
}: {
	appTicketInfoMap: {
		[userId: string]: (VaunceAppOTTTicketOnPosType & { canUseTicket: boolean })[];
	};
}) => {
	const userIdList = getSortedUserIdList({ ticketOrCouponInfoMap: appTicketInfoMap });

	return (
		<Stack>
			{userIdList.map((memberId, idx) => {
				const { uid, userName, userId, userBirth, userTelephone } = appTicketInfoMap[memberId][0];

				return (
					<Stack
						key={idx}
						spacing={1}>
						<VaunceAppTicketUserMemberInfoBox
							uid={uid}
							userName={userName}
							userId={userId}
							userBirth={userBirth}
							userTelephone={userTelephone}
						/>
						{appTicketInfoMap[memberId].map((ott, ottIdx) => {
							return (
								<VaunceTicketItemAccordion
									key={ottIdx}
									ticket={ott}
									canUseTicket={ott.canUseTicket && ott.expiredTicket === 'N'}
									rightPanel={
										<Stack
											width={'33%'}
											direction={'row'}
											justifyContent={'flex-end'}
											spacing={1}>
											<VaunceAppOttUseButton
												ott={ott}
												useInfo={ott.detailTicket[0]}
											/>
											<WithDialog
												dialog={(open, closeDialog) => (
													<VaunceAppTicketHistoryDialog
														ticketId={ott.ticketId}
														open={open}
														closeDialog={closeDialog}
													/>
												)}>
												{(openDialog) => (
													<IconButton onClick={openDialog}>
														<HistoryOutlined />
													</IconButton>
												)}
											</WithDialog>
										</Stack>
									}>
									<></>
								</VaunceTicketItemAccordion>
							);
						})}
					</Stack>
				);
			})}
		</Stack>
	);
};

const VaunceAppOttUseButton = ({
	ott,
	useInfo,
}: {
	ott: VaunceAppTicketOnPosType;
	useInfo: VaunceAppTicketOnPosType['detailTicket'][number];
}) => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const ticketUsages = useAtomValue(OrderState.cart.lines.ticketUsageSelector).filter(
		(usage) => usage.type === 'VAUNCE_APP_OTT'
	);
	const cartDispatch = OrderState.cart.useDispatcher();

	const status =
		useInfo.status === 'USED'
			? '이미사용됨'
			: ticketUsages.some((u) => u.ticketUsageInfo.ticketUsageId === useInfo.ticketNum)
			? '사용중'
			: ('사용가능' as const);

	const handleClickButton = () => {
		if (status === '사용가능') {
			cartDispatch({
				type: 'USAGES',
				subAction: {
					type: 'ADD_TICKET',
					cartLineId: getUuidV4(),
					ticketUsage: {
						type: 'ONETIME',
						useId: useInfo.ticketNum,
						name: ott.ticketTitle,
						ticketId: ott.ticketId,
						ticketInfoId: 0,
						userName: ott.ticketUserName,
						userTelephone: ott.userTelephone,
						memberId: ott.uid,
						ddiziInfo: {
							name: ott.ticketTitle,
							ddiziType: 'NORMAL',
							minutes: storeInfo.policy.defaultMinutesInCenter,
							hasNoAdditionalCharge: false,
							isNotForStatistics: false,
							available: 'ALL',
						},
					},
				},
			});
		} else {
			cartDispatch({
				type: 'USAGES',
				subAction: { type: 'DELETE', useId: useInfo.ticketNum, ticketType: 'ONETIME' },
			});
		}
	};

	return (
		<Button
			variant='outlined'
			sx={{ flex: 1 }}
			disabled={status === '이미사용됨'}
			// checked={
			// 	useInfo.status === 'USED' ||
			// 	ticketUsages.map((u) => u.ticketUsageInfo.ticketUsageId).includes(useInfo.ticketNum)
			// }
			color={status === '사용중' ? 'warning' : 'error'}
			onClick={handleClickButton}>
			<Typography variant='body1'>
				{status === '이미사용됨' ? '이미 사용됨' : status === '사용가능' ? '사용' : '사용 취소'}
			</Typography>
		</Button>
	);
};
