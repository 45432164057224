import { WaiverApis } from '@kinderlabs-pos/apis/waivers';
import { AppTicketSearchState, GuestVisitQueryState } from '@kinderlabs-pos/state';
import { useAtomValue, useSetAtom } from 'jotai';
import { QueryClient } from '../QueryClient';
import { StoreInfoState } from '../StoreInfoState';

const useGuestVisitCall = () => {
	const storeId = useAtomValue(StoreInfoState.curStoreInfo).id;
	const posId = useAtomValue(StoreInfoState.curPosInfo).id;

	return QueryClient.useMutation(
		async ({ guestVisitId }: { guestVisitId?: number }) => {
			return await WaiverApis.callLatestGuestAsync({
				storeId,
				posId,
				id: guestVisitId,
			});
		},
		{
			onSuccess: (data) => {
				GuestVisitQueryState.invalidateQueries();
			},
		}
	);
};

const useGuestVisitCancel = () => {
	const storeId = useAtomValue(StoreInfoState.curStoreInfo).id;
	const posId = useAtomValue(StoreInfoState.curPosInfo).id;

	return QueryClient.useMutation(
		async ({ guestVisitId }: { guestVisitId: number }) => {
			return await WaiverApis.changeGuestVisitStatus({
				storeId,
				posId,
				guestVisitId,
				status: 'CANCELED',
			});
		},
		{
			onSuccess: (data) => {
				GuestVisitQueryState.invalidateQueries();
			},
		}
	);
};

const useGuestVisitReturnToWaiting = () => {
	const storeId = useAtomValue(StoreInfoState.curStoreInfo).id;
	const posId = useAtomValue(StoreInfoState.curPosInfo).id;

	return QueryClient.useMutation(
		async ({ guestVisitId }: { guestVisitId: number }) => {
			return await WaiverApis.changeGuestVisitStatus({
				storeId,
				posId,
				guestVisitId,
				status: 'WAITING',
			});
		},
		{
			onSuccess: (data) => {
				GuestVisitQueryState.invalidateQueries();
			},
		}
	);
};

const useGuestVisitReRun = () => {
	const storeId = useAtomValue(StoreInfoState.curStoreInfo).id;
	const posId = useAtomValue(StoreInfoState.curPosInfo).id;
	const setAppTicketSearch = useSetAtom(AppTicketSearchState);

	return QueryClient.useMutation(
		async ({ guestVisitId }: { guestVisitId: number }) => {
			return await WaiverApis.changeGuestVisitStatus({
				storeId,
				posId,
				guestVisitId,
				status: 'COMPLETED',
			});
		},
		{
			onSuccess: (data) => {
				if (data.memberInfo) {
					data.memberInfo.telephone && setAppTicketSearch(data.memberInfo.telephone.substring(3));
					GuestVisitQueryState.invalidateQueries();
				}
			},
		}
	);
};

export const GuestVisitActions = {
	useCallMutation: useGuestVisitCall,
	useCancelMutation: useGuestVisitCancel,
	useReturnToWaitingMutation: useGuestVisitReturnToWaiting,
	useRerunMutation: useGuestVisitReRun,
};
