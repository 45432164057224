import { VaultCashHistoryType } from '@kinderlabs-pos/shared-data-type';

export const getOverAndShort = (content: VaultCashHistoryType) => {
	switch (content.type) {
		case 'CLOSE':
		case 'CHECK':
		case 'DEPOSIT':
		case 'WITHDRAW':
		case 'OPEN':
		default:
			return `${content.overAndShort > 0 ? '+' : ''}${content.overAndShort.toLocaleString()} 원`;
	}
};

export const getCountedAmount = (content: VaultCashHistoryType) => {
	switch (content.type) {
		case 'DEPOSIT':
		// return `+${content.countedAmount.toLocaleString()} 원`;
		case 'WITHDRAW':
		// return `-${content.countedAmount.toLocaleString()} 원`;
		case 'OPEN':
		case 'CLOSE':
		case 'CHECK':
		default:
			return `${content.countedAmount.toLocaleString()} 원`;
	}
};

export const getExpectedAmount = (content: VaultCashHistoryType) => {
	switch (content.type) {
		case 'CHECK':
		case 'DEPOSIT':
		case 'WITHDRAW':
		case 'OPEN':
		case 'CLOSE':
		default:
			return `${content.expectedAmount.toLocaleString()} 원`;
	}
};

export const VaultCashUtils = {
	getOverAndShort,
	getCountedAmount,
	getExpectedAmount,
};
