import { MenuItem, Select, SelectProps } from "@mui/material";

export interface IInstallmentSelectProps extends SelectProps<number> { }
export const InstallmentSelect: React.FC<IInstallmentSelectProps> = (props) => {
  return (
    <Select
      {...props}
    >
      <MenuItem value={0}>일시불</MenuItem>
      <MenuItem value={2}>2개월</MenuItem>
      <MenuItem value={3}>3개월</MenuItem>
      <MenuItem value={4}>4개월</MenuItem>
      <MenuItem value={5}>5개월</MenuItem>
      <MenuItem value={6}>6개월</MenuItem>
      <MenuItem value={7}>7개월</MenuItem>
      <MenuItem value={8}>8개월</MenuItem>
      <MenuItem value={9}>9개월</MenuItem>
      <MenuItem value={10}>10개월</MenuItem>
      <MenuItem value={11}>11개월</MenuItem>
      <MenuItem value={12}>12개월</MenuItem>
    </Select>
  )
};