import { AdminProductInfoState, StoreInfoState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { FullSizeCircularProgress, SearchBox } from '@kinderlabs-pos/ui-components';
import {
	Button,
	DialogActions,
	DialogContent,
	Divider,
	List,
	Stack,
	Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ProductInfoItemOnPos } from 'apps/pos-main/src/modules/productBoard/components/PosBoardInPos/ProductInfoItemOnPos';
import { useAtomValue } from 'jotai';
import { Fragment, Suspense, useMemo, useRef, useState } from 'react';

export const BarcodeScanDialog = ({ closeDialog, ...others }: XlDialogProps & {}) => {
	const [tempSearchValue, setTempSearchValue] = useState<string>('');
	const [searchValue, setSearchValue] = useState<string>('');

	return (
		<WithDialog.XlDialog
			{...others}
			closeDialog={closeDialog}
			maxWidth='md'
			dialogTitle={'상품 검색'}>
			<DialogContent>
				<Stack spacing={1}>
					<Stack spacing={2}>
						<Stack
							alignItems={'center'}
							spacing={1}>
							<SearchBox
								width={420}
								value={tempSearchValue}
								onChange={(val) => {
									setTempSearchValue(val);
								}}
								hasDeleteKey
								hasSearchButton
								blurWhenSearch
								onSearch={() => {
									console.log('onsearch');
									setSearchValue(tempSearchValue);
								}}
							/>
							<Typography
								variant={'body2'}
								textAlign={'center'}>
								{'상품 바코드, 상품 이름, 상품 코드로 검색됩니다.'}
							</Typography>
						</Stack>
					</Stack>
					<Stack
						minHeight={200}
						maxHeight={300}
						overflow={'auto'}>
						<Suspense fallback={<FullSizeCircularProgress />}>
							<BarcodeScanSearchResult
								searchValue={searchValue}
								closeSearchDialog={closeDialog}
							/>
						</Suspense>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					variant='outlined'
					onClick={closeDialog}>
					닫기
				</Button>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};

const BarcodeScanSearchResult = ({
	searchValue,
	closeSearchDialog,
}: {
	searchValue: string;
	closeSearchDialog?: () => void;
}) => {
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	const trimmedSearchValue = searchValue.trim();
	const { data } = useQuery({
		...AdminProductInfoState.keys.inStore(storeId),
		keepPreviousData: true,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
	const productInfoList = data ?? [];
	const searchedProductInfoList = useMemo(() => {
		return searchValue?.length > 2
			? productInfoList.filter((p) => p.barcode === searchValue || p.name.includes(searchValue))
			: [];
	}, [productInfoList, searchValue]);

	const hasValidResult = searchedProductInfoList.length > 0;

	const ref = useRef<HTMLUListElement>(null);
	const virtualizer = useVirtualizer({
		count: searchedProductInfoList.length,
		getScrollElement: () => ref.current,
		estimateSize: () => 56,
		overscan: 5,
	});
	return (
		<>
			{trimmedSearchValue ? (
				<Stack
					sx={hasValidResult ? {} : { flex: 1 }}
					alignItems={'center'}
					justifyContent={'center'}>
					{hasValidResult ? (
						<List
							sx={{ width: '100%' }}
							ref={ref}>
							{virtualizer.getVirtualItems().map((item, idx) => {
								return (
									<Fragment key={idx}>
										<ProductInfoItemOnPos
											productInfo={searchedProductInfoList[item.index]}
											closeSearchDialog={closeSearchDialog}
										/>
										<Divider />
									</Fragment>
								);
							})}
						</List>
					) : (
						<Typography
							variant='h5'
							color={'text.secondary'}>
							{searchValue?.length > 2
								? '검색결과가 없습니다.'
								: '검색어를 3글자 이상 입력해주세요'}
						</Typography>
					)}
				</Stack>
			) : (
				<></>
			)}
		</>
	);
};
