import { GuestVisitType } from '@kinderlabs-pos/shared-data-type';
import { GuestVisitQueryState, GuestVisitState, StoreInfoState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { CircularProgress, Divider, List, ListItem, ListItemButton, Stack } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { GuestVisitDisplay } from 'apps/pos-main/src/pages/GuestVisitPage/GuestVisitDisplay';
import dayjs, { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { GuestVisitActionButtons } from '../../../GuestVisitActionButtons';
import { GuestVisitCallDisplay } from './GuestCallDisplay';
import { GuestVisitDetailDialog } from './GuestVisitDetailDialog';

let interval: NodeJS.Timer | null = null;
const todayStart = dayjs().startOf('day');
const todayEnd = dayjs().endOf('day');
export const GuestVisitPannels = ({
	tabValue,
	closePannel,
}: {
	tabValue: 'WAITING' | 'COMPLETED' | 'OTHERS';
	closePannel: () => void;
}) => {
	const statusFilterValue: GuestVisitType['status'][] =
		tabValue === 'OTHERS' ? ['CANCELED'] : [tabValue];

	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const [now, setNow] = useState<Dayjs>(dayjs());
	useEffect(() => {
		interval = setInterval(() => setNow(dayjs()), 1000);
		return () => {
			interval && clearInterval(interval);
		};
	}, []);

	const {
		data: todayGuestVisits,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		...GuestVisitQueryState.keys.infinite({
			params: {
				storeIdList: [storeId],
				search: undefined,
				from: todayStart,
				to: todayEnd,
				status: statusFilterValue,
				sort: { name: 'sunbun', direction: tabValue === 'WAITING' ? 'asc' : 'desc' },
			},
		}),
		getNextPageParam: (lastPage) => {
			return lastPage.startCursorId ? { startCursorId: lastPage.startCursorId } : undefined;
		},
		refetchOnWindowFocus: true,
	});

	const guestVisitData = useMemo(() => {
		if (!todayGuestVisits) return [];
		else return todayGuestVisits.pages.flatMap((data) => data.content);
	}, [todayGuestVisits]);

	return (
		<List>
			{guestVisitData.map((guestVisit, idx) => {
				const recentGuestCall = GuestVisitState.utils.getRecentCall(guestVisit);

				return (
					<Fragment key={idx}>
						<WithDialog
							key={idx}
							dialog={(open, closeDialog) => (
								<GuestVisitDetailDialog
									guestVisitId={guestVisit.id}
									open={open}
									closeDialog={closeDialog}
								/>
							)}>
							{(openDialog) => (
								<ListItemButton onClick={openDialog}>
									<GuestVisitDisplay guestVisit={guestVisit} />
								</ListItemButton>
							)}
						</WithDialog>
						<ListItem>
							<Stack
								direction={'row'}
								spacing={1}
								width={'100%'}
								justifyContent={'space-between'}>
								<GuestVisitActionButtons
									guestVisit={guestVisit}
									withCalls={false}
									closePannel={closePannel}
									withoutCancelBtn={tabValue !== 'WAITING'}
								/>
								<GuestVisitCallDisplay
									now={now}
									guestVisit={guestVisit}
									guestVisitCall={recentGuestCall}
								/>
							</Stack>
						</ListItem>
						<Divider />
					</Fragment>
				);
			})}
			{isFetchingNextPage && (
				<ListItem>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						<CircularProgress />
					</Stack>
				</ListItem>
			)}
			{hasNextPage && !isFetchingNextPage && (
				<ListItemButton onClick={() => fetchNextPage()}>
					<Stack
						width={'100%'}
						alignItems={'center'}>
						더 보기
					</Stack>
				</ListItemButton>
			)}
		</List>
	);
};
