import { PaymentInfoType } from '@kinderlabs-pos/shared-data-type';
import { OrderQueryState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { CircularProgress, DialogActions, DialogContent, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Suspense } from 'react';
import { PaymentDetailContentsWrapper } from './PaymentDetailContentsWrapper';

interface IPaymentDetailDialogProps extends XlDialogProps {
	payment: PaymentInfoType;
	withRefunds?: boolean;
}
export const PaymentDetailDialog = ({
	open,
	closeDialog,
	payment,
	withRefunds = false,
}: IPaymentDetailDialogProps) => {
	return (
		<WithDialog.XlDialog
			open={open}
			closeDialog={closeDialog}
			dialogTitle={'결제 상세'}>
			<DialogContent sx={{ minWidth: 360 }}>
				<Suspense
					fallback={
						<Stack alignItems='center'>
							<CircularProgress />
						</Stack>
					}>
					<InternalContent
						orderId={payment.orderId}
						payment={payment}
						closeDialog={closeDialog}
						withRefunds={withRefunds}
					/>
				</Suspense>
			</DialogContent>
			<DialogActions />
		</WithDialog.XlDialog>
	);
};

const InternalContent = ({
	payment,
	orderId,
	closeDialog,
	withRefunds,
}: {
	orderId: string;
	payment: PaymentInfoType;
	closeDialog?: () => void;
	withRefunds?: boolean;
}) => {
	const { data: orderWithDetail } = useQuery({
		...OrderQueryState.keys.detailByOrderId(orderId),
		refetchOnWindowFocus: true,
	});
	if (!orderWithDetail) return null;

	if (!payment.id) return null;

	return (
		<PaymentDetailContentsWrapper
			payment={payment}
			order={orderWithDetail}
			closeDialog={closeDialog}
			withRefunds={withRefunds}
		/>
	);
};
