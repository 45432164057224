import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import MonitorIcon from '@mui/icons-material/Monitor';
import { ButtonProps, Typography } from '@mui/material';

export interface IDesktopButtonProps extends ButtonProps { }
export const DesktopButton: React.FC<IDesktopButtonProps> = ({
  onClick
}) => {
  return (
    <IconTextButton
      variant='white'
      onClick={onClick}
      icon={<MonitorIcon fontSize="small" sx={theme => ({ fill: "white" })} />}
      label={<Typography>
        {"바탕화면"}
      </Typography>}
    />
  )
};