import { EyeOutlined } from '@ant-design/icons';
import { getTelephoneNumberWithDash } from '@kinderlabs-pos/shared-util';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import { VaunceAppTicketListDialog } from '../VaunceAppTicketListDialog';

export const VaunceAppTicketUserMemberInfoBox = ({
	uid,
	userId,
	userName,
	userBirth,
	userTelephone,
}: {
	uid: number;
	userId: string;
	userName: string;
	userBirth: string;
	userTelephone: string;
}) => {
	return (
		<Stack
			direction={'row'}
			justifyContent={'space-between'}
			alignItems={'center'}
			spacing={2}
			py={1.5}
			px={2}>
			<Avatar />
			<Stack
				direction={'column'}
				flex={1}>
				<Typography variant='h3'>{`${userName} (${userBirth})`}</Typography>
				<Typography variant='subtitle1'>{getTelephoneNumberWithDash(userTelephone)}</Typography>
				{/* <Typography variant='body1'>{`${userId}`}</Typography> */}
			</Stack>
			<WithDialog
				dialog={(open, closeDialog) => (
					<VaunceAppTicketListDialog
						open={open}
						closeDialog={closeDialog}
						uid={uid}
						userName={userName}
						userBirth={userBirth}
						userTelephone={userTelephone}
					/>
				)}>
				{(openDialog) => (
					<IconButton onClick={openDialog}>
						<EyeOutlined />
					</IconButton>
				)}
			</WithDialog>
		</Stack>
	);
};
