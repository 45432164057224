import { EyeOutlined, ProfileOutlined } from '@ant-design/icons';
import {
	KitchenOrderInfoType,
	KitchenOrderLineTypeRule,
	KitchenOrderStatusTypeColor,
	kitchenOrderStatusLabel,
} from '@kinderlabs-pos/shared-data-type';
import { getTimeFormat } from '@kinderlabs-pos/shared-util';
import { AdminPosInfoState } from '@kinderlabs-pos/state';
import { Button, Stack, Typography } from '@mui/material';

export interface IKitchenOrderDisplayProps {
	kitchenOrder: KitchenOrderInfoType;
	openDialog?: () => void;
}
export const KitchenOrderDisplay: React.FC<IKitchenOrderDisplayProps> = ({
	kitchenOrder,
	openDialog,
}) => {
	AdminPosInfoState.usePosAndKioskSelectorList;
	const deviceInfo = AdminPosInfoState.useDeviceInfo({
		storeId: kitchenOrder.storeId,
		deviceId: kitchenOrder.deviceId,
	});

	return (
		<Stack
			width={'100%'}
			direction={'row'}
			spacing={2}
			alignItems={'center'}>
			<Stack
				direction={'row'}
				flex={1}
				spacing={2}
				alignItems={'center'}>
				<Button
					variant={'outlined'}
					sx={{
						px: 0.5,
						width: 80,
					}}
					color={KitchenOrderStatusTypeColor[kitchenOrder.status]}>
					<Typography variant='body1'>{kitchenOrderStatusLabel[kitchenOrder.status]}</Typography>
				</Button>
				<Stack sx={{ width: 180 }}>
					<Typography variant='h5'>{`순번 ${kitchenOrder.dailySeq} (${getOrderTimeAndStatus({
						kitchenOrder,
					})})`}</Typography>
					<Typography variant='body2'>{deviceInfo?.label || '-'}</Typography>
				</Stack>
				<Stack
					onClick={openDialog}
					alignItems={'center'}
					direction={'row'}
					spacing={1}>
					<ProfileOutlined style={{ fontSize: 20 }} />
					<Typography>
						{KitchenOrderLineTypeRule.getKitchenOrderLineLabel(kitchenOrder.kitchenOrderLines)}
					</Typography>
					<EyeOutlined style={{ fontSize: 20 }} />
				</Stack>
			</Stack>
		</Stack>
	);
};

const getOrderTimeAndStatus = ({
	kitchenOrder,
}: {
	kitchenOrder: KitchenOrderInfoType;
}): string => {
	switch (kitchenOrder.status) {
		case 'ORDERED':
			return `${getTimeFormat(kitchenOrder.created)} 주문 접수`;
		case 'COOKING':
			return kitchenOrder.cooked ? `${getTimeFormat(kitchenOrder.cooked)} 조리 시작` : '';
		case 'COMPLETED':
			return kitchenOrder.completed ? `${getTimeFormat(kitchenOrder.completed)} 주문 완료` : '';
		case 'REAL_COMPLETED':
			return kitchenOrder.completed ? `${getTimeFormat(kitchenOrder.completed)} 배부 완료` : '';
		case 'CANCELED':
			return kitchenOrder.canceled ? `${getTimeFormat(kitchenOrder.canceled)} 주문 취소` : '';
	}
};
