import { AdminStoreInfoState, GuestMemberInfoState } from '@kinderlabs-pos/state';
import { AdminDatePicker, WithDialog } from '@kinderlabs-pos/ui-atoms';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { useState } from 'react';

const VALUE_NO_STOREID = -1;
export const VisitHistoryCreateDialog = ({
	open,
	closeDialog,
	memberId,
	visitedList,
	storeId,
}: {
	open: boolean;
	closeDialog: () => void;
	memberId: number;
	visitedList: string[];
	storeId?: number;
}) => {
	const [info, setInfo] = useState<{
		visitDate: Dayjs | null;
		storeId?: number;
	}>({
		visitDate: null,
		storeId: storeId ?? VALUE_NO_STOREID,
	});

	const { data } = useQuery({
		...AdminStoreInfoState.keys.all(),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});
	const storeInfoList = data ?? [];

	const createHistory = GuestMemberInfoState.visitHistory.create();

	const handleCreateHistory = async () => {
		const { storeId, visitDate } = info;
		if (!storeId || !visitDate) return;
		await createHistory.mutateAsync(
			{ storeId, visitDate: visitDate.format('YYYY-MM-DD'), memberId },
			{
				onSuccess: () => closeDialog(),
			}
		);
	};

	return (
		<WithDialog.XlDialog
			maxWidth={'sm'}
			fullWidth
			open={open}
			closeDialog={closeDialog}>
			<DialogTitle>{'방문 기록 추가'}</DialogTitle>
			<DialogContent>
				<Stack
					spacing={3}
					flex={1}
					sx={{ overflow: 'auto', p: 3 }}>
					<Stack flex={1}>
						<InputLabel>
							<Typography color={'text.secondary'}>{'적용 날짜'}</Typography>
						</InputLabel>
						<AdminDatePicker
							sx={{ flex: 1 }}
							value={info.visitDate}
							onChange={(val: Dayjs | null) => setInfo((prev) => ({ ...prev, visitDate: val }))}
							shouldDisableDate={(day) => visitedList.includes(day.format('YYYY-MM-DD'))}
						/>
					</Stack>
					<Stack flex={1}>
						<InputLabel>
							<Typography color={'text.secondary'}>{'적용 매장'}</Typography>
						</InputLabel>
						<Select
							displayEmpty
							size={'small'}
							sx={{ flex: 1, width: '100%' }}
							value={info.storeId}
							onChange={(e) => setInfo((prev) => ({ ...prev, storeId: e.target.value as number }))}>
							{!storeId && <MenuItem value={VALUE_NO_STOREID}>{'매장을 선택해주세요'}</MenuItem>}
							{storeInfoList.map((storeInfo) => (
								<MenuItem
									key={storeInfo.id}
									value={storeInfo.id}>
									{storeInfo.name}
								</MenuItem>
							))}
						</Select>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					disabled={!info.visitDate || !info.storeId || info.storeId < 1}
					onClick={handleCreateHistory}>
					{'추가'}
				</Button>
				<Button
					variant='outlined'
					onClick={closeDialog}>
					{'닫기'}
				</Button>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
