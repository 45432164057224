import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import { IconButtonProps, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { ReactNode } from 'react';

export interface ITicketCompleteButtonProps extends IconButtonProps {
  label?: ReactNode;
}
export const TicketCompleteButton: React.FC<ITicketCompleteButtonProps> = ({
  label = '티켓완료',
  onClick,
  disabled,
}) => {
  return (
    <IconTextButton
      onClick={onClick}
      disabled={disabled}
      icon={<CheckIcon fontSize="small" />}
      label={label}
    />
  );
};
