import { GuestVisitQueryState, PosStatusQueryState } from '@kinderlabs-pos/state';
import { CardStack } from '@kinderlabs-pos/ui-atoms';
import { FullSizeCircularProgress, RotatingRefreshButton } from '@kinderlabs-pos/ui-components';
import { Button, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Suspense, useEffect, useState } from 'react';
import { GuestMemberSearchPage } from './pannels/GuestMemberSearchPage';
import { GuestVisitPannels } from './pannels/GuestVisitPannels';
import { ProcessingPanel } from './pannels/ProcessingPanel';

// 매표소
export const MainPosDrawerContents = ({
	open,
	setOpen,
	defaultSidebar,
}: {
	open: boolean;
	setOpen: (open: boolean) => void;
	defaultSidebar: 'VISIT' | 'MEMBER';
}) => {
	const [tabValue, setTabValue] = useState<PosDrawerTabValueType | 'MEMBER'>(
		defaultSidebar === 'MEMBER' ? 'MEMBER' : 'WAITING'
	);

	return (
		<Stack
			flex={1}
			p={1}
			spacing={1}
			overflow={'auto'}>
			<CardStack
				variant='nocolors'
				sx={{ px: 1 }}>
				<ProcessingPanel />
			</CardStack>
			<Stack
				direction={'row'}
				spacing={1}>
				<Button
					variant={tabValue === 'MEMBER' ? 'contained' : 'outlined'}
					value={'POS'}
					onClick={() => setTabValue('MEMBER')}
					sx={{ px: 3 }}>
					{'POS 회원 검색'}
				</Button>
				<ToggleButtonGroup
					fullWidth
					exclusive
					onChange={(e, value: PosDrawerTabValueType) => {
						if (!value) return;
						setTabValue(value);
					}}
					size={'small'}
					value={tabValue}
					sx={{ flex: 1 }}>
					{PosDrawerTabvalueArray.map((info, idx) => (
						<ToggleButton
							key={idx}
							value={info}>
							<Typography variant='subtitle1'>{PosDrawerTabvalueLabel[info]}</Typography>
						</ToggleButton>
					))}
				</ToggleButtonGroup>
				<RotatingRefreshButton
					handleClick={() => {
						GuestVisitQueryState.invalidateQueries();
						PosStatusQueryState.invalidateQueries();
					}}
				/>
			</Stack>
			{getPageByTabValue({ tabValue, setClose: () => setOpen(false) })}
		</Stack>
	);
};

const getPageByTabValue = ({
	tabValue,
	setClose,
}: {
	tabValue: PosDrawerTabValueType | 'MEMBER';
	setClose: () => void;
}): JSX.Element => {
	switch (tabValue) {
		case 'MEMBER':
			return (
				<CardStack
					flex={1}
					overflow={'auto'}>
					<GuestMemberSearchPage onClose={setClose} />
				</CardStack>
			);
		default:
			return (
				<CardStack
					flex={1}
					spacing={1}
					overflow={'auto'}>
					<Suspense fallback={<FullSizeCircularProgress />}>
						<GuestVisitPannels
							tabValue={tabValue}
							closePannel={setClose}
						/>
					</Suspense>
				</CardStack>
			);
	}
};

const PosDrawerTabvalueArray = ['WAITING', 'COMPLETED', 'OTHERS'] as const;
type PosDrawerTabValueType = (typeof PosDrawerTabvalueArray)[number];
const PosDrawerTabvalueLabel: Record<PosDrawerTabValueType, string> = {
	WAITING: '대기중',
	COMPLETED: '완료',
	OTHERS: '취소',
};
