import { PosState } from '@kinderlabs-pos/state';
import { IconTextButton, WithPopover } from '@kinderlabs-pos/ui-atoms';
import CloudIcon from '@mui/icons-material/Cloud';
import { Badge, styled, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { NetworkPopup } from '../NetworkPopup';

export interface INetworkButtonProps { }
export const NetworkButton: React.FC<INetworkButtonProps> = ({ }) => {
  const { sseConnected } = useAtomValue(PosState.status);

  return (
    <WithPopover PopupComponent={<NetworkPopup />}>
      {(openPopover) => (
        <IconTextButton
          onClick={openPopover}
          variant="white"
          icon={
            <StyledBadge
              variant="dot"
              color={sseConnected ? 'success' : 'error'}
            >
              <CloudIcon sx={(theme) => ({ fill: 'white' })} />
            </StyledBadge>
          }
          label={<Typography sx={{ mt: 0 }}>{'네트워크'}</Typography>}
        />
      )}
    </WithPopover>
  );
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 2,
    top: 10,
    border: `0.5px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    width: 12,
    height: 12,
    borderRadius: '50%',
  },
}));
