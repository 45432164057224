import { Button } from '@mui/material';
import { OrderAndPaymentsType } from './OrderAndPaymentsType';

export const OrderAndPaymentsActions = ({ selected, setSelected }: OrderAndPaymentsType) => {
	return (
		<Button
			fullWidth
			variant={selected.type === 'orderActions' ? 'contained' : 'outlined'}
			onClick={() => setSelected({ type: 'orderActions' })}
			color={'primary'}>
			{'영수증 재인쇄 | 환불 | 취소'}
		</Button>
	);
};
