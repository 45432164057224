import { PaymentInfoType } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { PaymentState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { Button, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { useHandle현금영수증발행Formik } from '../PaymentDetailDialog/hooks/useHandleCashReceiptPublishFormik';
import { 현금영수증발급ActionStack, 현금영수증선택Stack } from './CashReceiptActionStacks';

export interface CashReceiptSubmitDialogProps extends Omit<XlDialogProps, 'maxWidth'> {
	payment: PaymentInfoType;
}
export const CashReceiptSubmitDialogContent = ({
	open,
	closeDialog,
	payment,
}: CashReceiptSubmitDialogProps) => {
	const { values, setValues, handleChange, isValid, isSubmitting, handleSubmit, errors } =
		useHandle현금영수증발행Formik({
			payment,
			handleSuccess: () => {
				closeDialog && closeDialog();
			},
		});

	const handle결제모듈강제취소 = PaymentState.actions.submit.useHandlePaymentPM강제취소();
	const handleClose = async () => {
		await handle결제모듈강제취소();
		closeDialog && closeDialog();
	};

	return (
		<WithDialog.XlDialog
			open={open}
			closeDialog={closeDialog}
			dialogTitle={'현금 영수증 발급'}
			maxWidth={'md'}>
			<DialogContent>
				<Stack minWidth={428}>
					<form onSubmit={handleSubmit}>
						<Stack spacing={2}>
							<Stack
								direction={'row'}
								justifyContent={'space-between'}>
								<Typography variant={'h3'}>{'결제 금액'}</Typography>
								<Typography variant={'h3'}>{numberWithCommasAnd원(payment.amount)}</Typography>
							</Stack>
							<현금영수증선택Stack
								includes현금영수증미발급={false}
								options={values.options}
								setOptions={(options) => setValues({ ...values, options })}
							/>
							<현금영수증발급ActionStack
								options={values.options}
								setOptions={(options) => setValues({ ...values, options })}
							/>
						</Stack>
					</form>
					{errors.submit && (
						<Stack direction={'row'}>
							<Typography
								variant='subtitle1'
								color={'error'}>
								{errors.submit}
							</Typography>
						</Stack>
					)}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					variant={'contained'}
					onClick={() => handleSubmit()}
					disabled={!isValid || isSubmitting}>
					확인
				</Button>
				<Button
					variant={'outlined'}
					disabled={isSubmitting}
					onClick={handleClose}>
					취소
				</Button>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
