import { Box, Divider, Stack, Typography, StackProps } from '@mui/material';
import { ReactNode } from "react"

/**
 * Stack 을 통해 Key: Value 정보를 줄 단위로 표현할때 유용
 */
export const InfoPrintStackTitle = ({
  title,
  leftButtons,
  rightButtons,
  sx
}: {
  title: ReactNode,
  leftButtons?: ReactNode
  rightButtons?: ReactNode
  sx?: StackProps["sx"]
}) => {
  return <Stack
    spacing={1}
    sx={{ position: "relative", ...sx }}>
    <Divider />
    <Stack
      direction={"row"}
      sx={{ position: "absolute", top: 0, bottom: 0, left: 0, }}
      style={{ marginTop: "auto", marginBottom: "auto" }}>
      {leftButtons}
    </Stack>
    <Typography variant="h4" textAlign={"center"}>
      {title}
    </Typography>
    <Stack
      direction={"row"}
      sx={{ position: "absolute", top: 0, bottom: 0, right: 0, }}
      style={{ marginTop: "auto", marginBottom: "auto" }}>
      {rightButtons}
    </Stack>
    <Divider />
  </Stack>
}