import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HistoryTable } from './HistoryTable';

export const VaultcashHistory = () => {
	return (
		<ErrorBoundary fallback={<Typography>시재 조회에 실패했습니다</Typography>}>
			<Suspense
				fallback={
					<Stack
						width={'100%'}
						height={'100%'}
						alignItems={'center'}
						justifyContent={'center'}
					>
						<CircularProgress />
					</Stack>
				}
			>
				<HistoryTable />
			</Suspense>
		</ErrorBoundary>
	);
};
