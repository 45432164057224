import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { alpha, Box, Button, ButtonGroup, Typography } from '@mui/material';

interface IWonButtonGroupProps {
	unit: 50000 | 10000 | 5000 | 1000 | 500 | 100 | 50 | 10;
	onClickMinus: (unit: number) => void;
	onClickPlus: (unit: number) => void;
}
export const WonButtonGroup: React.FC<IWonButtonGroupProps> = ({
	unit,
	onClickMinus,
	onClickPlus,
}) => {
	return (
		<ButtonGroup
			variant="outlined"
			sx={{ display: 'flex', width: '100%', height: 42 }}>
			<Button onClick={() => onClickMinus(unit)}>
				<Typography variant={'h5'}>
					<MinusOutlined />
				</Typography>
			</Button>
			<Box
				sx={(theme) => ({
					flex: 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					px: 2,
					border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
				})}>
				<Typography
					variant={'h5'}
					color={'primary'}>
					{numberWithCommasAnd원(unit)}
				</Typography>
			</Box>
			<Button onClick={() => onClickPlus(unit)}>
				<Typography variant={'h5'}>
					<PlusOutlined />
				</Typography>
			</Button>
		</ButtonGroup>
	);
};
