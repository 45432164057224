import { PosTypeLabel } from '@kinderlabs-pos/shared-data-type';
import { AdminKioskInfoState, AdminPosInfoState, StoreInfoState } from '@kinderlabs-pos/state';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { FilterWith전체Selector } from './FilterWithAllSelector';

export interface IPosFilterSelectorProps {
	targetPosId?: number;
	onChangeValue: (val?: string) => void;
}
export const PosFilterSelector: React.FC<IPosFilterSelectorProps> = ({
	targetPosId,
	onChangeValue,
}) => {
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	const { data: posInfoListData } = useQuery({
		...AdminPosInfoState.keys.list(storeId),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});
	const posInfoList = posInfoListData ?? [];

	const { data: kioskInfoListData } = useQuery({
		...AdminKioskInfoState.keys.list(storeId),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});
	const kioskInfoList = kioskInfoListData ?? [];

	const curPosInfo = useAtomValue(StoreInfoState.curPosInfo);

	const posAndKioskInfoList = useMemo(() => {
		if (!storeId) return [];

		const posInfoListToRender = posInfoList.map((pos) => ({
			value: `${pos.id}`,
			label: `[${PosTypeLabel[pos.posType]}] ${pos.name} ${curPosInfo.id === pos.id ? ' ●' : ''}`,
		}));

		const kioskInfoListToRender = kioskInfoList.map((kiosk) => ({
			value: `${kiosk.id}`,
			label: `[KIOSK] ${kiosk.name}`,
		}));

		return [...posInfoListToRender, ...kioskInfoListToRender];
	}, [posInfoList, kioskInfoList, curPosInfo, storeId]);

	return (
		<FilterWith전체Selector
			label={'POS/KIOSK'}
			candidates={posAndKioskInfoList}
			value={targetPosId ? `${targetPosId}` : undefined}
			onChangeValue={onChangeValue}
		/>
	);
};
