import { Button, Stack, styled, Typography } from '@mui/material';
import {
	exitsRouteObject,
	guestVisitsRouteObject,
	kitchenOrderRouteObject,
	ordersRouteObject,
	usePosRouter,
} from '../../pages/routes';
import { PosType } from '@kinderlabs-pos/shared-data-type';
import { useAtomValue } from 'jotai';
import { StoreInfoState } from '@kinderlabs-pos/state';

export interface IBottomMenuProps {}
export const BottomMenu: React.FC<IBottomMenuProps> = ({}) => {
	const posInfo = useAtomValue(StoreInfoState.curPosInfo);
	const { navigate대기관리, navigate주문조회, navigate퇴장관리, navigate주방주문조회 } =
		usePosRouter();

	return (
		<Stack
			direction={'row'}
			gap={1}
			justifyContent={'space-between'}>
			<FlexItemButton
				variant='contained'
				onClick={
					posInfo.posType === 'KITCHEN' ? () => navigate주방주문조회() : () => navigate대기관리()
				}>
				<Typography variant='subtitle1'>{getFirstButtonText(posInfo.posType)}</Typography>
			</FlexItemButton>
			<FlexItemButton
				variant='contained'
				onClick={() => navigate주문조회()}>
				<Typography variant='subtitle1'>{ordersRouteObject.title}</Typography>
			</FlexItemButton>
			<FlexItemButton
				variant='contained'
				onClick={() => navigate퇴장관리()}>
				<Typography variant='subtitle1'>{exitsRouteObject.title}</Typography>
			</FlexItemButton>
		</Stack>
	);
};

const FlexItemButton = styled(Button)({
	flex: 1,
});

const getFirstButtonText = (posInfo: PosType): string | undefined => {
	switch (posInfo) {
		case 'KITCHEN':
			return kitchenOrderRouteObject.title;
		case 'MAIN':
		case 'WEBPOS':
		case 'GOODS':
			return guestVisitsRouteObject.title;
	}
};
