import { OrderDetailType, PaymentInfoType } from '@kinderlabs-pos/shared-data-type';
import { GuestVisitQueryState } from '@kinderlabs-pos/state';
import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { PointPaymentInfoToRefundType, useHandlePointFormik } from './hooks/useHandlePointFormik';

interface ActionType {
	order: OrderDetailType;
	payment: PointPaymentInfoToRefundType;
	onCompleteRefund?: (paymentInfo: PaymentInfoType) => void;
	closeDialog?: () => void;
}
export const PaymentETCDetailActions: React.FC<ActionType> = ({
	order,
	payment,
	onCompleteRefund,
	closeDialog,
}) => {
	const { handleSubmit, errors } = useHandlePointFormik({
		payment,
		order,
		onComplete: () => {
			payment.method === 'POINT' && GuestVisitQueryState.invalidateQueries();
			onCompleteRefund && onCompleteRefund(payment);
		},
	});

	return (
		<Stack spacing={1}>
			<Stack
				height={40}
				direction={'row'}
				spacing={1}
				justifyContent='flex-end'>
				{!payment.refunded && (
					<Button
						onClick={() => handleSubmit()}
						variant={'outlined'}
						sx={{ pl: 1, pr: 1 }}>
						<Typography variant={'body1'}>환불</Typography>
					</Button>
				)}
				{closeDialog && (
					<Button
						variant='contained'
						onClick={closeDialog}>
						확인
					</Button>
				)}
			</Stack>
			{errors.submit && (
				<Stack direction={'row'}>
					<Typography
						variant='subtitle1'
						color={'error'}>
						{errors.submit}
					</Typography>
				</Stack>
			)}
		</Stack>
	);
};
