import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { IconButtonProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface IRunButtonProps {
	onClick: IconButtonProps['onClick'];
	disabled?: boolean;
	label?: string;
}
export const RunButton: React.FC<IRunButtonProps> = ({ onClick, disabled, label }) => {
	return (
		<IconTextButton
			iconSize='large'
			onClick={onClick}
			disabled={disabled}
			icon={<PlayArrowIcon fontSize='small' />}
			label={<Typography>{label || '응대 시작'}</Typography>}
		/>
	);
};
