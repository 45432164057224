import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { FormikFrameworks } from '@kinderlabs-pos/frameworks/forms';
import { useControlledReactTable } from '@kinderlabs-pos/frameworks/table';
import {
	GuestVisitSummaryType,
	OrderType,
	UserInfoSearchStatusType,
	VaunceMemberInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { getDateFormat, getDateFormatWithHyphen, isSame } from '@kinderlabs-pos/shared-util';
import {
	AdminStoreInfoState,
	GuestMemberInfoState,
	OrderState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import {
	FullSizeCircularProgress,
	VisitHistoryCreateDialog,
	useConfirm,
} from '@kinderlabs-pos/ui-components';
import {
	Button,
	Card,
	FormHelperText,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef, Row } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { VaunceAppMemberInfo } from '../../components/UserInfoBox';
import { useMemberInfoFormik } from './Formiks/useGuestMemberInfoFormik';
import { getAppUserInfoGroup, getUserBasicInfoListGroup } from './Formiks/userInfoFormikComponent';
import isToday from 'dayjs/plugin/isToday';

export const UserInfoResultSection = ({
	searchStatus,
	guestMemberInfoActions,
	appMemberInfo,
	visitListInfo,
	showAppMember = true,
}: {
	searchStatus?: UserInfoSearchStatusType;
	guestMemberInfoActions?: {
		guestMemberInfo: OrderType['guestMemberInfo'];
		onClose?: () => void;
		resetMemberInfo?: () => void;
		setIsRender?: () => void;
		withoutUpdateAction?: boolean;
		withoutDeleteAction?: boolean;
		withoutRunAction?: boolean;
	};
	appMemberInfo?: {
		guestMemberId?: number;
		detailMemberInfo?: VaunceMemberInfoType;
		withAllInfo?: boolean;
		setAppUserId?: (val?: string) => void;
	};
	visitListInfo?: {
		guestMemberId: number;
	};
	showAppMember?: boolean;
}) => {
	const 결과없음 = searchStatus && searchStatus !== '검색완료';

	if (결과없음)
		return (
			<NoResultPage
				searchStatus={searchStatus}
				isForAppUser={!!appMemberInfo}
			/>
		);

	return (
		<Stack
			spacing={1}
			flex={1}
			sx={{ overflow: 'auto' }}>
			{guestMemberInfoActions && (
				<Suspense fallback={<FullSizeCircularProgress />}>
					<GuestMemberInfoSection
						guestMemberInfoActions={guestMemberInfoActions}
						showAppMemberButton={!showAppMember}
					/>
				</Suspense>
			)}
			{showAppMember && appMemberInfo && (
				<Suspense fallback={<FullSizeCircularProgress />}>
					<AppMemberInfoSection appMemberInfo={appMemberInfo} />
				</Suspense>
			)}
			{visitListInfo && (
				<Suspense fallback={<FullSizeCircularProgress />}>
					<VisitListInfoSection visitListInfo={visitListInfo} />
				</Suspense>
			)}
		</Stack>
	);
};

const GuestMemberInfoSection = ({
	guestMemberInfoActions,
	showAppMemberButton = false,
}: {
	guestMemberInfoActions: {
		guestMemberInfo: OrderType['guestMemberInfo'];
		onClose?: () => void;
		resetMemberInfo?: () => void;
		setIsRender?: () => void;
		withoutUpdateAction?: boolean;
		withoutDeleteAction?: boolean;
		withoutRunAction?: boolean;
	};
	showAppMemberButton?: boolean;
}) => {
	const {
		guestMemberInfo,
		onClose,
		resetMemberInfo,
		setIsRender,
		withoutDeleteAction = false,
		withoutRunAction = false,
		withoutUpdateAction = false,
	} = guestMemberInfoActions;

	if (!guestMemberInfo) return <NoResultPage searchStatus={'결과없음'} />;

	const theme = useTheme();
	const confirm = useConfirm();
	const { readOnly, goDetailMode, goEditMode } = useEditModeToggle();
	const formikPropsOption = { readOnly: readOnly, showChanged: true };

	const { guestMemberInfo: curProcessing } = useAtomValue(OrderState.value);
	const dispatchOrder = useSetAtom(OrderState.value);

	const updateGuestMemberInfo = GuestMemberInfoState.update();
	const deleteGuestMemberInfo = GuestMemberInfoState.delete();

	const { data, isFetched } = useQuery({
		...GuestMemberInfoState.keys.detailById(guestMemberInfo.memberId),
		keepPreviousData: false,
	});
	const addressInfoMap =
		useQuery({ ...AdminStoreInfoState.keys.addressInfo() }).data?.sigunguMap ?? {};

	useEffect(() => {
		if (isFetched) {
			setIsRender && setIsRender();
		}
	}, [isFetched]);

	if (!data) return <NoResultPage searchStatus={'결과없음'} />;

	const {
		values,
		setValues,
		getFormikProps,
		handleSubmit,
		initialValues,
		handleReset,
		submitErrorMessage,
		isSubmitting,
	} = useMemberInfoFormik({
		initialValues: data,
		onSubmit: async (values) => {
			await updateGuestMemberInfo.mutateAsync(
				{
					...values.member,
					isMarketingAgree: values.member.marketingAgreement.isAgree,
				},
				{
					onSuccess: () => {
						if (values.member.id === curProcessing?.memberId) {
							dispatchOrder({
								type: 'SET_GUEST_USERS_INFO',
								guestMemberInfo: {
									...curProcessing,
									name: values.member.name,
									telephone: values.member.telephone,
								},
							});
						}
						goDetailMode();
					},
				}
			);
		},
	});

	const UserBasicInfoGroup = getUserBasicInfoListGroup({
		name: getFormikProps('textfield', 'member.name', formikPropsOption),
		telephone: getFormikProps('textfield', 'member.telephone', formikPropsOption),
		birthday: getFormikProps('textfield', 'member.birthday', formikPropsOption),
		gender: getFormikProps('select', 'member.gender', formikPropsOption),
		address: {
			addressList: addressInfoMap,
			formInfo: getFormikProps('select', 'member.address', formikPropsOption),
			value: {
				region: values.member.address?.region ?? '',
				district: values.member.address?.district ?? '',
			},
			setRegion: (region: string) =>
				setValues((prev) => ({
					...prev,
					member: { ...prev.member, address: { region, district: '' } },
				})),
			setDistrict: (district: string) =>
				setValues((prev) => ({
					...prev,
					member: {
						...prev.member,
						address: prev.member.address
							? { region: prev.member.address.region, district }
							: undefined,
					},
				})),
		},
		marketingAgree: getFormikProps(
			'select',
			'member.marketingAgreement.isAgree',
			formikPropsOption
		),
		setMarketingAgree: (val: boolean) =>
			setValues((prev) => ({
				...prev,
				member: {
					...prev.member,
					marketingAgreement: {
						agreeDateTime: prev.member.marketingAgreement.agreeDateTime,
						isAgree: val,
					},
				},
			})),
	});

	const isChanged = !isSame(initialValues, values);

	const handleDeleteMember = () =>
		confirm(
			<Stack spacing={2}>
				<Typography variant='h4'>{'POS 회원을 삭제하시겠습니까?'}</Typography>
				<Stack
					sx={{ alignItems: 'center' }}
					direction={'row'}
					spacing={2}>
					<WarningOutlined style={{ fontSize: 32, color: theme.palette.error.main }} />
					<Typography>
						{'POS 회원 삭제 시, 모든 방문 정보 및 특정 주문 정보에서 회원 정보가 삭제 됩니다.'}
					</Typography>
				</Stack>
			</Stack>,
			async () => {
				await deleteGuestMemberInfo.mutateAsync([values.member.id], {
					onSuccess: () => {
						resetMemberInfo && resetMemberInfo();
					},
				});
			}
		);

	const handleClickRunButton = () => {
		dispatchOrder({
			type: 'SET_GUEST_USERS_INFO',
			guestMemberInfo: {
				memberId: values.member.id,
				name: values.member.name,
				telephone: values.member.telephone,
				appUserId: values.vaunceMemberInfo?.userId,
			},
		});
		onClose && onClose();
	};

	return (
		<Stack
			spacing={1}
			flex={1}
			sx={{ overflow: 'auto' }}>
			<Stack
				spacing={0.5}
				flex={1}
				sx={{ overflow: 'auto' }}>
				<Card
					variant='outlined'
					sx={{
						p: 2,
						width: '100%',
						height: '100%',
						overflow: 'auto',
					}}>
					<FormikFrameworks formGrids={UserBasicInfoGroup} />
					<FormHelperText
						error
						sx={{ textAlign: 'end' }}>
						{submitErrorMessage}
					</FormHelperText>
				</Card>
			</Stack>
			<Stack
				direction={'row'}
				spacing={1}
				sx={{ alignItems: 'center' }}>
				{!withoutRunAction && (
					<Button
						disabled={!!curProcessing}
						onClick={handleClickRunButton}
						variant='contained'
						sx={{ flex: 2 }}>
						{'응대 시작'}
					</Button>
				)}
				{!withoutUpdateAction &&
					(readOnly ? (
						<Button
							onClick={goEditMode}
							variant='outlined'
							sx={{ flex: 1 }}>
							{'회원 수정'}
						</Button>
					) : (
						<Stack
							flex={1}
							direction={'row'}
							sx={{ alignItems: 'center' }}
							spacing={1}>
							<Button
								disabled={!isChanged || isSubmitting}
								onClick={() => handleSubmit()}
								variant='contained'
								sx={{ flex: 1 }}>
								{'저장'}
							</Button>
							<Button
								onClick={(e) => {
									handleReset(e);
									goDetailMode();
								}}
								variant='outlined'
								sx={{ flex: 1 }}>
								{'취소'}
							</Button>
						</Stack>
					))}
				{!withoutDeleteAction && (
					<Button
						disabled={!!curProcessing}
						onClick={handleDeleteMember}
						variant='outlined'
						sx={{ flex: 1 }}>
						{'회원 삭제'}
					</Button>
				)}

				{showAppMemberButton && (
					<VaunceAppMemberInfo vaunceMemberId={values.vaunceMemberInfo?.userId} />
				)}
			</Stack>
		</Stack>
	);
};

const AppMemberInfoSection = ({
	appMemberInfo,
}: {
	appMemberInfo: {
		guestMemberId?: number;
		detailMemberInfo?: VaunceMemberInfoType;
		withAllInfo?: boolean;
		setAppUserId?: (val?: string) => void;
	};
}) => {
	if (appMemberInfo.guestMemberId) {
		const { data, isFetched } = useQuery({
			...GuestMemberInfoState.keys.detailById(appMemberInfo.guestMemberId),
			keepPreviousData: false,
		});

		if (!data || !data.vaunceMemberInfo) return <></>;

		useEffect(() => {
			if (appMemberInfo.setAppUserId && isFetched && data) {
				appMemberInfo.setAppUserId(data.vaunceMemberInfo?.userId);
			}
		}, [data, appMemberInfo, isFetched]);

		const AppUserInfoGroup = getAppUserInfoGroup({
			vacuneAppMemberInfo: data.vaunceMemberInfo,
			withAllInfo: appMemberInfo.withAllInfo,
		});

		return (
			<Stack>
				<Card
					variant='outlined'
					sx={{
						p: 2,
						width: '100%',
						height: '100%',
						overflow: 'auto',
					}}>
					<FormikFrameworks formGrids={AppUserInfoGroup} />
				</Card>
			</Stack>
		);
	} else if (appMemberInfo.detailMemberInfo) {
		const AppUserInfoGroup = getAppUserInfoGroup({
			vacuneAppMemberInfo: appMemberInfo.detailMemberInfo,
			withAllInfo: appMemberInfo.withAllInfo,
		});

		return (
			<Stack>
				<Card
					variant='outlined'
					sx={{
						p: 2,
						width: '100%',
						height: '100%',
						overflow: 'auto',
					}}>
					<FormikFrameworks formGrids={AppUserInfoGroup} />
				</Card>
			</Stack>
		);
	} else return <></>;
};

const VisitListInfoSection = ({
	visitListInfo,
}: {
	visitListInfo: {
		guestMemberId: number;
	};
}) => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const { data } = useQuery({
		...GuestMemberInfoState.keys.detailById(visitListInfo.guestMemberId),
		keepPreviousData: false,
	});

	const { visitList, member } = data!;
	const userVisitList = visitList ?? [];
	const columns = useColumn({ memberId: member.id });
	const { TableComponent } = useControlledReactTable({
		reactTableOptions: {
			columns,
			data: userVisitList ?? [],
			enableRowSelection: false,
		},
		useAutoPagination: true,
		onlyPaginationNumbers: true,
	});

	return (
		<Stack
			flex={1}
			sx={{ overflow: 'auto' }}>
			<Card
				variant='outlined'
				sx={{
					p: 2,
					width: '100%',
					height: '100%',
					overflow: 'auto',
				}}>
				<Stack spacing={1}>
					<Stack
						direction={'row'}
						spacing={1}
						sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
						<Stack
							direction={'row'}
							spacing={1}
							sx={{ alignItems: 'center' }}>
							<Typography variant='h3'>{'방문 내역'}</Typography>
							<Typography variant='h3'>{`(총 ${visitList?.length} 회 방문)`}</Typography>
						</Stack>
						<WithDialog
							dialog={(open, closeDiaog) => (
								<VisitHistoryCreateDialog
									open={open}
									closeDialog={closeDiaog}
									memberId={member.id}
									storeId={storeInfo.id}
									visitedList={userVisitList.map((visit) =>
										dayjs(visit.visitedDate).format('YYYY-MM-DD')
									)}
								/>
							)}>
							{(openDialog) => (
								<Button
									size='small'
									onClick={openDialog}
									variant='contained'>
									{'임의 추가'}
								</Button>
							)}
						</WithDialog>
					</Stack>
					<TableComponent />
				</Stack>
			</Card>
		</Stack>
	);
};

const useColumn = ({ memberId }: { memberId: number }) => {
	const { data } = useQuery({
		...AdminStoreInfoState.keys.all(),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});
	const storeInfoList = data ?? [];
	return useMemo<ColumnDef<GuestVisitSummaryType>[]>(
		() => [
			{
				id: 'createdDateTime',
				header: '방문일자',
				accessorFn: (row) => getDateFormat(row.visitedDate),
				maxSize: 120,
				enableSorting: false,
			},
			{
				id: 'storeId',
				header: '방문 매장',
				accessorFn: (row) => storeInfoList.find((s) => s.id === row.storeId)?.name,
				enableSorting: false,
			},
			{
				id: 'others',
				header: '',
				cell: ({ row }) => {
					const isToday = dayjs(row.original.visitedDate).isToday();
					const isOutlier = row.original.isOutlier;

					return (
						<Typography
							variant='h5'
							color={isToday ? 'error' : undefined}>
							{isToday ? '오늘' : isOutlier ? '임의 추가' : undefined}
						</Typography>
					);
				},
				enableSorting: false,
				maxSize: 60,
			},
			{
				id: 'action',
				header: '삭제',
				cell: ({ row }) => (
					<RowActions
						row={row}
						memberId={memberId}
					/>
				),
				maxSize: 50,
			},
		],
		[]
	);
};

const RowActions = ({ row, memberId }: { row: Row<GuestVisitSummaryType>; memberId: number }) => {
	const theme = useTheme();
	const confirm = useConfirm();
	const deleteVisitHistory = GuestMemberInfoState.visitHistory.delete();

	const handleDeleteHistory = () =>
		confirm(
			<Stack
				spacing={2}
				sx={{ alignItems: 'flex-start' }}>
				<Typography variant='h4'>{'방문 내역을 삭제하시겠습니까?'}</Typography>
				<Stack
					spacing={2}
					direction={'row'}
					sx={{ alignItems: 'center' }}>
					<WarningOutlined style={{ fontSize: 32, color: theme.palette.error.main }} />
					<Typography>
						{
							'해당 일자, 해당 매장에서의 방문 정보 삭제 및 특정 주문 정보에서 회원 정보가 삭제됩니다.'
						}
					</Typography>
				</Stack>
			</Stack>,
			async () => {
				await deleteVisitHistory.mutateAsync({
					visitDate: getDateFormatWithHyphen(row.original.visitedDate),
					memberId,
					storeId: row.original.storeId,
				});
			}
		);

	return (
		<Stack width={'100%'}>
			<IconButton onClick={handleDeleteHistory}>
				<DeleteOutlined />
			</IconButton>
		</Stack>
	);
};

const NoResultPage = ({
	searchStatus,
	isForAppUser = false,
}: {
	searchStatus: UserInfoSearchStatusType;
	isForAppUser?: boolean;
}) => {
	return (
		<Stack
			flex={1}
			sx={{
				overflow: 'auto',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				p: 2,
			}}>
			<Typography
				variant='h4'
				textAlign={'center'}
				sx={{ whiteSpace: 'pre-line' }}
				color={'text.secondary'}>
				{searchStatus === '검색전'
					? getGuideTextBeforeSearch(isForAppUser)
					: '해당하는 사용자가 없습니다. 검색어를 확인해주세요.'}
			</Typography>
		</Stack>
	);
};

const getGuideTextBeforeSearch = (isForAppUser: boolean) => {
	return isForAppUser
		? '앱 회원 이름, 핸드폰 번호 8자리(010제외)로 검색 가능합니다.'
		: '핸드폰 번호 11자리, 주문, 티켓 ID로 검색 가능합니다.';
};

const useEditModeToggle = () => {
	const [readOnly, setReadOnly] = useState(true);
	const goDetailMode = () => {
		setReadOnly(true);
	};
	const goEditMode = () => {
		setReadOnly(false);
	};

	return { readOnly, goDetailMode, goEditMode };
};
