import {
	CartLineInfoType,
	CartLineTypeRules,
	PosCouponTicketType,
} from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import {
	OrderState,
	PosCouponInfoState,
	PosCouponTicketState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { SelectableListItem } from '@kinderlabs-pos/ui-atoms';
import { useAlert } from '@kinderlabs-pos/ui-components';
import { Button, Checkbox, Divider, List, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useAtomValue } from 'jotai';
import { Fragment, useEffect, useState } from 'react';
import { ProductQuantityAdjustItemStack } from '../DiscountAndCoupon/contents/ProductQuantityAdjustItemStack';
import { PosCouponInfoItem } from './PosCouponInfoItem';

export const PosCouponDialogContents = ({
	keyword,
	onClose,
}: {
	keyword?: string;
	onClose: () => void;
}) => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const cartDispatcher = OrderState.cart.useDispatcher();
	const { data: coupon } = useQuery({
		...PosCouponTicketState.keys.detail(keyword),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const { data: couponInfo } = useQuery({
		...PosCouponInfoState.keys.detail(coupon?.couponInfoId),
		refetchOnWindowFocus: true,
	});

	const customAlert = useAlert();

	const [selectedCoupon, setSelectedCoupon] = useState<PosCouponTicketType | undefined>(undefined);
	const handleSelectCoupon = (coupon?: PosCouponTicketType) => {
		if (couponInfo?.usableForAllCenter || couponInfo?.storeWhiteList.includes(storeInfo.id)) {
			setSelectedCoupon(coupon);
		} else {
			customAlert('이 매장에서 사용 가능한 쿠폰이 아닙니다.');
			return;
		}
	};

	const cartLines = useAtomValue(OrderState.cart.selector).lines;
	const inputCartLines = cartLines.filter((cl) => CartLineTypeRules.canApplyDiscount[cl.type]);

	const { handleSubmit, handleChange, values, setValues, errors } = useFormik<{
		cartLines: (CartLineInfoType & { selected: boolean })[];
	}>({
		initialValues: {
			cartLines: inputCartLines.map((cl, index) => ({ ...cl, selected: !index ? true : false })),
		},
		onSubmit: (values, { setErrors, resetForm }) => {
			if (!selectedCoupon || !couponInfo) return;

			if (couponInfo.couponType === 'DISCOUNT') {
				const selectedCartLine = values.cartLines.find((cl) => cl.selected);
				if (!selectedCartLine) return;

				cartDispatcher({
					type: 'KL_COUPON',
					subAction: {
						type: 'ADD_DISCOUNT',
						cartLineId: getUuidV4(),
						couponId: selectedCoupon.id,
						couponInfoId: couponInfo.id,
						couponName: couponInfo.name,
						targetCartLineId: selectedCartLine.id,
						productDiscountInfo: couponInfo.discountCouponInfo,
						taxFreeTarget: selectedCartLine.productAdditionalInfo.taxFree,
					},
				});
			}

			if (couponInfo.couponType === 'PRODUCT') {
				cartDispatcher({
					type: 'KL_COUPON',
					subAction: {
						type: 'ADD_PRODUCT',
						cartLineId: getUuidV4(),
						couponId: selectedCoupon.id,
						couponInfoId: couponInfo.id,
						couponName: couponInfo.name,
						ddiziInfo: {
							name: couponInfo.name,
							ddiziType: 'NORMAL',
							minutes: storeInfo.policy.defaultMinutesInCenter,
							hasNoAdditionalCharge: false,
							isNotForStatistics: false,
							available: 'ALL',
						},
					},
				});
			}

			onClose();
		},
	});

	useEffect(() => {
		if (couponInfo?.couponType === 'PRODUCT') {
			setValues({ cartLines: values.cartLines.map((cl) => ({ ...cl, selected: false })) });
		}
		setSelectedCoupon(undefined);
	}, [couponInfo?.couponType]);

	const handleClickSelect = (targetIdx: number) => {
		/** 하나만 선택하도록 */
		setValues({
			...values,
			cartLines: values.cartLines.map((cl, idx) => {
				return { ...cl, selected: idx === targetIdx };
			}),
		});
	};

	return (
		<Stack flex={1}>
			{coupon && couponInfo ? (
				<Stack flex={1}>
					<List
						disablePadding
						sx={{ width: '100%', overflow: 'auto' }}>
						<PosCouponInfoItem
							couponTicket={coupon}
							couponInfo={couponInfo}
							selectedCoupon={selectedCoupon}
							setSelectedCoupon={handleSelectCoupon}
							alreadyUsing={cartLines.some((cl) => cl.couponId === coupon?.id)}
						/>
						<Divider component={'li'} />
					</List>
				</Stack>
			) : (
				<Stack
					flex={1}
					width={'100%'}
					justifyContent={'center'}
					alignItems={'center'}>
					<Typography
						variant='subtitle2'
						color={'text.secondary'}>
						{keyword && `${keyword} 검색 결과 없음`}
					</Typography>
				</Stack>
			)}
			<Stack
				flex={1}
				spacing={1}>
				<Typography variant='h5'>적용할 상품</Typography>
				<List
					disablePadding
					sx={{ width: '100%', overflow: 'auto' }}>
					{values.cartLines.map((cartLine, idx) => {
						return (
							<Fragment key={idx}>
								<SelectableListItem
									sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}
									onClick={() => handleClickSelect(idx)}
									disabled={couponInfo?.couponType === 'PRODUCT'}
									selected={cartLine.selected}>
									<Checkbox
										sx={{ width: 18, height: 18 }}
										checked={cartLine.selected}
									/>
									<ProductQuantityAdjustItemStack
										inputDisabled
										cartLine={cartLine}
										changeQuantity={() => {}}
									/>
								</SelectableListItem>
								<Divider component={'li'} />
							</Fragment>
						);
					})}
				</List>
			</Stack>
			<Stack
				direction='row'
				justifyContent='flex-end'
				spacing={1}>
				<Button
					onClick={() => handleSubmit()}
					disabled={!selectedCoupon}
					variant={'contained'}>
					적용
				</Button>
				<Button
					onClick={onClose}
					variant={'outlined'}>
					취소
				</Button>
			</Stack>
		</Stack>
	);
};
