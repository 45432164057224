import {
	GuestUserType,
	GuestVisitStatusTypeLabel,
	VisitUserGendersTypeLabel,
	VisitUserRelationsTypeLabel,
} from '@kinderlabs-pos/shared-data-type';
import {
	getDateFormat,
	getRelativeTimeFormat,
	getTelephoneNumberWithDash,
	getTimeFormat,
} from '@kinderlabs-pos/shared-util';
import { GuestVisitQueryState } from '@kinderlabs-pos/state';
import { InfoPrintStackRow, InfoPrintStackTitle } from '@kinderlabs-pos/ui-components';
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Fragment, ReactNode, useMemo } from 'react';

interface IGuestVisitDetailContentProps {
	guestVisitId: number;
	GuestVisitActions?: ReactNode;
	navigate주문내역?: (orderId: string) => void;
}
export const GuestVisitDetailContent: React.FC<IGuestVisitDetailContentProps> = ({
	guestVisitId,
	GuestVisitActions,
	navigate주문내역,
}) => {
	const { data } = useQuery({
		...GuestVisitQueryState.keys.detail(guestVisitId),
		refetchOnWindowFocus: true,
	});

	if (!data) return null;

	const { sunbun, status, createdDate, createdDateTime, users, calls, visitCount, memberInfo } =
		data;
	const guestVisitInfoData = [
		{
			label: '순번',
			value: sunbun,
		},
		{
			label: '방문 일자',
			value: getDateFormat(createdDate),
		},
		{
			label: '방문 시간',
			value: getTimeFormat(createdDateTime),
		},
		{
			label: '이름',
			value: memberInfo ? memberInfo.name : '-',
		},
		{
			label: '전화번호',
			value: memberInfo ? getTelephoneNumberWithDash(memberInfo.telephone) : '-',
		},
		{
			label: '상태',
			value: GuestVisitStatusTypeLabel[status],
		},
		{
			label: '최근 호출',
			value:
				calls && calls.length > 0 ? getRelativeTimeFormat(dayjs(), calls[0].calledDateTime) : '-',
		},
	];

	const filteredUsers: GuestUserType[] = useMemo(
		() =>
			users.length > 0
				? users
				: visitCount
				? [...new Array(visitCount.totalCount)].map(() => ({ guestType: 'NORMAL' }))
				: [],
		[users, visitCount]
	);

	return (
		<Box>
			<Stack spacing={1}>
				<InfoPrintStackTitle title={`고객 방문 정보`} />
				{guestVisitInfoData.map(({ label, value }, idx) => (
					<InfoPrintStackRow
						key={idx}
						label={label}
						value={value}
					/>
				))}
				<Divider />
				{!!GuestVisitActions && dayjs(createdDate).isToday() && (
					<>
						{GuestVisitActions}
						<Divider />
					</>
				)}
				{filteredUsers.map((user, idx) => {
					return (
						<Fragment key={idx}>
							<Stack
								direction={'row'}
								sx={{ alignItems: 'center' }}
								spacing={1}>
								{user.memberInfo?.avatar ? (
									<Avatar
										sx={{ width: 32, height: 32 }}
										src={user.memberInfo.avatar}
									/>
								) : (
									<Avatar sx={{ width: 32, height: 32 }} />
								)}
								<Typography variant='h5'>{`${user.name ?? '이름없음'}`}</Typography>
							</Stack>
							<InfoPrintStackRow
								label={'생년월일'}
								value={user.birthday ?? '-'}
							/>
							{user.relationship && (
								<InfoPrintStackRow
									label={'관계'}
									value={VisitUserRelationsTypeLabel[user.relationship]}
								/>
							)}
							<InfoPrintStackRow
								label={'성별'}
								value={user.gender ? VisitUserGendersTypeLabel[user.gender] : '-'}
							/>
							<Divider />
						</Fragment>
					);
				})}
			</Stack>
		</Box>
	);
};
