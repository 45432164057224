import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Button, Divider, Stack, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const StyledTitle = styled(Typography)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	color: theme.palette.text.secondary,
	width: '20%',
	display: 'flex',
	alignItems: 'center',
	...theme.typography.h4,
}));

export const VaultcashMenuItem = ({ title, innerElement }: { title: string; innerElement: ReactJSXElement }) => {
	return (
		<Stack direction={'row'} spacing={5} divider={<Divider orientation="vertical" />} marginBottom={4}>
			<StyledTitle>{title}</StyledTitle>
			{innerElement}
		</Stack>
	);
};

export const VaultcashMenuButton = styled(Button)({ marginRight: 16 });

export const VaultcashFinishButton = () => {
	const navigate = useNavigate();

	return (
		<Stack flexGrow={1} direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
			<Button
				variant="contained"
				sx={{ width: 80 }}
				onClick={() => {
					navigate('/main');
				}}
			>
				완료
			</Button>
		</Stack>
	);
};
