import { MonthCalendarFilterOptionType } from '../CalendarFilterOptionType';
import { GuestVisitSummaryType, VaunceMemberInfoType } from '../GuestVisitType';
import { OrderType } from '../Order/OrderType';
import { Pageable } from '../PageType';

export const VisitUserGendersTypeArray = ['MAN', 'WOMAN', 'UNKNOWN'] as const;
export type VisitUserGendersType = (typeof VisitUserGendersTypeArray)[number];
export const VisitUserGendersTypeLabel: Record<VisitUserGendersType, string> = {
	MAN: '남',
	WOMAN: '여',
	UNKNOWN: '-',
};

export type AddressType = {
	region: string;
	district: string;
};

export const VisitUserRelationsTypeArray = [
	'PARENT',
	'CHILD',
	'FRIEND',
	'LOVER',
	'MATE',
	'ETC',
	'SELF',
	'UNKNOWN',
];
type VisitUserRelationsType = (typeof VisitUserRelationsTypeArray)[number];
export const VisitUserRelationsTypeLabel: Record<VisitUserRelationsType, string> = {
	PARENT: '부모',
	CHILD: '자녀',
	FRIEND: '친구',
	LOVER: '연인',
	MATE: '배우자',
	ETC: '기타',
	SELF: '본인',
	UNKNOWN: '-',
};
export const VisitUserRelations = {
	PARENT: '부모',
	CHILD: '자녀',
	FRIEND: '친구',
	LOVER: '연인',
	MATE: '배우자',
	ETC: '기타',
	SELF: '본인',
};

export const GuestTypeArray = ['NORMAL', 'CHILD'] as const;
export type GuestType = (typeof GuestTypeArray)[number];
export const GuestTypeLabel: Record<GuestType, string> = {
	NORMAL: '일반',
	CHILD: '아동',
};

// export const VisitUserRelations: {
//   [key in VisitUserTypes]: string
// } = {
//   "PARENT": "부모",
//   "CHILD": "자식",
//   "FRIEND": "친구",
//   "LOVER": "연인",
//   "MATE": "배우자",
//   "ETC": "기타"
// }

type AgreementInfoType = {
	isAgree: boolean;
	agreeDateTime: Date | null;
};

export type GuestMemberInfoType = {
	id: number;
	storeId?: number;
	created?: Date;
	name: string;
	telephone: string;
	gender: VisitUserGendersType;
	address?: {
		region: string;
		district: string;
	};
	kinderlabsAgreement: AgreementInfoType;
	storeAgreement: AgreementInfoType;
	marketingAgreement: AgreementInfoType;
	birthday: string;
};

export type GuestMemberDetailInfoType = {
	member: GuestMemberInfoType;
	vaunceMemberInfo?: VaunceMemberInfoType;
	visitList?: GuestVisitSummaryType[];
};

export const TierTypeArray = ['FIRST', 'SECOND', 'THIRD', 'FOURTH'] as const;
export type TierType = (typeof TierTypeArray)[number];
export const TierTypeLabel: Record<TierType, string> = {
	FIRST: '최우수',
	SECOND: '우수',
	THIRD: '일반',
	FOURTH: '잠재',
};

type TierDescAndPointType = {
	desc: string;
	point: number;
};

export const descAndPointByTier: Record<TierType, TierDescAndPointType> = {
	FIRST: {
		desc: '가장 최근, 자주, 많이 소비하는 최고의 고객',
		point: 5,
	},
	SECOND: {
		desc: '자주 많이 소비하지만 최근 빈도는 조금 떨어지는 고객',
		point: 4,
	},
	THIRD: {
		desc: '구매 빈도와 금액이 중간이며, 최근 구매가 줄어든 고객',
		point: 3,
	},
	FOURTH: {
		desc: '구매 빈도와 금액이 낮고 최근 구매가 거의 없는 고객',
		point: 2,
	},
};

export type GuestMemberStatisticsType = {
	frequency: number;
	recentDate: Date;
	stayTime: number;
	mostVisitStoreId: number;
	salesByVisit: {
		visitDate: Date;
		orderValue: number;
		count: number;
	}[];
	salesByCategory: {
		category: string;
		orderValue: number;
		count: number;
	}[];
	tier: TierType;
};

export type AdminGuestMemberMgFilterType = {
	page: number;
	size?: number;
	search?: string;
	sort?: Pageable['sort'];
};

export interface VisitUserType {
	name?: string;
	telephone?: string; // 아이들은 telephone 이 없을때도 있다.
	birthday?: string;
	gender?: VisitUserGendersType;
	guestType: GuestType;
	// '본인, '부모', '자녀', '친구', '연인', '배우자', '기타'Z
	relationship?: VisitUserRelationsType;
}

export type MainUserType = {
	name: VisitUserType['name'];
	birthday: VisitUserType['birthday'];
	gender: VisitUserType['gender'];
	relationship: 'SELF';
	telephone: VisitUserType['telephone'];
	// termCheck?: boolean;
};

export interface GuestUserType extends VisitUserType {
	memberInfo?: {
		id: number;
		level: '로열' | '황제' | '일반';
		avatar: string | null;
		mileage: number;
	};
}

export interface SimpleGuestUserForTicketType {
	name: string;
	telephone: string;
	memberId: number;
}

export const UserInfoSearchStatusTypeArray = ['검색전', '검색완료', '결과없음'] as const;
export type UserInfoSearchStatusType = (typeof UserInfoSearchStatusTypeArray)[number];

export type GuestMemberInfoSearchType = {
	info: OrderType['guestMemberInfo'];
	status: UserInfoSearchStatusType;
};

export type AppMemberInfoSearchType = {
	info: VaunceMemberInfoType | undefined;
	status: UserInfoSearchStatusType;
};

export const AgeTypeArray = [
	'TEENAGER',
	'TWENTIES',
	'THIRTIES',
	'FORTIES',
	'FIFTIES',
	'SIXTIES',
	'OVER_SEVENTIES',
] as const;
export type AgeType = (typeof AgeTypeArray)[number];
export const AgeTypeLabel: Record<AgeType, string> = {
	TEENAGER: '11 ~ 20',
	TWENTIES: '21 ~ 30',
	THIRTIES: '31 ~ 40',
	FORTIES: '41 ~ 50',
	FIFTIES: '51 ~ 60',
	SIXTIES: '61 ~ 70',
	OVER_SEVENTIES: '70세 이상',
};

export const IndicatorTypeArray = [
	'TOTAL_VISIT_COUNT',
	'OVER_SECOND_COUNT',
	'AVERAGE_ORDER_VALUE',
	'REVISIT_COUNT',
	'NEW_VISIT_COUNT',
	'AVERAGE_TICKET_COUNT',
] as const;
export type IndicatorType = (typeof IndicatorTypeArray)[number];
export const IndicatorLabel: Record<IndicatorType, string> = {
	TOTAL_VISIT_COUNT: '총 방문회원수',
	OVER_SECOND_COUNT: '우수회원 이상 회원 수',
	AVERAGE_ORDER_VALUE: '방문별 평균 매출액',
	REVISIT_COUNT: '재방문 회원 수',
	NEW_VISIT_COUNT: '신규 회원 수',
	AVERAGE_TICKET_COUNT: '방문별 평균 티켓 구매 수',
};
export const IndicatorUnitLabel: Record<IndicatorType, string> = {
	TOTAL_VISIT_COUNT: '명',
	OVER_SECOND_COUNT: '명',
	AVERAGE_ORDER_VALUE: '원',
	REVISIT_COUNT: '명',
	NEW_VISIT_COUNT: '명',
	AVERAGE_TICKET_COUNT: '개',
};

export type GuestMemberStatisticsFilterType = {
	calendarOption: MonthCalendarFilterOptionType;
	storeIdList?: number[];
	ageGroupList?: AgeType[];
	gender?: VisitUserGendersType;
	tierList?: TierType[];
	indicator: IndicatorType;
};
