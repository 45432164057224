export const mainWindowSize = {
  width: 1024,
  height: 768,
};

export const subWindowSize = {
  width: 1024,
  height: 768,
};

export const kioskWindowSize = {
  width: 1080,
  height: 1920,
};
