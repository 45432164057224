import {
	IFormikFrameworksProps,
	ISelectFormProps,
	IStringFormProps,
	SelectForm,
	StringForm,
} from '@kinderlabs-pos/frameworks/forms';
import {
	getArrangedAppUserId,
	GuestMemberInfoType,
	VaunceMemberInfoType,
	VisitUserGendersTypeArray,
	VisitUserGendersTypeLabel,
} from '@kinderlabs-pos/shared-data-type';
import { getTelephoneNumberWithDash } from '@kinderlabs-pos/shared-util';
import {
	FormControlLabel,
	Unstable_Grid2 as Grid,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Typography,
} from '@mui/material';
import { Stack } from '@mui/system';

const getUserNameFormComponent = (props: IStringFormProps) => (
	<StringForm
		{...props}
		value={props.value ?? ''}
		disabledHelperText
		label={'이름'}
	/>
);

const getUserTelephoneFormComponent = (props: IStringFormProps) => (
	<StringForm
		{...props}
		disabledHelperText
		label={'핸드폰 번호'}
	/>
);

const getUserBirthdayFormComponent = (props: IStringFormProps) => (
	<StringForm
		{...props}
		value={props.value ?? ''}
		disabledHelperText
		label={'생년월일'}
	/>
);

const getUserGenderFormComponent = (props: ISelectFormProps) => (
	<SelectForm
		{...props}
		disabledHelperText
		label={'성별'}>
		{VisitUserGendersTypeArray.map((gender) => (
			<MenuItem
				key={gender}
				value={gender}>
				{VisitUserGendersTypeLabel[gender]}
			</MenuItem>
		))}
	</SelectForm>
);

const getUserAddressFormComponent = (props: {
	addressList: Record<string, string[]>;
	value?: GuestMemberInfoType['address'];
	setRegion: (val: string) => void;
	setDistrict: (val: string) => void;
	formInfo: ISelectFormProps;
}) => (
	<Stack>
		<InputLabel>
			<Typography
				variant={'body2'}
				color={props.formInfo.changed ? 'primary' : 'unset'}>
				{'주소'}
			</Typography>
		</InputLabel>
		<Stack
			direction={'row'}
			spacing={1}>
			<Select
				{...props.formInfo}
				size='small'
				sx={{ flex: 1 }}
				value={props.value?.region ?? ''}
				onChange={(e) => {
					props.setRegion(e.target.value as string);
				}}>
				{[...Object.keys(props.addressList)].map((시와도) => (
					<MenuItem
						value={시와도}
						key={시와도}>
						{시와도}
					</MenuItem>
				))}
			</Select>
			<Select
				{...props.formInfo}
				size='small'
				sx={{ flex: 1 }}
				name='address.district'
				value={props.value?.district ?? ''}
				onChange={(e) => props.setDistrict(e.target.value as string)}>
				{(props.value?.region ? props.addressList[props.value.region] : [])
					.sort((a, b) => a.localeCompare(b, 'ko'))
					.map((시군구) => (
						<MenuItem
							value={시군구}
							key={시군구}>
							{시군구}
						</MenuItem>
					))}
			</Select>
		</Stack>
	</Stack>
);

const getUserMarketingAgreeFormComponent = (
	props: ISelectFormProps,
	setMarketingAgree: (val: boolean) => void
) => (
	<Stack>
		<InputLabel>
			<Typography
				variant={'body2'}
				color={props.changed ? 'primary' : 'unset'}>
				{'마케팅 동의'}
			</Typography>
		</InputLabel>
		<Stack
			direction={'row'}
			flex={1}
			sx={{ alignItems: 'center' }}>
			<RadioGroup
				sx={{ flex: 1 }}
				row
				value={props.value as boolean}
				onChange={(e) => {
					setMarketingAgree(!(e.target.value === 'false'));
				}}>
				<FormControlLabel
					sx={{ flex: 1, justifyContent: 'center' }}
					disabled={props.readOnly}
					value={true}
					control={<Radio />}
					label='동의'
				/>
				<FormControlLabel
					sx={{ flex: 1, justifyContent: 'center' }}
					disabled={props.readOnly}
					value={false}
					control={<Radio />}
					label='거부'
				/>
			</RadioGroup>
		</Stack>
	</Stack>
);

const getVacuneAppUserInfo = (memberInfo?: VaunceMemberInfoType, withAllInfo?: boolean) => {
	if (!memberInfo) return <></>;

	const memberInfoArray: { label: string; value: string | number }[] = [
		{ label: '번호', value: memberInfo.id },
		{ label: '이름', value: memberInfo.username },
		{ label: '등급', value: memberInfo.vaunceMemberGrade },
		{ label: '포인트', value: memberInfo.remainingPoint },
	];

	if (withAllInfo) {
		memberInfoArray.push({
			label: '핸드폰 번호',
			value: getTelephoneNumberWithDash(memberInfo.telephone),
		});
		memberInfoArray.push({
			label: '가입 아이디',
			value: getArrangedAppUserId(memberInfo.providerType, memberInfo.userId),
		});
	}

	return (
		<Grid
			container
			rowGap={2}>
			<Grid xs={12}>
				<Typography variant='h4'>{'앱 회원 정보'}</Typography>
			</Grid>
			<Grid
				xs={12}
				container
				rowGap={1}>
				{memberInfoArray.map((info) => (
					<Grid
						key={info.label}
						xs={6}
						container>
						<Grid xs={6}>
							<Typography variant='h5'>{info.label}</Typography>
						</Grid>
						<Grid xs={1}>
							<Typography variant='h5'>{':'}</Typography>
						</Grid>
						<Grid xs={5}>
							<Typography variant='h5'>{info.value}</Typography>
						</Grid>
					</Grid>
				))}
			</Grid>
		</Grid>
	);
};

export const getUserBasicInfoListGroup: (props: {
	name: IStringFormProps;
	telephone: IStringFormProps;
	birthday: IStringFormProps;
	gender: ISelectFormProps;
	address: {
		addressList: Record<string, string[]>;
		value: GuestMemberInfoType['address'];
		setRegion: (val: string) => void;
		setDistrict: (val: string) => void;
		formInfo: ISelectFormProps;
	};
	marketingAgree: ISelectFormProps;
	setMarketingAgree: (val: boolean) => void;
}) => IFormikFrameworksProps['formGrids'] = ({
	// memberId,
	name,
	telephone,
	birthday,
	gender,
	address,
	marketingAgree,
	setMarketingAgree,
}) => [
	{
		FormComponent: getUserNameFormComponent(name),
		GridProps: { xs: 6 },
	},
	{
		FormComponent: getUserTelephoneFormComponent(telephone),
		GridProps: { xs: 6 },
	},
	{
		FormComponent: getUserBirthdayFormComponent(birthday),
		GridProps: { xs: 6 },
	},
	{
		FormComponent: getUserGenderFormComponent(gender),
		GridProps: { xs: 6 },
	},
	{
		FormComponent: getUserAddressFormComponent(address),
		GridProps: { xs: 8 },
	},
	{
		FormComponent: getUserMarketingAgreeFormComponent(marketingAgree, setMarketingAgree),
		GridProps: { xs: 4 },
	},
];

export const getAppUserInfoGroup: (props: {
	vacuneAppMemberInfo?: VaunceMemberInfoType;
	withAllInfo?: boolean;
}) => IFormikFrameworksProps['formGrids'] = ({ vacuneAppMemberInfo, withAllInfo }) => [
	{
		FormComponent: getVacuneAppUserInfo(vacuneAppMemberInfo, withAllInfo),
		GridProps: { xs: 12 },
	},
];
