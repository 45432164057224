import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButtonProps, Typography } from '@mui/material';

export interface IDeleteButtonProps {
	onClick: IconButtonProps['onClick'];
	disabled?: boolean;
	label?: string;
}
export const DeleteButton: React.FC<IDeleteButtonProps> = ({ onClick, disabled, label }) => {
	return (
		<IconTextButton
			disabled={disabled}
			iconSize='large'
			onClick={onClick}
			icon={<DeleteIcon fontSize='small' />}
			label={<Typography>{label || '취소'}</Typography>}
		/>
	);
};
