import { IconTextButton } from '@kinderlabs-pos/ui-atoms';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { IconButtonProps, Typography } from '@mui/material';

export interface ICompaniesButtonProps extends Pick<IconButtonProps, 'onClick' | 'disabled'> {
	numberOfUsers?: number;
}
export const CompaniesButton: React.FC<ICompaniesButtonProps> = ({
	onClick,
	disabled,
	numberOfUsers,
}) => {
	return (
		<IconTextButton
			variant='white'
			onClick={onClick}
			disabled={disabled}
			icon={<PeopleAltIcon fontSize='small' />}
			label={<Typography>{numberOfUsers ? `${numberOfUsers}명` : '-'}</Typography>}
		/>
	);
};
