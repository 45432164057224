import { CartLineBellInfoType } from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { OrderState, PosElectronStoreState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { NumberBoard } from '@kinderlabs-pos/ui-components';
import { Button, DialogActions, DialogContent, Stack, Typography, alpha } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';

interface BellDialogProps extends XlDialogProps {
	onNavigatePayment: () => void;
}
export const BellDialog = ({ onNavigatePayment, open, closeDialog }: BellDialogProps) => {
	const { lines } = useAtomValue(OrderState.cart.selector);
	const { recentBells, saveRecentBells } = useRecentBells();

	const dispatchOrder = useSetAtom(OrderState.value);
	const alreadySelectedBell = lines.find((cl) => cl.type === 'BELL') as
		| CartLineBellInfoType
		| undefined;
	const [value, setValue] = useState<number>(
		alreadySelectedBell ? +alreadySelectedBell.bellNumber : 0
	);

	const cartLineReducer = OrderState.cart.useDispatcher();

	const handlePaymentWithoutConfirm = () => {
		cartLineReducer({
			type: 'BELL',
			subAction: {
				type: 'REMOVE_BELL',
			},
		});

		onNavigatePayment();
		closeDialog && closeDialog();
	};

	const handleConfirm = () => {
		saveRecentBells(value);

		cartLineReducer({
			type: 'BELL',
			subAction: {
				type: 'ADD_BELL',
				cartLineId: getUuidV4(),
				bellNumber: `${value}`,
			},
		});

		onNavigatePayment();
		closeDialog && closeDialog();
	};

	const recentBellsToRender = [
		...recentBells,
		...Array.from(Array(10 - recentBells.length).keys()).map((k) => null),
	];

	return (
		<WithDialog.XlDialog
			dialogTitle={'진동벨 선택'}
			open={open}
			closeDialog={closeDialog}>
			<DialogContent>
				<Stack
					spacing={3}
					pb={5}>
					<Stack
						direction={'row'}
						spacing={3}>
						<Stack
							minWidth={320}
							flex={1}
							spacing={1}
							alignItems={'center'}
							justifyContent={'center'}
							sx={(theme) => ({ bgcolor: alpha(theme.palette.primary.main, 0.1) })}>
							<Typography
								variant={'subtitle1'}
								color={'text.secondary'}>
								진동벨 번호
							</Typography>
							<Typography
								variant={'h1'}
								sx={{ fontSize: 88 }}>
								{value}
							</Typography>
						</Stack>
						<Stack>
							<NumberBoard
								maxValue={999}
								value={value}
								setValue={setValue}
							/>
						</Stack>
					</Stack>
					<Stack>
						<Typography
							color={'text.secondary'}
							variant='subtitle1'>
							최근 진동벨 내역
						</Typography>
						<Grid2
							container
							spacing={1}
							minHeight={124}
							width={620}>
							{recentBellsToRender.map((bellNum, idx) => (
								<Grid2
									xs={2.4}
									key={idx}>
									<Button
										onClick={() => bellNum && setValue(bellNum)}
										disabled={!bellNum}
										variant='contained'
										sx={{ p: 0, width: '100%', height: '100%' }}>
										{bellNum && <Typography variant={'h2'}>{bellNum}</Typography>}
									</Button>
								</Grid2>
							))}
						</Grid2>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Stack
					direction={'row'}
					width={'100%'}
					justifyContent={'space-between'}>
					<Button
						onClick={handlePaymentWithoutConfirm}
						color={'secondary'}
						variant='contained'>
						진동벨 없이 결제
					</Button>
					<Stack
						direction={'row'}
						spacing={1}>
						<Button
							disabled={!value}
							onClick={handleConfirm}
							variant='contained'>
							결제하기
						</Button>
						<Button
							variant='outlined'
							onClick={() => {
								// dispatchOrder({
								// 	type: 'SET_GUEST_USERS_INFO',
								// 	guestMemberInfo: undefined,
								// });
								closeDialog && closeDialog();
							}}>
							닫기
						</Button>
					</Stack>
				</Stack>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};

const useRecentBells = () => {
	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings().data;
	const recentBells = localSettings?.recentBells ?? [];

	const saveLocalSettingsMutation = PosElectronStoreState.useSavePosLocalSettings();
	const saveRecentBells = (recentBell: number) => {
		const newBells = [recentBell, ...recentBells.filter((bell) => bell !== recentBell)].filter(
			(_, idx) => idx < 10
		);

		if (localSettings)
			saveLocalSettingsMutation.mutate({
				...localSettings,
				recentBells: newBells,
			});
	};

	return {
		recentBells,
		saveRecentBells,
	};
};
