import { VaunceMemberInfoType } from '@kinderlabs-pos/shared-data-type';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useGetAppMemberSearchingActions } from '../Formiks/useGetMemberSearchingActions';
import { UserInfoResultSection } from '../UserInfoResultSection';
import { UserInfoSearchSection } from '../UserInfoSearchSection';

export const AppUserMemberInfoSearchDialog = ({
	open,
	closeDialog,
	selectMemberInfo,
}: {
	open: boolean;
	closeDialog: () => void;
	selectMemberInfo: (memberInfo?: VaunceMemberInfoType) => void;
}) => {
	const { result, isSearching, handleSearchByKeyword, MultipleDialogComponent } =
		useGetAppMemberSearchingActions();

	const setMemberInfo = () => {
		selectMemberInfo(result.info);
		closeDialog();
	};
	return (
		<WithDialog.XlDialog
			maxWidth={'md'}
			fullWidth
			open={open}
			closeDialog={closeDialog}>
			<DialogTitle>{'앱 회원 검색'}</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<UserInfoSearchSection
						isLoading={isSearching}
						handleSearchByKeyword={handleSearchByKeyword}
					/>
					<UserInfoResultSection
						searchStatus={result.status}
						appMemberInfo={{ detailMemberInfo: result.info, withAllInfo: true }}
					/>
					<MultipleDialogComponent />
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					disabled={!result.info}
					variant='contained'
					onClick={setMemberInfo}>
					{'적용'}
				</Button>
				<Button
					onClick={closeDialog}
					variant='outlined'>
					{'닫기'}
				</Button>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
