import { SelectOutlined } from '@ant-design/icons';
import {
	KitchenOrderInfoType,
	OrderStatusTypeColor,
	OrderStatusTypeLabel,
	OrderTypeLabel,
} from '@kinderlabs-pos/shared-data-type';
import { getDateTimeFormat, numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { OrderQueryState } from '@kinderlabs-pos/state';
import { InfoPrintStackRow, InfoPrintStackTitle, useConfirm } from '@kinderlabs-pos/ui-components';
import { IconButton, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { usePosRouter } from '../routes';

export interface IKitchenOrderSummaryContentProps {
	selectedKitchenOrder: KitchenOrderInfoType;
}
export const KitchenOrderSummaryContent: React.FC<IKitchenOrderSummaryContentProps> = ({
	selectedKitchenOrder,
}) => {
	const { data } = useQuery({
		...OrderQueryState.keys.detailByOrderId(selectedKitchenOrder.orderId),
		refetchOnWindowFocus: true,
	});
	const { navigate주문조회 } = usePosRouter();

	if (!data)
		return (
			<Stack
				flex={1}
				width={'100%'}
				alignItems={'center'}>
				<InfoPrintStackTitle
					title={`주문 요약`}
					sx={{ width: '100%' }}
				/>
				<Typography variant={'subtitle1'}>아직 주문이 없습니다.</Typography>
			</Stack>
		);

	const { id, type, status, created, completed, aggregate } = data;
	const confirm = useConfirm();
	const handleGoto주문조회 = () => {
		confirm(`주문 번호 ${id} 로 주문 페이지에서 검색하시겠습니까?`, () => {
			navigate주문조회({
				keyword: `${id}`,
			});
		});
	};

	const orderInfoData = [
		{
			label: '주문 번호',
			value: `${id}`,
		},
		{
			label: '주문 종류',
			value: `${OrderTypeLabel[type]}`,
		},
		{
			label: '주문 상태',
			value: (
				<Typography
					component={'span'}
					color={OrderStatusTypeColor[status]}>
					{`${OrderStatusTypeLabel[status]}`}
				</Typography>
			),
		},
		{
			label: '주문 일자',
			value: getDateTimeFormat(created),
		},
		{
			label: '주문 완료 일자',
			value: completed ? getDateTimeFormat(completed) : '',
		},
		{
			label: '주문 총액',
			value: numberWithCommasAnd원(aggregate.totalToPay),
		},
	];

	return (
		<Stack spacing={1}>
			<Stack
				spacing={1}
				sx={{ position: 'relative' }}>
				<InfoPrintStackTitle
					title={'주문 요약'}
					rightButtons={
						<IconButton onClick={handleGoto주문조회}>
							<SelectOutlined style={{ fontSize: 18 }} />
						</IconButton>
					}
				/>
			</Stack>
			{orderInfoData.map((orderData, idx) => (
				<InfoPrintStackRow
					key={idx}
					label={orderData.label}
					value={orderData.value}
				/>
			))}
		</Stack>
	);
};
