import { OrderDetailType } from '@kinderlabs-pos/shared-data-type';
import { PaymentDetailContentsWrapper } from '../../../dialogs/PaymentDetailDialog/PaymentDetailContentsWrapper';

export const PaymentDetailContentsInOrder = ({
	orderWithDetail,
	paymentId,
	withRefunds,
}: {
	orderWithDetail: OrderDetailType;
	paymentId: string;
	withRefunds: boolean;
}) => {
	const payment = orderWithDetail.payments.find((payment) => payment.id === paymentId);
	if (!payment) return null;

	return (
		<PaymentDetailContentsWrapper
			order={orderWithDetail}
			payment={payment}
			withRefunds={withRefunds}
		/>
	);
};
