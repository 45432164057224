import { getGroupBy } from '@kinderlabs-pos/shared-util';
import {
	OnlineTicketQueryState,
	OnlineTicketSearchState,
	OrderState,
	StoreInfoState,
	authState,
	onlineTicketKeys,
} from '@kinderlabs-pos/state';
import { CardStack, SimpleScrollbar } from '@kinderlabs-pos/ui-atoms';
import {
	FullSizeCircularProgress,
	RotatingRefreshButton,
	SearchBox,
} from '@kinderlabs-pos/ui-components';
import { List, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, useEffect, useState } from 'react';
import { OnlineTicketListGroupItem } from './OnlineTicketListGroupItem';

export const OnlineTicketUsageBoard = () => {
	const { guestMemberInfo } = useAtomValue(OrderState.value);
	const [search, setSearch] = useAtom(OnlineTicketSearchState);

	const handleClickReload = () => {
		OnlineTicketQueryState.invalidateQueries();
	};

	const isVaunce = useAtomValue(authState.enterpriseInfo)?.id === 'vaunce';

	useEffect(() => {
		if (guestMemberInfo && search === '') setSearch(guestMemberInfo.telephone.substring(3));
	}, [guestMemberInfo, search, setSearch]);

	return (
		<Stack
			height={'100%'}
			spacing={1}
			alignItems={'center'}
			pt={1}
			overflow={'auto'}>
			<Stack
				width={'100%'}
				spacing={1}
				direction={'row'}>
				<RotatingRefreshButton handleClick={handleClickReload} />
				<OnlineTicketSearchBox
					keyword={search}
					setSearch={setSearch}
				/>
			</Stack>
			<Typography
				variant={'body2'}
				textAlign={'center'}>
				{!isVaunce
					? '온라인티켓 QR/바코드로 검색됩니다'
					: '온라인티켓 QR/바코드, 예약자 이름 혹은 예약자 전화번호 뒷 8자리로 검색됩니다.'}
				<br />
				<Typography
					component={'span'}
					variant={'caption'}
					color={'text.secondary'}
					textAlign={'center'}>
					{'유효기간이 3개월 이상 지난 티켓은 검색되지 않습니다.'}
				</Typography>
			</Typography>
			<CardStack
				variant='nocolors'
				flex={1}
				spacing={1}
				overflow={'auto'}
				width={'100%'}
				height={'100%'}>
				<Suspense fallback={<FullSizeCircularProgress />}>
					<OnlineTicketUsageBoardInternal search={search} />
				</Suspense>
			</CardStack>
		</Stack>
	);
};

const OnlineTicketSearchBox = ({
	keyword,
	setSearch,
}: {
	keyword: string;
	setSearch: (keyword: string) => void;
}) => {
	const [tempSearchValue, setTempSearchValue] = useState(keyword);
	useEffect(() => {
		setTempSearchValue(keyword);
	}, [keyword]);

	return (
		<SearchBox
			value={tempSearchValue}
			onChange={(val) => {
				setTempSearchValue(val);
			}}
			hasDeleteKey
			hasSearchButton
			onSearch={() => {
				setSearch(tempSearchValue);
			}}
			stackProps={{ width: '100%' }}
		/>
	);
};

const OnlineTicketUsageBoardInternal = ({ search }: { search: string }) => {
	const { storeId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const { data, isSuccess } = useQuery({
		...onlineTicketKeys.list({ storeId, search }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	if (!isSuccess) return null;

	const onlineTicketGroupByOrderId = getGroupBy(data, (ticket) => `${ticket.externalOrderId}`);

	const noContents = search !== '' && Object.keys(onlineTicketGroupByOrderId).length === 0;

	return (
		<>
			{noContents && (
				<Stack
					height='100%'
					justifyContent={'center'}
					alignItems={'center'}>
					<Typography
						variant='subtitle1'
						color='text.secondary'>
						검색 결과가 없습니다.
					</Typography>
				</Stack>
			)}
			{!noContents && (
				<SimpleScrollbar>
					<List>
						{Object.keys(onlineTicketGroupByOrderId).map((onlineTickerOrderId, idx) => {
							return (
								<OnlineTicketListGroupItem
									key={idx}
									onlineTicketsGroup={onlineTicketGroupByOrderId[onlineTickerOrderId]}
									defaultOpen={Object.values(onlineTicketGroupByOrderId).length < 3}
								/>
							);
						})}
					</List>
				</SimpleScrollbar>
			)}
		</>
	);
};
