import { OrderState, PosElectronStoreState, StoreInfoState } from '@kinderlabs-pos/state';
import { Button, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { BellDialog } from '../modules/fab/bell/BellDialog';
import { MemberDialog } from '../modules/fab/member/MemberDialog';
import { TelephoneDialog } from '../modules/fab/telephone/TelephoneDialog';
import { usePosRouter } from './routes';

type PaymentDialogWithActionType = {
	DialogComponent: JSX.Element | null;
	handleDialog: () => void;
};

export const PosPaymentButton = ({ isGuestMemberMapped }: { isGuestMemberMapped: boolean }) => {
	const order = useAtomValue(OrderState.value);
	const isEmptyCart = OrderState.cart.utils.isEmptyCart(order.cart);
	const { totalToPay } = useAtomValue(OrderState.aggregate.cart);
	const { DialogComponent, handleDialog } = useGetPaymentDialogWithAction({
		isGuestMemberMapped,
	});

	return (
		<>
			<Button
				onClick={handleDialog}
				variant='contained'
				sx={{ width: '100%', height: '100%' }}
				disabled={isEmptyCart || totalToPay < 0}>
				<Typography variant={'h1'}>{'결제'}</Typography>
			</Button>
			{DialogComponent}
		</>
	);
};

const useGetPaymentDialogWithAction = ({
	isGuestMemberMapped,
}: {
	isGuestMemberMapped: boolean;
}): PaymentDialogWithActionType => {
	const 응대고객없는경우정보입력활성화설정 =
		PosElectronStoreState.usePosMainPageLocalSettings().data
			?.using응대고객없는경우정보입력활성화설정;
	const { posType } = useAtomValue(StoreInfoState.curPosInfo);

	const 고객정보입력필요 = (응대고객없는경우정보입력활성화설정 && !isGuestMemberMapped) ?? false;

	const { navigate결제 } = usePosRouter();
	const onFinish = () => {
		navigate결제({ mode: 'order' });
	};

	return usePosPaymentStepHandlerWithDialogComponent({
		isKitchen: posType === 'KITCHEN',
		고객정보입력필요,
		onFinish,
	});
};

const PosPaymentStepTypeArray = ['memberMapping', 'telephoneOrBell'] as const;
type PosPaymentStepType = (typeof PosPaymentStepTypeArray)[number];

const usePosPaymentStepHandlerWithDialogComponent = ({
	isKitchen,
	고객정보입력필요,
	onFinish,
}: {
	isKitchen: boolean;
	고객정보입력필요: boolean;
	onFinish: () => void;
}): PaymentDialogWithActionType => {
	const posPaymentStep = getPosPaymentDialogStep({ isKitchen, 고객정보입력필요 });
	const [curStep, setCurStep] = useState<PosPaymentStepType | undefined>(undefined);

	const closeDialog = useCallback(() => setCurStep(undefined), []);

	const handleDialog = useCallback(() => {
		const index = posPaymentStep.findIndex((step) => step === curStep);

		if (index < posPaymentStep.length - 1) {
			setCurStep(posPaymentStep[index + 1]);
		} else {
			onFinish();
		}
	}, [curStep, setCurStep, onFinish, posPaymentStep]);

	const DialogComponent = useMemo(() => {
		return (
			<>
				<MemberDialog
					open={curStep === 'memberMapping'}
					handleNextStep={handleDialog}
					closeDialog={closeDialog}
				/>
				<KitchenPosNumpadDialog
					open={curStep === 'telephoneOrBell'}
					closeDialog={closeDialog}
					handleNextStep={handleDialog}
				/>
			</>
		);
	}, [curStep, closeDialog, handleDialog]);

	return { DialogComponent, handleDialog };
};

const KitchenPosNumpadDialog = ({
	open,
	closeDialog,
	handleNextStep,
}: {
	open: boolean;
	closeDialog: () => void;
	handleNextStep: () => void;
}) => {
	const kitchenSystemInfo = StoreInfoState.useKitchenSystemInfo();
	const isPhoneNeeded = kitchenSystemInfo?.callingMethod === 'KAKAO';

	return isPhoneNeeded ? (
		<TelephoneDialog
			open={open}
			closeDialog={closeDialog}
			onNavigatePayment={handleNextStep}
		/>
	) : (
		<BellDialog
			open={open}
			closeDialog={closeDialog}
			onNavigatePayment={handleNextStep}
		/>
	);
};

const getPosPaymentDialogStep = ({
	isKitchen,
	고객정보입력필요,
}: {
	isKitchen: boolean;
	고객정보입력필요: boolean;
}): PosPaymentStepType[] => {
	const result: PosPaymentStepType[] = [];

	if (고객정보입력필요) result.push('memberMapping');
	if (isKitchen) result.push('telephoneOrBell');

	return result;
};
