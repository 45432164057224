// import {
// 	AdminPosBoardEmptyItemType,
// 	PosBoardItemType,
// } from '@kinderlabs-pos/shared-data-type';
// import { ReactNode } from 'react';
// import { PosBoardActionItem } from './PosBoardActionItem';
// import { PosBoardEmptyItem } from './PosBoardEmptyItem';

import { PosBoardBaseItem } from './PosBoardBaseItem';
import { PosBoardEmptyItem } from './PosBoardEmptyItem';
import { PosBoardSelectableEmptyItem } from './PosBoardSelectableEmptyItem';
import { PosBoardSelectableItem } from './PosBoardSelectableItem';
import { PosBoardPaginationItem } from './PosBoardPaginationItem';

// type Props<T> = {
// 	idx: number;
// 	item: (PosBoardItemType | AdminPosBoardEmptyItemType) & { id: number };
// 	selected: boolean;
// 	selectable: boolean;
// 	selectItemIdx: React.Dispatch<React.SetStateAction<number | null>>;
// };

// export const PosProductBoardSelectableNullableItem = <T extends ReactNode>({
// 	item,
// 	selectable,
// 	...props
// }: Props<T>) => {
// 	return item.color === null ? (
// 		<PosBoardEmptyItem
// 			{...props}
// 			item={item}
// 			selectable={selectable}
// 		/>
// 	) : (
// 		<PosBoardActionItem
// 			{...props}
// 			item={item}
// 			selectable={selectable}
// 		/>
// 	);
// };

export const PosBoardItem = {
	Base: PosBoardBaseItem,
	Selectable: PosBoardSelectableItem,
	SelectableEmpty: PosBoardSelectableEmptyItem,
	EmptyItem: PosBoardEmptyItem,
	PaginationItem: PosBoardPaginationItem,
};
