import {
	CashPaymentInfoType,
	GiftCardPaymentInfoType,
	GiftCardProviderTypeLabel,
	has비공식영수증,
	PaymentInfoType,
	PaymentMethodTypeLabel,
} from '@kinderlabs-pos/shared-data-type';
import { getDateTimeFormat, numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { InfoPrintStackRow, InfoPrintStackTitle } from '@kinderlabs-pos/ui-components';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';

export const PaymentDetailContents = ({
	payment,
	Actions,
}: {
	payment: PaymentInfoType;
	Actions?: ReactNode;
}) => {
	// 억지로 타입매핑
	const { giftCardName, giftCardNumber, giftCardProvider } = payment as GiftCardPaymentInfoType;

	const paymentInfoData = [
		{
			label: '결제번호',
			value: `${payment.id}${payment.refunded ? '(환불)' : ''}`,
		},
		{
			label: '결제금액 / 부가세',
			value: `${numberWithCommasAnd원(payment.amount)} / ${numberWithCommasAnd원(payment.tax)}`,
		},
		...(payment.method === 'CASH' && (payment as CashPaymentInfoType).receivedMoney
			? [
					{
						label: '받은 현금',
						value: numberWithCommasAnd원((payment as CashPaymentInfoType).receivedMoney!),
					},
			  ]
			: []),
		{
			label: '결제일자',
			value: payment.created && getDateTimeFormat(payment.created),
		},
		{
			label: '환불일자',
			value: payment.refunded && getDateTimeFormat(payment.refunded),
		},
		...(giftCardName
			? [
					{
						label: '상품권 이름',
						value: giftCardName,
					},
					{
						label: '상품권 번호',
						value: giftCardNumber,
					},
					{
						label: '상품권 공급사',
						value: GiftCardProviderTypeLabel[giftCardProvider],
					},
			  ]
			: []),
	];

	return (
		<Stack
			flex={1}
			justifyContent={'space-between'}>
			<Stack
				flex={1}
				spacing={1}>
				<InfoPrintStackTitle title={`결제 정보`} />
				{paymentInfoData.map((paymentInfo, idx) => {
					return (
						<InfoPrintStackRow
							key={idx}
							value={paymentInfo.value}
							label={paymentInfo.label}
						/>
					);
				})}
				{/* {payment.refundReceipt && <RefundDetail refundReceipt={payment.refundReceipt} />} */}
				<InfoPrintStackTitle title={'영수증 내역'} />
				<ReceiptDetail payment={payment} />
			</Stack>
			{Actions}
		</Stack>
	);
};

const ReceiptDetail = ({ payment }: { payment: PaymentInfoType }) => {
	const { receipt } = payment;
	const isCash = has비공식영수증[payment.method];

	const paymentData = [
		{
			label: '결제금액',
			value: numberWithCommasAnd원(payment.amount),
		},
		{
			label: '결제방법',
			value: PaymentMethodTypeLabel[payment.method],
		},
		...(receipt
			? [
					{
						label: '결제승인일자',
						value: getDateTimeFormat(receipt.approvalDate),
					},
					{
						label: '결제일련번호',
						value: receipt.transactionId,
					},
					{
						label: '결제승인번호',
						value: receipt.approvalId,
					},
					{
						label: isCash ? '전화번호/사업자등록번호' : '카드번호',
						value: receipt.bin,
					},
					{
						label: '할부개월',
						value:
							receipt.installment === 0 || isCash ? '일시불' : `${receipt.installment} 개월 할부`,
					},
					{
						label: '승인기관',
						value: receipt.issuer,
					},
					// {
					// 	label: '매장번호',
					// 	value: receipt.merchantNumber,
					// },
					{
						label: '공급가액 / 세액',
						value: `${numberWithCommasAnd원(receipt.supply)} / ${numberWithCommasAnd원(
							receipt.tax
						)}`,
					},
			  ]
			: []),
		{
			label: '메모',
			value: payment.memo || '',
		},
	];

	return (
		<Stack
			spacing={1}
			overflow={'auto'}>
			{paymentData.map(({ label, value }, idx) => (
				<InfoPrintStackRow
					key={idx}
					label={label}
					value={value}
					font={'body2'}
				/>
			))}
		</Stack>
	);
};

// const RefundDetail = ({ refundReceipt }: { refundReceipt: PaymentInfoType['refundReceipt'] }) => {
// 	if (!refundReceipt) return null;

// 	const refundData = [
// 		// 필요없을듯해서 일단 지움.
// 		// {
// 		// 	label: '환불 승인 번호',
// 		// 	value: refundReceipt.approvalId,
// 		// },
// 		{
// 			label: '환불일자',
// 			value: getDateTimeFormat(refundReceipt.approvalDate),
// 		},
// 	];

// 	return (
// 		<Stack spacing={1}>
// 			{refundData.map(({ label, value }, idx) => (
// 				<InfoPrintStackRow
// 					key={idx}
// 					label={label}
// 					value={value}
// 				/>
// 			))}
// 		</Stack>
// 	);
// };
