import '@fontsource/nanum-gothic';
import { createTheme, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { deepmerge } from '@mui/utils';
import { baseThemeWithFontFamily } from '../base/realBaseTheme';
import { stagingColor } from '../stagingColor';
import { posComponentOverrides } from '../posTheme/posComponentOverrides';
import { waitingTypographyWithFontFamily } from '../posTheme/posTypographyWithFontFamily';

const isStagingMode = process.env['NX_STAGING_MODE'] ? true : false;
const fontFamily = `"Nanum Gothic", sans-serif`;

/**
 * KDS, POS 사용
 */
export const waitingTheme: Theme = createTheme(
	deepmerge(baseThemeWithFontFamily(fontFamily), {
		palette: {
			primary: {
				main: isStagingMode ? stagingColor : '#3f51b5',
			},
			secondary: {
				main: '#009688',
			},
			background: {
				paper: 'white',
				default: grey[200],
			},
		},
		typography: waitingTypographyWithFontFamily(fontFamily),
		components: posComponentOverrides,
	})
);
