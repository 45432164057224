import {
	GuestMemberInfoSearchType,
	GuestMemberInfoType,
	OrderType,
} from '@kinderlabs-pos/shared-data-type';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import SearchIcon from '@mui/icons-material/Search';
import { Button, CircularProgress, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { CurFiveGuestVisitHistoryDialog } from './CurFiveGuestVisitHistoryDialog';

export const UserInfoSearchSection = ({
	handleSearchByKeyword,
	guestMemberInfoActions,
	withoutCurFive = false,
	isLoading = false,
}: {
	handleSearchByKeyword: (keyword: string) => void;
	guestMemberInfoActions?: {
		setGuestMemberInfo: (info: GuestMemberInfoSearchType) => void;
		multipleUserInfoList: GuestMemberInfoType[];
	};
	withoutCurFive?: boolean;
	isLoading?: boolean;
}) => {
	const [keyword, setKeyword] = useState<string>('');

	return (
		<form>
			<Stack
				spacing={1}
				direction={'row'}
				sx={{ alignItems: 'center', width: '100%' }}>
				<TextField
					autoFocus
					value={keyword}
					onChange={(e) => setKeyword(e.currentTarget.value)}
					sx={{ flex: 1 }}
					size='small'
				/>
				<Button
					type={'submit'}
					disabled={keyword === '' || isLoading}
					endIcon={isLoading ? <CircularProgress size={20} /> : <SearchIcon />}
					onClick={(e) => {
						e.preventDefault();
						handleSearchByKeyword(keyword);
					}}
					variant='contained'>
					{'검색'}
				</Button>
				{guestMemberInfoActions && !withoutCurFive && (
					<WithDialog
						dialog={(open, closeDialog) => (
							<CurFiveGuestVisitHistoryDialog
								open={open}
								closeDialog={closeDialog}
								setGuestMemberInfo={(info: OrderType['guestMemberInfo']) => {
									guestMemberInfoActions.setGuestMemberInfo({ info, status: '검색완료' });
									setKeyword(info?.name ?? '');
								}}
							/>
						)}>
						{(openDialog) => (
							<Button
								variant='outlined'
								onClick={openDialog}>
								{'최근 가입'}
							</Button>
						)}
					</WithDialog>
				)}
			</Stack>
		</form>
	);
};
