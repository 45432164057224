import CloseIcon from '@mui/icons-material/Close';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, IconButton, Paper, styled, Typography } from "@mui/material";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import { forwardRef, useCallback, useState, ReactNode } from 'react';
import { NotiStackCard } from ".";
import { snackBarStyle } from '.';
import { usePosRouter } from '../../pages/routes';

interface OrderActionProps extends Omit<CustomContentProps, "message"> {
  orderId: string
}
// eslint-disable-next-line react/display-name
export const OrderAction = forwardRef<HTMLDivElement, OrderActionProps>(({ id, orderId }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  // snackBarStyle 대신 ref 오브젝트로 바꿔보자.
  return (
    <SnackbarContent ref={ref} style={snackBarStyle}>
      <NotiStackCard cardColor={"skyblue"}>
        <ConfirmationNumberIcon sx={{ pr: 1 }} />
        <Typography variant="subtitle2">
          {orderId}
        </Typography>
        <Box sx={{ marginLeft: "auto" }}>
          <Button onClick={() => { }}>
            {"주문조회"}
          </Button>
          <IconButton
            aria-label="Show more"
            sx={{
              p: 1,
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all .2s"
            }}
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </IconButton>
          <IconButton sx={{
            p: 1,
            transform: "rotate(0deg)",
            transition: "all .2s"
          }} onClick={handleDismiss}>
            <CloseIcon />
          </IconButton>
        </Box>
      </NotiStackCard>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Paper sx={{ p: 1 }}>
          <Button onClick={() => { }}>
            {"결제확인"}
          </Button>
          <Button onClick={() => { }}>
            {"결제확인"}
          </Button>
          <Button onClick={() => { }}>
            {"주문확인"}
          </Button>
          <Button onClick={() => {}}>
            {"티켓확인"}
          </Button>
        </Paper>
      </Collapse>
    </SnackbarContent >
  );
});